"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = logout;

var _oauth = _interopRequireDefault(require("./oauth"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function logout() {
  window.location.href = _oauth["default"].getRevokeUrl();
}