"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FIND_GATEWAYS_SUCCESS = exports.FIND_GATEWAYS_REQUEST = exports.FIND_GATEWAYS_ERROR = void 0;
exports.findAllGateways = findAllGateways;
var FIND_GATEWAYS_REQUEST = 'FIND_GATEWAYS_REQUEST';
exports.FIND_GATEWAYS_REQUEST = FIND_GATEWAYS_REQUEST;
var FIND_GATEWAYS_SUCCESS = 'FIND_GATEWAYS_SUCCESS';
exports.FIND_GATEWAYS_SUCCESS = FIND_GATEWAYS_SUCCESS;
var FIND_GATEWAYS_ERROR = 'FIND_GATEWAYS_ERROR';
exports.FIND_GATEWAYS_ERROR = FIND_GATEWAYS_ERROR;

function findAllGateways() {
  return {
    type: FIND_GATEWAYS_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {}
  };
}