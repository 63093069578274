"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_SUCCESS = exports.UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_REQUEST = exports.UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_ERROR = exports.UPDATE_TOUR_FOR_TOUR_BOARD_SUCCESS = exports.UPDATE_TOUR_FOR_TOUR_BOARD_REQUEST = exports.UPDATE_TOUR_FOR_TOUR_BOARD_ERROR = exports.UPDATE_COLLECTED_TOURS_SUCCESS = exports.TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_SUCCESS = exports.TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_REQUEST = exports.TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_ERROR = exports.TERMINATE_TOUR_FOR_TOUR_BOARD_SUCCESS = exports.TERMINATE_TOUR_FOR_TOUR_BOARD_REQUEST = exports.TERMINATE_TOUR_FOR_TOUR_BOARD_ERROR = exports.MANUAL_START_TOUR_FOR_TOUR_BOARD_SUCCESS = exports.MANUAL_START_TOUR_FOR_TOUR_BOARD_REQUEST = exports.MANUAL_START_TOUR_FOR_TOUR_BOARD_ERROR = exports.GET_TOUR_IMPORT_PATTERN_SUCCESS = exports.GET_TOUR_IMPORT_PATTERN_REQUEST = exports.GET_TOUR_IMPORT_PATTERN_ERROR = exports.FIND_TOURS_FOR_TOUR_BOARD_SUCCESS = exports.FIND_TOURS_FOR_TOUR_BOARD_REQUEST = exports.FIND_TOURS_FOR_TOUR_BOARD_ERROR = exports.DELETE_TOUR_FOR_TOUR_BOARD_SUCCESS = exports.DELETE_TOUR_FOR_TOUR_BOARD_REQUEST = exports.DELETE_TOUR_FOR_TOUR_BOARD_ERROR = exports.CREATE_TOUR_FOR_TOUR_BOARD_SUCCESS = exports.CREATE_TOUR_FOR_TOUR_BOARD_REQUEST = exports.CREATE_TOUR_FOR_TOUR_BOARD_ERROR = exports.CREATE_TOUR_BOARD_EXPORT_SUCCESS = exports.CREATE_TOUR_BOARD_EXPORT_REQUEST = exports.CREATE_TOUR_BOARD_EXPORT_ERROR = void 0;
exports.createNewTour = createNewTour;
exports.createTourExport = exports.createTourBoardExport = void 0;
exports.deleteTour = deleteTour;
exports.getTourImportTemplate = void 0;
exports.getToursFromBackend = getToursFromBackend;
exports.startTour = startTour;
exports.terminateTour = terminateTour;
exports.updateTour = updateTour;
exports.updateTourAlarms = void 0;
var CREATE_TOUR_FOR_TOUR_BOARD_SUCCESS = 'CREATE_TOUR_FOR_TOUR_BOARD_SUCCESS';
exports.CREATE_TOUR_FOR_TOUR_BOARD_SUCCESS = CREATE_TOUR_FOR_TOUR_BOARD_SUCCESS;
var CREATE_TOUR_FOR_TOUR_BOARD_REQUEST = 'CREATE_TOUR_FOR_TOUR_BOARD_REQUEST';
exports.CREATE_TOUR_FOR_TOUR_BOARD_REQUEST = CREATE_TOUR_FOR_TOUR_BOARD_REQUEST;
var CREATE_TOUR_FOR_TOUR_BOARD_ERROR = 'CREATE_TOUR_FOR_TOUR_BOARD_ERROR';
exports.CREATE_TOUR_FOR_TOUR_BOARD_ERROR = CREATE_TOUR_FOR_TOUR_BOARD_ERROR;

function createNewTour(tourData, portalId) {
  return {
    type: CREATE_TOUR_FOR_TOUR_BOARD_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      tour: tourData
    }
  };
}

var UPDATE_TOUR_FOR_TOUR_BOARD_REQUEST = 'UPDATE_TOUR_FOR_TOUR_BOARD_REQUEST';
exports.UPDATE_TOUR_FOR_TOUR_BOARD_REQUEST = UPDATE_TOUR_FOR_TOUR_BOARD_REQUEST;
var UPDATE_TOUR_FOR_TOUR_BOARD_SUCCESS = 'UPDATE_TOUR_FOR_TOUR_BOARD_SUCCESS';
exports.UPDATE_TOUR_FOR_TOUR_BOARD_SUCCESS = UPDATE_TOUR_FOR_TOUR_BOARD_SUCCESS;
var UPDATE_TOUR_FOR_TOUR_BOARD_ERROR = 'UPDATE_TOUR_FOR_TOUR_BOARD_ERROR';
exports.UPDATE_TOUR_FOR_TOUR_BOARD_ERROR = UPDATE_TOUR_FOR_TOUR_BOARD_ERROR;

function updateTour(tourData, portalId) {
  return {
    type: UPDATE_TOUR_FOR_TOUR_BOARD_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      tour: tourData
    }
  };
}

var UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_REQUEST = 'UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_REQUEST';
exports.UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_REQUEST = UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_REQUEST;
var UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_SUCCESS = 'UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_SUCCESS';
exports.UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_SUCCESS = UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_SUCCESS;
var UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_ERROR = 'UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_ERROR';
exports.UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_ERROR = UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_ERROR;

var updateTourAlarms = function updateTourAlarms(id, alarms, segments, portalId) {
  return {
    type: UPDATE_TOUR_SEGMENTS_FOR_TOUR_BOARD_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      tour: {
        id: id,
        alarms: alarms,
        segments: segments
      }
    }
  };
};

exports.updateTourAlarms = updateTourAlarms;
var DELETE_TOUR_FOR_TOUR_BOARD_REQUEST = 'DELETE_TOUR_FOR_TOUR_BOARD_REQUEST';
exports.DELETE_TOUR_FOR_TOUR_BOARD_REQUEST = DELETE_TOUR_FOR_TOUR_BOARD_REQUEST;
var DELETE_TOUR_FOR_TOUR_BOARD_SUCCESS = 'DELETE_TOUR_FOR_TOUR_BOARD_SUCCESS';
exports.DELETE_TOUR_FOR_TOUR_BOARD_SUCCESS = DELETE_TOUR_FOR_TOUR_BOARD_SUCCESS;
var DELETE_TOUR_FOR_TOUR_BOARD_ERROR = 'DELETE_TOUR_FOR_TOUR_BOARD_ERROR';
exports.DELETE_TOUR_FOR_TOUR_BOARD_ERROR = DELETE_TOUR_FOR_TOUR_BOARD_ERROR;

function deleteTour(id, portalId) {
  return {
    type: DELETE_TOUR_FOR_TOUR_BOARD_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      tour: {
        id: id
      }
    }
  };
}

var FIND_TOURS_FOR_TOUR_BOARD_REQUEST = 'FIND_TOURS_FOR_TOUR_BOARD_REQUEST';
exports.FIND_TOURS_FOR_TOUR_BOARD_REQUEST = FIND_TOURS_FOR_TOUR_BOARD_REQUEST;
var FIND_TOURS_FOR_TOUR_BOARD_ERROR = 'FIND_TOURS_FOR_TOUR_BOARD_ERROR';
exports.FIND_TOURS_FOR_TOUR_BOARD_ERROR = FIND_TOURS_FOR_TOUR_BOARD_ERROR;
var FIND_TOURS_FOR_TOUR_BOARD_SUCCESS = 'FIND_TOURS_FOR_TOUR_BOARD_SUCCESS';
exports.FIND_TOURS_FOR_TOUR_BOARD_SUCCESS = FIND_TOURS_FOR_TOUR_BOARD_SUCCESS;

function getToursFromBackend(portalId) {
  var dynamicFilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var dynamicPaging = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var dynamicSorters = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var action = {
    type: FIND_TOURS_FOR_TOUR_BOARD_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      dynamicFilters: dynamicFilters,
      dynamicPaging: dynamicPaging,
      dynamicSorters: dynamicSorters
    }
  };
  return action;
} // The action used in the tour throttle middleware, to pass on an array of tours from the backend


var UPDATE_COLLECTED_TOURS_SUCCESS = 'UPDATE_COLLECTED_TOURS_SUCCESS';
exports.UPDATE_COLLECTED_TOURS_SUCCESS = UPDATE_COLLECTED_TOURS_SUCCESS;
var TERMINATE_TOUR_FOR_TOUR_BOARD_REQUEST = 'TERMINATE_TOUR_FOR_TOUR_BOARD_REQUEST';
exports.TERMINATE_TOUR_FOR_TOUR_BOARD_REQUEST = TERMINATE_TOUR_FOR_TOUR_BOARD_REQUEST;
var TERMINATE_TOUR_FOR_TOUR_BOARD_SUCCESS = 'TERMINATE_TOUR_FOR_TOUR_BOARD_SUCCESS';
exports.TERMINATE_TOUR_FOR_TOUR_BOARD_SUCCESS = TERMINATE_TOUR_FOR_TOUR_BOARD_SUCCESS;
var TERMINATE_TOUR_FOR_TOUR_BOARD_ERROR = 'TERMINATE_TOUR_FOR_TOUR_BOARD_ERROR';
exports.TERMINATE_TOUR_FOR_TOUR_BOARD_ERROR = TERMINATE_TOUR_FOR_TOUR_BOARD_ERROR;

function terminateTour(id, portalId) {
  return {
    type: TERMINATE_TOUR_FOR_TOUR_BOARD_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      tour: {
        id: id
      }
    }
  };
}

var MANUAL_START_TOUR_FOR_TOUR_BOARD_REQUEST = 'MANUAL_START_TOUR_FOR_TOUR_BOARD_REQUEST';
exports.MANUAL_START_TOUR_FOR_TOUR_BOARD_REQUEST = MANUAL_START_TOUR_FOR_TOUR_BOARD_REQUEST;
var MANUAL_START_TOUR_FOR_TOUR_BOARD_SUCCESS = 'MANUAL_START_TOUR_FOR_TOUR_BOARD_SUCCESS';
exports.MANUAL_START_TOUR_FOR_TOUR_BOARD_SUCCESS = MANUAL_START_TOUR_FOR_TOUR_BOARD_SUCCESS;
var MANUAL_START_TOUR_FOR_TOUR_BOARD_ERROR = 'MANUAL_START_TOUR_FOR_TOUR_BOARD_ERROR';
exports.MANUAL_START_TOUR_FOR_TOUR_BOARD_ERROR = MANUAL_START_TOUR_FOR_TOUR_BOARD_ERROR;

function startTour(id, portalId) {
  return {
    type: MANUAL_START_TOUR_FOR_TOUR_BOARD_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      tour: {
        id: id
      }
    }
  };
}

var TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_REQUEST = 'TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_REQUEST';
exports.TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_REQUEST = TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_REQUEST;
var TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_SUCCESS = 'TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_SUCCESS';
exports.TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_SUCCESS = TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_SUCCESS;
var TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_ERROR = 'TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_ERROR';
exports.TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_ERROR = TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_ERROR;

var createTourExport = function createTourExport(portalId, tour) {
  return {
    type: TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      tour: tour
    }
  };
};

exports.createTourExport = createTourExport;
var CREATE_TOUR_BOARD_EXPORT_REQUEST = 'CREATE_TOUR_BOARD_EXPORT_REQUEST';
exports.CREATE_TOUR_BOARD_EXPORT_REQUEST = CREATE_TOUR_BOARD_EXPORT_REQUEST;
var CREATE_TOUR_BOARD_EXPORT_SUCCESS = 'CREATE_TOUR_BOARD_EXPORT_SUCCESS';
exports.CREATE_TOUR_BOARD_EXPORT_SUCCESS = CREATE_TOUR_BOARD_EXPORT_SUCCESS;
var CREATE_TOUR_BOARD_EXPORT_ERROR = 'CREATE_TOUR_BOARD_EXPORT_ERROR';
exports.CREATE_TOUR_BOARD_EXPORT_ERROR = CREATE_TOUR_BOARD_EXPORT_ERROR;

var createTourBoardExport = function createTourBoardExport(portalId) {
  var dynamicFilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var dynamicSorters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var dynamicPaging = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var visibleColumns = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
  return {
    type: CREATE_TOUR_BOARD_EXPORT_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      filters: {
        dynamicFilters: dynamicFilters,
        dynamicSorters: dynamicSorters,
        dynamicPaging: dynamicPaging
      },
      visibleColumns: visibleColumns
    }
  };
};

exports.createTourBoardExport = createTourBoardExport;
var GET_TOUR_IMPORT_PATTERN_REQUEST = 'GET_TOUR_IMPORT_PATTERN_REQUEST';
exports.GET_TOUR_IMPORT_PATTERN_REQUEST = GET_TOUR_IMPORT_PATTERN_REQUEST;
var GET_TOUR_IMPORT_PATTERN_SUCCESS = 'GET_TOUR_IMPORT_PATTERN_SUCCESS';
exports.GET_TOUR_IMPORT_PATTERN_SUCCESS = GET_TOUR_IMPORT_PATTERN_SUCCESS;
var GET_TOUR_IMPORT_PATTERN_ERROR = 'GET_TOUR_IMPORT_PATTERN_ERROR';
exports.GET_TOUR_IMPORT_PATTERN_ERROR = GET_TOUR_IMPORT_PATTERN_ERROR;

var getTourImportTemplate = function getTourImportTemplate(portalId) {
  return {
    type: GET_TOUR_IMPORT_PATTERN_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    }
  };
};

exports.getTourImportTemplate = getTourImportTemplate;