"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeStatusFilterFunction = void 0;

var _types = require("@nicbase/types");

var _combineFunctionsAndNodes = require("../combineFunctionsAndNodes");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var EbsErrorCodes;

(function (EbsErrorCodes) {
  EbsErrorCodes["DtcAlarm"] = "DTC_ALARM";
  EbsErrorCodes["DtcCaution"] = "DTC_CAUTION";
  EbsErrorCodes["DtcNotification"] = "DTC_NOTIFICATION";
})(EbsErrorCodes || (EbsErrorCodes = {}));

var validSimpleStatusTypes = [_types.LatestDataSimpleStatusType.NoGps, _types.LatestDataSimpleStatusType.Coupled, _types.LatestDataSimpleStatusType.NotCoupled, _types.LatestDataSimpleStatusType.Driving, _types.LatestDataSimpleStatusType.Standing, _types.LatestDataSimpleStatusType.Loaded, _types.LatestDataSimpleStatusType.NotLoaded, _types.LatestDataSimpleStatusType.Door1Open, _types.LatestDataSimpleStatusType.Door1Closed, _types.LatestDataSimpleStatusType.Door2Open, _types.LatestDataSimpleStatusType.Door2Closed, _types.LatestDataSimpleStatusType.Door3Open, _types.LatestDataSimpleStatusType.Door3Closed, _types.LatestDataSimpleStatusType.ReeferOn, _types.LatestDataSimpleStatusType.ReeferOff, _types.LatestDataSimpleStatusType.NoFuelReserve, _types.LatestDataSimpleStatusType.FuelReserve];
var validRedReeferCodes = [_types.LatestDataErrorCodeStatusType.CarrierAlert, _types.LatestDataErrorCodeStatusType.CbtAlert, _types.LatestDataErrorCodeStatusType.TkAlert];
var validYellowReeferCodes = [_types.LatestDataErrorCodeStatusType.CarrierCaution, _types.LatestDataErrorCodeStatusType.CbtCaution, _types.LatestDataErrorCodeStatusType.TkCaution];
var validGreenReeferCodes = [_types.LatestDataErrorCodeStatusType.CarrierNotification, _types.LatestDataErrorCodeStatusType.CbtNotification, _types.LatestDataErrorCodeStatusType.TkNotification, _types.LatestDataErrorCodeStatusType.CarrierInformation, _types.LatestDataErrorCodeStatusType.CbtInformation, _types.LatestDataErrorCodeStatusType.TkInformation];
var validEbsErrorCodes = Object.values(EbsErrorCodes); // create equal filter for every valid selected status type

var createStatusFilterFunction = function createStatusFilterFunction(selectedStatusTypes, validStatusTypes, filterName) {
  return selectedStatusTypes.filter(function (statusType) {
    return validStatusTypes.includes(statusType);
  }).map(function (statusType) {
    return (0, _combineFunctionsAndNodes.equal)(filterName, statusType, _types.FilterAttributeType.String);
  });
};

var makeStatusFilterFunction = function makeStatusFilterFunction(filterName, status) {
  var selectedStatusTypes = Object.keys(status !== null && status !== void 0 ? status : {}).filter(function (key) {
    return status[key];
  });
  return _combineFunctionsAndNodes.and.apply(void 0, _toConsumableArray(createStatusFilterFunction(selectedStatusTypes, validSimpleStatusTypes, filterName)).concat([_combineFunctionsAndNodes.or.apply(void 0, _toConsumableArray(createStatusFilterFunction(selectedStatusTypes, validRedReeferCodes, filterName))), _combineFunctionsAndNodes.or.apply(void 0, _toConsumableArray(createStatusFilterFunction(selectedStatusTypes, validYellowReeferCodes, filterName))), _combineFunctionsAndNodes.or.apply(void 0, _toConsumableArray(createStatusFilterFunction(selectedStatusTypes, validGreenReeferCodes, filterName))), _combineFunctionsAndNodes.or.apply(void 0, _toConsumableArray(createStatusFilterFunction(selectedStatusTypes, validEbsErrorCodes, filterName)))]));
};

exports.makeStatusFilterFunction = makeStatusFilterFunction;