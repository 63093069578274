"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.relativeFormatPropTypes = exports.pluralFormatPropTypes = exports.numberFormatPropTypes = exports.messageDescriptorPropTypes = exports.intlShape = exports.intlFormatPropTypes = exports.intlConfigPropTypes = exports.dateTimeFormatPropTypes = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var bool = _propTypes["default"].bool,
    number = _propTypes["default"].number,
    string = _propTypes["default"].string,
    func = _propTypes["default"].func,
    object = _propTypes["default"].object,
    oneOf = _propTypes["default"].oneOf,
    shape = _propTypes["default"].shape,
    any = _propTypes["default"].any,
    oneOfType = _propTypes["default"].oneOfType;
var localeMatcher = oneOf(['best fit', 'lookup']);
var narrowShortLong = oneOf(['narrow', 'short', 'long']);
var numeric2digit = oneOf(['numeric', '2-digit']);
var funcReq = func.isRequired;
var intlConfigPropTypes = {
  locale: string,
  timeZone: string,
  formats: object,
  messages: object,
  textComponent: any,
  defaultLocale: string,
  defaultFormats: object,
  onError: func
};
exports.intlConfigPropTypes = intlConfigPropTypes;
var intlFormatPropTypes = {
  formatDate: funcReq,
  formatTime: funcReq,
  formatRelativeTime: funcReq,
  formatNumber: funcReq,
  formatPlural: funcReq,
  formatMessage: funcReq
};
exports.intlFormatPropTypes = intlFormatPropTypes;
var intlShape = shape(_objectSpread(_objectSpread({}, intlConfigPropTypes), intlFormatPropTypes));
exports.intlShape = intlShape;
var messageDescriptorPropTypes = {
  id: string.isRequired,
  description: oneOfType([string, object]),
  defaultMessage: string
};
exports.messageDescriptorPropTypes = messageDescriptorPropTypes;
var dateTimeFormatPropTypes = {
  localeMatcher: localeMatcher,
  formatMatcher: oneOf(['basic', 'best fit']),
  timeZone: string,
  hour12: bool,
  weekday: narrowShortLong,
  era: narrowShortLong,
  year: numeric2digit,
  month: oneOf(['numeric', '2-digit', 'narrow', 'short', 'long']),
  day: numeric2digit,
  hour: numeric2digit,
  minute: numeric2digit,
  second: numeric2digit,
  timeZoneName: oneOf(['short', 'long'])
};
exports.dateTimeFormatPropTypes = dateTimeFormatPropTypes;
var numberFormatPropTypes = {
  localeMatcher: localeMatcher,
  style: oneOf(['decimal', 'currency', 'percent']),
  currency: string,
  currencyDisplay: oneOf(['symbol', 'code', 'name']),
  useGrouping: bool,
  minimumIntegerDigits: number,
  minimumFractionDigits: number,
  maximumFractionDigits: number,
  minimumSignificantDigits: number,
  maximumSignificantDigits: number
};
exports.numberFormatPropTypes = numberFormatPropTypes;
var relativeFormatPropTypes = {
  style: oneOf(['best fit', 'numeric']),
  units: oneOf(['second', 'minute', 'hour', 'day', 'month', 'year', 'second-short', 'minute-short', 'hour-short', 'day-short', 'month-short', 'year-short'])
};
exports.relativeFormatPropTypes = relativeFormatPropTypes;
var pluralFormatPropTypes = {
  style: oneOf(['cardinal', 'ordinal'])
};
exports.pluralFormatPropTypes = pluralFormatPropTypes;