"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var rangesForLocale = function rangesForLocale(locale, timezone) {
  var now = _momentTimezone["default"].tz((0, _momentTimezone["default"])().locale(locale), timezone);

  return {
    today: {
      label: 'datepicker.rangeTabs.today',
      range: [now.clone().startOf('day'), now.clone().endOf('day')]
    },
    last7days: {
      label: 'datepicker.rangeTabs.lastSevenDays',
      range: [now.clone().subtract(168, 'hour'), now.clone()]
    },
    sinceMonthStart: {
      label: 'datepicker.rangeTabs.sinceBeginningMonth',
      range: [now.clone().startOf('month'), now.clone().endOf('day')]
    },
    lastWeek: {
      label: 'datepicker.rangeTabs.lastWeek',
      range: [now.clone().subtract(1, 'week').startOf('week'), now.clone().subtract(1, 'week').endOf('week')]
    },
    lastMonth: {
      label: 'datepicker.rangeTabs.lastMonth',
      range: [now.clone().subtract(1, 'month').startOf('month'), now.clone().subtract(1, 'month').endOf('month')]
    }
  };
};

var _default = rangesForLocale;
exports["default"] = _default;