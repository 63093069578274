"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FIND_GATEWAYS_ERROR", {
  enumerable: true,
  get: function get() {
    return _gateways2.FIND_GATEWAYS_ERROR;
  }
});
Object.defineProperty(exports, "FIND_GATEWAYS_REQUEST", {
  enumerable: true,
  get: function get() {
    return _gateways2.FIND_GATEWAYS_REQUEST;
  }
});
Object.defineProperty(exports, "FIND_GATEWAYS_SUCCESS", {
  enumerable: true,
  get: function get() {
    return _gateways2.FIND_GATEWAYS_SUCCESS;
  }
});
exports["default"] = void 0;
Object.defineProperty(exports, "findAllGateways", {
  enumerable: true,
  get: function get() {
    return _gateways2.findAllGateways;
  }
});

var _gateways = _interopRequireDefault(require("./gateways.reducer"));

var _gateways2 = require("./gateways.actions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = _gateways["default"];
exports["default"] = _default;