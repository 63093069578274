import { addTranslations, FIND_ALL_TRANSLATIONS_REQUEST, FIND_ALL_TRANSLATIONS_SUCCESS } from 'nicbase-reducers';

const LOCAL_STORAGE_TRANSLATION_CACHE_KEY = 'nb-translations';

const getTranslationCache = () => {
  const cachedTranslationsBodyJson = localStorage.getItem(LOCAL_STORAGE_TRANSLATION_CACHE_KEY);
  return cachedTranslationsBodyJson && JSON.parse(cachedTranslationsBodyJson);
};

const translationCachingMiddleware = store => next => action => {
  // when the translations are first requested, load the translations from the cache and add a version number to the request
  // also load the cached translations into redux
  if (action.type === FIND_ALL_TRANSLATIONS_REQUEST) {
    const translationCache = getTranslationCache();

    if (translationCache) {
      const addCachedTranslationsAction = addTranslations(translationCache.language, translationCache.translations);
      store.dispatch(addCachedTranslationsAction);
    }

    // we require a version comparison (by sending the cache versionTimestamp) when:
    // - there are cached translations
    // - AND
    // - (
    //   - there is no language in the request body
    //   - OR
    //   - the cached translations language is the same as the requested language
    // - )
    const isVersionComparisonRequired = translationCache && (!action.body.language || translationCache.language === action.body.language);

    // only add a timestamp when no different language is requested.
    // this will force the backend to answer with all translations even if
    // the timestamp versions would have been identical.
    if (isVersionComparisonRequired) {
      // send the REQUEST action with the cached timestamp to next middleware
      const actionWithCacheVersion = Object.assign({}, action, {
        body: Object.assign({}, action.body, { translationsVersionTimestamp: translationCache.translationsVersionTimestamp }),
      });
      return next(actionWithCacheVersion);
    }
    // do not add time stamp if there is no cache or the language has changed
    return next(action);
  } else if (action.type === FIND_ALL_TRANSLATIONS_SUCCESS) {
    // we need to save the response to the cache WHEN:
    // - There are translations in the response
    const needsCacheUpdate = typeof action.body.translations === 'object' && Object.keys(action.body.translations).length > 0;
    if (needsCacheUpdate) {
      localStorage.setItem(LOCAL_STORAGE_TRANSLATION_CACHE_KEY, JSON.stringify(action.body));
    }
  }

  return next(action);
};

export default translationCachingMiddleware;
