"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _widgetTypes = require("./widgetTypes.actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var widgetTypes = function widgetTypes() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    types: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _widgetTypes.LOAD_WIDGET_TYPES_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          types: action.body.widgetTypeList.reduce(function (result, widgetType) {
            return _objectSpread(_objectSpread({}, result), {}, _defineProperty({}, widgetType.id, _objectSpread(_objectSpread({}, widgetType), {}, {
              type: widgetType.id,
              name: widgetType.typeName
            })));
          }, {})
        });
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return {
        types: {}
      };

    default:
      return state;
  }
};

var _default = widgetTypes;
exports["default"] = _default;