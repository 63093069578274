"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TourWayPointType = exports.TourWayPointStatus = exports.TourStatus = exports.PoiTriggerCondition = exports.EtaProvider = void 0;
var TourStatus;
exports.TourStatus = TourStatus;

(function (TourStatus) {
  TourStatus["Waiting"] = "WAITING";
  TourStatus["Running"] = "RUNNING";
  TourStatus["Finished"] = "FINISHED";
  TourStatus["Deleted"] = "DELETED";
})(TourStatus || (exports.TourStatus = TourStatus = {}));

var TourWayPointType;
exports.TourWayPointType = TourWayPointType;

(function (TourWayPointType) {
  TourWayPointType["Start"] = "START";
  TourWayPointType["Intermediate"] = "INTERMEDIATE";
  TourWayPointType["End"] = "END";
})(TourWayPointType || (exports.TourWayPointType = TourWayPointType = {}));

var TourWayPointStatus;
exports.TourWayPointStatus = TourWayPointStatus;

(function (TourWayPointStatus) {
  TourWayPointStatus["Early"] = "EARLY";
  TourWayPointStatus["InTime"] = "IN_TIME";
  TourWayPointStatus["Late"] = "LATE";
})(TourWayPointStatus || (exports.TourWayPointStatus = TourWayPointStatus = {}));

var PoiTriggerCondition;
exports.PoiTriggerCondition = PoiTriggerCondition;

(function (PoiTriggerCondition) {
  PoiTriggerCondition["PoiEntered"] = "POI_ENTERED";
  PoiTriggerCondition["PoiExited"] = "POI_EXITED";
  PoiTriggerCondition["DoorOpenedInsidePoi"] = "DOOR_OPENED_INSIDE_POI";
  PoiTriggerCondition["DoorClosedInsidePoi"] = "DOOR_CLOSED_INSIDE_POI";
  PoiTriggerCondition["StandingInsidePoi"] = "STANDING_INSIDE_POI";
})(PoiTriggerCondition || (exports.PoiTriggerCondition = PoiTriggerCondition = {}));

var EtaProvider;
exports.EtaProvider = EtaProvider;

(function (EtaProvider) {
  EtaProvider["Kasasi"] = "KASASI";
  EtaProvider["PTV"] = "PTV";
})(EtaProvider || (exports.EtaProvider = EtaProvider = {}));