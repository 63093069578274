"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var MapCenter = {
  US: [-94.58048, 39.101],
  EU: [10.29998, 47.75083]
};
var _default = MapCenter;
exports["default"] = _default;