"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _alarms = require("../alarm-administration/alarms.actions");

var _alarmInformationList = require("./alarm-information-list.actions");

// we only care about the following props in this reducer
var getReducedAlarm = function getReducedAlarm(fullAlarm) {
  return {
    id: fullAlarm.id,
    name: fullAlarm.name
  };
};

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _alarms.CREATE_ALARM_SUCCESS:
      {
        return state.concat(getReducedAlarm(action.body.alarms));
      }

    case _alarms.UPDATE_ALARM_SUCCESS:
      {
        return state.map(function (obj) {
          return obj.id === action.body.alarms.id ? Object.assign({}, obj, getReducedAlarm(action.body.alarms)) : obj;
        });
      }

    case _alarms.DELETE_ALARMS_REQUEST:
    case _alarms.DELETE_ALARMS_SUCCESS:
      {
        return state.filter(function (obj) {
          return !action.body.alarmIds.includes(obj.id);
        });
      }

    case _alarmInformationList.GET_ALARMS_SUCCESS:
      {
        return action.body.alarms;
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
    case _alarmInformationList.GET_ALARMS_ERROR:
      {
        return [];
      }

    default:
      {
        return state;
      }
  }
};

exports["default"] = _default;