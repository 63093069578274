import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import style from './navigation-item.module.scss';

export const NavigationItem = ({ children, to, ...props }) => (
  <NavLink to={to} className={style.navigationItem} {...props}>
    {children}
  </NavLink>
);

NavigationItem.propTypes = {
  children: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
};
NavigationItem.defaultProps = {
  children: null,
};
export default NavigationItem;
