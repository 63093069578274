"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orCondition = exports.andCondition = void 0;

var _types = require("@nicbase/types");

var filterEmptyCondition = function filterEmptyCondition(node) {
  if (node.type === _types.ConditionOperator.AndCondition || node.type === _types.ConditionOperator.OrCondition) {
    return node.filterNodes.length > 0;
  }

  return true;
};

var filterNullNode = function filterNullNode(node) {
  if (node.type === _types.FilterOperator.InFilter || node.type === _types.FilterOperator.NotInFilter) {
    return node.attributeValues !== null;
  }

  if (node.type === _types.FilterOperator.LikeFilter || node.type === _types.FilterOperator.EqualFilter || node.type === _types.FilterOperator.NotEqualFilter || node.type === _types.FilterOperator.CompareFilter) {
    return node.attributeValue.value !== null;
  }

  return true;
};

var andCondition = function andCondition() {
  for (var _len = arguments.length, filterNodes = new Array(_len), _key = 0; _key < _len; _key++) {
    filterNodes[_key] = arguments[_key];
  }

  return {
    type: _types.ConditionOperator.AndCondition,
    filterNodes: filterNodes.filter(function (node) {
      return filterEmptyCondition(node) && filterNullNode(node);
    })
  };
};

exports.andCondition = andCondition;

var orCondition = function orCondition() {
  for (var _len2 = arguments.length, filterNodes = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    filterNodes[_key2] = arguments[_key2];
  }

  return {
    type: _types.ConditionOperator.OrCondition,
    filterNodes: filterNodes.filter(function (node) {
      return filterEmptyCondition(node) && filterNullNode(node);
    })
  };
};

exports.orCondition = orCondition;