"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  DRIVING: 'global.status.DRIVING',
  STANDING: 'global.status.STANDING',
  REEFER_ON: 'global.status.REEFER_ON',
  REEFER_OFF: 'global.status.REEFER_OFF',
  DOOR_1_OPEN: 'global.status.DOOR_1_OPEN',
  DOOR_1_CLOSED: 'global.status.DOOR_1_CLOSED',
  DOOR_2_OPEN: 'global.status.DOOR_2_OPEN',
  DOOR_2_CLOSED: 'global.status.DOOR_2_CLOSED',
  DOOR_3_OPEN: 'global.status.DOOR_3_OPEN',
  DOOR_3_CLOSED: 'global.status.DOOR_3_CLOSED',
  COUPLED: 'global.status.COUPLED',
  NOT_COUPLED: 'global.status.NOT_COUPLED',
  LOADED: 'global.status.LOADED',
  NOT_LOADED: 'global.status.NOT_LOADED',
  FUEL_RESERVE: 'global.status.FUEL_RESERVE',
  NO_FUEL_RESERVE: 'global.status.NO_FUEL_RESERVE',
  NO_GPS: 'global.status.NO_GPS',
  TK_ALERT: 'global.status.TK_ALERT',
  TK_CAUTION: 'global.status.TK_CAUTION',
  TK_NOTIFICATION: 'global.status.TK_NOTIFICATION',
  CBT_ALERT: 'global.status.CBT_ALERT',
  CBT_CAUTION: 'global.status.CBT_CAUTION',
  CBT_NOTIFICATION: 'global.status.CBT_NOTIFICATION',
  CARRIER_ALERT: 'global.status.CARRIER_ALERT',
  CARRIER_CAUTION: 'global.status.CARRIER_CAUTION',
  CARRIER_NOTIFICATION: 'global.status.CARRIER_NOTIFICATION',
  FLAT_SPOT: 'global.status.FLAT_SPOT',
  GREEN_ALARM: 'global.status.GREEN_ALARM',
  YELLOW_ALARM: 'global.status.YELLOW_ALARM',
  RED_ALARM: 'global.status.RED_ALARM',
  EVAPORATOR_1_DEFROST: 'global.status.EVAPORATOR_1_DEFROST',
  PANIC_BUTTON_ACTIVE: 'global.status.PANIC_BUTTON_ACTIVE',
  PANIC_BUTTON_INACTIVE: 'global.status.PANIC_BUTTON_INACTIVE',
  DOOR_LOCK_OPEN: 'global.status.DOOR_LOCK_OPEN',
  DOOR_LOCK_CLOSED: 'global.status.DOOR_LOCK_CLOSED',
  DOOR_LOCK_UNLOCKED: 'global.status.DOOR_LOCK_UNLOCKED',
  DOOR_LOCK_LOCKED: 'global.status.DOOR_LOCK_LOCKED',
  DOOR_LOCK_MODE_SECURE: 'global.status.DOOR_LOCK_MODE_SECURE',
  DOOR_LOCK_MODE_NORMAL: 'global.status.DOOR_LOCK_MODE_NORMAL',
  DTC_NOTIFICATION: 'global.status.DTC_NOTIFICATION',
  DTC_CAUTION: 'global.status.DTC_CAUTION',
  DTC_ALARM: 'global.status.DTC_ALARM'
};
exports["default"] = _default;