"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {};
exports["default"] = void 0;

var _contentBoxAdministration = _interopRequireDefault(require("../home-administration/content-box-administration.reducer"));

var _contentBoxCategoryAdministration = _interopRequireDefault(require("../home-administration/content-box-category-administration.reducer"));

var _impersonation = _interopRequireDefault(require("../impersonation/impersonation.reducer"));

var _layoutAdministration = _interopRequireDefault(require("../layout-administration/layoutAdministration.reducer"));

var _index = require("../permission-administration/index");

var _index2 = _interopRequireDefault(require("../portal-administration/index"));

var _accounting = _interopRequireDefault(require("./accounting/accounting.reducer"));

var _assetTypes = _interopRequireDefault(require("./assetTypes/assetTypes.reducer"));

var _debtors = _interopRequireDefault(require("./debtors/debtors.reducer"));

var _products = _interopRequireDefault(require("./products/products.reducer"));

var _index3 = _interopRequireDefault(require("./users/index"));

var _boxes = require("./boxes");

Object.keys(_boxes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _boxes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _boxes[key];
    }
  });
});

var _gatewayAccounts = require("./gatewayAccounts");

Object.keys(_gatewayAccounts).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _gatewayAccounts[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _gatewayAccounts[key];
    }
  });
});

var _permissions = require("./permissions");

Object.keys(_permissions).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _permissions[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _permissions[key];
    }
  });
});

var _portals = require("./portals");

Object.keys(_portals).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _portals[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _portals[key];
    }
  });
});

var _debtors2 = require("./debtors");

Object.keys(_debtors2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _debtors2[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _debtors2[key];
    }
  });
});

var _users = require("./users");

Object.keys(_users).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _users[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _users[key];
    }
  });
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var adminReducer = {
  assetTypes: _assetTypes["default"],
  products: _products["default"],
  portal: _index2["default"],
  permissionAdministration: _index.permissionAdministrationReducer,
  impersonation: _impersonation["default"],
  layouts: _layoutAdministration["default"],
  contentBoxes: _contentBoxAdministration["default"],
  contentBoxCategories: _contentBoxCategoryAdministration["default"],
  debtors: _debtors["default"],
  portals: _portals.portalsReducer,
  boxes: _boxes.boxesReducer,
  permissions: _permissions.permissionsReducer,
  user: _index3["default"],
  gatewayAccounts: _gatewayAccounts.gatewayAccountsReducer,
  accounting: _accounting["default"]
};
var _default = adminReducer;
exports["default"] = _default;