"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  degC: '°C',
  degF: '°F',
  kelvin: 'K',
  cm: 'cm',
  inch: 'in',
  foot: 'ft',
  yard: 'yd',
  m: 'm',
  mile: 'mi',
  km: 'km',
  kmh: 'km/h',
  mph: 'mph',
  g: 'g',
  ounce: 'oz',
  lbs: 'lbs',
  kg: 'kg',
  tonne: 't',
  ton: 'tn. sh.',
  gallon: 'gal',
  litre: 'l',
  m3: 'm³',
  cuyd: 'yd³',
  bar: 'bar',
  psi: 'psi',
  percent: '%'
};
exports["default"] = _default;