"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _reactRedux = require("react-redux");

var _authorize = require("./authorize");

var usePermissions = function usePermissions() {
  var scope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'BASE';
  // permissions loaded from Store
  var scopePermissions = (0, _reactRedux.useSelector)(function (state) {
    return state.permissions[scope] || [];
  }); // permission check

  var hasPermissions = (0, _react.useCallback)(function (permissionsToCheck) {
    return (0, _authorize.checkPermissions)(scopePermissions)(permissionsToCheck);
  }, [scopePermissions]);
  return {
    hasPermissions: hasPermissions,
    permissions: scopePermissions
  };
};

var _default = usePermissions;
exports["default"] = _default;