"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.notEqualNode = exports.likeNode = exports.lessThanNode = exports.lessThanEqualNode = exports.isNullNode = exports.isNotNullNode = exports.isNotInNode = exports.isInNode = exports.greaterThanNode = exports.greaterThanEqualNode = exports.equalNode = void 0;

var _types = require("@nicbase/types");

var _getAttributeValue = _interopRequireDefault(require("./getAttributeValue"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// filter nodes
var equalNode = function equalNode(attributeName, attributeValue, type) {
  return {
    type: _types.FilterOperator.EqualFilter,
    attributeName: attributeName,
    attributeValue: (0, _getAttributeValue["default"])(type, attributeValue)
  };
};

exports.equalNode = equalNode;

var notEqualNode = function notEqualNode(attributeName, attributeValue, type) {
  return {
    type: _types.FilterOperator.NotEqualFilter,
    attributeName: attributeName,
    attributeValue: (0, _getAttributeValue["default"])(type, attributeValue)
  };
};

exports.notEqualNode = notEqualNode;

var likeNode = function likeNode(attributeName, attributeValue, type) {
  return {
    type: _types.FilterOperator.LikeFilter,
    attributeName: attributeName,
    attributeValue: (0, _getAttributeValue["default"])(type, attributeValue)
  };
};

exports.likeNode = likeNode;

var greaterThanNode = function greaterThanNode(attributeName, attributeValue, type) {
  return {
    type: _types.FilterOperator.CompareFilter,
    compareOperator: _types.CompareOperator.Gt,
    attributeName: attributeName,
    attributeValue: (0, _getAttributeValue["default"])(type, attributeValue)
  };
};

exports.greaterThanNode = greaterThanNode;

var greaterThanEqualNode = function greaterThanEqualNode(attributeName, attributeValue, type) {
  return {
    type: _types.FilterOperator.CompareFilter,
    compareOperator: _types.CompareOperator.Gte,
    attributeName: attributeName,
    attributeValue: (0, _getAttributeValue["default"])(type, attributeValue)
  };
};

exports.greaterThanEqualNode = greaterThanEqualNode;

var lessThanNode = function lessThanNode(attributeName, attributeValue, type) {
  return {
    type: _types.FilterOperator.CompareFilter,
    compareOperator: _types.CompareOperator.Lt,
    attributeName: attributeName,
    attributeValue: (0, _getAttributeValue["default"])(type, attributeValue)
  };
};

exports.lessThanNode = lessThanNode;

var lessThanEqualNode = function lessThanEqualNode(attributeName, attributeValue, type) {
  return {
    type: _types.FilterOperator.CompareFilter,
    compareOperator: _types.CompareOperator.Lte,
    attributeName: attributeName,
    attributeValue: (0, _getAttributeValue["default"])(type, attributeValue)
  };
};

exports.lessThanEqualNode = lessThanEqualNode;

var isNullNode = function isNullNode(attributeName) {
  return {
    type: _types.FilterOperator.NullFilter,
    attributeName: attributeName,
    shouldBeNull: true
  };
};

exports.isNullNode = isNullNode;

var isNotNullNode = function isNotNullNode(attributeName) {
  return {
    type: _types.FilterOperator.NullFilter,
    attributeName: attributeName,
    shouldBeNull: false
  };
};

exports.isNotNullNode = isNotNullNode;

var isInNode = function isInNode(attributeName, attributeValues, type) {
  var _attributeValues$map;

  return {
    type: _types.FilterOperator.InFilter,
    attributeName: attributeName,
    attributeValues: (_attributeValues$map = attributeValues === null || attributeValues === void 0 ? void 0 : attributeValues.map(function (value) {
      return (0, _getAttributeValue["default"])(type, value);
    })) !== null && _attributeValues$map !== void 0 ? _attributeValues$map : null
  };
};

exports.isInNode = isInNode;

var isNotInNode = function isNotInNode(attributeName, attributeValues, type) {
  var _attributeValues$map2;

  return {
    type: _types.FilterOperator.NotInFilter,
    attributeName: attributeName,
    attributeValues: (_attributeValues$map2 = attributeValues === null || attributeValues === void 0 ? void 0 : attributeValues.map(function (value) {
      return (0, _getAttributeValue["default"])(type, value);
    })) !== null && _attributeValues$map2 !== void 0 ? _attributeValues$map2 : null
  };
};

exports.isNotInNode = isNotInNode;