"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPortalContacts = exports.getContactsB2B = exports["default"] = void 0;

var _reselect = require("reselect");

var _portals = require("./portals.actions");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var contactsById = function contactsById() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _portals.GET_PORTAL_CONTACTS_SUCCESS:
      {
        var _action$body$contacts = action.body.contacts,
            contacts = _action$body$contacts === void 0 ? [] : _action$body$contacts;
        var newState = contacts.reduce(function (result, contact) {
          var existingContact = state[contact.transactionCode];
          result[contact.transactionCode] = _objectSpread(_objectSpread({}, existingContact), contact);
          return result;
        }, {});
        return newState;
      }

    case _portals.CHANGE_PORTAL_CONTACT_STATUS_SUCCESS:
    case _portals.SEND_PORTAL_CONNECT_SUCCESS:
      {
        var contact = action.body.contact;
        var transactionCode = contact.transactionCode;
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, transactionCode, _objectSpread({}, contact)));
      }

    case _portals.CHANGE_ACTIVE_PORTAL:
      {
        return {};
      }

    default:
      return state;
  }
};

var allContacts = function allContacts() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _portals.GET_PORTAL_CONTACTS_SUCCESS:
      {
        var _action$body$contacts2 = action.body.contacts,
            contacts = _action$body$contacts2 === void 0 ? [] : _action$body$contacts2;
        return contacts.reduce(function (result, contact) {
          if (!state.some(function (id) {
            return id === contact.transactionCode;
          })) {
            result.push(contact.transactionCode);
          }

          return result;
        }, []);
      }

    case _portals.CHANGE_PORTAL_CONTACT_STATUS_SUCCESS:
    case _portals.SEND_PORTAL_CONNECT_SUCCESS:
      {
        var body = action.body;
        var contact = body.contact;
        var transactionCode = contact.transactionCode;

        if (!state.some(function (id) {
          return id === transactionCode;
        })) {
          return [].concat(_toConsumableArray(state), [transactionCode]);
        }

        return state;
      }

    case _portals.CHANGE_ACTIVE_PORTAL:
      {
        return [];
      }

    default:
      return state;
  }
};

var errorReducer = function errorReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _portals.SEND_PORTAL_CONNECT_ERROR:
      {
        var _action$body = action.body,
            body = _action$body === void 0 ? {} : _action$body;
        var _body$exceptionName = body.exceptionName,
            exceptionName = _body$exceptionName === void 0 ? '' : _body$exceptionName;

        if (exceptionName === 'PortalAlreadyConnectedException') {
          return _objectSpread(_objectSpread({}, state), {}, {
            portalAlreadyConnected: true
          });
        }

        return state;
      }

    case _portals.SEND_PORTAL_CONNECT_RESET:
      {
        var _action$body2 = action.body,
            _body = _action$body2 === void 0 ? {} : _action$body2;

        var _body$portalAlreadyCo = _body.portalAlreadyConnected,
            portalAlreadyConnected = _body$portalAlreadyCo === void 0 ? false : _body$portalAlreadyCo;
        return _objectSpread(_objectSpread({}, state), {}, {
          portalAlreadyConnected: portalAlreadyConnected
        });
      }

    case _portals.CHANGE_ACTIVE_PORTAL:
      {
        return {};
      }

    default:
      return state;
  }
}; // SELECTORS


var getPortalContacts = (0, _reselect.createSelector)([function (state) {
  return state.portal.connectedPortals.allIds;
}, function (state) {
  return state.portal.connectedPortals.byId;
}], function (allIds, contacts) {
  return allIds.map(function (id) {
    return contacts[id];
  });
});
exports.getPortalContacts = getPortalContacts;
var getContactsB2B = (0, _reselect.createSelector)([getPortalContacts], function (contacts) {
  return contacts === null || contacts === void 0 ? void 0 : contacts.map(function (contact) {
    var _ref = contact !== null && contact !== void 0 ? contact : {},
        _ref$transactionCode = _ref.transactionCode,
        transactionCode = _ref$transactionCode === void 0 ? null : _ref$transactionCode,
        name = _ref.name,
        inCurrentStateSince = _ref.inCurrentStateSince,
        status = _ref.status,
        companyInformation = _ref.companyInformation,
        portalId = _ref.portalId;

    return {
      id: transactionCode,
      name: name,
      inCurrentStateSince: inCurrentStateSince,
      status: status,
      companyInformation: companyInformation,
      portalId: portalId
    };
  });
});
exports.getContactsB2B = getContactsB2B;
var _default = {
  byId: contactsById,
  allIds: allContacts,
  hasErrors: errorReducer
};
exports["default"] = _default;