"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _workspaces = require("../workspaces/workspaces.actions");

var _defaultWidget = _interopRequireDefault(require("./defaultWidget.reducer"));

var _widgets = require("./widgets.actions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var findWorkspaceWidgets = function findWorkspaceWidgets(state) {
  return function (widgetId) {
    var workspaceId = state[widgetId].workspaceId;
    return Object.keys(state).map(function (id) {
      return state[id];
    }).filter(function (w) {
      return w.workspaceId === workspaceId;
    });
  };
};

var _default = function _default(state, action) {
  switch (action.type) {
    case _widgets.WORKSPACE_CONNECT_EVENT:
      {
        var widgetId = action.headers.widgetId;
        var connectProperties = action.body.connectProperties;
        var newState = findWorkspaceWidgets(state)(widgetId).reduce(function (result, widget) {
          var id = widget.id,
              connected = widget.connected,
              existingProps = widget.connectProperties;

          if (connected || widgetId === id) {
            var _ref = existingProps !== null && existingProps !== void 0 ? existingProps : {
              outgoing: {},
              incoming: {}
            },
                existingIncoming = _ref.incoming,
                existingOutgoing = _ref.outgoing;

            if (widgetId === id) {
              var outgoing = _objectSpread(_objectSpread({}, existingOutgoing), connectProperties);

              var incoming = Object.entries(existingIncoming).reduce(function (newProps, _ref2) {
                var _ref3 = _slicedToArray(_ref2, 2),
                    existingProp = _ref3[0],
                    value = _ref3[1];

                if (!Object.prototype.hasOwnProperty.call(outgoing, existingProp)) {
                  newProps[existingProp] = value;
                }

                return newProps;
              }, {}); // outgoing connect properties for widget that sent event

              result[id] = Object.assign({}, widget, {
                connectProperties: _objectSpread(_objectSpread(_objectSpread({}, outgoing), incoming), {}, {
                  outgoing: outgoing,
                  incoming: incoming
                })
              });
            } else {
              var _incoming = _objectSpread(_objectSpread({}, existingIncoming), connectProperties);

              var _outgoing = Object.entries(existingOutgoing).reduce(function (newProps, _ref4) {
                var _ref5 = _slicedToArray(_ref4, 2),
                    existingProp = _ref5[0],
                    value = _ref5[1];

                if (!Object.prototype.hasOwnProperty.call(_incoming, existingProp)) {
                  newProps[existingProp] = value;
                }

                return newProps;
              }, {}); // incoming connect properties for all other widgets on workspace


              result[id] = Object.assign({}, widget, {
                connectProperties: _objectSpread(_objectSpread(_objectSpread({}, _outgoing), _incoming), {}, {
                  outgoing: _outgoing,
                  incoming: _incoming
                })
              });
            }
          } else {
            // nothing to change
            result[id] = widget;
          }

          return result;
        }, {});
        return Object.assign({}, state, newState);
      }

    case _workspaces.REMOVE_WORKSPACE_SUCCESS:
      {
        var workspaceId = action.body.workspace.id;
        return Object.keys(state).filter(function (widgetId) {
          return state[widgetId].workspaceId !== workspaceId;
        }).map(function (widgetId) {
          return state[widgetId];
        }).reduce(function (result, widget) {
          // eslint-disable-next-line no-param-reassign
          result[widget.id] = widget;
          return result;
        }, {});
      }

    default:
      return (0, _defaultWidget["default"])(state, action);
  }
};

exports["default"] = _default;