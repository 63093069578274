"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_USER_PROFILE_SUCCESS = exports.UPDATE_USER_PROFILE_REQUEST = exports.UPDATE_USER_PROFILE_ERROR = exports.FIND_USER_PROFILE_SUCCESS = exports.FIND_USER_PROFILE_REQUEST = exports.FIND_USER_PROFILE_ERROR = exports.AUTHENTICATE_USER_SUCCESS = exports.AUTHENTICATE_USER_ERROR = void 0;
exports.findUserProfile = findUserProfile;
exports.updateUserProfile = updateUserProfile;
var AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
exports.AUTHENTICATE_USER_SUCCESS = AUTHENTICATE_USER_SUCCESS;
var AUTHENTICATE_USER_ERROR = 'AUTHENTICATE_USER_ERROR';
exports.AUTHENTICATE_USER_ERROR = AUTHENTICATE_USER_ERROR;
var FIND_USER_PROFILE_REQUEST = 'FIND_USER_PROFILE_REQUEST';
exports.FIND_USER_PROFILE_REQUEST = FIND_USER_PROFILE_REQUEST;
var FIND_USER_PROFILE_SUCCESS = 'FIND_USER_PROFILE_SUCCESS';
exports.FIND_USER_PROFILE_SUCCESS = FIND_USER_PROFILE_SUCCESS;
var FIND_USER_PROFILE_ERROR = 'FIND_USER_PROFILE_ERROR';
exports.FIND_USER_PROFILE_ERROR = FIND_USER_PROFILE_ERROR;

function findUserProfile(id) {
  return {
    type: FIND_USER_PROFILE_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      user: {
        id: id
      }
    }
  };
}

var UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
exports.UPDATE_USER_PROFILE_REQUEST = UPDATE_USER_PROFILE_REQUEST;
var UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
exports.UPDATE_USER_PROFILE_SUCCESS = UPDATE_USER_PROFILE_SUCCESS;
var UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR';
exports.UPDATE_USER_PROFILE_ERROR = UPDATE_USER_PROFILE_ERROR;

function updateUserProfile(user) {
  return {
    type: UPDATE_USER_PROFILE_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      user: user
    }
  };
}