"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CREATE_REPORT_SERIES_ERROR", {
  enumerable: true,
  get: function get() {
    return _reports2.CREATE_REPORT_SERIES_ERROR;
  }
});
Object.defineProperty(exports, "CREATE_REPORT_SERIES_REQUEST", {
  enumerable: true,
  get: function get() {
    return _reports2.CREATE_REPORT_SERIES_REQUEST;
  }
});
Object.defineProperty(exports, "CREATE_REPORT_SERIES_SUCCESS", {
  enumerable: true,
  get: function get() {
    return _reports2.CREATE_REPORT_SERIES_SUCCESS;
  }
});
Object.defineProperty(exports, "DELETE_REPORT_SERIES_ERROR", {
  enumerable: true,
  get: function get() {
    return _reports2.DELETE_REPORT_SERIES_ERROR;
  }
});
Object.defineProperty(exports, "DELETE_REPORT_SERIES_REQUEST", {
  enumerable: true,
  get: function get() {
    return _reports2.DELETE_REPORT_SERIES_REQUEST;
  }
});
Object.defineProperty(exports, "DELETE_REPORT_SERIES_SUCCESS", {
  enumerable: true,
  get: function get() {
    return _reports2.DELETE_REPORT_SERIES_SUCCESS;
  }
});
Object.defineProperty(exports, "FIND_REPORT_SERIES_ERROR", {
  enumerable: true,
  get: function get() {
    return _reports2.FIND_REPORT_SERIES_ERROR;
  }
});
Object.defineProperty(exports, "FIND_REPORT_SERIES_REQUEST", {
  enumerable: true,
  get: function get() {
    return _reports2.FIND_REPORT_SERIES_REQUEST;
  }
});
Object.defineProperty(exports, "FIND_REPORT_SERIES_SUCCESS", {
  enumerable: true,
  get: function get() {
    return _reports2.FIND_REPORT_SERIES_SUCCESS;
  }
});
Object.defineProperty(exports, "UPDATE_REPORT_SERIES_ERROR", {
  enumerable: true,
  get: function get() {
    return _reports2.UPDATE_REPORT_SERIES_ERROR;
  }
});
Object.defineProperty(exports, "UPDATE_REPORT_SERIES_REQUEST", {
  enumerable: true,
  get: function get() {
    return _reports2.UPDATE_REPORT_SERIES_REQUEST;
  }
});
Object.defineProperty(exports, "UPDATE_REPORT_SERIES_SUCCESS", {
  enumerable: true,
  get: function get() {
    return _reports2.UPDATE_REPORT_SERIES_SUCCESS;
  }
});
Object.defineProperty(exports, "createReportSeries", {
  enumerable: true,
  get: function get() {
    return _reports2.createReportSeries;
  }
});
exports["default"] = void 0;
Object.defineProperty(exports, "deleteReportSeries", {
  enumerable: true,
  get: function get() {
    return _reports2.deleteReportSeries;
  }
});
Object.defineProperty(exports, "findReportSeries", {
  enumerable: true,
  get: function get() {
    return _reports2.findReportSeries;
  }
});
Object.defineProperty(exports, "updateReportSeries", {
  enumerable: true,
  get: function get() {
    return _reports2.updateReportSeries;
  }
});

var _reports = _interopRequireDefault(require("./reports.reducer"));

var _reports2 = require("./reports.actions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = {
  series: _reports["default"]
};
exports["default"] = _default;