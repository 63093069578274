"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var CountryCodes = {
  US: 'country.us',
  CA: 'country.ca',
  AD: 'country.ad',
  AL: 'country.al',
  AM: 'country.am',
  AZ: 'country.az',
  AT: 'country.at',
  BA: 'country.ba',
  BE: 'country.be',
  BG: 'country.bg',
  BY: 'country.by',
  CH: 'country.ch',
  CY: 'country.cy',
  CZ: 'country.cz',
  DK: 'country.dk',
  DE: 'country.de',
  DZ: 'country.dz',
  EE: 'country.ee',
  EG: 'country.eg',
  ES: 'country.es',
  FO: 'country.fo',
  FI: 'country.fi',
  FR: 'country.fr',
  GB: 'country.gb',
  GE: 'country.ge',
  GI: 'country.gi',
  GR: 'country.gr',
  HR: 'country.hr',
  HU: 'country.hu',
  IE: 'country.ie',
  IL: 'country.il',
  IS: 'country.is',
  IT: 'country.it',
  JO: 'country.jo',
  LB: 'country.lb',
  LI: 'country.li',
  LT: 'country.lt',
  LU: 'country.lu',
  LV: 'country.lv',
  LY: 'country.ly',
  MA: 'country.ma',
  MC: 'country.mc',
  MD: 'country.md',
  ME: 'country.me',
  MK: 'country.mk',
  MT: 'country.mt',
  NL: 'country.nl',
  NO: 'country.no',
  PL: 'country.pl',
  PS: 'country.ps',
  PT: 'country.pt',
  RO: 'country.ro',
  RS: 'country.rs',
  RU: 'country.ru',
  SE: 'country.se',
  SI: 'country.si',
  SK: 'country.sk',
  SM: 'country.sm',
  SY: 'country.sy',
  TN: 'country.tn',
  TR: 'country.tr',
  UA: 'country.ua'
};
var _default = CountryCodes;
exports["default"] = _default;