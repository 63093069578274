"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ACTIVATE_USER_ERROR", {
  enumerable: true,
  get: function get() {
    return _userProfile2.ACTIVATE_USER_ERROR;
  }
});
Object.defineProperty(exports, "ACTIVATE_USER_REQUEST", {
  enumerable: true,
  get: function get() {
    return _userProfile2.ACTIVATE_USER_REQUEST;
  }
});
Object.defineProperty(exports, "ACTIVATE_USER_SUCCESS", {
  enumerable: true,
  get: function get() {
    return _userProfile2.ACTIVATE_USER_SUCCESS;
  }
});
Object.defineProperty(exports, "activateUser", {
  enumerable: true,
  get: function get() {
    return _userProfile2.activateUser;
  }
});
exports["default"] = void 0;

var _userProfile = _interopRequireDefault(require("./user-profile.reducer"));

var _userProfile2 = require("./user-profile.actions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = _userProfile["default"];
exports["default"] = _default;