"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reports = require("./reports.actions");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _reports.FIND_REPORT_SERIES_SUCCESS:
      {
        var headers = action.headers,
            body = action.body;
        return Object.assign({}, state, _defineProperty({}, headers.widgetId, body.reportSeries));
      }

    case _reports.UPDATE_REPORT_SERIES_SUCCESS:
      {
        var _headers = action.headers,
            _body = action.body;
        var oldState = state[_headers.widgetId] || [];
        return Object.assign({}, state, _defineProperty({}, _headers.widgetId, oldState.map(function (item) {
          if (item.id === _body.reportSeries.id && _body.reportSeries.interval !== 'immediate') {
            return _body.reportSeries;
          }

          return item;
        })));
      }

    case _reports.DELETE_REPORT_SERIES_SUCCESS:
      {
        var _headers2 = action.headers,
            _body2 = action.body;

        var _oldState = state[_headers2.widgetId] || [];

        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, _headers2.widgetId, _oldState.filter(function (serie) {
          return serie.id !== _body2.id;
        })));
      }

    case _reports.CREATE_REPORT_SERIES_SUCCESS:
      {
        var _headers3 = action.headers,
            _body3 = action.body;

        var _oldState2 = state[_headers3.widgetId] || [];

        if (_body3.reportSeries.interval !== 'immediate') {
          return Object.assign({}, state, _defineProperty({}, _headers3.widgetId, [].concat(_toConsumableArray(_oldState2), [_body3.reportSeries])));
        }
      }
      return state;

    default:
      {
        return state;
      }
  }
};

exports["default"] = _default;