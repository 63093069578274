"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _users = require("./users.actions");

/**
 * Created by FFendt on 27.04.2017.
 */
var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _users.FIND_USERS_FOR_USERS_ADMINISTRATION_SUCCESS:
      {
        return action.body.users;
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
    case _users.FIND_USERS_FOR_USER_ADMINISTRATION_ERROR:
      {
        return [];
      }

    case _users.CREATE_USER_FROM_USER_ADMINISTRATION_SUCCESS:
      {
        // return a cloned copy of state with the new user concatenated into it
        return state.concat(action.body.user);
      }

    case _users.UPDATE_USER_FOR_USER_ADMINISTRATION_REQUEST:
    case _users.UPDATE_USER_FOR_USER_ADMINISTRATION_SUCCESS:
      {
        // replace the user
        return state.map(function (obj) {
          return obj.id === action.body.user.id ? action.body.user : obj;
        });
      }

    case _users.DELETE_USER_FROM_USER_ADMINISTRATION_SUCCESS:
    case _users.DELETE_USER_FROM_USER_ADMINISTRATION_REQUEST:
      {
        // remove the user from the users list
        return state.filter(function (obj) {
          return obj.id !== action.body.user.id;
        });
      }

    default:
      return state;
  }
};

exports["default"] = _default;