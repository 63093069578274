import React, { useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { RequestProvider, useRequest } from '@nicbase/request';
import { StateProvider } from '@nicbase/state';
import moment from 'moment';
import DataRequest from 'nicbase-data-request';
import PortalRegistration from 'nicbase-portal-registration';
import { enhancer, impersonationMiddleware, makeInitialReducer, reducerDefinitions, UserProvider } from 'nicbase-reducers';
import { auth } from 'nicbase-security';
import TourDataView from 'nicbase-tour-data-view';
import { Provider } from 'nicbase-translation';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunk from 'redux-thunk';

import ApplicationComponent from './components/core/application';
import deTranslations from './i18n/de.json';
import enTranslations from './i18n/en.json';
import addAccessToken from './middleware/addAccessToken';
import requestId from './middleware/requestId';
import { createSocketMiddleware } from './middleware/socket-emitter';
import { throttleAssetAssignError, throttleAssetAssignSuccess } from './middleware/throttle-asset-assign-middleware';
import { throttleTourActionsMiddleware } from './middleware/throttle-tour-actions-middleware';
import translationCachingMiddleware from './middleware/translation-caching-middleware';
import ReceiveToken from './receive-token';
import RevokeToken from './revoke-token';

import './general.scss';

import 'intl';
import 'intl/locale-data/jsonp/de';
import './match-selector-polyfill';

const mapStateToProps = state => ({ user: state.user });
const AuthenticatedApp = connect(mapStateToProps)(({ user }) => {
  if (!user.isAuthenticated) {
    window.location.assign(auth.getAuthorizeUrl());
    return null;
  }
  moment.locale(user.locale);

  return (
    <UserProvider user={user}>
      <ApplicationComponent />
    </UserProvider>
  );
});

const App = () => {
  const { socket } = useRequest();
  const socketMiddleware = useRef(createSocketMiddleware(socket));

  const store = useMemo(() => {
    const initialState = Object.assign(ENV.initialState, {
      user: { isAuthenticated: auth.isAuthenticated() },
      translation: { messages: { de: deTranslations, en: enTranslations } },
    });

    const middlewares = applyMiddleware(
      thunk,
      addAccessToken,
      impersonationMiddleware,
      throttleTourActionsMiddleware,
      throttleAssetAssignSuccess,
      throttleAssetAssignError,
      translationCachingMiddleware,
      socketMiddleware.current.middleware,
      requestId
    );

    return createStore(makeInitialReducer(reducerDefinitions), initialState, composeWithDevTools(middlewares, enhancer));
  }, []);

  // keep socket in middleware up to date
  useEffect(() => {
    socketMiddleware.current.updateSocket(socket);
  }, [socket]);

  return (
    <Provider store={store}>
      <StateProvider>
        <Router>
          <Switch>
            <Route path="/portal-registration" component={PortalRegistration} />
            <Route path="/data-view" component={TourDataView} />
            <Route path="/data-request" component={DataRequest} />
            <Route exact path="/receive_token" component={ReceiveToken} />
            <Route exact path="/logout" component={RevokeToken} />
            <Route path="/" component={AuthenticatedApp} />
          </Switch>
        </Router>
      </StateProvider>
    </Provider>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <RequestProvider socketURL={ENV.socket.host}>
      <App />
    </RequestProvider>
    <ReactQueryDevtools />
  </QueryClientProvider>,
  document.getElementById('root')
);
