"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _contentBox = require("./content-box.actions");

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _contentBox.FIND_CONTENT_BOXES_FOR_USER_SUCCESS:
      {
        var newState = state.slice();

        var _loop = function _loop(i) {
          var contentBoxIndex = newState.findIndex(function (cb) {
            return cb.id === action.body.contentBoxes[i].id;
          });

          if (contentBoxIndex === -1) {
            newState.push(action.body.contentBoxes[i]);
          } else {
            newState[contentBoxIndex] = action.body.contentBoxes[i];
          }
        };

        for (var i = 0; i < action.body.contentBoxes.length; i += 1) {
          _loop(i);
        }

        return newState;
      }

    case _contentBox.CLEAR_CONTENT_BOXES_FOR_USER:
      {
        return [];
      }

    default:
      return state;
  }
};

exports["default"] = _default;