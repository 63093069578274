"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _addressSearchMaps = require("./address-search-maps.actions");

/**
 * Created by EHelbing on 08.01.2018.
 */
var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _addressSearchMaps.SEARCH_ADDRESS_SUCCESS:
      {
        var newState = Object.assign({}, state);

        if (action.headers.widgetId) {
          newState[action.headers.widgetId] = action.body.addressList;
        }

        return newState;
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return {};

    default:
      return state;
  }
};

exports["default"] = _default;