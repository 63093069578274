"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.socketDisconnected = exports.socketConnected = exports.disconnectSocket = exports.connectSocket = exports.SOCKET_DISCONNECTED = exports.SOCKET_CONNECTED = exports.DISCONNECT_SOCKET = exports.CONNECT_SOCKET = void 0;

var _socketConstants = _interopRequireDefault(require("./socket-constants"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var CONNECT_SOCKET = 'CONNECT_SOCKET';
exports.CONNECT_SOCKET = CONNECT_SOCKET;
var DISCONNECT_SOCKET = 'DISCONNECT_SOCKET';
exports.DISCONNECT_SOCKET = DISCONNECT_SOCKET;
var SOCKET_CONNECTED = 'SOCKET_CONNECTED';
exports.SOCKET_CONNECTED = SOCKET_CONNECTED;
var SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';
exports.SOCKET_DISCONNECTED = SOCKET_DISCONNECTED;

var connectSocket = function connectSocket() {
  return {
    type: CONNECT_SOCKET,
    state: _socketConstants["default"].CONNECTING
  };
};

exports.connectSocket = connectSocket;

var disconnectSocket = function disconnectSocket() {
  return {
    type: DISCONNECT_SOCKET,
    state: _socketConstants["default"].CLOSING
  };
};

exports.disconnectSocket = disconnectSocket;

var socketConnected = function socketConnected() {
  return {
    type: SOCKET_CONNECTED,
    state: _socketConstants["default"].OPEN
  };
};

exports.socketConnected = socketConnected;

var socketDisconnected = function socketDisconnected() {
  return {
    type: SOCKET_DISCONNECTED,
    state: _socketConstants["default"].CLOSED
  };
};

exports.socketDisconnected = socketDisconnected;