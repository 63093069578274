"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  NO_GPS: false,
  COUPLED: false,
  NOT_COUPLED: false,
  DRIVING: false,
  STANDING: false,
  LOADED: false,
  NOT_LOADED: false,
  DOOR_1_OPEN: false,
  DOOR_1_CLOSED: false,
  DOOR_2_OPEN: false,
  DOOR_2_CLOSED: false,
  DOOR_3_OPEN: false,
  DOOR_3_CLOSED: false,
  REEFER_ON: false,
  REEFER_OFF: false,
  NO_FUEL_RESERVE: false,
  FUEL_RESERVE: false,
  CARRIER_ALERT: false,
  CBT_ALERT: false,
  TK_ALERT: false,
  CARRIER_CAUTION: false,
  CBT_CAUTION: false,
  TK_CAUTION: false,
  CARRIER_NOTIFICATION: false,
  CBT_NOTIFICATION: false,
  TK_NOTIFICATION: false,
  CARRIER_INFORMATION: false,
  CBT_INFORMATION: false,
  TK_INFORMATION: false,
  DTC_ALARM: false,
  DTC_CAUTION: false,
  DTC_NOTIFICATION: false
};
exports["default"] = _default;