"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Status", {
  enumerable: true,
  get: function get() {
    return _status["default"];
  }
});
Object.defineProperty(exports, "StatusIcon", {
  enumerable: true,
  get: function get() {
    return _nicStatusMapping.StatusIcon;
  }
});
Object.defineProperty(exports, "alarmTypes", {
  enumerable: true,
  get: function get() {
    return _nicStatusMapping.alarmTypes;
  }
});
Object.defineProperty(exports, "errorCodeTypes", {
  enumerable: true,
  get: function get() {
    return _nicStatusMapping.errorCodeTypes;
  }
});
Object.defineProperty(exports, "greenErrorCodeTypes", {
  enumerable: true,
  get: function get() {
    return _nicStatusMapping.greenErrorCodeTypes;
  }
});
Object.defineProperty(exports, "redErrorCodeTypes", {
  enumerable: true,
  get: function get() {
    return _nicStatusMapping.redErrorCodeTypes;
  }
});
Object.defineProperty(exports, "statusToIcon", {
  enumerable: true,
  get: function get() {
    return _nicStatusMapping.statusToIcon;
  }
});
Object.defineProperty(exports, "statusToTranslation", {
  enumerable: true,
  get: function get() {
    return _nicStatusMapping.statusToTranslation;
  }
});
Object.defineProperty(exports, "yellowErrorCodeTypes", {
  enumerable: true,
  get: function get() {
    return _nicStatusMapping.yellowErrorCodeTypes;
  }
});

var _nicStatusMapping = require("./nic-status-mapping");

var _status = _interopRequireDefault(require("./status"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }