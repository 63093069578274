"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _types = require("@nicbase/types");

var typeMapper = function typeMapper(type) {
  return function (value) {
    if (value === null || value === undefined) {
      return null;
    }

    switch (type) {
      case _types.FilterAttributeType.ObjectId:
      case _types.FilterAttributeType.String:
        return String(value);

      case _types.FilterAttributeType.Integer:
        if (typeof value === 'string') {
          return parseInt(value, 10);
        }

        return value;

      case _types.FilterAttributeType.Double:
        if (typeof value === 'string') {
          return parseFloat(value);
        }

        return value;

      case _types.FilterAttributeType.Boolean:
        return Boolean(value);

      case _types.FilterAttributeType.DateTime:
        if (typeof value === 'string' || typeof value === 'number' || value instanceof Date) {
          return new Date(value);
        }

        return value;

      default:
        return null;
    }
  };
};

var _default = typeMapper;
exports["default"] = _default;