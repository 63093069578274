/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ChevronLeft, NicplaceLogoSimple } from 'nicbase-icons';

import style from './app-bar.module.scss';

const AppBar = ({ location, history }) => {
  // eslint-disable-next-line react/prop-types
  const { title } = location.state || {};
  return (
    <div className={style.appBar}>
      <Link to="/" className={style.logo}>
        <NicplaceLogoSimple />
      </Link>
      {/* eslint-disable-next-line react/prop-types */}
      <div className={style.title} onClick={() => history.goBack()}>
        <ChevronLeft />
        {title}
      </div>
    </div>
  );
};
AppBar.propTypes = {
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};
AppBar.defaultProps = {};

export default AppBar;
