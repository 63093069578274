"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findSetpointMonitorIntervals = exports.findSetpointIntervalDetails = exports.SETPOINT_MONITOR_SUCCESS = exports.SETPOINT_MONITOR_REQUEST = exports.SETPOINT_MONITOR_ERROR = exports.SETPOINT_MONITOR_DETAILS_SUCCESS = exports.SETPOINT_MONITOR_DETAILS_REQUEST = exports.SETPOINT_MONITOR_DETAILS_ERROR = void 0;
var SETPOINT_MONITOR_REQUEST = 'SETPOINT_MONITOR_REQUEST';
exports.SETPOINT_MONITOR_REQUEST = SETPOINT_MONITOR_REQUEST;
var SETPOINT_MONITOR_SUCCESS = 'SETPOINT_MONITOR_SUCCESS';
exports.SETPOINT_MONITOR_SUCCESS = SETPOINT_MONITOR_SUCCESS;
var SETPOINT_MONITOR_ERROR = 'SETPOINT_MONITOR_ERROR';
exports.SETPOINT_MONITOR_ERROR = SETPOINT_MONITOR_ERROR;

var findSetpointMonitorIntervals = function findSetpointMonitorIntervals(portalId, widgetId, _ref) {
  var intervals = _ref.intervals,
      assetIds = _ref.assetIds,
      assetGroupIds = _ref.assetGroupIds;
  return {
    type: SETPOINT_MONITOR_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      intervals: intervals,
      assetIds: assetIds,
      assetGroupIds: assetGroupIds
    }
  };
};

exports.findSetpointMonitorIntervals = findSetpointMonitorIntervals;
var SETPOINT_MONITOR_DETAILS_REQUEST = 'SETPOINT_MONITOR_DETAILS_REQUEST';
exports.SETPOINT_MONITOR_DETAILS_REQUEST = SETPOINT_MONITOR_DETAILS_REQUEST;
var SETPOINT_MONITOR_DETAILS_SUCCESS = 'SETPOINT_MONITOR_DETAILS_SUCCESS';
exports.SETPOINT_MONITOR_DETAILS_SUCCESS = SETPOINT_MONITOR_DETAILS_SUCCESS;
var SETPOINT_MONITOR_DETAILS_ERROR = 'SETPOINT_MONITOR_DETAILS_ERROR';
exports.SETPOINT_MONITOR_DETAILS_ERROR = SETPOINT_MONITOR_DETAILS_ERROR;

var findSetpointIntervalDetails = function findSetpointIntervalDetails(portalId, widgetId, _ref2) {
  var interval = _ref2.interval,
      intervalStart = _ref2.intervalStart,
      intervalEnd = _ref2.intervalEnd,
      assetIds = _ref2.assetIds,
      assetGroupIds = _ref2.assetGroupIds,
      dynamicPaging = _ref2.dynamicPaging;
  return {
    type: SETPOINT_MONITOR_DETAILS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      interval: interval,
      intervalStart: intervalStart,
      intervalEnd: intervalEnd,
      assetIds: assetIds,
      assetGroupIds: assetGroupIds,
      dynamicPaging: dynamicPaging
    }
  };
};

exports.findSetpointIntervalDetails = findSetpointIntervalDetails;