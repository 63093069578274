"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.psiToBar = exports.psiTo = exports.meterTo = exports.meterFrom = exports.mapUnitByMeasurementSystem = exports.litreTo = exports.litreFrom = exports.kmhTo = exports.kmhFrom = exports.kilogramTo = exports.kilogramFrom = exports.gallonToLitre = exports.gallonTo = exports.cubicYardsTo = exports.cubicYardsFrom = exports.cubicMeterTo = exports.cubicMeterFrom = exports.convertUnit = exports.convertTo = exports.convertFrom = exports.convertDifferenceFrom = exports.celsiusTo = exports.celsiusFrom = exports.barToPsi = exports.barTo = void 0;

var _math = _interopRequireDefault(require("./math"));

var _metricToImperial = _interopRequireDefault(require("./metric-to-imperial"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var convertFrom = function convertFrom(baseUnit) {
  return function (convertUnit) {
    return function (value) {
      return _math["default"].unit(value, baseUnit).toNumber(convertUnit);
    };
  };
};

exports.convertFrom = convertFrom;

var convertDifferenceFrom = function convertDifferenceFrom(baseUnit) {
  return function (convertUnit) {
    return function (value) {
      if (convertUnit === 'degC') {
        return value * (5 / 9);
      } else {
        return value * (9 / 5);
      }
    };
  };
};

exports.convertDifferenceFrom = convertDifferenceFrom;

var convertTo = function convertTo(convertUnit) {
  return function (baseUnit) {
    return function (value) {
      return _math["default"].unit(value, baseUnit).toNumber(convertUnit);
    };
  };
};

exports.convertTo = convertTo;

var mapUnitByMeasurementSystem = function mapUnitByMeasurementSystem(baseUnit) {
  return function (measurementSystem) {
    if (measurementSystem === 'IMPERIAL') {
      return _metricToImperial["default"][baseUnit] || baseUnit;
    }

    if (measurementSystem === 'METRIC') {
      return Object.keys(_metricToImperial["default"]).find(function (key) {
        return _metricToImperial["default"][key] === baseUnit;
      }) || baseUnit;
    }

    return measurementSystem;
  };
};

exports.mapUnitByMeasurementSystem = mapUnitByMeasurementSystem;

var convertUnit = function convertUnit(baseUnit) {
  return function (toUnit) {
    return function (value) {
      return convertFrom(baseUnit)(mapUnitByMeasurementSystem(baseUnit)(toUnit))(value);
    };
  };
}; // length


exports.convertUnit = convertUnit;
var meterTo = convertFrom('m');
exports.meterTo = meterTo;
var meterFrom = convertTo('m'); // export const meterToCm = meterTo('cm');
// export const meterToInch = meterTo('inch');
// export const meterToFoot = meterTo('ft');
// export const meterToYard = meterTo('yd');
// export const meterToKm = meterTo('km');
// export const meterToMile = meterTo('mi');
// export const mileTo = convertFrom('mi');
// export const mileToMeter = mileTo('m');
// export const mileToCm = mileTo('cm');
// export const mileToInch = mileTo('inch');
// export const mileToFoot = mileTo('ft');
// export const mileToYard = mileTo('yd');
// export const mileToKm = mileTo('km');
// temperature

exports.meterFrom = meterFrom;
var celsiusTo = convertFrom('celsius');
exports.celsiusTo = celsiusTo;
var celsiusFrom = convertTo('celsius'); // export const celsiusToFahrenheit = celsiusTo('fahrenheit');
// export const celsiusToKelvin = celsiusTo('kelvin');
// export const fahrenheitTo = convertFrom('fahrenheit');
// export const fahrenheitToCelsius = fahrenheitTo('celsius');
// export const fahrenheitToKelvin = fahrenheitTo('kelvin');
// export const kelvinTo = convertFrom('kelvin');
// export const kelvinToFahrenheit = kelvinTo('fahrenheit');
// export const kelvinToCelsius = kelvinTo('celsius');
// weight

exports.celsiusFrom = celsiusFrom;
var kilogramTo = convertFrom('kg');
exports.kilogramTo = kilogramTo;
var kilogramFrom = convertTo('kg'); // export const kilogramToTonne = kilogramTo('tonne');
// export const kilogramToTon = kilogramTo('ton');
// export const kilogramToPound = kilogramTo('lb');
// pressure

exports.kilogramFrom = kilogramFrom;
var barTo = convertFrom('bar');
exports.barTo = barTo;
var barToPsi = barTo('psi');
exports.barToPsi = barToPsi;
var psiTo = convertFrom('psi');
exports.psiTo = psiTo;
var psiToBar = psiTo('bar'); // volume

exports.psiToBar = psiToBar;
var litreTo = convertFrom('litre');
exports.litreTo = litreTo;
var litreFrom = convertTo('litre'); // export const litreToGallons = litreTo('gal');

exports.litreFrom = litreFrom;
var gallonTo = convertFrom('gal');
exports.gallonTo = gallonTo;
var gallonToLitre = gallonTo('litre');
exports.gallonToLitre = gallonToLitre;
var cubicMeterTo = convertFrom('m3');
exports.cubicMeterTo = cubicMeterTo;
var cubicMeterFrom = convertTo('m3');
exports.cubicMeterFrom = cubicMeterFrom;
var cubicYardsTo = convertFrom('cuyd');
exports.cubicYardsTo = cubicYardsTo;
var cubicYardsFrom = convertTo('cuyd');
exports.cubicYardsFrom = cubicYardsFrom;
var kmhTo = convertFrom('kmh');
exports.kmhTo = kmhTo;
var kmhFrom = convertTo('kmh');
exports.kmhFrom = kmhFrom;