import React, { useCallback, useEffect, useRef } from 'react';
import loadable from 'react-loadable';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  usePortalAssetGroups,
  usePortalAssets,
  usePortalCoupledAssets,
  usePortalHistoricalAssets,
  usePortalHistoricalCoupledAssets,
  usePortalPoiCategories,
  usePortalPois,
  useScopePermissions,
  useTranslations,
} from '@nicbase/hooks';
import { useRequest } from '@nicbase/request';
import CONSTANTS from 'nicbase-default-theme/lib/index';
import { NicbaseSpinnerComponent, Refresh } from 'nicbase-icons';
import { changeActivePortal, nicplaceLogin, PortalProvider, setResponsiveMode, SOCKET_READY_STATES, toggleForceDesktopMode } from 'nicbase-reducers';
import { auth } from 'nicbase-security';
import { injectIntl, intlShape } from 'nicbase-translation';
import { createSelector } from 'reselect';

import MobileLayout from '../mobile/mobile-app';
import activePortalSelector from '../nav-bar/activePortalSelector';
import NavBarLeftDesktop from '../nav-bar/nav-bar-left-desktop';
import NavbarTopDesktop from '../nav-bar/top/nav-bar-top-desktop';

import Notification from './notification-components/notification-container';

import './application.scss';

export const Dashboard = loadable({
  loader: () => import('nicbase-dashboard'),
  loading: () => null,
  render(loaded, props) {
    const { Dashboard: DashboardFrontend } = loaded;
    return <DashboardFrontend {...props} />;
  },
});
export const Administration = loadable({
  loader: () => import('nicbase-administration'),
  loading: () => null,
});
export const UserProfile = loadable({
  loader: () => import('nicbase-user-profile'),
  loading: () => null,
  render(loaded, props) {
    const { UserProfile: UserProfileFrontend } = loaded;
    return <UserProfileFrontend {...props} />;
  },
});
export const Home = loadable({
  loader: () => import('nicbase-home'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Home;
    return <Component {...props} />;
  },
});
export const HomeLight = loadable({
  loader: () => import('nicbase-home'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.HomeLight;
    return <Component {...props} />;
  },
});
export const HomeBGL = loadable({
  loader: () => import('nicbase-home'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.HomeBGL;
    return <Component {...props} />;
  },
});
export const CommandCenter = loadable({
  loader: () => import('nicbase-data-control-center'),
  loading: () => null,
});

const MOBILE = 'mobile';
const TABLET = 'tablet';
const DESKTOP = 'desktop';
const DESKTOP_LARGE = 'desktop-large';

export const createTheme = ({ sidebar, iconSidebar, head, iconHead }) => ({
  '--color-background-nav-bar-left': sidebar,
  '--color-icon-nav-bar-left': iconSidebar,
  '--color-background-nav-bar-top': head,
  '--color-icon-nav-bar-top': iconHead,
});

export const themeImageUrl = imageHash => `${ENV.imageStoreBaseUrl}/layout/${imageHash}/original.png`;

export const Application = ({ responsiveMode, setResponsiveMode: dispatchResponsiveMode, portals, activePortal, changePortal, logIntoNicplace }) => {
  const { socket, reconnect } = useRequest();

  const portalId = portals[0]?.id;

  const setResponsiveModeAndSize = useCallback(() => {
    const width = window.innerWidth || document.body.clientWidth;
    const height = window.innerHeight || document.body.clientHeight;
    if (width <= CONSTANTS.MAX_WIDTH_MOBILE) {
      dispatchResponsiveMode(MOBILE, width, height);
    } else if (width <= CONSTANTS.MAX_WIDTH_TABLET) {
      dispatchResponsiveMode(TABLET, width, height);
    } else if (width <= CONSTANTS.MAX_WIDTH_DESKTOP) {
      dispatchResponsiveMode(DESKTOP, width, height);
    } else {
      dispatchResponsiveMode(DESKTOP_LARGE, width, height);
    }
  }, [dispatchResponsiveMode]);

  const reconnectTryCount = useRef(0);

  // handle open and close events of socket
  useEffect(() => {
    const handleOpen = () => {
      logIntoNicplace(auth.getAccessToken());
    };
    socket.addEventListener('open', handleOpen);
    const handleClose = () => {
      if (reconnectTryCount.current < 5) {
        reconnectTryCount.current += 1;
        reconnect();
      }
    };
    socket.addEventListener('close', handleClose);

    return () => {
      socket.removeEventListener('open', handleOpen);
      socket.removeEventListener('close', handleClose);
    };
  }, [logIntoNicplace, reconnect, socket]);

  // request to load translations
  const { isLoading: isLoadingTranslations } = useTranslations(undefined, {
    enabled: socket.readyState === SOCKET_READY_STATES.OPEN,
  });

  // request to load BASE permissions
  const { isLoading: isLoadingBasePermissions } = useScopePermissions('BASE', null, {
    enabled: socket.readyState === SOCKET_READY_STATES.OPEN,
  });

  // request to load PORTAL permissions if user only has one portal
  const { data: portalPermissions = [] } = useScopePermissions('PORTAL', portalId, {
    staleTime: 0,
    enabled: socket.readyState === SOCKET_READY_STATES.OPEN && portals.length === 1 && Boolean(portalId),
  });

  // request to load portal assets if user only has one portal
  usePortalAssets(portalId, {
    staleTime: 0,
    enabled: socket.readyState === SOCKET_READY_STATES.OPEN && portals.length === 1 && Boolean(portalId),
  });

  // request to load portal coupled assets if user only has one portal
  usePortalCoupledAssets(portalId, {
    staleTime: 0,
    enabled: socket.readyState === SOCKET_READY_STATES.OPEN && portals.length === 1 && Boolean(portalId),
  });

  // request to load portal historical assets if user only has one portal
  usePortalHistoricalAssets(portalId, {
    staleTime: 0,
    enabled: socket.readyState === SOCKET_READY_STATES.OPEN && portals.length === 1 && Boolean(portalId),
  });

  // request to load portal historical coupled assets if user only has one portal
  usePortalHistoricalCoupledAssets(portalId, {
    staleTime: 0,
    enabled: socket.readyState === SOCKET_READY_STATES.OPEN && portals.length === 1 && Boolean(portalId),
  });

  // request to load portal asset groups if user only has one portal
  usePortalAssetGroups(portalId, {
    staleTime: 0,
    enabled: socket.readyState === SOCKET_READY_STATES.OPEN && portals.length === 1 && Boolean(portalId),
  });

  // request to load portal POIs if user only has one portal
  usePortalPois(portalId, null, {
    staleTime: 0,
    enabled: socket.readyState === SOCKET_READY_STATES.OPEN && portals.length === 1 && Boolean(portalId) && portalPermissions.includes('module.poi.read'),
  });

  // request to load portal POI categories if user only has one portal
  usePortalPoiCategories(portalId, null, {
    staleTime: 0,
    enabled: socket.readyState === SOCKET_READY_STATES.OPEN && portals.length === 1 && Boolean(portalId),
  });

  useEffect(() => {
    if (portals.length > 0) {
      // change active portal to show dcc button (and make it usable)
      changePortal(portals[0].id);
    }
  }, [changePortal, portals]);

  const reestablishWebsocketConnection = useCallback(() => {
    // when the connection is reestablished, we will have to Log in again
    reconnectTryCount.current += 1;
    reconnect();
  }, [reconnect]);

  // prefetch dashboard after mount
  useEffect(() => {
    Dashboard.preload();
  }, []);

  // add event listeners for resize and responsive mode
  useEffect(() => {
    setResponsiveModeAndSize();
    let resizeTimer;

    const resizeListener = window.addEventListener('resize', () => {
      // wait with calculation until resize stopped
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        // When the full screen API of the brower is in use and the browser switches to full screen, we have to block the resize/rerender of the application
        const isFullScreen = window.fullScreenApi && window.fullScreenApi.isFullScreen();
        if (isFullScreen) {
          setResponsiveModeAndSize();
        }
      }, 250);
    });

    // unmount
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [setResponsiveModeAndSize]);

  if (socket.readyState !== SOCKET_READY_STATES.OPEN) {
    if (socket.readyState === SOCKET_READY_STATES.CONNECTING) {
      return (
        <div className="h-full w-full flex">
          <NicbaseSpinnerComponent />
        </div>
      );
    }
    return (
      <div className="h-full w-full flex justify-center items-center" onClick={reestablishWebsocketConnection}>
        <span role="button" title="Reconnect">
          <Refresh className="h-8 w-8 cursor-pointer" />
        </span>
      </div>
    );
  }

  // show load spinner if permissions or translations are still being loaded
  if (isLoadingBasePermissions || isLoadingTranslations) {
    return (
      <div className="h-full w-full flex">
        <NicbaseSpinnerComponent />
      </div>
    );
  }

  // TODO: REMOVE after IAA
  let showWielton = false;
  let portalTheme = {};
  let logo;
  if (portals.length === 1) {
    showWielton = ENV.homeThemes.wielton.includes(portals[0].id);
    const { layout = {} } = portals[0];
    if (layout !== null) {
      const { properties = {} } = layout;
      if (properties.logo) {
        logo = <img className="portal-logo" src={themeImageUrl(properties.logo)} alt="logo" />;
      }
      portalTheme = createTheme(properties);
    }
  }
  // TODO: REMOVE after IAA
  if (responsiveMode.mode !== 'mobile') {
    // return the desktop application
    return (
      <PortalProvider portal={activePortal}>
        <div className="application-container-full-screen" style={portalTheme}>
          <Notification />
          <NavbarTopDesktop showWielton={showWielton} responsiveMode={responsiveMode} logo={logo} />
          <div className="content-container-responsive desktop">
            <NavBarLeftDesktop responsiveMode={responsiveMode} />
            <Switch>
              <Route path="/profile" render={props => <UserProfile {...props} />} />
              <Route path="/dashboard/:portalId" render={props => <Dashboard {...props} responsiveMode={responsiveMode} />} />

              <Route path="/admin" render={props => <Administration {...props} responsiveMode={responsiveMode} />} />
              <Route path="/data-control-center/:portalId">
                <CommandCenter />
              </Route>
              <Route
                path="/"
                render={() => {
                  if (portals.length === 1 && portals[0]?.type === 'NIC_PLACE_LIGHT') {
                    return <HomeLight portalId={portalId} />;
                  }
                  if (portals.length === 1 && portals[0]?.type === 'BGL') {
                    return <HomeBGL portalId={portalId} />;
                  }
                  return <Home responsiveMode={responsiveMode} />;
                }}
              />
            </Switch>
          </div>
        </div>
      </PortalProvider>
    );
  }
  // render the responsive view
  return (
    <PortalProvider portal={activePortal}>
      <Route render={props => <MobileLayout {...props} theme={portalTheme} />} />
    </PortalProvider>
  );
};

Application.propTypes = {
  intl: intlShape.isRequired,
  responsiveMode: PropTypes.shape({
    mode: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  logIntoNicplace: PropTypes.func,
  changePortal: PropTypes.func,
  setResponsiveMode: PropTypes.func,
  toggleForceDesktopMode: PropTypes.func,
  portals: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, type: PropTypes.string, layout: PropTypes.shape({ properties: PropTypes.shape({ logo: PropTypes.string }) }) })
  ),
  activePortal: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
};

const noop = () => {};
Application.defaultProps = {
  logIntoNicplace: noop,
  changePortal: noop,
  setResponsiveMode: noop,
  toggleForceDesktopMode: noop,
  responsiveMode: {
    mode: 'desktop',
    width: 0,
    height: 0,
  },
  portals: [],
  activePortal: {},
};
const getPortals = state => state.portals;
const portalsSelector = createSelector(getPortals, portals => Object.values(portals));
export const mapStateToProps = state => ({
  responsiveMode: state.responsiveMode,
  portals: portalsSelector(state),
  activePortal: activePortalSelector(state),
});

const actionCreators = {
  changePortal: changeActivePortal,
  setResponsiveMode,
  toggleForceDesktopMode,
  logIntoNicplace: nicplaceLogin,
};

export default withRouter(connect(mapStateToProps, actionCreators)(injectIntl(Application)));
