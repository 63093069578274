"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_ASSETS_TO_ALARM_SUCCESS = exports.UPDATE_ASSETS_TO_ALARM_REQUEST = exports.UPDATE_ASSETS_TO_ALARM_ERROR = exports.UPDATE_ALARM_SUCCESS = exports.UPDATE_ALARM_REQUEST = exports.UPDATE_ALARM_ERROR = exports.UPDATE_ALARMS_TO_ASSET_SUCCESS = exports.UPDATE_ALARMS_TO_ASSET_REQUEST = exports.UPDATE_ALARMS_TO_ASSET_ERROR = exports.SET_ALARM_ACTIVE_REQUEST = exports.SET_ALARM_ACTIVE_ERROR = exports.FIND_ALARMS_SUCCESS = exports.FIND_ALARMS_REQUEST = exports.FIND_ALARMS_ERROR = exports.DELETE_ALARMS_SUCCESS = exports.DELETE_ALARMS_REQUEST = exports.DELETE_ALARMS_ERROR = exports.CREATE_ALARM_SUCCESS = exports.CREATE_ALARM_REQUEST = exports.CREATE_ALARM_ERROR = void 0;
exports.assignAlarmsToAsset = assignAlarmsToAsset;
exports.assignAssetsToAlarm = assignAssetsToAlarm;
exports.createNewAlarm = createNewAlarm;
exports.deleteAlarms = deleteAlarms;
exports.getAlarmsFromBackend = getAlarmsFromBackend;
exports.setAlarmActive = setAlarmActive;
exports.updateAlarm = updateAlarm;

/**
 * Created by PKing on 28.09.2017
 */
var CREATE_ALARM_SUCCESS = 'CREATE_ALARM_SUCCESS';
exports.CREATE_ALARM_SUCCESS = CREATE_ALARM_SUCCESS;
var CREATE_ALARM_REQUEST = 'CREATE_ALARM_REQUEST';
exports.CREATE_ALARM_REQUEST = CREATE_ALARM_REQUEST;
var CREATE_ALARM_ERROR = 'CREATE_ALARM_ERROR';
exports.CREATE_ALARM_ERROR = CREATE_ALARM_ERROR;

function createNewAlarm(alarmData, portalId) {
  return {
    type: CREATE_ALARM_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: alarmData
  };
}

var UPDATE_ALARM_SUCCESS = 'UPDATE_ALARM_SUCCESS';
exports.UPDATE_ALARM_SUCCESS = UPDATE_ALARM_SUCCESS;
var UPDATE_ALARM_REQUEST = 'UPDATE_ALARM_REQUEST';
exports.UPDATE_ALARM_REQUEST = UPDATE_ALARM_REQUEST;
var UPDATE_ALARM_ERROR = 'UPDATE_ALARM_ERROR';
exports.UPDATE_ALARM_ERROR = UPDATE_ALARM_ERROR;

function updateAlarm(alarmData, portalId) {
  return {
    type: UPDATE_ALARM_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: alarmData
  };
}

var DELETE_ALARMS_REQUEST = 'DELETE_ALARMS_BY_ID_REQUEST';
exports.DELETE_ALARMS_REQUEST = DELETE_ALARMS_REQUEST;
var DELETE_ALARMS_SUCCESS = 'DELETE_ALARMS_BY_ID_SUCCESS';
exports.DELETE_ALARMS_SUCCESS = DELETE_ALARMS_SUCCESS;
var DELETE_ALARMS_ERROR = 'DELETE_ALARMS_BY_ID_ERROR';
exports.DELETE_ALARMS_ERROR = DELETE_ALARMS_ERROR;

function deleteAlarms(alarmIds, portalId) {
  return {
    type: DELETE_ALARMS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      alarmIds: alarmIds
    }
  };
}

var SET_ALARM_ACTIVE_REQUEST = 'SET_ALARM_ACTIVE_REQUEST';
exports.SET_ALARM_ACTIVE_REQUEST = SET_ALARM_ACTIVE_REQUEST;
var SET_ALARM_ACTIVE_ERROR = 'SET_ALARM_ACTIVE_ERROR';
exports.SET_ALARM_ACTIVE_ERROR = SET_ALARM_ACTIVE_ERROR;

function setAlarmActive(alarmId, activationStatus, portalId) {
  return {
    type: SET_ALARM_ACTIVE_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      alarmId: alarmId,
      activationStatus: activationStatus
    }
  };
}

var FIND_ALARMS_REQUEST = 'FIND_ALARMS_FOR_PORTAL_REQUEST';
exports.FIND_ALARMS_REQUEST = FIND_ALARMS_REQUEST;
var FIND_ALARMS_ERROR = 'FIND_ALARMS_FOR_PORTAL_ERROR';
exports.FIND_ALARMS_ERROR = FIND_ALARMS_ERROR;
var FIND_ALARMS_SUCCESS = 'FIND_ALARMS_FOR_PORTAL_SUCCESS';
exports.FIND_ALARMS_SUCCESS = FIND_ALARMS_SUCCESS;

function getAlarmsFromBackend(portalId) {
  var dynamicFilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var dynamicPaging = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var dynamicSorters = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var action = {
    type: FIND_ALARMS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      dynamicFilters: dynamicFilters,
      dynamicPaging: dynamicPaging,
      dynamicSorters: dynamicSorters
    }
  };
  return action;
}

var UPDATE_ASSETS_TO_ALARM_REQUEST = 'UPDATE_ASSETS_TO_ALARM_REQUEST';
exports.UPDATE_ASSETS_TO_ALARM_REQUEST = UPDATE_ASSETS_TO_ALARM_REQUEST;
var UPDATE_ASSETS_TO_ALARM_SUCCESS = 'UPDATE_ASSETS_TO_ALARM_SUCCESS';
exports.UPDATE_ASSETS_TO_ALARM_SUCCESS = UPDATE_ASSETS_TO_ALARM_SUCCESS;
var UPDATE_ASSETS_TO_ALARM_ERROR = 'UPDATE_ASSETS_TO_ALARM_ERROR';
exports.UPDATE_ASSETS_TO_ALARM_ERROR = UPDATE_ASSETS_TO_ALARM_ERROR;

function assignAssetsToAlarm(alarm, portalId) {
  return {
    type: UPDATE_ASSETS_TO_ALARM_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      alarm: alarm
    }
  };
}

var UPDATE_ALARMS_TO_ASSET_REQUEST = 'UPDATE_ALARMS_TO_ASSET_REQUEST';
exports.UPDATE_ALARMS_TO_ASSET_REQUEST = UPDATE_ALARMS_TO_ASSET_REQUEST;
var UPDATE_ALARMS_TO_ASSET_SUCCESS = 'UPDATE_ALARMS_TO_ASSET_SUCCESS';
exports.UPDATE_ALARMS_TO_ASSET_SUCCESS = UPDATE_ALARMS_TO_ASSET_SUCCESS;
var UPDATE_ALARMS_TO_ASSET_ERROR = 'UPDATE_ALARMS_TO_ASSET_ERROR';
exports.UPDATE_ALARMS_TO_ASSET_ERROR = UPDATE_ALARMS_TO_ASSET_ERROR;

function assignAlarmsToAsset(assetId) {
  var alarms = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var portalId = arguments.length > 2 ? arguments[2] : undefined;
  return {
    type: UPDATE_ALARMS_TO_ASSET_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      assetId: assetId,
      alarms: alarms
    }
  };
}