import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { DataControlCenter } from 'nicbase-icons';
import { usePortal } from 'nicbase-reducers';
import { authorize } from 'nicbase-security';
import { useIntl } from 'nicbase-translation';

import '../nav-bar.scss';

export const CommandCenterLink = () => {
  const { id: portalId } = usePortal();
  const intl = useIntl();
  return (
    <Link
      to={`/data-control-center/${portalId}`}
      data-for="tool-tip-bottom-dcc"
      data-tip={intl.formatMessage({ id: 'navigation.commandCenter' })}
      title={intl.formatMessage({ id: 'navigation.commandCenter' })}
    >
      <ReactTooltip id="tool-tip-bottom-dcc" type="dark" place="bottom" effect="solid" />
      <span title={intl.formatMessage({ id: 'navigation.dcc.icon' })}>
        <DataControlCenter className="icon" />
      </span>
    </Link>
  );
};

export default authorize('PORTAL')(['module.commandCenter'])(CommandCenterLink);
