"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FIND_PROVIDERS_SUCCESS = exports.FIND_PROVIDERS_REQUEST = exports.FIND_PROVIDERS_ERROR = void 0;
exports.findAllProviders = findAllProviders;
var FIND_PROVIDERS_REQUEST = 'FIND_PROVIDERS_REQUEST';
exports.FIND_PROVIDERS_REQUEST = FIND_PROVIDERS_REQUEST;
var FIND_PROVIDERS_SUCCESS = 'FIND_PROVIDERS_SUCCESS';
exports.FIND_PROVIDERS_SUCCESS = FIND_PROVIDERS_SUCCESS;
var FIND_PROVIDERS_ERROR = 'FIND_PROVIDERS_ERROR';
exports.FIND_PROVIDERS_ERROR = FIND_PROVIDERS_ERROR;

function findAllProviders() {
  return {
    type: FIND_PROVIDERS_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {}
  };
}