"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultViewport = exports.defaultMapType = exports["default"] = void 0;

var _react = require("react");

var _reactMapGl = require("react-map-gl");

var _bbox3 = _interopRequireDefault(require("@turf/bbox"));

var _useClustering = require("./use-clustering");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var defaultViewport = {
  /** Initial zoom for the map */
  zoom: 3,

  /** Initial latitude of the map center */
  latitude: 47.763426,

  /** Initial longitude of the map center */
  longitude: 10.294631,

  /** Intial map type of the base map */

  /** initial width of the map */
  width: 0,

  /** initial height of the map */
  height: 0,

  /** initial minZoom for the map and hooks that use zoom */
  minZoom: 3,

  /** initial maxZoom for the map and hooks that use zoom */
  maxZoom: 17
};
exports.defaultViewport = defaultViewport;
var defaultMapType = 'google';
exports.defaultMapType = defaultMapType;

var useMap = function useMap() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    mapType: defaultMapType,
    initialViewport: defaultViewport
  },
      mapType = _ref.mapType,
      initialViewport = _ref.initialViewport;

  var _useState = (0, _react.useState)(function () {
    return _objectSpread(_objectSpread({}, defaultViewport), initialViewport);
  }),
      _useState2 = _slicedToArray(_useState, 2),
      viewport = _useState2[0],
      setViewport = _useState2[1];

  var updateViewport = (0, _react.useCallback)(function (newViewport) {
    setViewport(function (prevViewport) {
      var mergedViewport;

      if (typeof newViewport === 'function') {
        mergedViewport = newViewport(prevViewport);
      } else {
        mergedViewport = _objectSpread(_objectSpread({}, prevViewport), newViewport);
      }

      var _mergedViewport = mergedViewport,
          zoom = _mergedViewport.zoom,
          minZoom = _mergedViewport.minZoom,
          maxZoom = _mergedViewport.maxZoom;
      return _objectSpread(_objectSpread({}, mergedViewport), {}, {
        zoom: Math.min(Math.max(zoom, minZoom), maxZoom)
      });
    });
  }, []);
  var bounds = (0, _react.useMemo)(function () {
    if (viewport.height > 0 && viewport.width > 0) {
      var extendedViewport = new _reactMapGl.WebMercatorViewport(viewport);
      return extendedViewport.getBounds().flat();
    }

    return [0, 0, 0, 0];
  }, [viewport]); // create hook that is already pre-initialized with all possible values

  var usePresetClustering = function usePresetClustering(features, clusteringOptions) {
    var options = (0, _react.useRef)({});

    if (clusteringOptions) {
      options.current = clusteringOptions;
    } // set tile extent for corresponding map type


    options.current.extent = mapType === 'google' ? 256 : 512;
    return (0, _useClustering.useClustering)({
      features: features,
      zoom: viewport.zoom,
      bounds: bounds,
      options: options.current
    });
  };

  var height = viewport.height,
      width = viewport.width;
  var fitBounds = (0, _react.useCallback)(function (features, options) {
    if (height > 0 && width > 0) {
      var _bbox = (0, _bbox3["default"])(features),
          _bbox2 = _slicedToArray(_bbox, 4),
          lngSW = _bbox2[0],
          latSW = _bbox2[1],
          lngNE = _bbox2[2],
          latNE = _bbox2[3];

      var _WebMercatorViewport$ = new _reactMapGl.WebMercatorViewport({
        height: height,
        width: width
      }).fitBounds([[lngSW, latSW], [lngNE, latNE]], options),
          zoom = _WebMercatorViewport$.zoom,
          latitude = _WebMercatorViewport$.latitude,
          longitude = _WebMercatorViewport$.longitude;

      updateViewport({
        zoom: mapType === 'google' ? zoom - 1 : zoom,
        latitude: latitude,
        longitude: longitude
      });
    }
  }, [height, mapType, updateViewport, width]);
  var getBounds = (0, _react.useCallback)(function (vp) {
    var extendedViewport = new _reactMapGl.WebMercatorViewport(vp);
    return extendedViewport.getBounds();
  }, []);
  return {
    viewport: viewport,
    setViewport: updateViewport,
    useClustering: usePresetClustering,
    fitBounds: fitBounds,
    getBounds: getBounds,
    project: (0, _react.useCallback)(function (lngLat) {
      return new _reactMapGl.WebMercatorViewport(viewport).project(lngLat);
    }, [viewport]),
    unproject: (0, _react.useCallback)(function (xyz) {
      return new _reactMapGl.WebMercatorViewport(viewport).unproject(xyz);
    }, [viewport])
  };
};

var _default = useMap;
exports["default"] = _default;