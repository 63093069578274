"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = phoneNumberValidation;

function phoneNumberValidation(value) {
  // eslint-disable-next-line
  var number = new RegExp(/^[+]?[0-9]{1,20}$/);
  return number.test(value);
}