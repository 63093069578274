"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findSystemExchangeProjects = exports.findPushAccounts = exports.createSystemExchangeProject = exports.FIND_SYSTEM_EXCHANGE_PROJECTS_SUCCESS = exports.FIND_SYSTEM_EXCHANGE_PROJECTS_REQUEST = exports.FIND_SYSTEM_EXCHANGE_PROJECTS_ERROR = exports.FIND_PUSH_ACCOUNTS_SUCCESS = exports.FIND_PUSH_ACCOUNTS_REQUEST = exports.FIND_PUSH_ACCOUNTS_ERROR = exports.CREATE_SYSTEM_EXCHANGE_PROJECT_SUCCESS = exports.CREATE_SYSTEM_EXCHANGE_PROJECT_REQUEST = exports.CREATE_SYSTEM_EXCHANGE_PROJECT_ERROR = void 0;
var CREATE_SYSTEM_EXCHANGE_PROJECT_REQUEST = 'CREATE_SYSTEM_EXCHANGE_PROJECT_REQUEST';
exports.CREATE_SYSTEM_EXCHANGE_PROJECT_REQUEST = CREATE_SYSTEM_EXCHANGE_PROJECT_REQUEST;
var CREATE_SYSTEM_EXCHANGE_PROJECT_SUCCESS = 'CREATE_SYSTEM_EXCHANGE_PROJECT_SUCCESS';
exports.CREATE_SYSTEM_EXCHANGE_PROJECT_SUCCESS = CREATE_SYSTEM_EXCHANGE_PROJECT_SUCCESS;
var CREATE_SYSTEM_EXCHANGE_PROJECT_ERROR = 'CREATE_SYSTEM_EXCHANGE_PROJECT_ERROR';
exports.CREATE_SYSTEM_EXCHANGE_PROJECT_ERROR = CREATE_SYSTEM_EXCHANGE_PROJECT_ERROR;

var createSystemExchangeProject = function createSystemExchangeProject(portalId, portal) {
  var _portal$projectName, _portal$shareToAccoun, _portal$sharedData;

  return {
    type: CREATE_SYSTEM_EXCHANGE_PROJECT_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      projectName: (_portal$projectName = portal === null || portal === void 0 ? void 0 : portal.projectName) !== null && _portal$projectName !== void 0 ? _portal$projectName : '',
      shareToAccount: (_portal$shareToAccoun = portal === null || portal === void 0 ? void 0 : portal.shareToAccount) !== null && _portal$shareToAccoun !== void 0 ? _portal$shareToAccoun : null,
      sharedData: (_portal$sharedData = portal === null || portal === void 0 ? void 0 : portal.sharedData) !== null && _portal$sharedData !== void 0 ? _portal$sharedData : [],
      projectType: portal === null || portal === void 0 ? void 0 : portal.projectType,
      rtvProperties: portal === null || portal === void 0 ? void 0 : portal.rtvProperties
    }
  };
};

exports.createSystemExchangeProject = createSystemExchangeProject;
var FIND_SYSTEM_EXCHANGE_PROJECTS_REQUEST = 'FIND_SYSTEM_EXCHANGE_PROJECTS_REQUEST';
exports.FIND_SYSTEM_EXCHANGE_PROJECTS_REQUEST = FIND_SYSTEM_EXCHANGE_PROJECTS_REQUEST;
var FIND_SYSTEM_EXCHANGE_PROJECTS_SUCCESS = 'FIND_SYSTEM_EXCHANGE_PROJECTS_SUCCESS';
exports.FIND_SYSTEM_EXCHANGE_PROJECTS_SUCCESS = FIND_SYSTEM_EXCHANGE_PROJECTS_SUCCESS;
var FIND_SYSTEM_EXCHANGE_PROJECTS_ERROR = 'FIND_SYSTEM_EXCHANGE_PROJECTS_ERROR';
exports.FIND_SYSTEM_EXCHANGE_PROJECTS_ERROR = FIND_SYSTEM_EXCHANGE_PROJECTS_ERROR;

var findSystemExchangeProjects = function findSystemExchangeProjects(portalId) {
  return {
    type: FIND_SYSTEM_EXCHANGE_PROJECTS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {}
  };
};

exports.findSystemExchangeProjects = findSystemExchangeProjects;
var FIND_PUSH_ACCOUNTS_REQUEST = 'FIND_PUSH_ACCOUNTS_REQUEST';
exports.FIND_PUSH_ACCOUNTS_REQUEST = FIND_PUSH_ACCOUNTS_REQUEST;
var FIND_PUSH_ACCOUNTS_SUCCESS = 'FIND_PUSH_ACCOUNTS_SUCCESS';
exports.FIND_PUSH_ACCOUNTS_SUCCESS = FIND_PUSH_ACCOUNTS_SUCCESS;
var FIND_PUSH_ACCOUNTS_ERROR = 'FIND_PUSH_ACCOUNTS_ERROR';
exports.FIND_PUSH_ACCOUNTS_ERROR = FIND_PUSH_ACCOUNTS_ERROR;

var findPushAccounts = function findPushAccounts(portalId) {
  return {
    type: FIND_PUSH_ACCOUNTS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {}
  };
};

exports.findPushAccounts = findPushAccounts;