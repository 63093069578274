"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortDate = sortDate;
exports.sortNumbers = sortNumbers;
exports.sortPosition = sortPosition;
exports.sortRealStartEndDate = sortRealStartEndDate;
exports.sortString = sortString;

function sortDate(dataGetter, sortDirection) {
  var direction = sortDirection === 'ASC' ? 1 : -1;
  return function (a, b) {
    return (new Date(dataGetter({
      rowData: a
    })).getTime() - new Date(dataGetter({
      rowData: b
    })).getTime()) * direction;
  };
}

function sortRealStartEndDate(dataGetter, sortDirection) {
  var direction = sortDirection === 'ASC' ? 1 : -1;
  return function (a, b) {
    var date1 = dataGetter({
      rowData: a
    });
    var date2 = dataGetter({
      rowData: b
    });
    return (new Date(date1.date).getTime() - new Date(date2.date).getTime()) * direction;
  };
}

function sortString(dataGetter, sortDirection) {
  var direction = sortDirection === 'ASC' ? 1 : -1;
  return function (a, b) {
    return String(dataGetter({
      rowData: a
    })).localeCompare(dataGetter({
      rowData: b
    })) * direction;
  };
}

function sortPosition(dataGetter, sortDirection) {
  var direction = sortDirection === 'ASC' ? 1 : -1;
  return function (a, b) {
    var asset1 = dataGetter({
      rowData: a
    });
    var asset2 = dataGetter({
      rowData: b
    });
    var address1 = asset1.pois || asset1.address || '';
    var address2 = asset2.pois || asset2.address || '';
    return String(address1).localeCompare(address2) * direction;
  };
}

function sortNumbers(dataGetter, sortDirection) {
  var direction = sortDirection === 'ASC' ? 1 : -1;
  return function (a, b) {
    return (dataGetter({
      rowData: a.children[0]
    }) - dataGetter({
      rowData: b.children[0]
    })) * direction;
  };
}