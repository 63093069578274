/* eslint-disable simple-import-sort/imports */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CloseButton } from 'nicbase-icons';
import { toast, ToastContainer, cssTransition } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import cx from 'classnames';
import Notification from './notification';

const Fade = cssTransition({
  enter: 'faderIn',
  exit: 'faderOut',
  duration: 2000,
});

// is necessary see documentation
const NicplaceCloseButton = ({ closeToast, severity }) => (
  <CloseButton className={cx('h-4 w-4', { 'fill-white': severity === 'red', 'fill-primary': severity !== 'red' })} onClick={closeToast} />
);
NicplaceCloseButton.propTypes = {
  closeToast: PropTypes.func,
  severity: PropTypes.string,
};
const noop = () => {};
NicplaceCloseButton.defaultProps = {
  closeToast: noop,
  severity: null,
};

export const NotificationContainer = ({ notification }) => {
  const toasterStyle = {
    closeOnClick: true,
    pauseOnHover: true,
    autoClose: false,
    closeButton: <NicplaceCloseButton severity={notification.severity} />,
    className: 'toast-container',
    progressClassName: 'toast-progress',
  };
  const autoCloseStyle = Object.assign({}, toasterStyle, {
    closeOnClick: false,
    autoClose: 10000,
  });
  const appliedStyle = notification.autoClose ? autoCloseStyle : toasterStyle;

  if (notification) {
    const notificationContent = <Notification {...notification} />;
    switch (notification.severity) {
      case 'red':
        toast.error(notificationContent, { ...appliedStyle, className: 'toast-container red' });
        break;
      case 'green':
        toast.success(notificationContent, appliedStyle);
        break;
      case 'white':
        toast.info(notificationContent, appliedStyle);
        break;
      case 'yellow':
        toast.warn(notificationContent, appliedStyle);
        break;
      case 'default':
        toast(notificationContent, appliedStyle);
        break;
      default:
        break;
    }
  }

  return <ToastContainer transition={Fade} />;
};

NotificationContainer.propTypes = {
  notification: PropTypes.shape({
    severity: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    titleAddition: PropTypes.shape(),
    text: PropTypes.string,
    textAddition: PropTypes.shape(),
    link: PropTypes.string,
    autoClose: PropTypes.bool,
  }),
};
NotificationContainer.defaultProps = {
  notification: {},
};

const mapStateToProps = state => ({
  notification: state.notifications,
});

export default connect(mapStateToProps)(NotificationContainer);
