"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAllDebtors = exports.FIND_ALL_DEBTORS_SUCCESS = exports.FIND_ALL_DEBTORS_REQUEST = exports.FIND_ALL_DEBTORS_ERROR = void 0;
var FIND_ALL_DEBTORS_REQUEST = 'FIND_ALL_DEBTORS_REQUEST';
exports.FIND_ALL_DEBTORS_REQUEST = FIND_ALL_DEBTORS_REQUEST;
var FIND_ALL_DEBTORS_SUCCESS = 'FIND_ALL_DEBTORS_SUCCESS';
exports.FIND_ALL_DEBTORS_SUCCESS = FIND_ALL_DEBTORS_SUCCESS;
var FIND_ALL_DEBTORS_ERROR = 'FIND_ALL_DEBTORS_ERROR';
exports.FIND_ALL_DEBTORS_ERROR = FIND_ALL_DEBTORS_ERROR;

var findAllDebtors = function findAllDebtors() {
  return {
    type: FIND_ALL_DEBTORS_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {}
  };
};

exports.findAllDebtors = findAllDebtors;