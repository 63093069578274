"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _poiCategories = require("../poi-categories/poi-categories.actions");

var _poiCategoryInformationList = require("./poi-category-information-list.actions");

// we only care about the following props in this reducer
var getReducedPoi = function getReducedPoi(fullPoi) {
  return {
    id: fullPoi.id,
    name: fullPoi.name
  };
};

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _poiCategories.CREATE_POI_CATEGORY_SUCCESS:
      {
        return state.concat(getReducedPoi(action.body.poiCategory));
      }

    case _poiCategories.UPDATE_POI_CATEGORY_SUCCESS:
      {
        var newState = state.map(function (obj) {
          return obj.id === action.body.poiCategory.id ? Object.assign({}, obj, getReducedPoi(action.body.poiCategory)) : obj;
        });
        return newState;
      }

    case _poiCategories.DELETE_POI_CATEGORY_REQUEST:
    case _poiCategories.DELETE_POI_CATEGORY_SUCCESS:
      {
        return state.filter(function (obj) {
          return action.body.poiCategory.id !== obj.id;
        });
      }

    case _poiCategoryInformationList.GET_POI_CATEGORIES_SUCCESS:
      {
        return action.body.poiCategories;
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
    case _poiCategoryInformationList.GET_POI_CATEGORIES_ERROR:
      {
        return [];
      }

    default:
      {
        return state;
      }
  }
};

exports["default"] = _default;