import { auth } from 'nicbase-security';

const RevokeToken = () => {
  const revokeUrl = auth.getRevokeUrl();
  auth.removeAccessToken();
  window.location.assign(revokeUrl);
  return null;
};

export default RevokeToken;
