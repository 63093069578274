"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUnassignedBoxes = exports.getBoxes = exports["default"] = void 0;

var _reselect = require("reselect");

var _activePortal = require("../../activePortal");

var _boxes = require("./boxes.actions");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }

function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var boxesById = function boxesById() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _boxes.FIND_UNASSIGNED_BOXES_BY_FILTER_SUCCESS:
      {
        var _action$body$unassign = action.body.unassignedBoxes,
            unassignedBoxes = _action$body$unassign === void 0 ? [] : _action$body$unassign;
        var newState = unassignedBoxes.reduce(function (result, box) {
          var existingBox = state[box.id];
          result[box.id] = _objectSpread(_objectSpread({}, existingBox), box);
          return result;
        }, {});
        return _objectSpread(_objectSpread({}, state), newState);
      }

    case _boxes.ASSIGN_BOX_TO_PORTAL_SUCCESS:
      {
        var boxId = action.body.boxId;

        var removedBox = state[boxId],
            _newState = _objectWithoutProperties(state, [boxId].map(_toPropertyKey));

        return _newState;
      }

    case _boxes.ASSIGN_BOXES_TO_PORTAL_SUCCESS:
      {
        var boxes = action.body.boxes;
        return Object.entries(state).reduce(function (newState, _ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              key = _ref2[0],
              unassignedBox = _ref2[1];

          if (boxes.some(function (assignedBox) {
            return assignedBox.boxId === unassignedBox.id;
          })) {
            return newState;
          }

          newState[key] = unassignedBox;
          return newState;
        }, {});
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      {
        return {};
      }

    default:
      return state;
  }
};

var allBoxes = function allBoxes() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _boxes.FIND_UNASSIGNED_BOXES_BY_FILTER_SUCCESS:
      {
        var _action$body$unassign2 = action.body.unassignedBoxes,
            unassignedBoxes = _action$body$unassign2 === void 0 ? [] : _action$body$unassign2;
        var newState = unassignedBoxes.reduce(function (result, box) {
          if (!state.some(function (id) {
            return id === box.id;
          })) {
            result.push(box.id);
          }

          return result;
        }, []);
        return [].concat(_toConsumableArray(state), _toConsumableArray(newState));
      }

    case _boxes.ASSIGN_BOX_TO_PORTAL_SUCCESS:
      {
        var boxId = action.body.boxId;
        return state.filter(function (id) {
          return id !== boxId;
        });
      }

    case _boxes.ASSIGN_BOXES_TO_PORTAL_SUCCESS:
      {
        var boxes = action.body.boxes;
        return state.filter(function (unassignedBoxId) {
          return !boxes.some(function (assignedBoxes) {
            return assignedBoxes.boxId === unassignedBoxId;
          });
        });
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      {
        return [];
      }

    default:
      return state;
  }
}; // SELECTORS


var getBoxes = (0, _reselect.createSelector)([function (state) {
  return state.portal.boxes.allIds;
}, function (state) {
  return state.portal.boxes.byId;
}], function (allIds, boxes) {
  return allIds.map(function (id) {
    return boxes[id];
  });
});
exports.getBoxes = getBoxes;
var getUnassignedBoxes = (0, _reselect.createSelector)([getBoxes], function (boxes) {
  return boxes;
});
exports.getUnassignedBoxes = getUnassignedBoxes;
var _default = {
  byId: boxesById,
  allIds: allBoxes
};
exports["default"] = _default;