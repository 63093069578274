"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.standingTimesReducer = exports.getStandingTimes = exports.getStandingTimeDetails = exports.getStandingTimeAssets = void 0;

var _reselect = require("reselect");

var _standingTimes = require("./standing-times.actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialIntervals = {
  green: 0,
  yellow: 0,
  red: 0
};

var intervalsReducer = function intervalsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialIntervals;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _standingTimes.STANDING_TIMES_SUCCESS:
      {
        var intervals = action.body.intervals;
        return _objectSpread(_objectSpread({}, state), intervals);
      }

    default:
      {
        return state;
      }
  }
};

var initialDetails = {
  green: [],
  yellow: [],
  red: []
};

var detailsReducer = function detailsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialDetails;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _standingTimes.STANDING_TIME_DETAILS_SUCCESS:
      {
        var _action$body = action.body,
            interval = _action$body.interval,
            details = _action$body.details;
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, interval, details));
      }

    default:
      {
        return state;
      }
  }
};

var standingTimesReducer = function standingTimesReducer() {
  var _action$headers, _state$widgetId, _state$widgetId2;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var widgetId = (_action$headers = action.headers) === null || _action$headers === void 0 ? void 0 : _action$headers.widgetId;

  if (!widgetId) {
    return state;
  }

  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, widgetId, {
    assetCount: intervalsReducer((_state$widgetId = state[widgetId]) === null || _state$widgetId === void 0 ? void 0 : _state$widgetId.assetCount, action),
    details: detailsReducer((_state$widgetId2 = state[widgetId]) === null || _state$widgetId2 === void 0 ? void 0 : _state$widgetId2.details, action)
  }));
};

exports.standingTimesReducer = standingTimesReducer;
var getStandingTimes = (0, _reselect.createSelector)([function (state, props) {
  return state.standingTimes[props.id];
}], function (standingTimes) {
  return standingTimes !== null && standingTimes !== void 0 ? standingTimes : {};
});
exports.getStandingTimes = getStandingTimes;
var getStandingTimeAssets = (0, _reselect.createSelector)([getStandingTimes], function (standingTimes) {
  var _standingTimes$assetC;

  return (_standingTimes$assetC = standingTimes.assetCount) !== null && _standingTimes$assetC !== void 0 ? _standingTimes$assetC : {};
});
exports.getStandingTimeAssets = getStandingTimeAssets;
var getStandingTimeDetails = (0, _reselect.createSelector)([getStandingTimes], function (standingTimes) {
  var _standingTimes$detail;

  return (_standingTimes$detail = standingTimes.details) !== null && _standingTimes$detail !== void 0 ? _standingTimes$detail : {};
});
exports.getStandingTimeDetails = getStandingTimeDetails;