"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onExportTransportOrderClick = exports.CREATE_GREENYARD_EXPORT_SUCCESS = exports.CREATE_GREENYARD_EXPORT_REQUEST = exports.CREATE_GREENYARD_EXPORT_ERROR = void 0;
var CREATE_GREENYARD_EXPORT_REQUEST = 'CREATE_GREENYARD_EXPORT_REQUEST';
exports.CREATE_GREENYARD_EXPORT_REQUEST = CREATE_GREENYARD_EXPORT_REQUEST;
var CREATE_GREENYARD_EXPORT_ERROR = 'CREATE_GREENYARD_EXPORT_ERROR';
exports.CREATE_GREENYARD_EXPORT_ERROR = CREATE_GREENYARD_EXPORT_ERROR;
var CREATE_GREENYARD_EXPORT_SUCCESS = 'CREATE_GREENYARD_EXPORT_SUCCESS';
exports.CREATE_GREENYARD_EXPORT_SUCCESS = CREATE_GREENYARD_EXPORT_SUCCESS;

var onExportTransportOrderClick = function onExportTransportOrderClick(_ref) {
  var _ref$tourId = _ref.tourId,
      tourId = _ref$tourId === void 0 ? null : _ref$tourId,
      _ref$portalId = _ref.portalId,
      portalId = _ref$portalId === void 0 ? null : _ref$portalId;
  return {
    type: CREATE_GREENYARD_EXPORT_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      tourId: tourId
    }
  };
};

exports.onExportTransportOrderClick = onExportTransportOrderClick;