"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findPoiLayoversByFilter = exports.FIND_POI_LAYOVERS_BY_FILTER_SUCCESS = exports.FIND_POI_LAYOVERS_BY_FILTER_REQUEST = exports.FIND_POI_LAYOVERS_BY_FILTER_ERROR = void 0;
var FIND_POI_LAYOVERS_BY_FILTER_REQUEST = 'FIND_POI_LAYOVERS_BY_FILTER_REQUEST';
exports.FIND_POI_LAYOVERS_BY_FILTER_REQUEST = FIND_POI_LAYOVERS_BY_FILTER_REQUEST;
var FIND_POI_LAYOVERS_BY_FILTER_SUCCESS = 'FIND_POI_LAYOVERS_BY_FILTER_SUCCESS';
exports.FIND_POI_LAYOVERS_BY_FILTER_SUCCESS = FIND_POI_LAYOVERS_BY_FILTER_SUCCESS;
var FIND_POI_LAYOVERS_BY_FILTER_ERROR = 'FIND_POI_LAYOVERS_BY_FILTER_ERROR';
exports.FIND_POI_LAYOVERS_BY_FILTER_ERROR = FIND_POI_LAYOVERS_BY_FILTER_ERROR;

var findPoiLayoversByFilter = function findPoiLayoversByFilter(portalId, widgetId) {
  var dynamicFilters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var dynamicPaging = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var dynamicSorters = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  var action = {
    type: FIND_POI_LAYOVERS_BY_FILTER_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      dynamicFilters: dynamicFilters,
      dynamicPaging: dynamicPaging,
      dynamicSorters: dynamicSorters
    }
  };
  return action;
};

exports.findPoiLayoversByFilter = findPoiLayoversByFilter;