"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOAD_GEOFENCES_MILEAGE_SUCCESS = exports.LOAD_GEOFENCES_MILEAGE_REQUEST = exports.LOAD_GEOFENCES_MILEAGE_ERROR = void 0;
exports.loadGeofencesMileage = loadGeofencesMileage;

/**
 * Created by SHartmann on 02.03.2017.
 */
var LOAD_GEOFENCES_MILEAGE_SUCCESS = 'LOAD_GEOFENCES_MILEAGE_SUCCESS';
exports.LOAD_GEOFENCES_MILEAGE_SUCCESS = LOAD_GEOFENCES_MILEAGE_SUCCESS;
var LOAD_GEOFENCES_MILEAGE_REQUEST = 'LOAD_GEOFENCES_MILEAGE_REQUEST';
exports.LOAD_GEOFENCES_MILEAGE_REQUEST = LOAD_GEOFENCES_MILEAGE_REQUEST;
var LOAD_GEOFENCES_MILEAGE_ERROR = 'LOAD_GEOFENCES_MILEAGE_ERROR';
exports.LOAD_GEOFENCES_MILEAGE_ERROR = LOAD_GEOFENCES_MILEAGE_ERROR;
var geofencesMileageMockData = [{
  name: 'Jan 17',
  stayCustomer: 73,
  internalTraffic: 19,
  garageApproach: 8
}, {
  name: 'Feb 17',
  stayCustomer: 70,
  internalTraffic: 18,
  garageApproach: 12
}, {
  name: 'Mrz 17',
  stayCustomer: 72,
  internalTraffic: 22,
  garageApproach: 6
}, {
  name: 'Apr 17',
  stayCustomer: 53,
  internalTraffic: 22,
  garageApproach: 25
}, {
  name: 'Mai 17',
  stayCustomer: 76,
  internalTraffic: 20,
  garageApproach: 4
}];

function loadGeofencesMileage() {
  // TODO: replace this for production
  return {
    type: LOAD_GEOFENCES_MILEAGE_SUCCESS,
    headers: {
      version: 'v1'
    },
    body: {
      geofencesMileage: geofencesMileageMockData
    }
  }; // TODO: uncomment this for production
  // return { type: LOAD_GEOFENCES_MILEAGE_REQUEST };
}