"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getActivePortal = exports["default"] = exports.changeActivePortal = exports.CHANGE_ACTIVE_PORTAL = void 0;

var _reselect = require("reselect");

var CHANGE_ACTIVE_PORTAL = 'CHANGE_ACTIVE_PORTAL';
exports.CHANGE_ACTIVE_PORTAL = CHANGE_ACTIVE_PORTAL;

var changeActivePortal = function changeActivePortal(portalId) {
  return {
    type: CHANGE_ACTIVE_PORTAL,
    headers: {
      portalId: portalId,
      version: 'v1'
    },
    body: {}
  };
};

exports.changeActivePortal = changeActivePortal;

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case CHANGE_ACTIVE_PORTAL:
      return action.headers.portalId;

    default:
      return state;
  }
};

exports["default"] = _default;
var getActivePortal = (0, _reselect.createSelector)(function (state) {
  return state.activePortal;
}, function (activePortal) {
  return activePortal;
});
exports.getActivePortal = getActivePortal;