"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findTwoWayAllAssetIdsForPortal = exports.FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_SUCCESS = exports.FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_REQUEST = exports.FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_ERROR = void 0;
var FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_REQUEST = 'FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_REQUEST';
exports.FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_REQUEST = FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_REQUEST;
var FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_SUCCESS = 'FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_SUCCESS';
exports.FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_SUCCESS = FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_SUCCESS;
var FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_ERROR = 'FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_ERROR';
exports.FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_ERROR = FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_ERROR;

var findTwoWayAllAssetIdsForPortal = function findTwoWayAllAssetIdsForPortal(portalId) {
  return {
    type: FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {}
  };
};

exports.findTwoWayAllAssetIdsForPortal = findTwoWayAllAssetIdsForPortal;