"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = alphanumericValidation;

/**
 * Created by SWensauer on 17.10.2017.
 */
function alphanumericValidation(value, min, max) {
  // eslint-disable-next-line
  var alphanumeric = '^s*([0-9a-zA-Z]+)s*$';
  var regex = new RegExp(alphanumeric);
  var noEmpty = new RegExp(/^(?=\s*\S).*$/);
  var noSpecial = new RegExp(/^\s*([0-9a-zA-Z\s]+)\s*$/);

  if (min > 0) {
    if (noEmpty.test(value) === false) {
      // if input is empty
      return 1;
    } else if (noSpecial.test(value) === false) {
      // if input is a special character
      return 2;
    } else if (value.length > max) {
      // if input to long
      return 3;
    } else if (value.length < min) {
      // if input to short
      return 4;
    } else if (regex.test(value)) {
      // if input correct
      return true;
    }
  }

  return false;
}