"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _jsonpathPlus = require("jsonpath-plus");

var _typeMapper = _interopRequireDefault(require("./typeMapper"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// return the property from a record defined by its path, then execute the comparator on its value(s);
var _default = function _default(path, filterFunction, type) {
  return function (objectToSearch) {
    var values = (0, _jsonpathPlus.JSONPath)({
      path: path,
      json: objectToSearch
    });

    if (values.length > 0) {
      return values.map(function (value) {
        if (type) {
          return (0, _typeMapper["default"])(type)(value);
        }

        return value;
      }).some(function (value) {
        return filterFunction(value);
      });
    }

    return filterFunction(undefined);
  };
};

exports["default"] = _default;