"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_POI_CATEGORY_SUCCESS = exports.UPDATE_POI_CATEGORY_REQUEST = exports.UPDATE_POI_CATEGORY_ERROR = exports.FIND_POI_CATEGORIES_SUCCESS = exports.FIND_POI_CATEGORIES_REQUEST = exports.FIND_POI_CATEGORIES_ERROR = exports.DELETE_POI_CATEGORY_SUCCESS = exports.DELETE_POI_CATEGORY_REQUEST = exports.DELETE_POI_CATEGORY_ERROR = exports.CREATE_POI_CATEGORY_SUCCESS = exports.CREATE_POI_CATEGORY_REQUEST = exports.CREATE_POI_CATEGORY_ERROR = void 0;
exports.createPOICategory = createPOICategory;
exports.deletePOICategory = deletePOICategory;
exports.getPOICategories = getPOICategories;
exports.updatePOICategory = updatePOICategory;

/**
 * Created by EHelbing on 16.11.2017.
 */
var FIND_POI_CATEGORIES_SUCCESS = 'FIND_POI_CATEGORIES_SUCCESS';
exports.FIND_POI_CATEGORIES_SUCCESS = FIND_POI_CATEGORIES_SUCCESS;
var FIND_POI_CATEGORIES_ERROR = 'FIND_POI_CATEGORIES_ERROR';
exports.FIND_POI_CATEGORIES_ERROR = FIND_POI_CATEGORIES_ERROR;
var FIND_POI_CATEGORIES_REQUEST = 'FIND_POI_CATEGORIES_REQUEST';
exports.FIND_POI_CATEGORIES_REQUEST = FIND_POI_CATEGORIES_REQUEST;

function getPOICategories(portalId) {
  return {
    type: FIND_POI_CATEGORIES_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {}
  };
}

var CREATE_POI_CATEGORY_SUCCESS = 'CREATE_POI_CATEGORY_SUCCESS';
exports.CREATE_POI_CATEGORY_SUCCESS = CREATE_POI_CATEGORY_SUCCESS;
var CREATE_POI_CATEGORY_ERROR = 'CREATE_POI_CATEGORY_ERROR';
exports.CREATE_POI_CATEGORY_ERROR = CREATE_POI_CATEGORY_ERROR;
var CREATE_POI_CATEGORY_REQUEST = 'CREATE_POI_CATEGORY_REQUEST';
exports.CREATE_POI_CATEGORY_REQUEST = CREATE_POI_CATEGORY_REQUEST;

function createPOICategory() {
  var poiCategory = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: CREATE_POI_CATEGORY_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      poiCategory: poiCategory
    }
  };
}

var UPDATE_POI_CATEGORY_SUCCESS = 'UPDATE_POI_CATEGORY_SUCCESS';
exports.UPDATE_POI_CATEGORY_SUCCESS = UPDATE_POI_CATEGORY_SUCCESS;
var UPDATE_POI_CATEGORY_ERROR = 'UPDATE_POI_CATEGORY_ERROR';
exports.UPDATE_POI_CATEGORY_ERROR = UPDATE_POI_CATEGORY_ERROR;
var UPDATE_POI_CATEGORY_REQUEST = 'UPDATE_POI_CATEGORY_REQUEST';
exports.UPDATE_POI_CATEGORY_REQUEST = UPDATE_POI_CATEGORY_REQUEST;

function updatePOICategory() {
  var poiCategory = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: UPDATE_POI_CATEGORY_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      poiCategory: poiCategory
    }
  };
}

var DELETE_POI_CATEGORY_SUCCESS = 'DELETE_POI_CATEGORY_SUCCESS';
exports.DELETE_POI_CATEGORY_SUCCESS = DELETE_POI_CATEGORY_SUCCESS;
var DELETE_POI_CATEGORY_ERROR = 'DELETE_POI_CATEGORY_ERROR';
exports.DELETE_POI_CATEGORY_ERROR = DELETE_POI_CATEGORY_ERROR;
var DELETE_POI_CATEGORY_REQUEST = 'DELETE_POI_CATEGORY_REQUEST';
exports.DELETE_POI_CATEGORY_REQUEST = DELETE_POI_CATEGORY_REQUEST;

function deletePOICategory() {
  var poiCategoryId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: DELETE_POI_CATEGORY_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      poiCategory: {
        id: poiCategoryId
      }
    }
  };
}