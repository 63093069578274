"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_USER_FOR_USER_ADMINISTRATION_SUCCESS = exports.UPDATE_USER_FOR_USER_ADMINISTRATION_REQUEST = exports.UPDATE_USER_FOR_USER_ADMINISTRATION_ERROR = exports.REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_SUCCESS = exports.REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_REQUEST = exports.REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_ERROR = exports.FIND_USERS_FOR_USER_ADMINISTRATION_REQUEST = exports.FIND_USERS_FOR_USER_ADMINISTRATION_ERROR = exports.FIND_USERS_FOR_USERS_ADMINISTRATION_SUCCESS = exports.DELETE_USER_FROM_USER_ADMINISTRATION_SUCCESS = exports.DELETE_USER_FROM_USER_ADMINISTRATION_REQUEST = exports.DELETE_USER_FROM_USER_ADMINISTRATION_ERROR = exports.CREATE_USER_FROM_USER_ADMINISTRATION_SUCCESS = exports.CREATE_USER_FROM_USER_ADMINISTRATION_REQUEST = exports.CREATE_USER_FROM_USER_ADMINISTRATION_ERROR = void 0;
exports.createUserForUserAdministration = createUserForUserAdministration;
exports.deleteUserForUserAdministration = deleteUserForUserAdministration;
exports.getUsers = getUsers;
exports.refreshUserRegistrationForUserAdministration = refreshUserRegistrationForUserAdministration;
exports.updateUserForUserAdministration = updateUserForUserAdministration;

/**
 * Created by Florian Fendt on 27.04.2017.
 */
var FIND_USERS_FOR_USERS_ADMINISTRATION_SUCCESS = 'FIND_USERS_FOR_ADMINISTRATION_SUCCESS';
exports.FIND_USERS_FOR_USERS_ADMINISTRATION_SUCCESS = FIND_USERS_FOR_USERS_ADMINISTRATION_SUCCESS;
var FIND_USERS_FOR_USER_ADMINISTRATION_ERROR = 'FIND_USERS_FOR_ADMINISTRATION_ERROR';
exports.FIND_USERS_FOR_USER_ADMINISTRATION_ERROR = FIND_USERS_FOR_USER_ADMINISTRATION_ERROR;
var FIND_USERS_FOR_USER_ADMINISTRATION_REQUEST = 'FIND_USERS_FOR_ADMINISTRATION_REQUEST';
exports.FIND_USERS_FOR_USER_ADMINISTRATION_REQUEST = FIND_USERS_FOR_USER_ADMINISTRATION_REQUEST;

function getUsers() {
  var portalId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return {
    type: FIND_USERS_FOR_USER_ADMINISTRATION_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      dynamicFilters: {
        type: 'EqualFilter',
        attributeValue: {
          value: portalId,
          type: 'Integer'
        },
        attributeName: 'user.portalId'
      },
      dynamicPaging: null,
      dynamicSorters: null
    }
  };
}

var CREATE_USER_FROM_USER_ADMINISTRATION_SUCCESS = 'CREATE_USER_FOR_ADMINISTRATION_SUCCESS';
exports.CREATE_USER_FROM_USER_ADMINISTRATION_SUCCESS = CREATE_USER_FROM_USER_ADMINISTRATION_SUCCESS;
var CREATE_USER_FROM_USER_ADMINISTRATION_ERROR = 'CREATE_USER_FOR_ADMINISTRATION_ERROR';
exports.CREATE_USER_FROM_USER_ADMINISTRATION_ERROR = CREATE_USER_FROM_USER_ADMINISTRATION_ERROR;
var CREATE_USER_FROM_USER_ADMINISTRATION_REQUEST = 'CREATE_USER_FOR_ADMINISTRATION_REQUEST';
exports.CREATE_USER_FROM_USER_ADMINISTRATION_REQUEST = CREATE_USER_FROM_USER_ADMINISTRATION_REQUEST;

function createUserForUserAdministration() {
  var user = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: CREATE_USER_FROM_USER_ADMINISTRATION_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      user: user
    }
  };
}

var UPDATE_USER_FOR_USER_ADMINISTRATION_SUCCESS = 'UPDATE_USER_FOR_ADMINISTRATION_SUCCESS';
exports.UPDATE_USER_FOR_USER_ADMINISTRATION_SUCCESS = UPDATE_USER_FOR_USER_ADMINISTRATION_SUCCESS;
var UPDATE_USER_FOR_USER_ADMINISTRATION_ERROR = 'UPDATE_USER_FOR_ADMINISTRATION_ERROR';
exports.UPDATE_USER_FOR_USER_ADMINISTRATION_ERROR = UPDATE_USER_FOR_USER_ADMINISTRATION_ERROR;
var UPDATE_USER_FOR_USER_ADMINISTRATION_REQUEST = 'UPDATE_USER_FOR_ADMINISTRATION_REQUEST';
exports.UPDATE_USER_FOR_USER_ADMINISTRATION_REQUEST = UPDATE_USER_FOR_USER_ADMINISTRATION_REQUEST;

function updateUserForUserAdministration() {
  var user = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: UPDATE_USER_FOR_USER_ADMINISTRATION_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      user: user
    }
  };
}

var DELETE_USER_FROM_USER_ADMINISTRATION_SUCCESS = 'REMOVE_USER_FOR_ADMINISTRATION_SUCCESS';
exports.DELETE_USER_FROM_USER_ADMINISTRATION_SUCCESS = DELETE_USER_FROM_USER_ADMINISTRATION_SUCCESS;
var DELETE_USER_FROM_USER_ADMINISTRATION_ERROR = 'REMOVE_USER_FOR_ADMINISTRATION_ERROR';
exports.DELETE_USER_FROM_USER_ADMINISTRATION_ERROR = DELETE_USER_FROM_USER_ADMINISTRATION_ERROR;
var DELETE_USER_FROM_USER_ADMINISTRATION_REQUEST = 'REMOVE_USER_FOR_ADMINISTRATION_REQUEST';
exports.DELETE_USER_FROM_USER_ADMINISTRATION_REQUEST = DELETE_USER_FROM_USER_ADMINISTRATION_REQUEST;

function deleteUserForUserAdministration() {
  var userId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: DELETE_USER_FROM_USER_ADMINISTRATION_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      user: {
        id: userId
      }
    }
  };
}

var REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_SUCCESS = 'REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_SUCCESS';
exports.REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_SUCCESS = REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_SUCCESS;
var REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_ERROR = 'REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_ERROR';
exports.REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_ERROR = REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_ERROR;
var REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_REQUEST = 'REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_REQUEST';
exports.REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_REQUEST = REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_REQUEST;

function refreshUserRegistrationForUserAdministration() {
  var userId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      user: {
        id: userId
      }
    }
  };
}