"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "getPoiCategoryIcon", {
  enumerable: true,
  get: function get() {
    return _poiCategoryTypeMapping.getPoiCategoryIcon;
  }
});
Object.defineProperty(exports, "getPoiCategoryTranslationKey", {
  enumerable: true,
  get: function get() {
    return _poiCategoryTypeMapping.getPoiCategoryTranslationKey;
  }
});

var _poiCategoryTypeMapping = require("./poi-category-type-mapping");