"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.splitDuration = exports.getDeviationColourAndSign = exports.WAYPOINT_TYPES = exports.TRIGGER_CONDITION_TRANSLATIONS = exports.TRIGGER_CONDITION_START_TRANSLATIONS = exports.TRIGGER_CONDITION_END_TRANSLATIONS = exports.TRIGGER_CONDITIONS = exports.TOUR_TYPE = exports.TOUR_STATUS = exports.ETA_PROVIDER_TRANSLATIONS = exports.ETA_PROVIDERS = exports.DAYS = exports.ASSET_OFFLINE_LIMIT_RAIL = exports.ASSET_OFFLINE_LIMIT = void 0;

var _TRIGGER_CONDITION_TR, _TRIGGER_CONDITION_ST, _TRIGGER_CONDITION_EN, _ETA_PROVIDER_TRANSLA;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ASSET_OFFLINE_LIMIT = 60 * 60 * 1000;
exports.ASSET_OFFLINE_LIMIT = ASSET_OFFLINE_LIMIT;
var ASSET_OFFLINE_LIMIT_RAIL = 24 * 60 * 60 * 1000;
exports.ASSET_OFFLINE_LIMIT_RAIL = ASSET_OFFLINE_LIMIT_RAIL;
var WAYPOINT_TYPES = {
  START: 'START',
  END: 'END',
  INTERMEDIATE: 'INTERMEDIATE'
};
exports.WAYPOINT_TYPES = WAYPOINT_TYPES;
var TRIGGER_CONDITIONS = {
  POI_ENTERED: 'POI_ENTERED',
  POI_EXITED: 'POI_EXITED',
  DOOR_OPENED_INSIDE_POI: 'DOOR_OPENED_INSIDE_POI',
  DOOR_CLOSED_INSIDE_POI: 'DOOR_CLOSED_INSIDE_POI',
  STANDING_INSIDE_POI: 'STANDING_INSIDE_POI'
};
exports.TRIGGER_CONDITIONS = TRIGGER_CONDITIONS;
var TRIGGER_CONDITION_TRANSLATIONS = (_TRIGGER_CONDITION_TR = {}, _defineProperty(_TRIGGER_CONDITION_TR, TRIGGER_CONDITIONS.POI_ENTERED, 'tourBoard.newTour.driveIn'), _defineProperty(_TRIGGER_CONDITION_TR, TRIGGER_CONDITIONS.POI_EXITED, 'tourBoard.newTour.driveOut'), _defineProperty(_TRIGGER_CONDITION_TR, TRIGGER_CONDITIONS.DOOR_OPENED_INSIDE_POI, 'widgets.tourBoard.doorOpenInPOI'), _defineProperty(_TRIGGER_CONDITION_TR, TRIGGER_CONDITIONS.DOOR_CLOSED_INSIDE_POI, 'widgets.tourBoard.doorCloseInPOI'), _defineProperty(_TRIGGER_CONDITION_TR, TRIGGER_CONDITIONS.STANDING_INSIDE_POI, 'widgets.tourBoard.standingInsidePOI'), _TRIGGER_CONDITION_TR);
exports.TRIGGER_CONDITION_TRANSLATIONS = TRIGGER_CONDITION_TRANSLATIONS;
var TRIGGER_CONDITION_START_TRANSLATIONS = (_TRIGGER_CONDITION_ST = {}, _defineProperty(_TRIGGER_CONDITION_ST, TRIGGER_CONDITIONS.POI_ENTERED, 'widgets.tourBoard.startWithDrivein'), _defineProperty(_TRIGGER_CONDITION_ST, TRIGGER_CONDITIONS.POI_EXITED, 'widgets.tourBoard.startWithDriveout'), _defineProperty(_TRIGGER_CONDITION_ST, TRIGGER_CONDITIONS.DOOR_OPENED_INSIDE_POI, 'widgets.tourBoard.startWithDoorOpenInPOI'), _defineProperty(_TRIGGER_CONDITION_ST, TRIGGER_CONDITIONS.DOOR_CLOSED_INSIDE_POI, 'widgets.tourBoard.startWithDoorCloseInPOI'), _defineProperty(_TRIGGER_CONDITION_ST, TRIGGER_CONDITIONS.STANDING_INSIDE_POI, 'widgets.tourBoard.startWithStandingInsidePOI'), _TRIGGER_CONDITION_ST);
exports.TRIGGER_CONDITION_START_TRANSLATIONS = TRIGGER_CONDITION_START_TRANSLATIONS;
var TRIGGER_CONDITION_END_TRANSLATIONS = (_TRIGGER_CONDITION_EN = {}, _defineProperty(_TRIGGER_CONDITION_EN, TRIGGER_CONDITIONS.POI_ENTERED, 'widgets.tourBoard.endWithDrivein'), _defineProperty(_TRIGGER_CONDITION_EN, TRIGGER_CONDITIONS.POI_EXITED, 'widgets.tourBoard.endWithDriveout'), _defineProperty(_TRIGGER_CONDITION_EN, TRIGGER_CONDITIONS.DOOR_OPENED_INSIDE_POI, 'widgets.tourBoard.endWithDoorOpenInPOI'), _defineProperty(_TRIGGER_CONDITION_EN, TRIGGER_CONDITIONS.DOOR_CLOSED_INSIDE_POI, 'widgets.tourBoard.endWithDoorCloseInPOI'), _TRIGGER_CONDITION_EN);
exports.TRIGGER_CONDITION_END_TRANSLATIONS = TRIGGER_CONDITION_END_TRANSLATIONS;
var ETA_PROVIDERS = {
  KASASI: 'KASASI',
  PTV: 'PTV',
  SYNFIOO: 'SYNFIOO',
  NOTHING: null
};
exports.ETA_PROVIDERS = ETA_PROVIDERS;
var ETA_PROVIDER_TRANSLATIONS = (_ETA_PROVIDER_TRANSLA = {}, _defineProperty(_ETA_PROVIDER_TRANSLA, ETA_PROVIDERS.PTV, 'widgets.tourBoard.eta.ptv'), _defineProperty(_ETA_PROVIDER_TRANSLA, ETA_PROVIDERS.SYNFIOO, 'widgets.tourBoard.eta.synfioo'), _defineProperty(_ETA_PROVIDER_TRANSLA, ETA_PROVIDERS.NOTHING, 'widgets.tourBoard.eta.none'), _ETA_PROVIDER_TRANSLA);
exports.ETA_PROVIDER_TRANSLATIONS = ETA_PROVIDER_TRANSLATIONS;
var TOUR_TYPE = {
  TOUR: 'TOUR',
  SHARE: 'SHARE'
};
exports.TOUR_TYPE = TOUR_TYPE;
var TOUR_STATUS = {
  WAITING: 'WAITING',
  RUNNING: 'RUNNING',
  FINISHED: 'FINISHED'
};
exports.TOUR_STATUS = TOUR_STATUS;
var DAYS = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 0
};
exports.DAYS = DAYS;

var splitDuration = function splitDuration(duration) {
  var hours = Math.floor(duration / 60);
  var minutes = Math.floor(duration - hours * 60);
  return {
    hours: hours,
    minutes: minutes
  };
};

exports.splitDuration = splitDuration;

var getDeviationColourAndSign = function getDeviationColourAndSign(duration, earlyTolerance, lateTolerance) {
  // determine correct sign of deviation
  var sign;
  var deviationColour; // too early

  if (duration < earlyTolerance * -1) {
    sign = '-';
    deviationColour = 'yellow'; // too late
  } else if (duration > lateTolerance) {
    sign = '+';
    deviationColour = 'red'; // on time
  } else if (duration < 0) {
    sign = '-';
    deviationColour = 'green'; // on time
  } else {
    sign = '+';
    deviationColour = 'green';
  }

  return {
    sign: sign,
    deviationColour: deviationColour
  };
};

exports.getDeviationColourAndSign = getDeviationColourAndSign;