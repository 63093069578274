"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FIND_ALL_TRANSLATIONS_SUCCESS = exports.FIND_ALL_TRANSLATIONS_REQUEST = exports.CHANGE_LOCALE = exports.ADD_TRANSLATIONS_SUCCESS = void 0;
exports.addTranslations = addTranslations;
exports.changeLocale = changeLocale;
exports.findAllTranslations = findAllTranslations;

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ADD_TRANSLATIONS_SUCCESS = 'ADD_TRANSLATIONS_SUCCESS';
exports.ADD_TRANSLATIONS_SUCCESS = ADD_TRANSLATIONS_SUCCESS;

function addTranslations(languageKey, translations) {
  return {
    type: ADD_TRANSLATIONS_SUCCESS,
    headers: {
      version: 'v1'
    },
    body: {
      languageKey: languageKey,
      translations: translations
    }
  };
}

var CHANGE_LOCALE = 'CHANGE_LOCALE';
exports.CHANGE_LOCALE = CHANGE_LOCALE;

function changeLocale(locale) {
  _moment["default"].locale(locale);

  return {
    type: CHANGE_LOCALE,
    headers: {
      version: 'v1'
    },
    body: {
      locale: locale
    }
  };
} // load all translations with
// if no language is provided, the users language will be used


var FIND_ALL_TRANSLATIONS_REQUEST = 'FIND_ALL_TRANSLATIONS_REQUEST';
exports.FIND_ALL_TRANSLATIONS_REQUEST = FIND_ALL_TRANSLATIONS_REQUEST;
var FIND_ALL_TRANSLATIONS_SUCCESS = 'FIND_ALL_TRANSLATIONS_SUCCESS';
exports.FIND_ALL_TRANSLATIONS_SUCCESS = FIND_ALL_TRANSLATIONS_SUCCESS;

function findAllTranslations(language) {
  return {
    type: FIND_ALL_TRANSLATIONS_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      language: language
    }
  };
}