"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AlarmValidityType = exports.AlarmValidityCondition = exports.AlarmTimeframeType = exports.AlarmTemperatureTriggerCondition = exports.AlarmTemperature = exports.AlarmShockAxis = exports.AlarmSetpoint = exports.AlarmReturnAir = exports.AlarmReeferManufacturer = exports.AlarmReeferErrorLevel = exports.AlarmPositionType = exports.AlarmOperation = exports.AlarmNotificationType = exports.AlarmNotificationTriggerCondition = exports.AlarmNotificationRecurrence = exports.AlarmNotificationDelay = exports.AlarmLoadingType = exports.AlarmFlag = exports.AlarmDoorTriggerCondition = exports.AlarmDoorNumber = exports.AlarmConditionCategory = void 0;
var AlarmFlag;
exports.AlarmFlag = AlarmFlag;

(function (AlarmFlag) {
  AlarmFlag[AlarmFlag["None"] = 0] = "None";
  AlarmFlag[AlarmFlag["Green"] = 1] = "Green";
  AlarmFlag[AlarmFlag["Yellow"] = 2] = "Yellow";
  AlarmFlag[AlarmFlag["Red"] = 3] = "Red";
})(AlarmFlag || (exports.AlarmFlag = AlarmFlag = {}));

var AlarmNotificationRecurrence;
exports.AlarmNotificationRecurrence = AlarmNotificationRecurrence;

(function (AlarmNotificationRecurrence) {
  AlarmNotificationRecurrence[AlarmNotificationRecurrence["Never"] = 0] = "Never";
  AlarmNotificationRecurrence[AlarmNotificationRecurrence["Permanent"] = 1] = "Permanent";
  AlarmNotificationRecurrence[AlarmNotificationRecurrence["Once"] = 2] = "Once";
  AlarmNotificationRecurrence[AlarmNotificationRecurrence["Periodic"] = 3] = "Periodic";
})(AlarmNotificationRecurrence || (exports.AlarmNotificationRecurrence = AlarmNotificationRecurrence = {}));

var AlarmNotificationType;
exports.AlarmNotificationType = AlarmNotificationType;

(function (AlarmNotificationType) {
  AlarmNotificationType[AlarmNotificationType["Email"] = 0] = "Email";
  AlarmNotificationType[AlarmNotificationType["Sms"] = 1] = "Sms";
})(AlarmNotificationType || (exports.AlarmNotificationType = AlarmNotificationType = {}));

var AlarmNotificationDelay;
exports.AlarmNotificationDelay = AlarmNotificationDelay;

(function (AlarmNotificationDelay) {
  AlarmNotificationDelay[AlarmNotificationDelay["Immediately"] = 0] = "Immediately";
  AlarmNotificationDelay[AlarmNotificationDelay["SecondNotification"] = 1] = "SecondNotification";
  AlarmNotificationDelay[AlarmNotificationDelay["ThirdNotification"] = 2] = "ThirdNotification";
  AlarmNotificationDelay[AlarmNotificationDelay["FourthNotification"] = 3] = "FourthNotification";
  AlarmNotificationDelay[AlarmNotificationDelay["FifthNotification"] = 4] = "FifthNotification";
  AlarmNotificationDelay[AlarmNotificationDelay["SixthNotification"] = 5] = "SixthNotification";
  AlarmNotificationDelay[AlarmNotificationDelay["SeventhNotification"] = 6] = "SeventhNotification";
  AlarmNotificationDelay[AlarmNotificationDelay["EighthNotification"] = 7] = "EighthNotification";
  AlarmNotificationDelay[AlarmNotificationDelay["NinthNotification"] = 8] = "NinthNotification";
  AlarmNotificationDelay[AlarmNotificationDelay["TenthNotification"] = 9] = "TenthNotification";
})(AlarmNotificationDelay || (exports.AlarmNotificationDelay = AlarmNotificationDelay = {}));

var AlarmNotificationTriggerCondition;
exports.AlarmNotificationTriggerCondition = AlarmNotificationTriggerCondition;

(function (AlarmNotificationTriggerCondition) {
  AlarmNotificationTriggerCondition[AlarmNotificationTriggerCondition["Immediately"] = 1] = "Immediately";
  AlarmNotificationTriggerCondition[AlarmNotificationTriggerCondition["AfterXNotifications"] = 2] = "AfterXNotifications";
  AlarmNotificationTriggerCondition[AlarmNotificationTriggerCondition["AfterXMinutes"] = 3] = "AfterXMinutes";
})(AlarmNotificationTriggerCondition || (exports.AlarmNotificationTriggerCondition = AlarmNotificationTriggerCondition = {}));

var AlarmTemperatureTriggerCondition;
exports.AlarmTemperatureTriggerCondition = AlarmTemperatureTriggerCondition;

(function (AlarmTemperatureTriggerCondition) {
  AlarmTemperatureTriggerCondition[AlarmTemperatureTriggerCondition["Immediately"] = 0] = "Immediately";
  AlarmTemperatureTriggerCondition[AlarmTemperatureTriggerCondition["AfterXNotifications"] = 2] = "AfterXNotifications";
  AlarmTemperatureTriggerCondition[AlarmTemperatureTriggerCondition["AfterXMinutes"] = 3] = "AfterXMinutes";
})(AlarmTemperatureTriggerCondition || (exports.AlarmTemperatureTriggerCondition = AlarmTemperatureTriggerCondition = {}));

var AlarmDoorTriggerCondition;
exports.AlarmDoorTriggerCondition = AlarmDoorTriggerCondition;

(function (AlarmDoorTriggerCondition) {
  AlarmDoorTriggerCondition[AlarmDoorTriggerCondition["Immediately"] = 0] = "Immediately";
  AlarmDoorTriggerCondition[AlarmDoorTriggerCondition["AfterXNotifications"] = 2] = "AfterXNotifications";
  AlarmDoorTriggerCondition[AlarmDoorTriggerCondition["AfterXMinutes"] = 3] = "AfterXMinutes";
})(AlarmDoorTriggerCondition || (exports.AlarmDoorTriggerCondition = AlarmDoorTriggerCondition = {}));

var AlarmValidityCondition;
exports.AlarmValidityCondition = AlarmValidityCondition;

(function (AlarmValidityCondition) {
  AlarmValidityCondition[AlarmValidityCondition["AtLeastOneConditionMustBeMet"] = 0] = "AtLeastOneConditionMustBeMet";
  AlarmValidityCondition[AlarmValidityCondition["AllConditionsMustBeMet"] = 1] = "AllConditionsMustBeMet";
})(AlarmValidityCondition || (exports.AlarmValidityCondition = AlarmValidityCondition = {}));

var AlarmValidityType;
exports.AlarmValidityType = AlarmValidityType;

(function (AlarmValidityType) {
  AlarmValidityType["MasterData"] = "MASTER_DATA";
  AlarmValidityType["CustomerData"] = "CUSTOMER_DATA";
  AlarmValidityType["TourData"] = "TOUR_DATA";
  AlarmValidityType["TourPoi"] = "TOUR_POI";
  AlarmValidityType["Loaded"] = "LOADED";
  AlarmValidityType["Simple"] = "SIMPLE";
})(AlarmValidityType || (exports.AlarmValidityType = AlarmValidityType = {}));

var AlarmConditionCategory;
exports.AlarmConditionCategory = AlarmConditionCategory;

(function (AlarmConditionCategory) {
  AlarmConditionCategory[AlarmConditionCategory["Position"] = 1] = "Position";
  AlarmConditionCategory[AlarmConditionCategory["Temperature"] = 2] = "Temperature";
  AlarmConditionCategory[AlarmConditionCategory["Setpoint"] = 3] = "Setpoint";
  AlarmConditionCategory[AlarmConditionCategory["Door"] = 4] = "Door";
  AlarmConditionCategory[AlarmConditionCategory["Reefer"] = 5] = "Reefer";
  AlarmConditionCategory[AlarmConditionCategory["ErrorCodes"] = 6] = "ErrorCodes";
  AlarmConditionCategory[AlarmConditionCategory["FuelLevelPercent"] = 7] = "FuelLevelPercent";
  AlarmConditionCategory[AlarmConditionCategory["FuelLevelLiter"] = 8] = "FuelLevelLiter";
  AlarmConditionCategory[AlarmConditionCategory["CouplingStatus"] = 9] = "CouplingStatus";
  AlarmConditionCategory[AlarmConditionCategory["EbsSignals"] = 10] = "EbsSignals";
  AlarmConditionCategory[AlarmConditionCategory["EbsErrors"] = 11] = "EbsErrors";
  AlarmConditionCategory[AlarmConditionCategory["TechnicalValues"] = 12] = "TechnicalValues";
  AlarmConditionCategory[AlarmConditionCategory["Overdue"] = 14] = "Overdue";
  AlarmConditionCategory[AlarmConditionCategory["Flags"] = 16] = "Flags";
  AlarmConditionCategory[AlarmConditionCategory["MissingTemperatureData"] = 17] = "MissingTemperatureData";
  AlarmConditionCategory[AlarmConditionCategory["PositionNotExisting"] = 18] = "PositionNotExisting";
  AlarmConditionCategory[AlarmConditionCategory["DueDate"] = 19] = "DueDate";
  AlarmConditionCategory[AlarmConditionCategory["ReturnAir"] = 25] = "ReturnAir";
  AlarmConditionCategory[AlarmConditionCategory["NoGpsDistanceGain"] = 26] = "NoGpsDistanceGain";
})(AlarmConditionCategory || (exports.AlarmConditionCategory = AlarmConditionCategory = {}));

var AlarmDoorNumber;
exports.AlarmDoorNumber = AlarmDoorNumber;

(function (AlarmDoorNumber) {
  AlarmDoorNumber[AlarmDoorNumber["Door1"] = 1] = "Door1";
  AlarmDoorNumber[AlarmDoorNumber["Door2"] = 2] = "Door2";
  AlarmDoorNumber[AlarmDoorNumber["Door3"] = 3] = "Door3";
})(AlarmDoorNumber || (exports.AlarmDoorNumber = AlarmDoorNumber = {}));

var AlarmOperation;
exports.AlarmOperation = AlarmOperation;

(function (AlarmOperation) {
  AlarmOperation[AlarmOperation["LessThan"] = 0] = "LessThan";
  AlarmOperation[AlarmOperation["Equal"] = 1] = "Equal";
  AlarmOperation[AlarmOperation["NotEqual"] = 2] = "NotEqual";
  AlarmOperation[AlarmOperation["GreaterThan"] = 3] = "GreaterThan";
  AlarmOperation[AlarmOperation["DriveIn"] = 4] = "DriveIn";
  AlarmOperation[AlarmOperation["DriveOut"] = 5] = "DriveOut";
  AlarmOperation[AlarmOperation["Inside"] = 6] = "Inside";
  AlarmOperation[AlarmOperation["Outside"] = 7] = "Outside";
  AlarmOperation[AlarmOperation["ActivatedOpened"] = 8] = "ActivatedOpened";
  AlarmOperation[AlarmOperation["DeactivatedClosed"] = 9] = "DeactivatedClosed";
  AlarmOperation[AlarmOperation["IsTrue"] = 10] = "IsTrue";
  AlarmOperation[AlarmOperation["IsFalse"] = 11] = "IsFalse";
})(AlarmOperation || (exports.AlarmOperation = AlarmOperation = {}));

var AlarmPositionType;
exports.AlarmPositionType = AlarmPositionType;

(function (AlarmPositionType) {
  AlarmPositionType[AlarmPositionType["Poi"] = 0] = "Poi";
  AlarmPositionType[AlarmPositionType["Country"] = 2] = "Country";
  AlarmPositionType[AlarmPositionType["AllPois"] = 3] = "AllPois";
})(AlarmPositionType || (exports.AlarmPositionType = AlarmPositionType = {}));

var AlarmTimeframeType;
exports.AlarmTimeframeType = AlarmTimeframeType;

(function (AlarmTimeframeType) {
  AlarmTimeframeType[AlarmTimeframeType["Always"] = 0] = "Always";
  AlarmTimeframeType[AlarmTimeframeType["Fixed"] = 1] = "Fixed";
  AlarmTimeframeType[AlarmTimeframeType["Rolling"] = 2] = "Rolling";
})(AlarmTimeframeType || (exports.AlarmTimeframeType = AlarmTimeframeType = {}));

var AlarmLoadingType;
exports.AlarmLoadingType = AlarmLoadingType;

(function (AlarmLoadingType) {
  AlarmLoadingType["Loaded"] = "LOADED";
})(AlarmLoadingType || (exports.AlarmLoadingType = AlarmLoadingType = {}));

var AlarmTemperature;
exports.AlarmTemperature = AlarmTemperature;

(function (AlarmTemperature) {
  AlarmTemperature[AlarmTemperature["Temperature1"] = 1] = "Temperature1";
  AlarmTemperature[AlarmTemperature["Temperature2"] = 2] = "Temperature2";
  AlarmTemperature[AlarmTemperature["Temperature3"] = 3] = "Temperature3";
  AlarmTemperature[AlarmTemperature["Temperature4"] = 4] = "Temperature4";
  AlarmTemperature[AlarmTemperature["Temperature5"] = 5] = "Temperature5";
  AlarmTemperature[AlarmTemperature["Temperature6"] = 6] = "Temperature6";
  AlarmTemperature[AlarmTemperature["Temperature7"] = 7] = "Temperature7";
  AlarmTemperature[AlarmTemperature["Temperature8"] = 8] = "Temperature8";
  AlarmTemperature[AlarmTemperature["Temperature9"] = 9] = "Temperature9";
  AlarmTemperature[AlarmTemperature["Temperature10"] = 10] = "Temperature10";
  AlarmTemperature[AlarmTemperature["Temperature11"] = 11] = "Temperature11";
  AlarmTemperature[AlarmTemperature["Temperature12"] = 12] = "Temperature12";
  AlarmTemperature[AlarmTemperature["Temperature13"] = 13] = "Temperature13";
  AlarmTemperature[AlarmTemperature["Temperature14"] = 14] = "Temperature14";
  AlarmTemperature[AlarmTemperature["Temperature15"] = 15] = "Temperature15";
  AlarmTemperature[AlarmTemperature["Temperature16"] = 16] = "Temperature16";
  AlarmTemperature[AlarmTemperature["Temperature17"] = 17] = "Temperature17";
  AlarmTemperature[AlarmTemperature["Temperature18"] = 18] = "Temperature18";
  AlarmTemperature[AlarmTemperature["BoxTemperature"] = 19] = "BoxTemperature";
})(AlarmTemperature || (exports.AlarmTemperature = AlarmTemperature = {}));

var AlarmSetpoint;
exports.AlarmSetpoint = AlarmSetpoint;

(function (AlarmSetpoint) {
  AlarmSetpoint[AlarmSetpoint["Setpoint1"] = 1001] = "Setpoint1";
  AlarmSetpoint[AlarmSetpoint["Setpoint2"] = 1002] = "Setpoint2";
  AlarmSetpoint[AlarmSetpoint["Setpoint3"] = 1003] = "Setpoint3";
})(AlarmSetpoint || (exports.AlarmSetpoint = AlarmSetpoint = {}));

var AlarmReturnAir;
exports.AlarmReturnAir = AlarmReturnAir;

(function (AlarmReturnAir) {
  AlarmReturnAir[AlarmReturnAir["ReturnAir1"] = 1] = "ReturnAir1";
  AlarmReturnAir[AlarmReturnAir["ReturnAir2"] = 2] = "ReturnAir2";
  AlarmReturnAir[AlarmReturnAir["ReturnAir3"] = 3] = "ReturnAir3";
})(AlarmReturnAir || (exports.AlarmReturnAir = AlarmReturnAir = {}));

var AlarmReeferManufacturer;
exports.AlarmReeferManufacturer = AlarmReeferManufacturer;

(function (AlarmReeferManufacturer) {
  AlarmReeferManufacturer[AlarmReeferManufacturer["ThermoKing"] = 0] = "ThermoKing";
  AlarmReeferManufacturer[AlarmReeferManufacturer["Carrier"] = 1] = "Carrier";
  AlarmReeferManufacturer[AlarmReeferManufacturer["Schmitz"] = 2] = "Schmitz";
})(AlarmReeferManufacturer || (exports.AlarmReeferManufacturer = AlarmReeferManufacturer = {}));

var AlarmReeferErrorLevel;
exports.AlarmReeferErrorLevel = AlarmReeferErrorLevel;

(function (AlarmReeferErrorLevel) {
  AlarmReeferErrorLevel[AlarmReeferErrorLevel["Warning"] = 0] = "Warning";
  AlarmReeferErrorLevel[AlarmReeferErrorLevel["Info"] = 1] = "Info";
  AlarmReeferErrorLevel[AlarmReeferErrorLevel["Error"] = 2] = "Error";
})(AlarmReeferErrorLevel || (exports.AlarmReeferErrorLevel = AlarmReeferErrorLevel = {}));

var AlarmShockAxis;
exports.AlarmShockAxis = AlarmShockAxis;

(function (AlarmShockAxis) {
  AlarmShockAxis[AlarmShockAxis["X"] = 0] = "X";
  AlarmShockAxis[AlarmShockAxis["Y"] = 1] = "Y";
  AlarmShockAxis[AlarmShockAxis["Z"] = 2] = "Z";
})(AlarmShockAxis || (exports.AlarmShockAxis = AlarmShockAxis = {}));