import { socketConnected, socketDisconnected } from 'nicbase-reducers';

export const createSocketMiddleware = socket => {
  let currentSocket;
  let dispatch = () => {};

  //
  const handleSocketOpen = () => {
    // tell store that socket is connected and ready
    dispatch(socketConnected());
  };
  //
  const handleSocketClose = () => {
    // tell store that socket disconnected
    dispatch(socketDisconnected());
  };

  const handleMessage = message => {
    // parse message and give store as action
    const decodedMsg = JSON.parse(message.data);
    dispatch(decodedMsg);
  };

  const updateSocket = newSocket => {
    if (!newSocket) {
      return;
    }

    if (currentSocket) {
      // remove old event listeners
      currentSocket.removeEventListener('open', handleSocketOpen);
      currentSocket.removeEventListener('close', handleSocketClose);
      currentSocket.removeEventListener('message', handleMessage);
    }

    // add new listeners
    newSocket.addEventListener('open', handleSocketOpen);
    newSocket.addEventListener('close', handleSocketClose);
    newSocket.addEventListener('message', handleMessage);
    // replace old with new socket
    currentSocket = newSocket;
  };

  // create function to send action to prevent stale closure
  const sendAction = action => {
    currentSocket.send(action);
  };

  // init socket
  updateSocket(socket);

  const middleware = store => {
    // save dispatch for usage in socket handlers
    ({ dispatch } = store);

    return next => action => {
      // if socket is ready and action is a request, send message
      if (currentSocket?.readyState === 1 && action.type.endsWith('_REQUEST')) {
        const encodedMsg = JSON.stringify(action);
        sendAction(encodedMsg);
      }
      return next(action);
    };
  };

  return { updateSocket, middleware };
};
export default createSocketMiddleware;
