"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeGetFleetAdministrationAssets = exports["default"] = void 0;

var _redux = require("redux");

var _reselect = require("reselect");

var _assets = require("./assets.actions");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var assetsById = function assetsById() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _assets.FIND_ASSETS_FOR_FLEET_ADMINISTRATION_REQUEST:
      {
        var _action$body$dynamicP;

        if (((_action$body$dynamicP = action.body.dynamicPaging) === null || _action$body$dynamicP === void 0 ? void 0 : _action$body$dynamicP.pageNumber) > 0) {
          return state;
        }

        return {};
      }

    case _assets.FIND_ASSETS_FOR_FLEET_ADMINISTRATION_SUCCESS:
      {
        var _action$body$assets = action.body.assets,
            assets = _action$body$assets === void 0 ? [] : _action$body$assets;
        var newState = assets.reduce(function (result, asset) {
          var existingAsset = state[asset.id];
          result[asset.id] = _objectSpread(_objectSpread({}, existingAsset), asset);
          return result;
        }, {});
        return _objectSpread(_objectSpread({}, state), newState);
      }

    default:
      return state;
  }
};

var allAssets = function allAssets() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _assets.FIND_ASSETS_FOR_FLEET_ADMINISTRATION_REQUEST:
      {
        var _action$body$dynamicP2;

        if (((_action$body$dynamicP2 = action.body.dynamicPaging) === null || _action$body$dynamicP2 === void 0 ? void 0 : _action$body$dynamicP2.pageNumber) > 0) {
          return state;
        }

        return [];
      }

    case _assets.FIND_ASSETS_FOR_FLEET_ADMINISTRATION_SUCCESS:
      {
        var _action$body$assets2 = action.body.assets,
            assets = _action$body$assets2 === void 0 ? [] : _action$body$assets2;
        var newState = assets.reduce(function (result, asset) {
          if (!state.some(function (assetId) {
            return assetId === asset.id;
          })) {
            result.push(asset.id);
          }

          return result;
        }, []);
        return [].concat(_toConsumableArray(state), _toConsumableArray(newState));
      }

    default:
      return state;
  }
};

var assetReducer = (0, _redux.combineReducers)({
  byId: assetsById,
  allIds: allAssets
});

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _assets.FIND_ASSETS_FOR_FLEET_ADMINISTRATION_REQUEST:
    case _assets.FIND_ASSETS_FOR_FLEET_ADMINISTRATION_SUCCESS:
      {
        var widgetId = action.headers.widgetId;
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, widgetId, assetReducer(state[widgetId], action)));
      }

    default:
      {
        return state;
      }
  }
};

exports["default"] = _default;

var widgetStateSelector = function widgetStateSelector(state, props) {
  return state.fleetAdministration[props.id] || {};
};

var allIdsSelector = (0, _reselect.createSelector)([widgetStateSelector], function (widgetState) {
  return widgetState.allIds || [];
});
var assetsByIdSelector = (0, _reselect.createSelector)([widgetStateSelector], function (widgetState) {
  return widgetState.byId || {};
}); // SELECTORS

var makeGetFleetAdministrationAssets = function makeGetFleetAdministrationAssets() {
  return (0, _reselect.createSelector)([allIdsSelector, assetsByIdSelector], function (allIds, assets) {
    return allIds.map(function (id) {
      return assets[id];
    });
  });
};

exports.makeGetFleetAdministrationAssets = makeGetFleetAdministrationAssets;