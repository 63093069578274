"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _standingTimes = require("./standing-times.actions");

Object.keys(_standingTimes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _standingTimes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _standingTimes[key];
    }
  });
});

var _standingTimes2 = require("./standing-times.reducer");

Object.keys(_standingTimes2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _standingTimes2[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _standingTimes2[key];
    }
  });
});