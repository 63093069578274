"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateRentalDetails = exports.rentVehicles = exports.findVehiclesForRentalTool = exports.createRentalToolExport = exports.cancelVehicleRentals = exports.UPDATE_RENTAL_DETAILS_SUCCESS = exports.UPDATE_RENTAL_DETAILS_REQUEST = exports.UPDATE_RENTAL_DETAILS_ERROR = exports.RENT_VEHICLES_SUCCESS = exports.RENT_VEHICLES_REQUEST = exports.RENT_VEHICLES_ERROR = exports.FIND_VEHICLES_FOR_RENTAL_TOOL_SUCCESS = exports.FIND_VEHICLES_FOR_RENTAL_TOOL_REQUEST = exports.FIND_VEHICLES_FOR_RENTAL_TOOL_ERROR = exports.CREATE_RENTAL_EXPORT_SUCCESS = exports.CREATE_RENTAL_EXPORT_REQUEST = exports.CREATE_RENTAL_EXPORT_ERROR = exports.CANCEL_VEHICLE_RENTALS_SUCCESS = exports.CANCEL_VEHICLE_RENTALS_REQUEST = exports.CANCEL_VEHICLE_RENTALS_ERROR = void 0;
var FIND_VEHICLES_FOR_RENTAL_TOOL_REQUEST = 'FIND_VEHICLES_FOR_RENTAL_TOOL_REQUEST';
exports.FIND_VEHICLES_FOR_RENTAL_TOOL_REQUEST = FIND_VEHICLES_FOR_RENTAL_TOOL_REQUEST;
var FIND_VEHICLES_FOR_RENTAL_TOOL_SUCCESS = 'FIND_VEHICLES_FOR_RENTAL_TOOL_SUCCESS';
exports.FIND_VEHICLES_FOR_RENTAL_TOOL_SUCCESS = FIND_VEHICLES_FOR_RENTAL_TOOL_SUCCESS;
var FIND_VEHICLES_FOR_RENTAL_TOOL_ERROR = 'FIND_VEHICLES_FOR_RENTAL_TOOL_ERROR';
exports.FIND_VEHICLES_FOR_RENTAL_TOOL_ERROR = FIND_VEHICLES_FOR_RENTAL_TOOL_ERROR;

var findVehiclesForRentalTool = function findVehiclesForRentalTool(portalId) {
  var dynamicFilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    type: FIND_VEHICLES_FOR_RENTAL_TOOL_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      dynamicFilters: dynamicFilters
    }
  };
};

exports.findVehiclesForRentalTool = findVehiclesForRentalTool;
var RENT_VEHICLES_REQUEST = 'RENT_VEHICLES_REQUEST';
exports.RENT_VEHICLES_REQUEST = RENT_VEHICLES_REQUEST;
var RENT_VEHICLES_SUCCESS = 'RENT_VEHICLES_SUCCESS';
exports.RENT_VEHICLES_SUCCESS = RENT_VEHICLES_SUCCESS;
var RENT_VEHICLES_ERROR = 'RENT_VEHICLES_ERROR';
exports.RENT_VEHICLES_ERROR = RENT_VEHICLES_ERROR;

var rentVehicles = function rentVehicles(portalId, vehicles) {
  return {
    type: RENT_VEHICLES_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      vehicles: vehicles
    }
  };
};

exports.rentVehicles = rentVehicles;
var CANCEL_VEHICLE_RENTALS_REQUEST = 'CANCEL_VEHICLE_RENTALS_REQUEST';
exports.CANCEL_VEHICLE_RENTALS_REQUEST = CANCEL_VEHICLE_RENTALS_REQUEST;
var CANCEL_VEHICLE_RENTALS_SUCCESS = 'CANCEL_VEHICLE_RENTALS_SUCCESS';
exports.CANCEL_VEHICLE_RENTALS_SUCCESS = CANCEL_VEHICLE_RENTALS_SUCCESS;
var CANCEL_VEHICLE_RENTALS_ERROR = 'CANCEL_VEHICLE_RENTALS_ERROR';
exports.CANCEL_VEHICLE_RENTALS_ERROR = CANCEL_VEHICLE_RENTALS_ERROR;

var cancelVehicleRentals = function cancelVehicleRentals(portalId, vehicles) {
  return {
    type: CANCEL_VEHICLE_RENTALS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      vehicles: vehicles
    }
  };
};

exports.cancelVehicleRentals = cancelVehicleRentals;
var UPDATE_RENTAL_DETAILS_REQUEST = 'UPDATE_RENTAL_DETAILS_REQUEST';
exports.UPDATE_RENTAL_DETAILS_REQUEST = UPDATE_RENTAL_DETAILS_REQUEST;
var UPDATE_RENTAL_DETAILS_SUCCESS = 'UPDATE_RENTAL_DETAILS_SUCCESS';
exports.UPDATE_RENTAL_DETAILS_SUCCESS = UPDATE_RENTAL_DETAILS_SUCCESS;
var UPDATE_RENTAL_DETAILS_ERROR = 'UPDATE_RENTAL_DETAILS_ERROR';
exports.UPDATE_RENTAL_DETAILS_ERROR = UPDATE_RENTAL_DETAILS_ERROR;

var updateRentalDetails = function updateRentalDetails(portalId, vehicle) {
  return {
    type: UPDATE_RENTAL_DETAILS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      vehicle: vehicle
    }
  };
};

exports.updateRentalDetails = updateRentalDetails;
var CREATE_RENTAL_EXPORT_REQUEST = 'CREATE_RENTAL_EXPORT_REQUEST';
exports.CREATE_RENTAL_EXPORT_REQUEST = CREATE_RENTAL_EXPORT_REQUEST;
var CREATE_RENTAL_EXPORT_SUCCESS = 'CREATE_RENTAL_EXPORT_SUCCESS';
exports.CREATE_RENTAL_EXPORT_SUCCESS = CREATE_RENTAL_EXPORT_SUCCESS;
var CREATE_RENTAL_EXPORT_ERROR = 'CREATE_RENTAL_EXPORT_ERROR';
exports.CREATE_RENTAL_EXPORT_ERROR = CREATE_RENTAL_EXPORT_ERROR;

var createRentalToolExport = function createRentalToolExport(portalId, dynamicFilters, visibleColumns) {
  return {
    type: CREATE_RENTAL_EXPORT_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      filters: {
        dynamicFilters: dynamicFilters
      },
      visibleColumns: visibleColumns
    }
  };
};

exports.createRentalToolExport = createRentalToolExport;