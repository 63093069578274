"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _assets = require("./assets.actions");

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _assets.REPLACE_UNASSIGNED_HW_ASSETS_SUCCESS:
      {
        return action.body.unassignedHwAssets;
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
    case _assets.REPLACE_UNASSIGNED_HW_ASSETS_ERROR:
      {
        return [];
      }

    default:
      {
        return state;
      }
  }
};

exports["default"] = _default;