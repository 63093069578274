"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FIND_PORTALS_FOR_ASSET_ALLOCATION_SUCCESS = exports.FIND_PORTALS_FOR_ASSET_ALLOCATION_REQUEST = exports.FIND_PORTALS_FOR_ASSET_ALLOCATION_ERROR = void 0;
exports.findPortalsForAssetAllocation = findPortalsForAssetAllocation;
var FIND_PORTALS_FOR_ASSET_ALLOCATION_REQUEST = 'FIND_PORTALS_FOR_ASSET_ALLOCATION_REQUEST';
exports.FIND_PORTALS_FOR_ASSET_ALLOCATION_REQUEST = FIND_PORTALS_FOR_ASSET_ALLOCATION_REQUEST;
var FIND_PORTALS_FOR_ASSET_ALLOCATION_SUCCESS = 'FIND_PORTALS_FOR_ASSET_ALLOCATION_SUCCESS';
exports.FIND_PORTALS_FOR_ASSET_ALLOCATION_SUCCESS = FIND_PORTALS_FOR_ASSET_ALLOCATION_SUCCESS;
var FIND_PORTALS_FOR_ASSET_ALLOCATION_ERROR = 'FIND_PORTALS_FOR_ASSET_ALLOCATION_ERROR'; // used by box administration, accounting administration and portal dual list

exports.FIND_PORTALS_FOR_ASSET_ALLOCATION_ERROR = FIND_PORTALS_FOR_ASSET_ALLOCATION_ERROR;

function findPortalsForAssetAllocation() {
  return {
    type: FIND_PORTALS_FOR_ASSET_ALLOCATION_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {}
  };
}