"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useOnlyHistoricalAssets = exports["default"] = void 0;

var _react = require("react");

var _usePortalAssets2 = require("./usePortalAssets");

var _usePortalCoupledAssets = require("./usePortalCoupledAssets");

var _usePortalHistoricalAssets = require("./usePortalHistoricalAssets");

var _usePortalHistoricalCoupledAssets = require("./usePortalHistoricalCoupledAssets");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var useOnlyHistoricalAssets = function useOnlyHistoricalAssets(portalId) {
  var _usePortalAssets = (0, _usePortalAssets2.usePortalAssets)(portalId, {
    enabled: Boolean(portalId)
  }),
      _usePortalAssets$data = _usePortalAssets.data,
      assets = _usePortalAssets$data === void 0 ? [] : _usePortalAssets$data;

  var _usePortalCoupledAsse = (0, _usePortalCoupledAssets.usePortalCoupledAssets)(portalId, {
    enabled: Boolean(portalId)
  }),
      _usePortalCoupledAsse2 = _usePortalCoupledAsse.data,
      coupledAssets = _usePortalCoupledAsse2 === void 0 ? [] : _usePortalCoupledAsse2;

  var _usePortalHistoricalA = (0, _usePortalHistoricalAssets.usePortalHistoricalAssets)(portalId, {
    enabled: Boolean(portalId)
  }),
      _usePortalHistoricalA2 = _usePortalHistoricalA.data,
      historicalAssets = _usePortalHistoricalA2 === void 0 ? [] : _usePortalHistoricalA2;

  var _usePortalHistoricalC = (0, _usePortalHistoricalCoupledAssets.usePortalHistoricalCoupledAssets)(portalId, {
    enabled: Boolean(portalId)
  }),
      _usePortalHistoricalC2 = _usePortalHistoricalC.data,
      historicalCoupledAssets = _usePortalHistoricalC2 === void 0 ? [] : _usePortalHistoricalC2;

  return (0, _react.useMemo)(function () {
    return [].concat(_toConsumableArray(historicalAssets.filter(function (historicalAsset) {
      return !assets.some(function (asset) {
        return asset.id === historicalAsset.id;
      });
    })), _toConsumableArray(historicalCoupledAssets.filter(function (historicalCoupledAsset) {
      return !coupledAssets.some(function (coupledAsset) {
        return coupledAsset.id === historicalCoupledAsset.id;
      });
    })));
  }, [assets, coupledAssets, historicalAssets, historicalCoupledAssets]);
};

exports.useOnlyHistoricalAssets = useOnlyHistoricalAssets;
var _default = useOnlyHistoricalAssets;
exports["default"] = _default;