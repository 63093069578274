"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _impersonation = require("./impersonation.actions");

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    email: null
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _impersonation.IMPERSONATE_USER:
      {
        var email = action.body.email;
        return Object.assign({}, state, {
          email: email
        });
      }

    case _impersonation.CANCEL_IMPERSONATION:
      return {
        email: null
      };

    default:
      return state;
  }
};

exports["default"] = _default;