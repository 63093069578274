"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.timezoneToUTC = exports["default"] = void 0;

var moment = require('moment-timezone');

var timezoneToUTC = function timezoneToUTC(date, timezone) {
  return moment.tz(date, timezone).tz('UTC').valueOf();
};

exports.timezoneToUTC = timezoneToUTC;
var _default = timezoneToUTC;
exports["default"] = _default;