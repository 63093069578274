"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _hooks = require("@nicbase/hooks");

var _helpers = require("@turf/helpers");

var _meta = require("@turf/meta");

var _baseMap = require("../base-map");

var _geojson = _interopRequireWildcard(require("../geojson"));

var _geojsonEditor = require("../geojson-editor");

var _propTypes2 = require("../propTypes");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function debounce(callback, wait) {
  var timerId;
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    clearTimeout(timerId);
    timerId = setTimeout(function () {
      callback.apply(void 0, args);
    }, wait);
  };
}

var coordinatesFromGooglePolygon = function coordinatesFromGooglePolygon(overlay) {
  return overlay.getPaths().getArray().map(function (path) {
    var coords = path.getArray().map(function (_ref) {
      var lng = _ref.lng,
          lat = _ref.lat;
      return [lng(), lat()];
    }); // close polygon correctly

    var _coords$ = _slicedToArray(coords[0], 2),
        lngStart = _coords$[0],
        latStart = _coords$[1];

    var _coords = _slicedToArray(coords[coords.length - 1], 2),
        lngEnd = _coords[0],
        latEnd = _coords[1];

    if (lngStart !== lngEnd || latStart !== latEnd) {
      coords.push(coords[0]);
    }

    return coords;
  });
};

var EditingMode = function EditingMode(_ref2) {
  var _mapInstances$google;

  var mode = _ref2.mode,
      features = _ref2.features,
      featureStyle = _ref2.featureStyle,
      onSelect = _ref2.onSelect,
      onUpdate = _ref2.onUpdate;
  var mapInstances = (0, _baseMap.useMapInstance)();
  var google = (_mapInstances$google = mapInstances.google) !== null && _mapInstances$google !== void 0 ? _mapInstances$google : {};
  var maps = google.maps;

  var _ref3 = maps !== null && maps !== void 0 ? maps : {},
      Circle = _ref3.Circle,
      Polygon = _ref3.Polygon,
      event = _ref3.event;

  var drawingMode = (0, _react.useMemo)(function () {
    switch (mode) {
      case _geojsonEditor.editModes.drawCircle:
        {
          return 'circle';
        }

      case _geojsonEditor.editModes.drawPolygon:
        {
          return 'polygon';
        }

      case _geojsonEditor.editModes.edit:
      default:
        {
          return null;
        }
    }
  }, [mode]);
  var featureCollection = (0, _react.useMemo)(function () {
    if (features.type === 'FeatureCollection') {
      return features;
    }

    if (features.type === 'Feature') {
      return (0, _helpers.featureCollection)([features]);
    }

    return (0, _helpers.featureCollection)(features);
  }, [features]);
  var featuresChanged = (0, _hooks.usePrevious)(features) !== features;
  var drawingManager = (0, _react.useRef)();
  var googleFeatures = (0, _react.useRef)([]); // handle map instance changes

  (0, _react.useEffect)(function () {
    if (drawingManager.current) {
      drawingManager.current.setMap(google.map);
      googleFeatures.current.forEach(function (feature) {
        return feature.setMap(google.map);
      });
    }
  }, [google.map]); // handle change of drawing mode

  (0, _react.useEffect)(function () {
    if (drawingManager.current) {
      drawingManager.current.setDrawingMode(drawingMode);
      googleFeatures.current.forEach(function (feature) {
        return feature.setOptions({
          editable: drawingMode === null,
          draggable: drawingMode === null
        });
      });
    }
  }, [drawingMode]); // handle change of feature style

  (0, _react.useEffect)(function () {
    if (drawingManager.current) {
      drawingManager.current.setOptions({
        circleOptions: _objectSpread({}, featureStyle),
        polygonOptions: _objectSpread({}, featureStyle)
      });
      googleFeatures.current.forEach(function (feature) {
        return feature.setOptions(_objectSpread({}, featureStyle));
      });
    }
  }, [featureStyle]);
  var replaceFeature = (0, _react.useCallback)(function (_ref4) {
    var featureIndex = _ref4.featureIndex,
        feature = _ref4.feature;
    var updatedCollection = (0, _helpers.featureCollection)(featureCollection.features.map(function (currentFeature, index) {
      if (index === featureIndex) {
        return feature;
      }

      return currentFeature;
    }));
    onUpdate(updatedCollection);
  }, [featureCollection.features, onUpdate]);
  var redrawFeatures = (0, _react.useCallback)(function () {
    googleFeatures.current.forEach(function (feature) {
      feature.setMap(null);
      event.clearInstanceListeners(feature);
    });
    googleFeatures.current = []; // create google classes for features and draw to map

    (0, _meta.featureEach)(featureCollection, function (feature, featureIndex) {
      var _feature$geometry, _feature$geometry2;

      if (((_feature$geometry = feature.geometry) === null || _feature$geometry === void 0 ? void 0 : _feature$geometry.type) === 'Polygon') {
        var polygonFeature = new Polygon(_objectSpread(_objectSpread({
          paths: feature.geometry.coordinates.map(function (path) {
            return (// throw away last point because google doesn't follow geojson spec (start+end must be equal) and otherwise the end point
              // overlaps start point. So the start cannot be moved
              path.slice(0, -1).map(function (_ref5) {
                var _ref6 = _slicedToArray(_ref5, 2),
                    lng = _ref6[0],
                    lat = _ref6[1];

                return {
                  lat: lat,
                  lng: lng
                };
              })
            );
          })
        }, featureStyle), {}, {
          editable: drawingMode === null,
          draggable: drawingMode === null
        }));
        polygonFeature.addListener('click', function () {
          polygonFeature.setEditable(true);
          polygonFeature.setDraggable(true);
          onSelect({
            selectedFeature: feature,
            selectedFeatureIndex: featureIndex
          });
        });
        polygonFeature.getPaths().forEach(function (path) {
          path.addListener('insert_at', function () {
            var polygon = (0, _helpers.polygon)(coordinatesFromGooglePolygon(polygonFeature));
            replaceFeature({
              featureIndex: featureIndex,
              feature: polygon
            });
          });
          path.addListener('set_at', debounce(function () {
            if (polygonFeature.getEditable()) {
              var polygon = (0, _helpers.polygon)(coordinatesFromGooglePolygon(polygonFeature));
              replaceFeature({
                featureIndex: featureIndex,
                feature: polygon
              });
            }
          }, 200));
          path.addListener('remove_at', function () {
            var polygon = (0, _helpers.polygon)(coordinatesFromGooglePolygon(polygonFeature));
            replaceFeature({
              featureIndex: featureIndex,
              feature: polygon
            });
          });
        });
        googleFeatures.current.push(polygonFeature);
        polygonFeature.setMap(google.map);
      }

      if (((_feature$geometry2 = feature.geometry) === null || _feature$geometry2 === void 0 ? void 0 : _feature$geometry2.type) === 'Point' && feature.properties.radius) {
        var _feature$geometry$coo = _slicedToArray(feature.geometry.coordinates, 2),
            lng = _feature$geometry$coo[0],
            lat = _feature$geometry$coo[1];

        var circleFeature = new Circle(_objectSpread(_objectSpread({
          center: {
            lat: lat,
            lng: lng
          },
          radius: feature.properties.radius
        }, featureStyle), {}, {
          editable: drawingMode === null,
          draggable: drawingMode === null
        }));
        circleFeature.addListener('click', function () {
          circleFeature.setEditable(true);
          circleFeature.setDraggable(true);
          onSelect({
            selectedFeature: feature,
            selectedFeatureIndex: featureIndex
          });
        });
        circleFeature.addListener('radius_changed', function () {
          var circle = _objectSpread(_objectSpread({}, feature), {}, {
            properties: _objectSpread(_objectSpread({}, feature.properties), {}, {
              radius: circleFeature.getRadius()
            })
          });

          replaceFeature({
            featureIndex: featureIndex,
            feature: circle
          });
        });
        circleFeature.addListener('center_changed', debounce(function () {
          if (circleFeature.getEditable()) {
            var center = circleFeature.getCenter();
            var circle = (0, _helpers.point)([center.lng(), center.lat()], _objectSpread({}, feature.properties));
            replaceFeature({
              featureIndex: featureIndex,
              feature: circle
            });
          }
        }, 200));
        googleFeatures.current.push(circleFeature);
        circleFeature.setMap(google.map);
      }
    });
  }, [Circle, Polygon, drawingMode, event, featureCollection, featureStyle, google.map, onSelect, replaceFeature]); // handle completion of drawn feature

  var addFeature = (0, _react.useCallback)(function (_ref7) {
    var overlay = _ref7.overlay,
        type = _ref7.type;

    switch (type) {
      case 'polygon':
        {
          var polygon = (0, _helpers.polygon)(coordinatesFromGooglePolygon(overlay));
          var updatedFeatures = (0, _helpers.featureCollection)([].concat(_toConsumableArray(featureCollection.features), [polygon]));
          onUpdate(updatedFeatures);
          overlay.setMap(null);
          break;
        }

      case 'circle':
        {
          var center = overlay.getCenter();
          var circle = (0, _helpers.point)([center.lng(), center.lat()], {
            radius: overlay.getRadius(),
            shape: 'Circle'
          });

          var _updatedFeatures = (0, _helpers.featureCollection)([].concat(_toConsumableArray(featureCollection.features), [circle]));

          onUpdate(_updatedFeatures);
          overlay.setMap(null);
          break;
        }

      default:
    }
  }, [featureCollection.features, onUpdate]); // handle feature changes

  (0, _react.useEffect)(function () {
    if (featuresChanged && google.map && event) {
      event.clearListeners(drawingManager.current, 'overlaycomplete');
      drawingManager.current.addListener('overlaycomplete', addFeature);
      redrawFeatures();
    }
  }, [addFeature, event, featuresChanged, google.map, redrawFeatures]);
  (0, _react.useEffect)(function () {
    if (google.maps && google.map && !drawingManager.current) {
      drawingManager.current = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        drawingMode: drawingMode,
        circleOptions: _objectSpread({}, featureStyle),
        polygonOptions: _objectSpread({}, featureStyle)
      });
      drawingManager.current.setMap(google.map);
      drawingManager.current.addListener('overlaycomplete', addFeature); // initial draw of features

      redrawFeatures();
    }
  }, [addFeature, drawingMode, featureStyle, google.map, google.maps, redrawFeatures]);
  return null;
};

EditingMode.propTypes = {
  features: _propTypes["default"].oneOfType([(0, _propTypes2.FeatureShape)(), (0, _propTypes2.FeatureCollectionShape)(), _propTypes["default"].arrayOf((0, _propTypes2.FeatureShape)())]),
  featureStyle: _geojson["default"].propTypes.featureStyle,
  mode: _propTypes["default"].oneOf(['EDIT', 'DRAW_CIRCLE', 'DRAW_POLYGON']),
  onSelect: _propTypes["default"].func,
  onUpdate: _propTypes["default"].func
};

var noop = function noop() {};

EditingMode.defaultProps = {
  features: {
    type: 'FeatureCollection',
    features: []
  },
  featureStyle: _geojson.defaultStyle,
  mode: null,
  onSelect: noop,
  onUpdate: noop
};
var _default = EditingMode;
exports["default"] = _default;