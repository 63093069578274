"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS = exports.UPDATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST = exports.UPDATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_ERROR = exports.FIND_ASSETGROUPS_SUCCESS = exports.FIND_ASSETGROUPS_REQUEST = exports.FIND_ASSETGROUPS_ERROR = exports.DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS = exports.DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST = exports.DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_ERROR = exports.CREATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS = exports.CREATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST = exports.CREATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_ERROR = void 0;
exports.createAssetgroupForAssetgroupAdministration = createAssetgroupForAssetgroupAdministration;
exports.deleteAssetgroupForAssetgroupAdministration = deleteAssetgroupForAssetgroupAdministration;
exports.getAssetgroups = getAssetgroups;
exports.updateAssetgroupForAssetgroupAdministration = updateAssetgroupForAssetgroupAdministration;
var FIND_ASSETGROUPS_REQUEST = 'FIND_ASSETGROUPS_FOR_ADMINISTRATION_REQUEST';
exports.FIND_ASSETGROUPS_REQUEST = FIND_ASSETGROUPS_REQUEST;
var FIND_ASSETGROUPS_SUCCESS = 'FIND_ASSETGROUPS_FOR_ADMINISTRATION_SUCCESS';
exports.FIND_ASSETGROUPS_SUCCESS = FIND_ASSETGROUPS_SUCCESS;
var FIND_ASSETGROUPS_ERROR = 'FIND_ASSETGROUPS_INSIDE_PORTAL_ERROR';
exports.FIND_ASSETGROUPS_ERROR = FIND_ASSETGROUPS_ERROR;

function getAssetgroups(portalId) {
  return {
    type: FIND_ASSETGROUPS_REQUEST,
    headers: {
      version: 'v2',
      portalId: portalId
    },
    body: {
      dynamicFilters: {
        type: 'NotEqualFilter',
        attributeValue: {
          value: 'GLOBAL',
          type: 'String'
        },
        attributeName: 'assetGroup.parent.type'
      },
      dynamicPaging: null,
      dynamicSorters: null
    }
  };
}

var CREATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS = 'CREATE_ASSET_ON_GROUP_LEVEL_FOR_ADMINISTRATION_SUCCESS';
exports.CREATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS = CREATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS;
var CREATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_ERROR = 'CREATE_ASSET_ON_GROUP_LEVEL_FOR_ADMINISTRATION_ERROR';
exports.CREATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_ERROR = CREATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_ERROR;
var CREATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST = 'CREATE_ASSET_ON_GROUP_LEVEL_FOR_ADMINISTRATION_REQUEST';
exports.CREATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST = CREATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST;

function createAssetgroupForAssetgroupAdministration() {
  var assetgroup = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: CREATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST,
    headers: {
      version: 'v2',
      portalId: portalId
    },
    body: {
      assetGroup: assetgroup,
      // TODO: Delete when new asset version is available
      assetgroup: assetgroup
    }
  };
}

var UPDATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS = 'UPDATE_ASSET_ON_GROUP_LEVEL_FOR_ADMINISTRATION_SUCCESS';
exports.UPDATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS = UPDATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS;
var UPDATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_ERROR = 'UPDATE_ASSET_ON_GROUP_LEVEL_FOR_ADMINISTRATION_ERROR';
exports.UPDATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_ERROR = UPDATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_ERROR;
var UPDATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST = 'UPDATE_ASSET_ON_GROUP_LEVEL_FOR_ADMINISTRATION_REQUEST';
exports.UPDATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST = UPDATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST;

function updateAssetgroupForAssetgroupAdministration() {
  var assetgroup = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: UPDATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST,
    headers: {
      version: 'v2',
      portalId: portalId
    },
    body: {
      assetGroup: assetgroup,
      // TODO: Delete when new asset version is available
      assetgroup: assetgroup
    }
  };
}

var DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS = 'DELETE_ASSET_ON_GROUP_LEVEL_FOR_ADMINISTRATION_SUCCESS';
exports.DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS = DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS;
var DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_ERROR = 'DELETE_ASSET_ON_GROUP_LEVEL_FOR_ADMINISTRATION_ERROR';
exports.DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_ERROR = DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_ERROR;
var DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST = 'DELETE_ASSET_ON_GROUP_LEVEL_FOR_ADMINISTRATION_REQUEST';
exports.DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST = DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST;

function deleteAssetgroupForAssetgroupAdministration() {
  var assetGroupId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST,
    headers: {
      version: 'v2',
      portalId: portalId
    },
    body: {
      id: assetGroupId
    }
  };
}