"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findContentBoxesForUser = exports.clearContentBoxesForUser = exports.FIND_CONTENT_BOXES_FOR_USER_SUCCESS = exports.FIND_CONTENT_BOXES_FOR_USER_REQUEST = exports.FIND_CONTENT_BOXES_FOR_USER_ERROR = exports.CLEAR_CONTENT_BOXES_FOR_USER = void 0;
var FIND_CONTENT_BOXES_FOR_USER_REQUEST = 'FIND_CONTENT_BOXES_FOR_USER_REQUEST';
exports.FIND_CONTENT_BOXES_FOR_USER_REQUEST = FIND_CONTENT_BOXES_FOR_USER_REQUEST;
var FIND_CONTENT_BOXES_FOR_USER_SUCCESS = 'FIND_CONTENT_BOXES_FOR_USER_SUCCESS';
exports.FIND_CONTENT_BOXES_FOR_USER_SUCCESS = FIND_CONTENT_BOXES_FOR_USER_SUCCESS;
var FIND_CONTENT_BOXES_FOR_USER_ERROR = 'FIND_CONTENT_BOXES_FOR_USER_ERROR';
exports.FIND_CONTENT_BOXES_FOR_USER_ERROR = FIND_CONTENT_BOXES_FOR_USER_ERROR;

var findContentBoxesForUser = function findContentBoxesForUser(pageNumber, pageSize) {
  return {
    type: FIND_CONTENT_BOXES_FOR_USER_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      dynamicSorters: [{
        sortOrder: 'desc',
        attributeName: 'order'
      }],
      dynamicPaging: {
        pageNumber: pageNumber,
        pageSize: pageSize
      },
      dynamicFilters: null
    }
  };
};

exports.findContentBoxesForUser = findContentBoxesForUser;
var CLEAR_CONTENT_BOXES_FOR_USER = 'CLEAR_CONTENT_BOXES_FOR_USER';
exports.CLEAR_CONTENT_BOXES_FOR_USER = CLEAR_CONTENT_BOXES_FOR_USER;

var clearContentBoxesForUser = function clearContentBoxesForUser() {
  return {
    type: CLEAR_CONTENT_BOXES_FOR_USER
  };
};

exports.clearContentBoxesForUser = clearContentBoxesForUser;