"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _portalAdministration = require("./portalAdministration.actions");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _portalAdministration.FIND_PORTALS_BY_FILTER_SUCCESS:
      {
        var _action$body$portals = action.body.portals,
            portals = _action$body$portals === void 0 ? [] : _action$body$portals;
        var mappedPortals = portals.reduce(function (result, portal) {
          result[portal.id] = portal;
          return result;
        }, {});
        return mappedPortals;
      }

    case _portalAdministration.CREATE_PORTAL_SUCCESS:
    case _portalAdministration.UPDATE_PORTAL_SUCCESS:
      {
        var portal = action.body.portal;
        return Object.assign({}, state, _defineProperty({}, portal.id, portal));
      }

    case _portalAdministration.DELETE_PORTAL_SUCCESS:
      {
        var portalId = action.body.portalId;
        return Object.keys(state).reduce(function (result, id) {
          if (id === String(portalId)) {
            return result;
          }

          result[id] = state[id];
          return result;
        }, {});
      }

    default:
      return state;
  }
};

exports["default"] = _default;