"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FilterOperator = exports.FilterAttributeType = exports.ConditionOperator = exports.CompareOperator = void 0;
var ConditionOperator;
exports.ConditionOperator = ConditionOperator;

(function (ConditionOperator) {
  ConditionOperator["OrCondition"] = "OrCondition";
  ConditionOperator["AndCondition"] = "AndCondition";
})(ConditionOperator || (exports.ConditionOperator = ConditionOperator = {}));

var FilterOperator;
exports.FilterOperator = FilterOperator;

(function (FilterOperator) {
  FilterOperator["LikeFilter"] = "LikeFilter";
  FilterOperator["EqualFilter"] = "EqualFilter";
  FilterOperator["NotEqualFilter"] = "NotEqualFilter";
  FilterOperator["InFilter"] = "InFilter";
  FilterOperator["NotInFilter"] = "NotInFilter";
  FilterOperator["CompareFilter"] = "CompareFilter";
  FilterOperator["NullFilter"] = "NullFilter";
})(FilterOperator || (exports.FilterOperator = FilterOperator = {}));

var FilterAttributeType;
exports.FilterAttributeType = FilterAttributeType;

(function (FilterAttributeType) {
  FilterAttributeType["Integer"] = "Integer";
  FilterAttributeType["Double"] = "Double";
  FilterAttributeType["String"] = "String";
  FilterAttributeType["Boolean"] = "Boolean";
  FilterAttributeType["DateTime"] = "DateTime";
  FilterAttributeType["ObjectId"] = "ObjectId";
})(FilterAttributeType || (exports.FilterAttributeType = FilterAttributeType = {}));

var CompareOperator;
exports.CompareOperator = CompareOperator;

(function (CompareOperator) {
  CompareOperator["Gte"] = "GTE";
  CompareOperator["Lte"] = "LTE";
  CompareOperator["Gt"] = "GT";
  CompareOperator["Lt"] = "LT";
})(CompareOperator || (exports.CompareOperator = CompareOperator = {}));