"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FIND_ALARM_BY_ID_SUCCESS = exports.FIND_ALARM_BY_ID_REQUEST = exports.FIND_ALARM_BY_ID_ERROR = void 0;
exports.findAlarmById = findAlarmById;

/**
 * Created by PKing on 06.10.2017
 */
var FIND_ALARM_BY_ID_REQUEST = 'FIND_ALARM_BY_ID_REQUEST';
exports.FIND_ALARM_BY_ID_REQUEST = FIND_ALARM_BY_ID_REQUEST;
var FIND_ALARM_BY_ID_SUCCESS = 'FIND_ALARM_BY_ID_SUCCESS';
exports.FIND_ALARM_BY_ID_SUCCESS = FIND_ALARM_BY_ID_SUCCESS;
var FIND_ALARM_BY_ID_ERROR = 'FIND_ALARM_BY_ID_ERROR';
exports.FIND_ALARM_BY_ID_ERROR = FIND_ALARM_BY_ID_ERROR;

function findAlarmById(alarmId, portalId) {
  return {
    type: FIND_ALARM_BY_ID_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      alarmId: alarmId
    }
  };
}