"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var useDidUpdate = function useDidUpdate(callback, dependencies) {
  var hasMount = (0, _react.useRef)(false);
  (0, _react.useEffect)(function () {
    if (hasMount.current) {
      callback();
    } else {
      hasMount.current = true;
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, dependencies);
};

var _default = useDidUpdate;
exports["default"] = _default;