"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  degC: 'degF',
  bar: 'psi',
  cm: 'inch',
  m: 'yard',
  km: 'mile',
  g: 'ounce',
  kg: 'lbs',
  tonne: 'ton',
  litre: 'gallon',
  kmh: 'mph',
  m3: 'cuyd',
  percent: 'percent'
};
exports["default"] = _default;