"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Home", {
  enumerable: true,
  get: function get() {
    return _home["default"];
  }
});
Object.defineProperty(exports, "HomeBGL", {
  enumerable: true,
  get: function get() {
    return _homeBgl["default"];
  }
});
Object.defineProperty(exports, "HomeLight", {
  enumerable: true,
  get: function get() {
    return _homeLight["default"];
  }
});
exports["default"] = void 0;

var _home = _interopRequireDefault(require("./home/home"));

var _homeBgl = _interopRequireDefault(require("./home-bgl/home-bgl"));

var _homeLight = _interopRequireDefault(require("./home-light/home-light"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = _home["default"];
exports["default"] = _default;