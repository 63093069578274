"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _coupledAssets = require("./coupledAssets.actions");

var INITIAL_STATE = [];

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return INITIAL_STATE;

    case _coupledAssets.FIND_COUPLED_ASSETS_SUCCESS:
      return action.body.assets;

    case _coupledAssets.CREATE_COUPLED_ASSET_SUCCESS:
      {
        var asset = action.body.asset;
        return state.concat(asset);
      }

    case _coupledAssets.DISSOLVE_COUPLED_ASSET_SUCCESS:
    case _coupledAssets.UPDATE_COUPLED_ASSET_SUCCESS:
      {
        var _asset = action.body.asset;
        return state.map(function (coupledAsset) {
          if (coupledAsset.id === _asset.id) {
            return _asset;
          }

          return coupledAsset;
        });
      }

    default:
      return state;
  }
};

exports["default"] = _default;