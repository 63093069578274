"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _contentBoxAdministration = require("./content-box-administration.actions");

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _contentBoxAdministration.UPDATE_CONTENT_BOXES_ORDER_SUCCESS:
    case _contentBoxAdministration.FIND_CONTENT_BOXES_SUCCESS:
      {
        return action.body.contentBoxes;
      }

    case _contentBoxAdministration.CREATE_CONTENT_BOX_SUCCESS:
      {
        var newState = state.slice();
        newState.push(action.body.contentBox);
        return newState;
      }

    case _contentBoxAdministration.UPDATE_CONTENT_BOX_SUCCESS:
      {
        return state.map(function (contentBox) {
          return contentBox.id === action.body.contentBox.id ? Object.assign({}, contentBox, action.body.contentBox) : contentBox;
        });
      }

    case _contentBoxAdministration.DELETE_CONTENT_BOX_SUCCESS:
      {
        return state.filter(function (cb) {
          return cb.id !== action.body.id;
        });
      }

    default:
      return state;
  }
};

exports["default"] = _default;