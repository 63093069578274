"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findLatestData = exports.FIND_LATESTDATA_SUCCESS = exports.FIND_LATESTDATA_REQUEST = exports.FIND_LATESTDATA_ERROR = void 0;
var FIND_LATESTDATA_REQUEST = 'FIND_LATESTDATA_REQUEST';
exports.FIND_LATESTDATA_REQUEST = FIND_LATESTDATA_REQUEST;
var FIND_LATESTDATA_SUCCESS = 'FIND_LATESTDATA_SUCCESS';
exports.FIND_LATESTDATA_SUCCESS = FIND_LATESTDATA_SUCCESS;
var FIND_LATESTDATA_ERROR = 'FIND_LATESTDATA_ERROR';
exports.FIND_LATESTDATA_ERROR = FIND_LATESTDATA_ERROR;

var findLatestData = function findLatestData(widgetId) {
  var dynamicFilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var portalId = arguments.length > 2 ? arguments[2] : undefined;
  return {
    type: FIND_LATESTDATA_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      dynamicFilters: dynamicFilters,
      dynamicSorters: null,
      dynamicPaging: null
    }
  };
};

exports.findLatestData = findLatestData;