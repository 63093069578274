"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeMapStateToProps = exports["default"] = void 0;

var _reactRedux = require("react-redux");

var _nicbaseReducers = require("nicbase-reducers");

var _nicbaseSecurity = require("nicbase-security");

var _redux = require("redux");

var _reselect = require("reselect");

var _columnDefinition = require("./table/column-definition");

var _tourBoardPresentation = _interopRequireDefault(require("./tour-board-presentation"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var makeMapStateToProps = function makeMapStateToProps(_, _ref) {
  var hasPermissions = _ref.hasPermissions;
  var visibleColumnSelector = (0, _reselect.createSelector)(function (_ref2) {
    var _ref2$columns = _ref2.columns,
        columns = _ref2$columns === void 0 ? [] : _ref2$columns;
    return columns;
  }, function (columns) {
    var isRail = hasPermissions(['module.tour.rail']);

    if (columns.length <= 0) {
      if (isRail) {
        return _columnDefinition.defaultRailColumns;
      }

      return hasPermissions(['module.tour.extended']) ? _columnDefinition.defaultColumns : _columnDefinition.defaultColumnsTouboardLite;
    }

    return columns.filter(function (column) {
      return column;
    }).filter(function (column) {
      return Object.keys((0, _columnDefinition.getColumnDefinitionsWithDefaultWidth)(isRail ? 'rail' : 'tourBoardExtended')).indexOf(column.dataKey) > -1;
    });
  });
  var tourAlarms = (0, _reselect.createSelector)(function (state, props) {
    return state.activatedAlarms[props.id];
  }, function (alarms) {
    return (alarms === null || alarms === void 0 ? void 0 : alarms.activatedAlarms) || [];
  });

  var mapStateToProps = function mapStateToProps(state, ownProps) {
    var _state$portals$state$;

    var widget = state.widgets[ownProps.id] || {};
    var settings = widget.settings || {};
    var connected = widget.connected;
    return {
      user: state.user,
      linkedPortals: state.informationLists.linkedPortals,
      alarms: state.informationLists.alarms,
      settings: settings,
      visibleColumns: visibleColumnSelector(settings),
      connected: connected,
      tourAlarms: tourAlarms(state, ownProps),
      portalName: (_state$portals$state$ = state.portals[state.activePortal]) === null || _state$portals$state$ === void 0 ? void 0 : _state$portals$state$.name
    };
  };

  return mapStateToProps;
};

exports.makeMapStateToProps = makeMapStateToProps;
var actionCreators = {
  updateSettings: _nicbaseReducers.updateWidgetSettings,
  onExportDispatch: _nicbaseReducers.onExportTransportOrderClick,
  getAlarmInformationListFromBackend: _nicbaseReducers.getAlarmInformationListFromBackend,
  getPoisFromBackend: _nicbaseReducers.getPoiCategoryInformationListFromBackend,
  getLinkedPortalsInformationListFromBackend: _nicbaseReducers.getLinkedPortalsInformationListFromBackend,
  exportTourReport: _nicbaseReducers.createTourExport,
  getTourAlarms: function getTourAlarms(portalId, widgetId, tourData) {
    var assetId = tourData.assetId,
        startDate = tourData.startDate,
        endDate = tourData.endDate;
    var dynamicFilters = {
      type: 'AndCondition',
      filterNodes: [{
        type: 'EqualFilter',
        attributeName: 'assetId',
        attributeValue: {
          value: assetId,
          type: 'Integer'
        }
      }, {
        type: 'AndCondition',
        filterNodes: [{
          type: 'CompareFilter',
          compareOperator: 'GTE',
          attributeName: 'dataTime',
          attributeValue: {
            value: startDate,
            type: 'DateTime'
          }
        }, {
          type: 'CompareFilter',
          compareOperator: 'LTE',
          attributeName: 'dataTime',
          attributeValue: {
            value: endDate,
            type: 'DateTime'
          }
        }]
      }]
    };
    return (0, _nicbaseReducers.getTriggeredAlarms)(widgetId, portalId, dynamicFilters);
  },
  downloadTourImportTemplate: _nicbaseReducers.getTourImportTemplate
};

var _default = (0, _redux.compose)((0, _nicbaseSecurity.authorize)('PORTAL')(['widget.type.26']), (0, _nicbaseReducers.withWorkspaceEvents)(), (0, _reactRedux.connect)(makeMapStateToProps, actionCreators))(_tourBoardPresentation["default"]);

exports["default"] = _default;