"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AddNewWidget", {
  enumerable: true,
  get: function get() {
    return _index["default"];
  }
});
Object.defineProperty(exports, "BaseAdministrationComponent", {
  enumerable: true,
  get: function get() {
    return _BaseAdministrationComponent["default"];
  }
});
Object.defineProperty(exports, "BaseWidget", {
  enumerable: true,
  get: function get() {
    return _BaseWidget["default"];
  }
});
Object.defineProperty(exports, "ErrorBoundary", {
  enumerable: true,
  get: function get() {
    return _errorBoundary["default"];
  }
});
exports["default"] = void 0;

var _index = _interopRequireDefault(require("./AddWidget/index"));

var _BaseAdministrationComponent = _interopRequireDefault(require("./BaseAdministrationComponent/BaseAdministrationComponent"));

var _errorBoundary = _interopRequireDefault(require("./ErrorBoundary/error-boundary"));

var _BaseWidget = _interopRequireDefault(require("./BaseWidget"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = _BaseWidget["default"];
exports["default"] = _default;