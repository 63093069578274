"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAssetsForUser = exports.FIND_ASSETS_FOR_USER_SUCCESS = exports.FIND_ASSETS_FOR_USER_REQUEST = exports.FIND_ASSETS_FOR_USER_ERROR = void 0;
var FIND_ASSETS_FOR_USER_REQUEST = 'FIND_ASSETS_FOR_USER_REQUEST';
exports.FIND_ASSETS_FOR_USER_REQUEST = FIND_ASSETS_FOR_USER_REQUEST;
var FIND_ASSETS_FOR_USER_SUCCESS = 'FIND_ASSETS_FOR_USER_SUCCESS';
exports.FIND_ASSETS_FOR_USER_SUCCESS = FIND_ASSETS_FOR_USER_SUCCESS;
var FIND_ASSETS_FOR_USER_ERROR = 'FIND_ASSETS_FOR_USER_ERROR';
exports.FIND_ASSETS_FOR_USER_ERROR = FIND_ASSETS_FOR_USER_ERROR;

var findAssetsForUser = function findAssetsForUser(portalId) {
  return {
    type: FIND_ASSETS_FOR_USER_REQUEST,
    headers: {
      portalId: portalId,
      version: 'v3'
    },
    body: {}
  };
};

exports.findAssetsForUser = findAssetsForUser;