"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IMPERSONATE_USER = exports.CANCEL_IMPERSONATION = void 0;
exports.cancelImpersonation = cancelImpersonation;
exports.impersonateUser = impersonateUser;
var IMPERSONATE_USER = 'IMPERSONATE_USER';
exports.IMPERSONATE_USER = IMPERSONATE_USER;
var CANCEL_IMPERSONATION = 'CANCEL_IMPERSONATION';
exports.CANCEL_IMPERSONATION = CANCEL_IMPERSONATION;

function impersonateUser(email) {
  return {
    type: IMPERSONATE_USER,
    headers: {
      version: 'v1'
    },
    body: {
      email: email
    }
  };
}

function cancelImpersonation() {
  return {
    type: CANCEL_IMPERSONATION,
    headers: {
      version: 'v1'
    },
    body: {
      email: null
    }
  };
}