"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(store) {
  return function (next) {
    return function (action) {
      // check if impersonation active and get userId
      var state = store.getState();
      var impersonationId = state.admin.impersonation.email;

      if (impersonationId) {
        // merge headers
        var headers = action.headers;
        var enhancedHeader = Object.assign({}, headers, {
          impersonationId: impersonationId
        });
        next(Object.assign(action, {
          headers: enhancedHeader
        }));
      } else {
        next(action);
      }
    };
  };
};

exports["default"] = _default;