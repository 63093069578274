import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AUTHENTICATE_USER_SUCCESS, changeLocale } from 'nicbase-reducers';
import { auth } from 'nicbase-security';

export const parseOauthParamsFromUrl = url => {
  const params = {};
  const queryString = url.substring(1);
  const regex = /([^&=]+)=([^&]*)/g;
  let m;

  // eslint-disable-next-line no-cond-assign
  while ((m = regex.exec(queryString))) {
    params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
  }
  return params;
};
// eslint-disable-next-line react/prop-types
const ReceiveToken = ({ location }) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line react/prop-types
  const params = parseOauthParamsFromUrl(location.hash);
  auth.setAccessToken(params.access_token);
  dispatch({ type: AUTHENTICATE_USER_SUCCESS });

  if (params.languageCode) {
    dispatch(changeLocale(params.languageCode));
  }
  return <Redirect to="/" />;
};

export default ReceiveToken;
