"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createFleetAdministrationExport = exports.UPLOAD_MASTERDATA_SUCCESS = exports.UPLOAD_MASTERDATA_ERROR = exports.FIND_ASSETS_FOR_FLEET_ADMINISTRATION_SUCCESS = exports.FIND_ASSETS_FOR_FLEET_ADMINISTRATION_REQUEST = exports.FIND_ASSETS_FOR_FLEET_ADMINISTRATION_ERROR = exports.CREATE_ASSET_MASTERDATA_EXPORT_SUCCESS = exports.CREATE_ASSET_MASTERDATA_EXPORT_REQUEST = exports.CREATE_ASSET_MASTERDATA_EXPORT_ERROR = void 0;
exports.getSimpleAssetsFromBackend = getSimpleAssetsFromBackend;
var FIND_ASSETS_FOR_FLEET_ADMINISTRATION_REQUEST = 'FIND_ASSETS_FOR_FLEET_ADMINISTRATION_REQUEST';
exports.FIND_ASSETS_FOR_FLEET_ADMINISTRATION_REQUEST = FIND_ASSETS_FOR_FLEET_ADMINISTRATION_REQUEST;
var FIND_ASSETS_FOR_FLEET_ADMINISTRATION_SUCCESS = 'FIND_ASSETS_FOR_FLEET_ADMINISTRATION_SUCCESS';
exports.FIND_ASSETS_FOR_FLEET_ADMINISTRATION_SUCCESS = FIND_ASSETS_FOR_FLEET_ADMINISTRATION_SUCCESS;
var FIND_ASSETS_FOR_FLEET_ADMINISTRATION_ERROR = 'FIND_ASSETS_FOR_FLEET_ADMINISTRATION_ERROR';
exports.FIND_ASSETS_FOR_FLEET_ADMINISTRATION_ERROR = FIND_ASSETS_FOR_FLEET_ADMINISTRATION_ERROR;

function getSimpleAssetsFromBackend(widgetId, portalId) {
  var dynamicFilters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var dynamicSorters = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var dynamicPaging = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  return {
    type: FIND_ASSETS_FOR_FLEET_ADMINISTRATION_REQUEST,
    headers: {
      version: 'v2',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      dynamicFilters: dynamicFilters,
      dynamicPaging: dynamicPaging,
      dynamicSorters: dynamicSorters
    }
  };
}

var CREATE_ASSET_MASTERDATA_EXPORT_REQUEST = 'CREATE_ASSET_MASTERDATA_EXPORT_REQUEST';
exports.CREATE_ASSET_MASTERDATA_EXPORT_REQUEST = CREATE_ASSET_MASTERDATA_EXPORT_REQUEST;
var CREATE_ASSET_MASTERDATA_EXPORT_SUCCESS = 'CREATE_ASSET_MASTERDATA_EXPORT_SUCCESS';
exports.CREATE_ASSET_MASTERDATA_EXPORT_SUCCESS = CREATE_ASSET_MASTERDATA_EXPORT_SUCCESS;
var CREATE_ASSET_MASTERDATA_EXPORT_ERROR = 'CREATE_ASSET_MASTERDATA_EXPORT_ERROR';
exports.CREATE_ASSET_MASTERDATA_EXPORT_ERROR = CREATE_ASSET_MASTERDATA_EXPORT_ERROR;

var createFleetAdministrationExport = function createFleetAdministrationExport(widgetId, portalId) {
  var dynamicFilters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var visibleColumns = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  return {
    type: CREATE_ASSET_MASTERDATA_EXPORT_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      filters: {
        dynamicFilters: dynamicFilters,
        dynamicPaging: null,
        dynamicSorters: null
      },
      visibleColumns: visibleColumns
    }
  };
};

exports.createFleetAdministrationExport = createFleetAdministrationExport;
var UPLOAD_MASTERDATA_SUCCESS = 'UPLOAD_MASTERDATA_SUCCESS';
exports.UPLOAD_MASTERDATA_SUCCESS = UPLOAD_MASTERDATA_SUCCESS;
var UPLOAD_MASTERDATA_ERROR = 'UPLOAD_MASTERDATA_ERROR';
exports.UPLOAD_MASTERDATA_ERROR = UPLOAD_MASTERDATA_ERROR;