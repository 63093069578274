"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _nicbaseIcons = require("nicbase-icons");

var _default = {
  1: {},
  2: {},
  3: {},
  100: {
    DEFAULT: _nicbaseIcons.Car,
    DRIVING: _nicbaseIcons.CarDrive,
    STANDING: _nicbaseIcons.CarStand
  },
  101: {
    DEFAULT: _nicbaseIcons.Van,
    DRIVING: _nicbaseIcons.VanDrive,
    STANDING: _nicbaseIcons.VanStand
  },
  102: {
    DEFAULT: _nicbaseIcons.Depot,
    STANDING: _nicbaseIcons.DepotStand
  },
  103: {
    DEFAULT: _nicbaseIcons.Device,
    DRIVING: _nicbaseIcons.DeviceDrive,
    STANDING: _nicbaseIcons.DeviceStand
  },
  104: {
    DEFAULT: _nicbaseIcons.SwapBody,
    DRIVING: _nicbaseIcons.SwapBodyDrive,
    STANDING: _nicbaseIcons.SwapBodyStand
  },
  105: {
    DEFAULT: _nicbaseIcons.TractorUnit,
    DRIVING: _nicbaseIcons.TractorUnitDrive,
    STANDING: _nicbaseIcons.TractorUnitStand
  },
  110: {},
  111: {
    DEFAULT: _nicbaseIcons.TruckBox,
    DRIVING: _nicbaseIcons.TruckBoxDrive,
    STANDING: _nicbaseIcons.TruckBoxStand
  },
  112: {},
  113: {},
  114: {},
  115: {},
  120: {
    DEFAULT: _nicbaseIcons.SemitrailerTarpaulin,
    DRIVING: _nicbaseIcons.SemitrailerTarpaulinDrive,
    STANDING: _nicbaseIcons.SemitrailerTarpaulinStand
  },
  121: {
    DEFAULT: _nicbaseIcons.SemitrailerBox,
    DRIVING: _nicbaseIcons.SemitrailerBoxDrive,
    STANDING: _nicbaseIcons.SemitrailerBoxStand
  },
  122: {
    DEFAULT: _nicbaseIcons.SemitrailerReeferBox,
    DRIVING: _nicbaseIcons.SemitrailerReeferBoxDrive,
    STANDING: _nicbaseIcons.SemitrailerReeferBoxStand
  },
  123: {},
  124: {},
  125: {},
  126: {
    DEFAULT: _nicbaseIcons.HeatingReefer,
    DRIVING: _nicbaseIcons.HeatingReeferDrive,
    STANDING: _nicbaseIcons.HeatingReeferStand
  },
  127: {
    DEFAULT: _nicbaseIcons.CoolingReefer,
    DRIVING: _nicbaseIcons.CoolingReeferDrive,
    STANDING: _nicbaseIcons.CoolingReeferStand
  },
  130: {},
  131: {
    DEFAULT: _nicbaseIcons.TrailerBox,
    DRIVING: _nicbaseIcons.TrailerBoxDrive,
    STANDING: _nicbaseIcons.TrailerBoxStand
  },
  132: {},
  133: {},
  134: {},
  135: {},
  140: {
    // Rail (Intermodal)
    DEFAULT: _nicbaseIcons.RailCarIntermodal,
    DRIVING: _nicbaseIcons.RailCarIntermodalDrive,
    STANDING: _nicbaseIcons.RailCarIntermodalStand,
    DRIVING_LOADED: _nicbaseIcons.RailCarIntermodalDriveFull,
    STANDING_LOADED: _nicbaseIcons.RailCarIntermodalStandFull
  },
  141: {
    // Rail (Gedeckt)
    DEFAULT: _nicbaseIcons.RailCarBox,
    DRIVING: _nicbaseIcons.RailCarBoxDrive,
    STANDING: _nicbaseIcons.RailCarBoxStand,
    DRIVING_LOADED: _nicbaseIcons.RailCarBoxDriveFull,
    STANDING_LOADED: _nicbaseIcons.RailCarBoxStandFull
  },
  142: {
    // Rail (Tankwagen)
    DEFAULT: _nicbaseIcons.RailCarTank,
    DRIVING: _nicbaseIcons.RailCarTankDrive,
    STANDING: _nicbaseIcons.RailCarTankStand,
    DRIVING_LOADED: _nicbaseIcons.RailCarTankDriveFull,
    STANDING_LOADED: _nicbaseIcons.RailCarTankStandFull
  },
  143: {
    // Rail (Schüttgut)
    DEFAULT: _nicbaseIcons.RailCarBulk,
    DRIVING: _nicbaseIcons.RailCarBulkDrive,
    STANDING: _nicbaseIcons.RailCarBulkStand,
    DRIVING_LOADED: _nicbaseIcons.RailCarBulkDriveFull,
    STANDING_LOADED: _nicbaseIcons.RailCarBulkStandFull
  },
  144: {
    // Rail (Personenwagen)
    DEFAULT: _nicbaseIcons.RailCarPassenger,
    DRIVING: _nicbaseIcons.RailCarPassengerDrive,
    STANDING: _nicbaseIcons.RailCarPassengerStand,
    DRIVING_LOADED: _nicbaseIcons.RailCarPassengerDriveFull,
    STANDING_LOADED: _nicbaseIcons.RailCarPassengerStandFull
  },
  2000: {
    // Rail (Tank-Container)
    DEFAULT: _nicbaseIcons.RailCarTankContainer,
    DRIVING: _nicbaseIcons.RailCarTankContainerDrive,
    STANDING: _nicbaseIcons.RailCarTankContainerStand
  },
  2001: {
    // Rail (Locomotive)
    DEFAULT: _nicbaseIcons.Locomotive,
    DRIVING: _nicbaseIcons.LocomotiveDrive,
    STANDING: _nicbaseIcons.LocomotiveStand
  },
  150: {
    DEFAULT: _nicbaseIcons.Container,
    DRIVING: _nicbaseIcons.ContainerDrive,
    STANDING: _nicbaseIcons.ContainerStand
  },
  200: {
    DEFAULT: _nicbaseIcons.BoxTrailer,
    DRIVING: _nicbaseIcons.BoxTrailerDrive,
    STANDING: _nicbaseIcons.BoxTrailerStand
  },
  210: {},
  240: {
    // train neutral
    DEFAULT: _nicbaseIcons.RailCarCoupled,
    DRIVING: _nicbaseIcons.RailCarCoupledDrive,
    STANDING: _nicbaseIcons.RailCarCoupledStand,
    DRIVING_LOADED: _nicbaseIcons.RailCarCoupledDriveFull,
    STANDING_LOADED: _nicbaseIcons.RailCarCoupledStandFull
  },
  241: {
    DEFAULT: _nicbaseIcons.RailCarBoxCoupled,
    DRIVING: _nicbaseIcons.RailCarBoxCoupledDrive,
    STANDING: _nicbaseIcons.RailCarBoxCoupledStand
  },
  242: {
    DEFAULT: _nicbaseIcons.RailCarTankCoupled,
    DRIVING: _nicbaseIcons.RailCarTankCoupledDrive,
    STANDING: _nicbaseIcons.RailCarTankCoupledStand
  },
  243: {
    DEFAULT: _nicbaseIcons.RailCarBulkCoupled,
    DRIVING: _nicbaseIcons.RailCarBulkCoupledDrive,
    STANDING: _nicbaseIcons.RailCarBulkCoupledStand
  },
  244: {
    DEFAULT: _nicbaseIcons.RailCarPassengerCoupled,
    DRIVING: _nicbaseIcons.RailCarPassengerCoupledDrive,
    STANDING: _nicbaseIcons.RailCarPassengerCoupledStand
  },
  245: {
    DEFAULT: _nicbaseIcons.RailCarIntermodalCoupled,
    DRIVING: _nicbaseIcons.RailCarIntermodalCoupledDrive,
    STANDING: _nicbaseIcons.RailCarIntermodalCoupledStand
  },
  4010: {
    DEFAULT: _nicbaseIcons.BargeNeutral,
    DRIVING: _nicbaseIcons.BargeDriving,
    STANDING: _nicbaseIcons.BargeStanding
  }
};
exports["default"] = _default;