"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  FormattedDuration: true,
  intlShape: true,
  Provider: true,
  TranslationProvider: true
};
Object.defineProperty(exports, "FormattedDuration", {
  enumerable: true,
  get: function get() {
    return _reactIntlFormattedDuration["default"];
  }
});
Object.defineProperty(exports, "Provider", {
  enumerable: true,
  get: function get() {
    return _Provider["default"];
  }
});
Object.defineProperty(exports, "TranslationProvider", {
  enumerable: true,
  get: function get() {
    return _TranslationProvider["default"];
  }
});
Object.defineProperty(exports, "intlShape", {
  enumerable: true,
  get: function get() {
    return _intlTypes.intlShape;
  }
});

var _reactIntlFormattedDuration = _interopRequireDefault(require("react-intl-formatted-duration"));

var _intlTypes = require("./intlTypes");

var _Provider = _interopRequireDefault(require("./Provider"));

var _TranslationProvider = _interopRequireDefault(require("./TranslationProvider"));

var _reactIntl = require("react-intl");

Object.keys(_reactIntl).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _reactIntl[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _reactIntl[key];
    }
  });
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }