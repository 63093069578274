"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WORKSPACE_CONNECT_EVENT = exports.UPDATE_WIDGET_SETTINGS_SUCCESS = exports.UPDATE_WIDGET_SETTINGS_REQUEST = exports.UPDATE_WIDGET_SETTINGS_ERROR = exports.TOGGLE_WIDGET_SETTINGS = exports.RESIZE_WIDGET_SUCCESS = exports.RESIZE_WIDGET_REQUEST = exports.RESIZE_WIDGET_ERROR = exports.REMOVE_WIDGET_SUCCESS = exports.REMOVE_WIDGET_REQUEST = exports.REMOVE_WIDGET_ERROR = exports.MOVE_WIDGET_SUCCESS = exports.MOVE_WIDGET_REQUEST = exports.MOVE_WIDGET_ERROR = exports.FIND_WIDGETS_BY_WORKSPACE_ID_SUCCESS = exports.FIND_WIDGETS_BY_WORKSPACE_ID_REQUEST = exports.FIND_WIDGETS_BY_WORKSPACE_ID_ERROR = exports.DISCONNECT_WIDGET_SUCCESS = exports.DISCONNECT_WIDGET_REQUEST = exports.DISCONNECT_WIDGET_ERROR = exports.CONNECT_WIDGET_SUCCESS = exports.CONNECT_WIDGET_REQUEST = exports.CONNECT_WIDGET_ERROR = exports.ADD_WIDGET_SUCCESS = exports.ADD_WIDGET_REQUEST = exports.ADD_WIDGET_ERROR = void 0;
exports.addWidget = addWidget;
exports.connectWidget = connectWidget;
exports.disconnectWidget = disconnectWidget;
exports.emitConnectEvent = emitConnectEvent;
exports.findWidgetsForWorkspace = void 0;
exports.moveWidget = moveWidget;
exports.removeWidget = removeWidget;
exports.resizeWidget = resizeWidget;
exports.toggleWidgetSettings = toggleWidgetSettings;
exports.updateWidgetSettings = updateWidgetSettings;
var FIND_WIDGETS_BY_WORKSPACE_ID_REQUEST = 'FIND_WIDGETS_BY_WORKSPACE_ID_REQUEST';
exports.FIND_WIDGETS_BY_WORKSPACE_ID_REQUEST = FIND_WIDGETS_BY_WORKSPACE_ID_REQUEST;
var FIND_WIDGETS_BY_WORKSPACE_ID_SUCCESS = 'FIND_WIDGETS_BY_WORKSPACE_ID_SUCCESS';
exports.FIND_WIDGETS_BY_WORKSPACE_ID_SUCCESS = FIND_WIDGETS_BY_WORKSPACE_ID_SUCCESS;
var FIND_WIDGETS_BY_WORKSPACE_ID_ERROR = 'FIND_WIDGETS_BY_WORKSPACE_ID_ERROR';
exports.FIND_WIDGETS_BY_WORKSPACE_ID_ERROR = FIND_WIDGETS_BY_WORKSPACE_ID_ERROR;

var findWidgetsForWorkspace = function findWidgetsForWorkspace(portalId, workspaceId) {
  return {
    type: FIND_WIDGETS_BY_WORKSPACE_ID_REQUEST,
    headers: {
      portalId: portalId,
      version: 'v1'
    },
    body: {
      workspaceId: workspaceId
    }
  };
};

exports.findWidgetsForWorkspace = findWidgetsForWorkspace;
var ADD_WIDGET_REQUEST = 'ADD_WIDGET_REQUEST';
exports.ADD_WIDGET_REQUEST = ADD_WIDGET_REQUEST;
var ADD_WIDGET_SUCCESS = 'ADD_WIDGET_SUCCESS';
exports.ADD_WIDGET_SUCCESS = ADD_WIDGET_SUCCESS;
var ADD_WIDGET_ERROR = 'ADD_WIDGET_ERROR';
exports.ADD_WIDGET_ERROR = ADD_WIDGET_ERROR;

function addWidget(portalId, workspaceId, type, layout, settings) {
  var x = layout.x,
      y = layout.y,
      h = layout.h,
      w = layout.w;
  return {
    type: ADD_WIDGET_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      widget: {
        workspaceId: workspaceId,
        type: type,
        layout: {
          x: x,
          y: y,
          h: h,
          w: w
        },
        settings: settings
      }
    }
  };
}

var REMOVE_WIDGET_REQUEST = 'REMOVE_WIDGET_REQUEST';
exports.REMOVE_WIDGET_REQUEST = REMOVE_WIDGET_REQUEST;
var REMOVE_WIDGET_SUCCESS = 'REMOVE_WIDGET_SUCCESS';
exports.REMOVE_WIDGET_SUCCESS = REMOVE_WIDGET_SUCCESS;
var REMOVE_WIDGET_ERROR = 'REMOVE_WIDGET_ERROR';
exports.REMOVE_WIDGET_ERROR = REMOVE_WIDGET_ERROR;

function removeWidget(id, portalId) {
  return {
    type: REMOVE_WIDGET_REQUEST,
    headers: {
      version: 'v1',
      widgetId: id,
      portalId: portalId
    },
    body: {
      widget: {
        id: id
      }
    }
  };
}

var TOGGLE_WIDGET_SETTINGS = 'TOGGLE_WIDGET_SETTINGS';
exports.TOGGLE_WIDGET_SETTINGS = TOGGLE_WIDGET_SETTINGS;

function toggleWidgetSettings(id) {
  return {
    type: TOGGLE_WIDGET_SETTINGS,
    headers: {
      version: 'v1',
      widgetId: id
    },
    body: {
      widget: {
        id: id
      }
    }
  };
}

var RESIZE_WIDGET_REQUEST = 'RESIZE_WIDGET_REQUEST';
exports.RESIZE_WIDGET_REQUEST = RESIZE_WIDGET_REQUEST;
var RESIZE_WIDGET_SUCCESS = 'RESIZE_WIDGET_SUCCESS';
exports.RESIZE_WIDGET_SUCCESS = RESIZE_WIDGET_SUCCESS;
var RESIZE_WIDGET_ERROR = 'RESIZE_WIDGET_ERROR';
exports.RESIZE_WIDGET_ERROR = RESIZE_WIDGET_ERROR;

function resizeWidget(layouts, portalId) {
  // create an array of widgets with id and layout
  var widgets = layouts.map(function (layout) {
    return {
      id: parseInt(layout.i, 10),
      layout: layout
    };
  });
  return {
    type: RESIZE_WIDGET_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      widgets: widgets
    }
  };
}

var MOVE_WIDGET_REQUEST = 'MOVE_WIDGET_REQUEST';
exports.MOVE_WIDGET_REQUEST = MOVE_WIDGET_REQUEST;
var MOVE_WIDGET_SUCCESS = 'MOVE_WIDGET_SUCCESS';
exports.MOVE_WIDGET_SUCCESS = MOVE_WIDGET_SUCCESS;
var MOVE_WIDGET_ERROR = 'MOVE_WIDGET_ERROR';
exports.MOVE_WIDGET_ERROR = MOVE_WIDGET_ERROR;

function moveWidget(layouts, portalId) {
  // create an array of widgets with id and layout
  var widgets = layouts.map(function (layout) {
    return {
      id: parseInt(layout.i, 10),
      layout: layout
    };
  });
  return {
    type: MOVE_WIDGET_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      widgets: widgets
    }
  };
}

var CONNECT_WIDGET_REQUEST = 'CONNECT_WIDGET_REQUEST';
exports.CONNECT_WIDGET_REQUEST = CONNECT_WIDGET_REQUEST;
var CONNECT_WIDGET_SUCCESS = 'CONNECT_WIDGET_SUCCESS';
exports.CONNECT_WIDGET_SUCCESS = CONNECT_WIDGET_SUCCESS;
var CONNECT_WIDGET_ERROR = 'CONNECT_WIDGET_ERROR';
exports.CONNECT_WIDGET_ERROR = CONNECT_WIDGET_ERROR;

function connectWidget(widgetId, portalId) {
  return {
    type: CONNECT_WIDGET_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      widget: {
        id: widgetId
      }
    }
  };
}

var DISCONNECT_WIDGET_REQUEST = 'DISCONNECT_WIDGET_REQUEST';
exports.DISCONNECT_WIDGET_REQUEST = DISCONNECT_WIDGET_REQUEST;
var DISCONNECT_WIDGET_SUCCESS = 'DISCONNECT_WIDGET_SUCCESS';
exports.DISCONNECT_WIDGET_SUCCESS = DISCONNECT_WIDGET_SUCCESS;
var DISCONNECT_WIDGET_ERROR = 'DISCONNECT_WIDGET_ERROR';
exports.DISCONNECT_WIDGET_ERROR = DISCONNECT_WIDGET_ERROR;

function disconnectWidget(widgetId, portalId) {
  return {
    type: DISCONNECT_WIDGET_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      widget: {
        id: widgetId
      }
    }
  };
}

var UPDATE_WIDGET_SETTINGS_REQUEST = 'UPDATE_WIDGET_SETTINGS_REQUEST';
exports.UPDATE_WIDGET_SETTINGS_REQUEST = UPDATE_WIDGET_SETTINGS_REQUEST;
var UPDATE_WIDGET_SETTINGS_SUCCESS = 'UPDATE_WIDGET_SETTINGS_SUCCESS';
exports.UPDATE_WIDGET_SETTINGS_SUCCESS = UPDATE_WIDGET_SETTINGS_SUCCESS;
var UPDATE_WIDGET_SETTINGS_ERROR = 'UPDATE_WIDGET_SETTINGS_ERROR';
exports.UPDATE_WIDGET_SETTINGS_ERROR = UPDATE_WIDGET_SETTINGS_ERROR;

function updateWidgetSettings(widgetId, settings, portalId) {
  return {
    type: UPDATE_WIDGET_SETTINGS_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      widget: {
        id: widgetId,
        settings: settings
      }
    }
  };
}

var WORKSPACE_CONNECT_EVENT = 'WORKSPACE_CONNECT_EVENT';
exports.WORKSPACE_CONNECT_EVENT = WORKSPACE_CONNECT_EVENT;

function emitConnectEvent(widgetId) {
  var connectProperties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    type: WORKSPACE_CONNECT_EVENT,
    headers: {
      version: 'v1',
      widgetId: widgetId
    },
    body: {
      connectProperties: connectProperties
    }
  };
}