"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _reactVirtual = require("react-virtual");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Wrapper for React Virtual that uses a ResizeObserver to automatically detect size changes (useful for animations).
// measureRefs: functions that, when called, measure HTML elements so React Virtual knows the correct sizes
// idea: wrap React Virtual measureRefs with functionality to observe the elements that are to be measured
var useVirtualWithResizeObserver = function useVirtualWithResizeObserver(options) {
  // stores all original measureRefs (used for actual measurement of elements)
  var originalMeasureRefs = (0, _react.useRef)([]); // stores all HTML elements that are currently observed

  var observedElementsRef = (0, _react.useRef)([]);
  var observerRef = (0, _react.useRef)(new ResizeObserver(function (entries) {
    // entries contains all elements that changed size
    entries.forEach(function (entry) {
      var element = entry.target;
      var index = element.getAttribute('data-index'); // remeasure element

      originalMeasureRefs.current[index](element);
    });
  }));
  (0, _react.useEffect)(function () {
    var resizeObserver = observerRef.current;
    return function () {
      resizeObserver.disconnect();
    };
  }, []); // whenever size of list changes, create new measureRefs (which wrap original measureRefs and add automatic resize detection)

  var resizeObserverMeasureRefs = (0, _react.useMemo)(function () {
    return Array(options.size).fill(undefined).map(function (_, index) {
      return function (element) {
        var observedElement = observedElementsRef.current[index]; // if element is rendered but not yet observed...

        if (element && !observedElement) {
          // set data-index so ResizeObserver can find current measureRef (since virtualized lists don't display all items at once)
          element.setAttribute('data-index', index.toString()); // start observing

          observerRef.current.observe(element);
          observedElementsRef.current[index] = element;
        } // if element is not rendered anymore but still observed...


        if (!element && observedElement) {
          // stop observing
          observerRef.current.unobserve(observedElement);
          observedElementsRef.current[index] = null;
        }
      };
    });
  }, [options.size]);
  var virtualizer = (0, _reactVirtual.useVirtual)(options);
  virtualizer.virtualItems = virtualizer.virtualItems.map(function (item) {
    // if virtual item does not yet have updated measureRef (i.e. the one with ResizeObserver)...
    if (item.measureRef !== resizeObserverMeasureRefs[item.index]) {
      // store original measureRef
      originalMeasureRefs.current[item.index] = item.measureRef; // and replace it with wrapper that observes element's size

      return _objectSpread(_objectSpread({}, item), {}, {
        measureRef: resizeObserverMeasureRefs[item.index]
      });
    }

    return item;
  });
  return virtualizer;
};

var _default = useVirtualWithResizeObserver;
exports["default"] = _default;