"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAllProducts = exports.FIND_PRODUCTS_SUCCESS = exports.FIND_PRODUCTS_REQUEST = exports.FIND_PRODUCTS_ERROR = void 0;
var FIND_PRODUCTS_REQUEST = 'FIND_PRODUCTS_REQUEST';
exports.FIND_PRODUCTS_REQUEST = FIND_PRODUCTS_REQUEST;
var FIND_PRODUCTS_SUCCESS = 'FIND_PRODUCTS_SUCCESS';
exports.FIND_PRODUCTS_SUCCESS = FIND_PRODUCTS_SUCCESS;
var FIND_PRODUCTS_ERROR = 'FIND_PRODUCTS_ERROR';
exports.FIND_PRODUCTS_ERROR = FIND_PRODUCTS_ERROR;

var findAllProducts = function findAllProducts() {
  var portalId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var dynamicFilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    type: 'AndCondition',
    filterNodes: []
  };
  var dynamicSorters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var dynamicPaging = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  return {
    type: FIND_PRODUCTS_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      portalId: portalId,
      dynamicFilters: dynamicFilters,
      dynamicPaging: dynamicPaging,
      dynamicSorters: dynamicSorters
    }
  };
};

exports.findAllProducts = findAllProducts;