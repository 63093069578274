"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = emailValidation;

/**
 * Created by SWensauer on 06.10.2017.
 */
function emailValidation(value) {
  // eslint-disable-next-line max-len
  var email = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return email.test(value);
}