"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  ASSIGN_BOX_TO_PORTAL_ERROR: true,
  ASSIGN_BOX_TO_PORTAL_REQUEST: true,
  ASSIGN_BOX_TO_PORTAL_SUCCESS: true,
  ASSIGN_BOXES_TO_PORTAL_ERROR: true,
  ASSIGN_BOXES_TO_PORTAL_SUCCESS: true,
  assignBoxToPortal: true,
  boxesReducer: true,
  FIND_UNASSIGNED_BOXES_BY_FILTER_ERROR: true,
  FIND_UNASSIGNED_BOXES_BY_FILTER_REQUEST: true,
  FIND_UNASSIGNED_BOXES_BY_FILTER_SUCCESS: true,
  findUnassignedBoxes: true,
  getBoxes: true,
  getUnassignedBoxes: true,
  portalsReducer: true,
  connectedPortalsReducer: true,
  getContactsB2B: true
};
Object.defineProperty(exports, "ASSIGN_BOXES_TO_PORTAL_ERROR", {
  enumerable: true,
  get: function get() {
    return _boxes.ASSIGN_BOXES_TO_PORTAL_ERROR;
  }
});
Object.defineProperty(exports, "ASSIGN_BOXES_TO_PORTAL_SUCCESS", {
  enumerable: true,
  get: function get() {
    return _boxes.ASSIGN_BOXES_TO_PORTAL_SUCCESS;
  }
});
Object.defineProperty(exports, "ASSIGN_BOX_TO_PORTAL_ERROR", {
  enumerable: true,
  get: function get() {
    return _boxes.ASSIGN_BOX_TO_PORTAL_ERROR;
  }
});
Object.defineProperty(exports, "ASSIGN_BOX_TO_PORTAL_REQUEST", {
  enumerable: true,
  get: function get() {
    return _boxes.ASSIGN_BOX_TO_PORTAL_REQUEST;
  }
});
Object.defineProperty(exports, "ASSIGN_BOX_TO_PORTAL_SUCCESS", {
  enumerable: true,
  get: function get() {
    return _boxes.ASSIGN_BOX_TO_PORTAL_SUCCESS;
  }
});
Object.defineProperty(exports, "FIND_UNASSIGNED_BOXES_BY_FILTER_ERROR", {
  enumerable: true,
  get: function get() {
    return _boxes.FIND_UNASSIGNED_BOXES_BY_FILTER_ERROR;
  }
});
Object.defineProperty(exports, "FIND_UNASSIGNED_BOXES_BY_FILTER_REQUEST", {
  enumerable: true,
  get: function get() {
    return _boxes.FIND_UNASSIGNED_BOXES_BY_FILTER_REQUEST;
  }
});
Object.defineProperty(exports, "FIND_UNASSIGNED_BOXES_BY_FILTER_SUCCESS", {
  enumerable: true,
  get: function get() {
    return _boxes.FIND_UNASSIGNED_BOXES_BY_FILTER_SUCCESS;
  }
});
Object.defineProperty(exports, "assignBoxToPortal", {
  enumerable: true,
  get: function get() {
    return _boxes.assignBoxToPortal;
  }
});
Object.defineProperty(exports, "boxesReducer", {
  enumerable: true,
  get: function get() {
    return _boxes.boxesReducer;
  }
});
Object.defineProperty(exports, "connectedPortalsReducer", {
  enumerable: true,
  get: function get() {
    return _portalsConnected["default"];
  }
});
Object.defineProperty(exports, "findUnassignedBoxes", {
  enumerable: true,
  get: function get() {
    return _boxes.findUnassignedBoxes;
  }
});
Object.defineProperty(exports, "getBoxes", {
  enumerable: true,
  get: function get() {
    return _boxes.getBoxes;
  }
});
Object.defineProperty(exports, "getContactsB2B", {
  enumerable: true,
  get: function get() {
    return _portalsConnected.getContactsB2B;
  }
});
Object.defineProperty(exports, "getUnassignedBoxes", {
  enumerable: true,
  get: function get() {
    return _boxes.getUnassignedBoxes;
  }
});
Object.defineProperty(exports, "portalsReducer", {
  enumerable: true,
  get: function get() {
    return _portals2["default"];
  }
});

var _boxes = require("./boxes");

var _portals = require("./portals.actions");

Object.keys(_portals).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _portals[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _portals[key];
    }
  });
});

var _portals2 = _interopRequireDefault(require("./portals.reducer"));

var _portalsConnected = _interopRequireWildcard(require("./portals-connected.reducer"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }