"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeIsInRangeBefore = exports.makeIsInRangeAround = exports.makeIsInRangeAfter = void 0;

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var makeIsInRangeAround = function makeIsInRangeAround(days, fromDate) {
  return function (dateToCheck) {
    return fromDate && (0, _momentTimezone["default"])(dateToCheck).isBetween((0, _momentTimezone["default"])(fromDate).subtract(days, 'days'), (0, _momentTimezone["default"])(fromDate).add(days, 'days'));
  };
};

exports.makeIsInRangeAround = makeIsInRangeAround;

var makeIsInRangeBefore = function makeIsInRangeBefore(days, fromDate) {
  return function (dateToCheck) {
    return fromDate && (0, _momentTimezone["default"])(dateToCheck).isBetween((0, _momentTimezone["default"])(fromDate).subtract(days, 'days'), (0, _momentTimezone["default"])(fromDate));
  };
};

exports.makeIsInRangeBefore = makeIsInRangeBefore;

var makeIsInRangeAfter = function makeIsInRangeAfter(days, fromDate) {
  return function (dateToCheck) {
    return fromDate && (0, _momentTimezone["default"])(dateToCheck).isBetween((0, _momentTimezone["default"])(fromDate), (0, _momentTimezone["default"])(fromDate).add(days, 'days'));
  };
};

exports.makeIsInRangeAfter = makeIsInRangeAfter;