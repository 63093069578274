"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = passwordValidation;

/**
 * Created by SWensauer on 22.02.2018.
 */
function passwordValidation(value) {
  // eslint-disable-next-line max-len
  var password = new RegExp(/^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/);
  return password.test(value);
}