import { DELETE_TOUR_FOR_TOUR_BOARD_SUCCESS, UPDATE_COLLECTED_TOURS_SUCCESS, UPDATE_TOUR_FOR_TOUR_BOARD_SUCCESS } from 'nicbase-reducers';

import throttleActionsMiddlewareFactory from './throttle-actions-middleware';

const dispatchUpdateCollection = (dispatch, updates) => {
  if (updates.length === 0) {
    return;
  }
  dispatch({
    type: UPDATE_COLLECTED_TOURS_SUCCESS,
    body: {
      tours: updates.reduce((previous, nextTourAction) => Object.assign(previous, nextTourAction.body.tours), {}),
    },
  });
};

// if there is a different event than update coming in then send the collected tour sets to the dispatcher and start collecting events from the beginning
export const dispatchTourCollection = (dispatch, collectedDataActions, next) => {
  let updates = [];

  for (let i = 0; i < collectedDataActions.length; i += 1) {
    const action = collectedDataActions[i];
    if (action.type === UPDATE_TOUR_FOR_TOUR_BOARD_SUCCESS) {
      updates.push(action);
    } else {
      if (updates.length > 0) {
        dispatchUpdateCollection(dispatch, updates);
        updates = [];
      }
      next(action);
    }
  }
  dispatchUpdateCollection(dispatch, updates);
};

// UPDATE_TOUR_SUCCESS action.tour is an object
// this action must be collected in an array and then sent on as UPDATE_COLLECTED_TOUR_SUCCESS
const TOUR_COLLECTION_PERIOD = 5000; // 5 seconds
export const throttleTourActionsMiddleware = throttleActionsMiddlewareFactory(
  [UPDATE_TOUR_FOR_TOUR_BOARD_SUCCESS, DELETE_TOUR_FOR_TOUR_BOARD_SUCCESS],
  dispatchTourCollection,
  TOUR_COLLECTION_PERIOD
);
export default null;
