"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserStatus = exports.UserGroupType = exports.UnitSystem = exports.TemperatureUnit = void 0;
var UnitSystem;
exports.UnitSystem = UnitSystem;

(function (UnitSystem) {
  UnitSystem["Metric"] = "METRIC";
  UnitSystem["Imperial"] = "IMPERIAL";
})(UnitSystem || (exports.UnitSystem = UnitSystem = {}));

var TemperatureUnit;
exports.TemperatureUnit = TemperatureUnit;

(function (TemperatureUnit) {
  TemperatureUnit["Celsius"] = "degC";
  TemperatureUnit["Fahrenheit"] = "degF";
})(TemperatureUnit || (exports.TemperatureUnit = TemperatureUnit = {}));

var UserStatus;
exports.UserStatus = UserStatus;

(function (UserStatus) {
  UserStatus["Active"] = "ACTIVE";
  UserStatus["Inactive"] = "INACTIVE";
  UserStatus["Registered"] = "REGISTERED";
  UserStatus["PasswordReset"] = "PASSWORD_RESET";
  UserStatus["EmailReset"] = "EMAIL_RESET";
  UserStatus["Locked"] = "LOCKED";
})(UserStatus || (exports.UserStatus = UserStatus = {}));

var UserGroupType;
exports.UserGroupType = UserGroupType;

(function (UserGroupType) {
  UserGroupType["Normal"] = "NORMAL";
  UserGroupType["DefaultNormal"] = "DEFAULT_NORMAL";
  UserGroupType["PortalAdmin"] = "PORTAL_ADMIN";
})(UserGroupType || (exports.UserGroupType = UserGroupType = {}));