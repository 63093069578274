"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGatewayProviders = exports["default"] = void 0;

var _reselect = require("reselect");

var _providers = require("./providers.actions");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var providersById = function providersById() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _providers.FIND_PROVIDERS_BY_FILTER_SUCCESS:
      {
        var providers = action.body.providers;
        var newState = providers.reduce(function (result, provider) {
          var existingProvider = state[provider.id];
          result[provider.id] = _objectSpread(_objectSpread({}, existingProvider), provider);
          return result;
        }, {});
        return _objectSpread(_objectSpread({}, state), newState);
      }

    default:
      {
        return state;
      }
  }
};

var allProviders = function allProviders() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _providers.FIND_PROVIDERS_BY_FILTER_SUCCESS:
      {
        var providers = action.body.providers;
        var newState = providers.reduce(function (result, provider) {
          if (!state.some(function (providerId) {
            return providerId === provider.id;
          })) {
            result.push(provider.id);
          }

          return result;
        }, []);
        return [].concat(_toConsumableArray(state), _toConsumableArray(newState));
      }

    default:
      {
        return state;
      }
  }
};

var getGatewayProviders = (0, _reselect.createSelector)([function (state) {
  return state.gatewayProviders.allIds;
}, function (state) {
  return state.gatewayProviders.byId;
}], function (allIds, providers) {
  return allIds.map(function (id) {
    return providers[id];
  });
});
exports.getGatewayProviders = getGatewayProviders;
var _default = {
  byId: providersById,
  allIds: allProviders
};
exports["default"] = _default;