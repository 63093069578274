"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFilterableStatusWithFilters = exports.useFilterableStatus = void 0;

var _react = require("react");

var _initialState = _interopRequireDefault(require("./initialState"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var init = function init(initialState) {
  return initialState;
};

var stateReducer = function stateReducer(state, action) {
  switch (action.type) {
    case 'select':
      {
        var value = action.value;
        return _objectSpread(_objectSpread({}, state), value);
      }

    case 'reset':
      {
        return _initialState["default"];
      }

    default:
      {
        throw new Error("Unhandled action type: ".concat(action.type));
      }
  }
};

var useFilterableStatus = function useFilterableStatus() {
  var _useReducer = (0, _react.useReducer)(stateReducer, _initialState["default"], init),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      states = _useReducer2[0],
      dispatch = _useReducer2[1];

  var onReset = (0, _react.useCallback)(function () {
    return dispatch({
      type: 'reset'
    });
  }, []);
  var onSelect = (0, _react.useCallback)(function (value) {
    dispatch({
      type: 'select',
      value: value
    });
  }, []);
  var selectedStatesLength = Object.values(states).filter(function (state) {
    return state;
  }).length;
  return {
    states: states,
    selectedStatesLength: selectedStatesLength,
    onSelect: onSelect,
    onReset: onReset
  };
};

exports.useFilterableStatus = useFilterableStatus;

var useFilterableStatusWithFilters = function useFilterableStatusWithFilters(filters) {
  var states = (0, _react.useMemo)(function () {
    var _filters$values$statu;

    return (_filters$values$statu = filters.values.status) !== null && _filters$values$statu !== void 0 ? _filters$values$statu : _initialState["default"];
  }, [filters.values]);
  var update = (0, _react.useCallback)(function (values) {
    return filters.update({
      status: _objectSpread({}, values)
    });
  }, [filters]);
  var onSelect = (0, _react.useCallback)(function (value) {
    update(_objectSpread(_objectSpread({}, states), value));
  }, [states, update]);
  var onReset = (0, _react.useCallback)(function () {
    update(_initialState["default"]);
  }, [update]);
  var selectedStatesLength = Object.values(states).filter(function (state) {
    return state;
  }).length;
  return {
    states: states,
    selectedStatesLength: selectedStatesLength,
    onSelect: onSelect,
    onReset: onReset
  };
};

exports.useFilterableStatusWithFilters = useFilterableStatusWithFilters;