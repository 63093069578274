const requestIdMiddleware = () => {
  const requestIds = {
    byType: {},
    byWidget: {},
  };

  return next => action => {
    const { headers = {}, type } = action;
    const { requestId, widgetId } = headers;
    if (requestId) {
      if (type.endsWith('_REQUEST')) {
        if (widgetId) {
          requestIds.byWidget[widgetId] = { [type]: requestId };
        } else {
          requestIds.byType[type] = requestId;
        }
        next(action);
      } else {
        const requestType = type.replace(/(_SUCCESS|_ERROR)$/, '_REQUEST');
        let responseRequestId = requestIds.byType[requestType];
        if (widgetId) {
          const requestsFromWidget = requestIds.byWidget[widgetId];
          if (!requestsFromWidget) {
            return;
          }
          responseRequestId = requestsFromWidget[requestType];
        }
        if (responseRequestId === undefined || responseRequestId === requestId) {
          next(action);
        }
      }
    } else {
      next(action);
    }
  };
};

export default requestIdMiddleware;
