import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { changeActivePortal } from 'nicbase-reducers';

import NavItem from './navigation-item';
import NavList from './navigation-list';

export const mapStateToProps = state => ({ portals: Object.values(state.portals) });
export const mapDispatchToProps = dispatch => ({
  login: portalId => {
    dispatch(changeActivePortal(portalId));
  },
});

export const PortalNav = ({ portals, login }) => (
  <NavList>
    {[...portals]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(portal => (
        <NavItem
          key={portal.id}
          to={{ pathname: `/dashboard/${portal.id}/workspace`, state: { title: 'Dashboard' } }}
          onClick={() => {
            login(portal.id);
          }}
        >
          {portal.name}
        </NavItem>
      ))}
  </NavList>
);

PortalNav.propTypes = {
  portals: PropTypes.arrayOf(PropTypes.shape()),
  login: PropTypes.func,
};

const noop = () => {};
PortalNav.defaultProps = {
  portals: [],
  login: noop,
};

export default connect(mapStateToProps, mapDispatchToProps)(PortalNav);
