"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAdminDebtors = exports["default"] = void 0;

var _reselect = require("reselect");

var _debtors = require("./debtors.actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var INITIAL_STATE = {};

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _debtors.FIND_ALL_DEBTORS_SUCCESS:
      {
        var debtors = action.body.debtors;
        return _objectSpread(_objectSpread({}, state), debtors.reduce(function (result, debtor) {
          result[debtor.id] = debtor;
          return result;
        }, {}));
      }

    default:
      return state;
  }
};

exports["default"] = _default;
var getAdminDebtors = (0, _reselect.createSelector)(function (state) {
  return state.admin.debtors;
}, function (debtors) {
  return Object.values(debtors);
});
exports.getAdminDebtors = getAdminDebtors;