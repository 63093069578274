"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FIND_TOUR_FOR_DATAREQUEST_SUCCESS = exports.FIND_TOUR_FOR_DATAREQUEST_REQUEST = exports.FIND_TOUR_FOR_DATAREQUEST_ERROR = exports.ASSIGN_ASSET_TO_TOUR_EVENT_SUCCESS = exports.ASSIGN_ASSET_TO_TOUR_EVENT_REQUEST = exports.ASSIGN_ASSET_TO_TOUR_EVENT_ERROR = void 0;
exports.assignAssetToTour = assignAssetToTour;
exports.findTourForDataRequest = findTourForDataRequest;
var FIND_TOUR_FOR_DATAREQUEST_REQUEST = 'FIND_TOUR_FOR_DATAREQUEST_REQUEST';
exports.FIND_TOUR_FOR_DATAREQUEST_REQUEST = FIND_TOUR_FOR_DATAREQUEST_REQUEST;
var FIND_TOUR_FOR_DATAREQUEST_SUCCESS = 'FIND_TOUR_FOR_DATAREQUEST_SUCCESS';
exports.FIND_TOUR_FOR_DATAREQUEST_SUCCESS = FIND_TOUR_FOR_DATAREQUEST_SUCCESS;
var FIND_TOUR_FOR_DATAREQUEST_ERROR = 'FIND_TOUR_FOR_DATAREQUEST_ERROR';
exports.FIND_TOUR_FOR_DATAREQUEST_ERROR = FIND_TOUR_FOR_DATAREQUEST_ERROR;

function findTourForDataRequest(id, portalId) {
  return {
    type: FIND_TOUR_FOR_DATAREQUEST_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      id: id
    }
  };
}

var ASSIGN_ASSET_TO_TOUR_EVENT_REQUEST = 'ASSIGN_ASSET_TO_TOUR_EVENT_REQUEST';
exports.ASSIGN_ASSET_TO_TOUR_EVENT_REQUEST = ASSIGN_ASSET_TO_TOUR_EVENT_REQUEST;
var ASSIGN_ASSET_TO_TOUR_EVENT_SUCCESS = 'ASSIGN_ASSET_TO_TOUR_EVENT_SUCCESS';
exports.ASSIGN_ASSET_TO_TOUR_EVENT_SUCCESS = ASSIGN_ASSET_TO_TOUR_EVENT_SUCCESS;
var ASSIGN_ASSET_TO_TOUR_EVENT_ERROR = 'ASSIGN_ASSET_TO_TOUR_EVENT_ERROR';
exports.ASSIGN_ASSET_TO_TOUR_EVENT_ERROR = ASSIGN_ASSET_TO_TOUR_EVENT_ERROR;

function assignAssetToTour(tourId, assetId, assetType, portalId) {
  return {
    type: ASSIGN_ASSET_TO_TOUR_EVENT_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      id: tourId,
      assetId: assetId,
      assetType: assetType
    }
  };
}