"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getHistoryData = exports.createHistoryDataExport = exports.HISTORY_DATA_SUCCESS = exports.HISTORY_DATA_REQUEST = exports.HISTORY_DATA_ERROR = exports.CREATE_HISTORY_DATA_EXPORT_SUCCESS = exports.CREATE_HISTORY_DATA_EXPORT_REQUEST = exports.CREATE_HISTORY_DATA_EXPORT_ERROR = void 0;
var HISTORY_DATA_REQUEST = 'HISTORY_DATA_REQUEST';
exports.HISTORY_DATA_REQUEST = HISTORY_DATA_REQUEST;
var HISTORY_DATA_SUCCESS = 'HISTORY_DATA_SUCCESS';
exports.HISTORY_DATA_SUCCESS = HISTORY_DATA_SUCCESS;
var HISTORY_DATA_ERROR = 'HISTORY_DATA_ERROR';
exports.HISTORY_DATA_ERROR = HISTORY_DATA_ERROR;

var getHistoryData = function getHistoryData(widgetId, filterJson, portalId) {
  return {
    type: HISTORY_DATA_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      dynamicFilters: filterJson,
      dynamicSorters: null,
      dynamicPaging: null
    }
  };
};

exports.getHistoryData = getHistoryData;
var CREATE_HISTORY_DATA_EXPORT_REQUEST = 'CREATE_HISTORY_DATA_EXPORT_REQUEST';
exports.CREATE_HISTORY_DATA_EXPORT_REQUEST = CREATE_HISTORY_DATA_EXPORT_REQUEST;
var CREATE_HISTORY_DATA_EXPORT_SUCCESS = 'CREATE_HISTORY_DATA_EXPORT_SUCCESS';
exports.CREATE_HISTORY_DATA_EXPORT_SUCCESS = CREATE_HISTORY_DATA_EXPORT_SUCCESS;
var CREATE_HISTORY_DATA_EXPORT_ERROR = 'CREATE_HISTORY_DATA_EXPORT_ERROR';
exports.CREATE_HISTORY_DATA_EXPORT_ERROR = CREATE_HISTORY_DATA_EXPORT_ERROR;

var createHistoryDataExport = function createHistoryDataExport(widgetId, filterNodes, portalId) {
  var visibleColumns = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  return {
    type: CREATE_HISTORY_DATA_EXPORT_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      filters: {
        dynamicFilters: filterNodes,
        dynamicSorters: null,
        dynamicPaging: null
      },
      visibleColumns: visibleColumns
    }
  };
};

exports.createHistoryDataExport = createHistoryDataExport;