"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateReportSeries = exports.findReportSeries = exports.deleteReportSeries = exports.createReportSeries = exports.UPDATE_REPORT_SERIES_SUCCESS = exports.UPDATE_REPORT_SERIES_REQUEST = exports.UPDATE_REPORT_SERIES_ERROR = exports.FIND_REPORT_SERIES_SUCCESS = exports.FIND_REPORT_SERIES_REQUEST = exports.FIND_REPORT_SERIES_ERROR = exports.DELETE_REPORT_SERIES_SUCCESS = exports.DELETE_REPORT_SERIES_REQUEST = exports.DELETE_REPORT_SERIES_ERROR = exports.CREATE_REPORT_SERIES_SUCCESS = exports.CREATE_REPORT_SERIES_REQUEST = exports.CREATE_REPORT_SERIES_ERROR = void 0;
var CREATE_REPORT_SERIES_REQUEST = 'CREATE_REPORT_SERIES_REQUEST';
exports.CREATE_REPORT_SERIES_REQUEST = CREATE_REPORT_SERIES_REQUEST;
var CREATE_REPORT_SERIES_SUCCESS = 'CREATE_REPORT_SERIES_SUCCESS';
exports.CREATE_REPORT_SERIES_SUCCESS = CREATE_REPORT_SERIES_SUCCESS;
var CREATE_REPORT_SERIES_ERROR = 'CREATE_REPORT_SERIES_ERROR';
exports.CREATE_REPORT_SERIES_ERROR = CREATE_REPORT_SERIES_ERROR;

var createReportSeries = function createReportSeries(widgetId, reportSeries, portalId) {
  return {
    type: CREATE_REPORT_SERIES_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      reportSeries: reportSeries
    }
  };
};

exports.createReportSeries = createReportSeries;
var FIND_REPORT_SERIES_REQUEST = 'FIND_REPORT_SERIES_REQUEST';
exports.FIND_REPORT_SERIES_REQUEST = FIND_REPORT_SERIES_REQUEST;
var FIND_REPORT_SERIES_SUCCESS = 'FIND_REPORT_SERIES_SUCCESS';
exports.FIND_REPORT_SERIES_SUCCESS = FIND_REPORT_SERIES_SUCCESS;
var FIND_REPORT_SERIES_ERROR = 'FIND_REPORT_SERIES_ERROR';
exports.FIND_REPORT_SERIES_ERROR = FIND_REPORT_SERIES_ERROR;

var findReportSeries = function findReportSeries(widgetId, portalId) {
  return {
    type: FIND_REPORT_SERIES_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {}
  };
};

exports.findReportSeries = findReportSeries;
var DELETE_REPORT_SERIES_REQUEST = 'DELETE_REPORT_SERIES_REQUEST';
exports.DELETE_REPORT_SERIES_REQUEST = DELETE_REPORT_SERIES_REQUEST;
var DELETE_REPORT_SERIES_SUCCESS = 'DELETE_REPORT_SERIES_SUCCESS';
exports.DELETE_REPORT_SERIES_SUCCESS = DELETE_REPORT_SERIES_SUCCESS;
var DELETE_REPORT_SERIES_ERROR = 'DELETE_REPORT_SERIES_ERROR';
exports.DELETE_REPORT_SERIES_ERROR = DELETE_REPORT_SERIES_ERROR;

var deleteReportSeries = function deleteReportSeries(widgetId, id, portalId) {
  return {
    type: DELETE_REPORT_SERIES_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      id: id
    }
  };
};

exports.deleteReportSeries = deleteReportSeries;
var UPDATE_REPORT_SERIES_REQUEST = 'UPDATE_REPORT_SERIES_REQUEST';
exports.UPDATE_REPORT_SERIES_REQUEST = UPDATE_REPORT_SERIES_REQUEST;
var UPDATE_REPORT_SERIES_SUCCESS = 'UPDATE_REPORT_SERIES_SUCCESS';
exports.UPDATE_REPORT_SERIES_SUCCESS = UPDATE_REPORT_SERIES_SUCCESS;
var UPDATE_REPORT_SERIES_ERROR = 'UPDATE_REPORT_SERIES_ERROR';
exports.UPDATE_REPORT_SERIES_ERROR = UPDATE_REPORT_SERIES_ERROR;

var updateReportSeries = function updateReportSeries(widgetId, reportSeries, portalId) {
  return {
    type: UPDATE_REPORT_SERIES_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      reportSeries: reportSeries
    }
  };
};

exports.updateReportSeries = updateReportSeries;