import React from 'react';
import { NicplaceLogoSimple } from 'nicbase-icons';
import { FormattedMessage } from 'nicbase-translation';

import DashboardItem from './dashboard-link';
import NavItem from './navigation-item';
import NavList from './navigation-list';

import styles from './main-nav.module.scss';

export const MainNav = () => (
  <div className={styles.mainNav}>
    <div className={styles.logo}>
      <NicplaceLogoSimple />
    </div>
    <FormattedMessage id="navigation.main.welcome">{message => <div className={styles.welcome}>{message}</div>}</FormattedMessage>
    <NavList>
      <NavItem to={{ pathname: '/home', state: { title: 'Home' } }}>Home</NavItem>
      <DashboardItem />
    </NavList>
  </div>
);
MainNav.propTypes = {};
MainNav.defaultProps = {};
export default MainNav;
