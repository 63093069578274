"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDamageCodes = exports["default"] = void 0;

var _reselect = require("reselect");

var _codes = require("./codes.actions");

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _codes.LIST_DAMAGE_CODES_REQUEST:
      return {};

    case _codes.LIST_DAMAGE_CODES_SUCCESS:
      {
        var codes = action.body.codes;
        return codes;
      }

    default:
      return state;
  }
};

exports["default"] = _default;
var getDamageCodes = (0, _reselect.createSelector)([function (state) {
  return state.damageCodes;
}], function (damageCodes) {
  return damageCodes;
});
exports.getDamageCodes = getDamageCodes;