"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  1: null,
  2: null,
  3: null,
  100: 'widgets.details.assetType.100',
  101: 'widgets.details.assetType.101',
  102: 'widgets.details.assetType.102',
  103: 'widgets.details.assetType.103',
  104: 'widgets.details.assetType.104',
  105: 'widgets.details.assetType.105',
  110: 'widgets.details.assetType.110',
  111: 'widgets.details.assetType.111',
  112: 'widgets.details.assetType.112',
  113: 'widgets.details.assetType.113',
  114: 'widgets.details.assetType.114',
  115: 'widgets.details.assetType.115',
  120: 'widgets.details.assetType.120',
  121: 'widgets.details.assetType.121',
  122: 'widgets.details.assetType.122',
  123: 'widgets.details.assetType.123',
  124: 'widgets.details.assetType.124',
  125: 'widgets.details.assetType.125',
  126: 'widgets.details.assetType.126',
  127: 'widgets.details.assetType.127',
  130: 'widgets.details.assetType.130',
  131: 'widgets.details.assetType.131',
  132: 'widgets.details.assetType.132',
  133: 'widgets.details.assetType.133',
  134: 'widgets.details.assetType.134',
  135: 'widgets.details.assetType.135',
  140: 'widgets.details.assetType.140',
  141: 'widgets.details.assetType.141',
  142: 'widgets.details.assetType.142',
  143: 'widgets.details.assetType.143',
  144: 'widgets.details.assetType.144',
  150: 'widgets.details.assetType.150',
  200: 'widgets.details.assetType.200',
  210: null,
  240: 'widgets.details.assetType.240',
  241: 'widgets.details.assetType.241',
  242: 'widgets.details.assetType.242',
  243: 'widgets.details.assetType.243',
  244: 'widgets.details.assetType.244',
  245: 'widgets.details.assetType.245',
  2000: 'widgets.details.assetType.2000',
  2001: 'widgets.details.assetType.2001',
  4010: 'widgets.details.assetType.4010'
};
exports["default"] = _default;