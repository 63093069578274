"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findPreCoolAssetInformation = exports.FIND_PRE_COOL_ASSET_INFORMATION_SUCCESS = exports.FIND_PRE_COOL_ASSET_INFORMATION_REQUEST = exports.FIND_PRE_COOL_ASSET_INFORMATION_ERROR = void 0;
var FIND_PRE_COOL_ASSET_INFORMATION_REQUEST = 'FIND_PRE_COOL_ASSET_INFORMATION_REQUEST';
exports.FIND_PRE_COOL_ASSET_INFORMATION_REQUEST = FIND_PRE_COOL_ASSET_INFORMATION_REQUEST;
var FIND_PRE_COOL_ASSET_INFORMATION_SUCCESS = 'FIND_PRE_COOL_ASSET_INFORMATION_SUCCESS';
exports.FIND_PRE_COOL_ASSET_INFORMATION_SUCCESS = FIND_PRE_COOL_ASSET_INFORMATION_SUCCESS;
var FIND_PRE_COOL_ASSET_INFORMATION_ERROR = 'FIND_PRE_COOL_ASSET_INFORMATION_ERROR';
exports.FIND_PRE_COOL_ASSET_INFORMATION_ERROR = FIND_PRE_COOL_ASSET_INFORMATION_ERROR;

var findPreCoolAssetInformation = function findPreCoolAssetInformation(portalId, widgetId, poiId) {
  return {
    type: FIND_PRE_COOL_ASSET_INFORMATION_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      poiId: poiId
    }
  };
};

exports.findPreCoolAssetInformation = findPreCoolAssetInformation;