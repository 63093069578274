"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAllLayoutInformation = exports.FIND_ALL_LAYOUTS_SHORT_SUCCESS = exports.FIND_ALL_LAYOUTS_SHORT_REQUEST = exports.FIND_ALL_LAYOUTS_SHORT_ERROR = void 0;
var FIND_ALL_LAYOUTS_SHORT_REQUEST = 'FIND_ALL_LAYOUTS_SHORT_REQUEST';
exports.FIND_ALL_LAYOUTS_SHORT_REQUEST = FIND_ALL_LAYOUTS_SHORT_REQUEST;
var FIND_ALL_LAYOUTS_SHORT_SUCCESS = 'FIND_ALL_LAYOUTS_SHORT_SUCCESS';
exports.FIND_ALL_LAYOUTS_SHORT_SUCCESS = FIND_ALL_LAYOUTS_SHORT_SUCCESS;
var FIND_ALL_LAYOUTS_SHORT_ERROR = 'FIND_ALL_LAYOUTS_SHORT_ERROR';
exports.FIND_ALL_LAYOUTS_SHORT_ERROR = FIND_ALL_LAYOUTS_SHORT_ERROR;

var findAllLayoutInformation = function findAllLayoutInformation() {
  return {
    type: FIND_ALL_LAYOUTS_SHORT_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {}
  };
};

exports.findAllLayoutInformation = findAllLayoutInformation;