"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findDepartments = exports.FIND_ALL_DEPARTMENTS_SUCCESS = exports.FIND_ALL_DEPARTMENTS_REQUEST = exports.FIND_ALL_DEPARTMENTS_ERROR = void 0;
var FIND_ALL_DEPARTMENTS_REQUEST = 'FIND_ALL_DEPARTMENTS_REQUEST';
exports.FIND_ALL_DEPARTMENTS_REQUEST = FIND_ALL_DEPARTMENTS_REQUEST;
var FIND_ALL_DEPARTMENTS_SUCCESS = 'FIND_ALL_DEPARTMENTS_SUCCESS';
exports.FIND_ALL_DEPARTMENTS_SUCCESS = FIND_ALL_DEPARTMENTS_SUCCESS;
var FIND_ALL_DEPARTMENTS_ERROR = 'FIND_ALL_DEPARTMENTS_ERROR';
exports.FIND_ALL_DEPARTMENTS_ERROR = FIND_ALL_DEPARTMENTS_ERROR;

var findDepartments = function findDepartments() {
  return {
    type: FIND_ALL_DEPARTMENTS_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {}
  };
};

exports.findDepartments = findDepartments;