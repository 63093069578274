"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(_ref) {
  var dispatch = _ref.dispatch;
  return {
    updateFilters: function updateFilters(_ref2) {
      var widgetId = _ref2.widgetId,
          values = _ref2.values;
      return dispatch({
        type: 'UPDATE_FILTERS',
        body: {
          id: widgetId,
          values: values
        }
      });
    }
  };
};

exports["default"] = _default;