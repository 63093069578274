"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _hooks = require("@nicbase/hooks");

var _useMap = _interopRequireDefault(require("./use-map"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * useUserMap hook that is updated with current user position once.
 * @param {*} options
 */
var useGeolocationMap = function useGeolocationMap() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _useGeolocation = (0, _hooks.useGeolocation)(),
      longitude = _useGeolocation.longitude,
      latitude = _useGeolocation.latitude,
      loading = _useGeolocation.loading,
      error = _useGeolocation.error;

  var map = (0, _useMap["default"])(options);
  var didMapUpdate = (0, _react.useRef)(false);
  var viewport = map.viewport,
      setViewport = map.setViewport;
  var mapLat = (0, _hooks.usePrevious)(viewport.latitude);
  var mapLng = (0, _hooks.usePrevious)(viewport.longitude); // if map coordinates already updated before geolocation is available, do nothing

  (0, _react.useEffect)(function () {
    // map coordinates changed (e.g. fitBounds oder setViewport were used)
    if (mapLat !== undefined && mapLat !== viewport.latitude || mapLng !== undefined && mapLng !== viewport.longitude) {
      didMapUpdate.current = true;
    } // geolocation is now available


    if (!error && !loading) {
      // map did not already update -> safe to set new position without overriding user interactions
      if (!didMapUpdate.current) {
        setViewport({
          latitude: latitude,
          longitude: longitude
        });
      }
    }
  }, [error, latitude, loading, longitude, mapLat, mapLng, setViewport, viewport.latitude, viewport.longitude]);
  return map;
};

var _default = useGeolocationMap;
exports["default"] = _default;