"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _assetInformation = require("../../asset-information-lists/asset-information.actions");

/* eslint-disable no-param-reassign */
var INITIAL_STATE = {};

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _assetInformation.FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_SUCCESS:
      {
        var vehicleAssetTypes = action.body.vehicleAssetTypes;
        return Object.assign({}, state, vehicleAssetTypes.reduce(function (result, assetType) {
          result[assetType.id] = assetType;
          return result;
        }, {}));
      }

    default:
      return state;
  }
};

exports["default"] = _default;