"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS = exports.UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST = exports.UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_ERROR = exports.GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_SUCCESS = exports.GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_REQUEST = exports.GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_ERROR = exports.GENERATE_INCIDENTS_EXPORT_BY_FILTER_SUCCESS = exports.GENERATE_INCIDENTS_EXPORT_BY_FILTER_REQUEST = exports.GENERATE_INCIDENTS_EXPORT_BY_FILTER_ERROR = exports.FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS = exports.FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST = exports.FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_ERROR = exports.CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS = exports.CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST = exports.CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_ERROR = void 0;
exports.createIndicentComment = createIndicentComment;
exports.findIncidentsByFilter = findIncidentsByFilter;
exports.generateOverallIncidentExport = exports.generateIncidentDetailExport = void 0;
exports.updateIncident = updateIncident;
var FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST = 'FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST';
exports.FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST = FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST;
var FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS = 'FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS';
exports.FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS = FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS;
var FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_ERROR = 'FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_ERROR';
exports.FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_ERROR = FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_ERROR;

function findIncidentsByFilter() {
  var widgetId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var portalId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var dynamicFilters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    type: 'AndCondition',
    filterNodes: []
  };
  var dynamicSorters = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var dynamicPaging = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  return {
    type: FIND_INCIDENTS_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      dynamicFilters: dynamicFilters,
      dynamicPaging: dynamicPaging,
      dynamicSorters: dynamicSorters
    }
  };
}

var UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST = 'UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST';
exports.UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST = UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST;
var UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS = 'UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS';
exports.UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS = UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS;
var UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_ERROR = 'UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_ERROR';
exports.UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_ERROR = UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_ERROR;

function updateIncident() {
  var widgetId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var portalId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var id = arguments.length > 2 ? arguments[2] : undefined;
  var status = arguments.length > 3 ? arguments[3] : undefined;
  return {
    type: UPDATE_INCIDENT_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      id: id,
      status: status
    }
  };
}

var CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST = 'CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST';
exports.CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST = CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST;
var CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS = 'CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS';
exports.CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS = CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_SUCCESS;
var CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_ERROR = 'CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_ERROR';
exports.CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_ERROR = CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_ERROR;

function createIndicentComment() {
  var widgetId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var portalId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var incidentId = arguments.length > 2 ? arguments[2] : undefined;
  var text = arguments.length > 3 ? arguments[3] : undefined;
  var infoReefer = arguments.length > 4 ? arguments[4] : undefined;
  var infoDriver = arguments.length > 5 ? arguments[5] : undefined;
  var surname = arguments.length > 6 ? arguments[6] : undefined;
  var forename = arguments.length > 7 ? arguments[7] : undefined;
  return {
    type: CREATE_INCIDENT_COMMENT_FOR_ALARM_MANAGEMENT_WIDGET_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      incidentId: incidentId,
      text: text,
      infoReefer: infoReefer,
      infoDriver: infoDriver,
      surname: surname,
      forename: forename
    }
  };
}

var GENERATE_INCIDENTS_EXPORT_BY_FILTER_REQUEST = 'GENERATE_INCIDENTS_EXPORT_BY_FILTER_REQUEST';
exports.GENERATE_INCIDENTS_EXPORT_BY_FILTER_REQUEST = GENERATE_INCIDENTS_EXPORT_BY_FILTER_REQUEST;
var GENERATE_INCIDENTS_EXPORT_BY_FILTER_SUCCESS = 'GENERATE_INCIDENTS_EXPORT_BY_FILTER_SUCCESS';
exports.GENERATE_INCIDENTS_EXPORT_BY_FILTER_SUCCESS = GENERATE_INCIDENTS_EXPORT_BY_FILTER_SUCCESS;
var GENERATE_INCIDENTS_EXPORT_BY_FILTER_ERROR = 'GENERATE_INCIDENTS_EXPORT_BY_FILTER_ERROR';
exports.GENERATE_INCIDENTS_EXPORT_BY_FILTER_ERROR = GENERATE_INCIDENTS_EXPORT_BY_FILTER_ERROR;

var generateOverallIncidentExport = function generateOverallIncidentExport(widgetId, portalId, dynamicFilters, dynamicSorters) {
  return {
    type: GENERATE_INCIDENTS_EXPORT_BY_FILTER_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      dynamicFilters: dynamicFilters,
      dynamicSorters: dynamicSorters
    }
  };
};

exports.generateOverallIncidentExport = generateOverallIncidentExport;
var GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_REQUEST = 'GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_REQUEST';
exports.GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_REQUEST = GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_REQUEST;
var GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_SUCCESS = 'GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_SUCCESS';
exports.GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_SUCCESS = GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_SUCCESS;
var GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_ERROR = 'GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_ERROR';
exports.GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_ERROR = GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_ERROR;

var generateIncidentDetailExport = function generateIncidentDetailExport(widgetId, portalId, dynamicFilters) {
  return {
    type: GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      dynamicFilters: dynamicFilters,
      dynamicPaging: null,
      dynamicSorters: null
    }
  };
};

exports.generateIncidentDetailExport = generateIncidentDetailExport;