"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DELETE_NOTIFICATION = void 0;
exports.deleteNotification = deleteNotification;

/**
 * Created by MBoehm on 05.01.2017.
 */
var DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
exports.DELETE_NOTIFICATION = DELETE_NOTIFICATION;

function deleteNotification(index) {
  return {
    type: DELETE_NOTIFICATION,
    headers: {
      version: 'v1'
    },
    body: {
      index: index
    }
  };
}