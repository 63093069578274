"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ReeferErrorCodeIcon", {
  enumerable: true,
  get: function get() {
    return _nicReeferErrorCodeMapping.ReeferErrorCodeIcon;
  }
});
Object.defineProperty(exports, "ebsErrorCodeToIcon", {
  enumerable: true,
  get: function get() {
    return _nicEbsErrorCodeMapping["default"];
  }
});
Object.defineProperty(exports, "makeErrorCodeTranslation", {
  enumerable: true,
  get: function get() {
    return _nicReeferErrorCodeMapping.makeErrorCodeTranslation;
  }
});
Object.defineProperty(exports, "makeErrorCodesTranslations", {
  enumerable: true,
  get: function get() {
    return _nicReeferErrorCodeMapping.makeErrorCodesTranslations;
  }
});
Object.defineProperty(exports, "reeferErrorCodeToIcon", {
  enumerable: true,
  get: function get() {
    return _nicReeferErrorCodeMapping.reeferErrorCodeToIcon;
  }
});
Object.defineProperty(exports, "reeferErrorCodeToTranslation", {
  enumerable: true,
  get: function get() {
    return _nicReeferErrorCodeMapping.reeferErrorCodeToTranslation;
  }
});
Object.defineProperty(exports, "tpmsErrorCodeToIcon", {
  enumerable: true,
  get: function get() {
    return _nicTpmsErrorCodeMapping["default"];
  }
});

var _nicEbsErrorCodeMapping = _interopRequireDefault(require("./ebs-mapping/nic-ebs-error-code-mapping"));

var _nicReeferErrorCodeMapping = require("./reefer-mapping/nic-reefer-error-code-mapping");

var _nicTpmsErrorCodeMapping = _interopRequireDefault(require("./tpms-mapping/nic-tpms-error-code-mapping"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }