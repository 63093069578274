"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendPortalConnect = exports.resetAlreadyConnectedError = exports.getPortalContacts = exports.changePortalContactStatus = exports.SEND_PORTAL_CONNECT_SUCCESS = exports.SEND_PORTAL_CONNECT_RESET = exports.SEND_PORTAL_CONNECT_REQUEST = exports.SEND_PORTAL_CONNECT_ERROR = exports.SEND_ALL_PORTAL_CONNECT_SUCCESS = exports.SEND_ALL_PORTAL_CONNECT_REQUEST = exports.SEND_ALL_PORTAL_CONNECT_ERROR = exports.GET_PORTAL_CONTACTS_SUCCESS = exports.GET_PORTAL_CONTACTS_REQUEST = exports.GET_PORTAL_CONTACTS_ERROR = exports.FIND_ALL_PORTALS_FOR_USER_SUCCESS = exports.CHANGE_PORTAL_CONTACT_STATUS_SUCCESS = exports.CHANGE_PORTAL_CONTACT_STATUS_REQUEST = exports.CHANGE_PORTAL_CONTACT_STATUS_ERROR = void 0;
var FIND_ALL_PORTALS_FOR_USER_SUCCESS = 'FIND_ALL_PORTALS_FOR_USER_SUCCESS';
exports.FIND_ALL_PORTALS_FOR_USER_SUCCESS = FIND_ALL_PORTALS_FOR_USER_SUCCESS;
var GET_PORTAL_CONTACTS_REQUEST = 'GET_PORTAL_CONTACTS_REQUEST';
exports.GET_PORTAL_CONTACTS_REQUEST = GET_PORTAL_CONTACTS_REQUEST;
var GET_PORTAL_CONTACTS_SUCCESS = 'GET_PORTAL_CONTACTS_SUCCESS';
exports.GET_PORTAL_CONTACTS_SUCCESS = GET_PORTAL_CONTACTS_SUCCESS;
var GET_PORTAL_CONTACTS_ERROR = 'GET_PORTAL_CONTACTS_ERROR';
exports.GET_PORTAL_CONTACTS_ERROR = GET_PORTAL_CONTACTS_ERROR;

var getPortalContacts = function getPortalContacts(portalId) {
  return {
    type: GET_PORTAL_CONTACTS_REQUEST,
    headers: {
      portalId: portalId,
      version: 'v1'
    },
    body: {}
  };
};

exports.getPortalContacts = getPortalContacts;
var SEND_PORTAL_CONNECT_REQUEST = 'SEND_PORTAL_CONNECT_REQUEST';
exports.SEND_PORTAL_CONNECT_REQUEST = SEND_PORTAL_CONNECT_REQUEST;
var SEND_PORTAL_CONNECT_SUCCESS = 'SEND_PORTAL_CONNECT_SUCCESS';
exports.SEND_PORTAL_CONNECT_SUCCESS = SEND_PORTAL_CONNECT_SUCCESS;
var SEND_PORTAL_CONNECT_ERROR = 'SEND_PORTAL_CONNECT_ERROR';
exports.SEND_PORTAL_CONNECT_ERROR = SEND_PORTAL_CONNECT_ERROR;
var SEND_PORTAL_CONNECT_RESET = 'SEND_PORTAL_CONNECT_RESET';
exports.SEND_PORTAL_CONNECT_RESET = SEND_PORTAL_CONNECT_RESET;
var SEND_ALL_PORTAL_CONNECT_REQUEST = 'SEND_ALL_PORTAL_CONNECT_REQUEST';
exports.SEND_ALL_PORTAL_CONNECT_REQUEST = SEND_ALL_PORTAL_CONNECT_REQUEST;
var SEND_ALL_PORTAL_CONNECT_SUCCESS = 'SEND_ALL_PORTAL_CONNECT_SUCCESS';
exports.SEND_ALL_PORTAL_CONNECT_SUCCESS = SEND_ALL_PORTAL_CONNECT_SUCCESS;
var SEND_ALL_PORTAL_CONNECT_ERROR = 'SEND_ALL_PORTAL_CONNECT_ERROR';
exports.SEND_ALL_PORTAL_CONNECT_ERROR = SEND_ALL_PORTAL_CONNECT_ERROR;

var sendPortalConnect = function sendPortalConnect(_ref) {
  var portalId = _ref.portalId,
      mail = _ref.mail,
      language = _ref.language;
  return {
    type: SEND_PORTAL_CONNECT_REQUEST,
    headers: {
      portalId: portalId,
      version: 'v1'
    },
    body: {
      mail: mail,
      language: language
    }
  };
};

exports.sendPortalConnect = sendPortalConnect;

var resetAlreadyConnectedError = function resetAlreadyConnectedError(_ref2) {
  var portalAlreadyConnected = _ref2.portalAlreadyConnected;
  return {
    type: SEND_PORTAL_CONNECT_RESET,
    headers: {
      version: 'v1'
    },
    body: {
      portalAlreadyConnected: portalAlreadyConnected
    }
  };
};

exports.resetAlreadyConnectedError = resetAlreadyConnectedError;
var CHANGE_PORTAL_CONTACT_STATUS_REQUEST = 'CHANGE_PORTAL_CONTACT_STATUS_REQUEST';
exports.CHANGE_PORTAL_CONTACT_STATUS_REQUEST = CHANGE_PORTAL_CONTACT_STATUS_REQUEST;
var CHANGE_PORTAL_CONTACT_STATUS_SUCCESS = 'CHANGE_PORTAL_CONTACT_STATUS_SUCCESS';
exports.CHANGE_PORTAL_CONTACT_STATUS_SUCCESS = CHANGE_PORTAL_CONTACT_STATUS_SUCCESS;
var CHANGE_PORTAL_CONTACT_STATUS_ERROR = 'CHANGE_PORTAL_CONTACT_STATUS_ERROR';
exports.CHANGE_PORTAL_CONTACT_STATUS_ERROR = CHANGE_PORTAL_CONTACT_STATUS_ERROR;

var changePortalContactStatus = function changePortalContactStatus(_ref3) {
  var portalId = _ref3.portalId,
      transactionCode = _ref3.transactionCode,
      newStatus = _ref3.newStatus;
  return {
    type: CHANGE_PORTAL_CONTACT_STATUS_REQUEST,
    headers: {
      portalId: portalId,
      version: 'v1'
    },
    body: {
      transactionCode: transactionCode,
      newStatus: newStatus
    }
  };
};

exports.changePortalContactStatus = changePortalContactStatus;