"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _socket = require("./socket.actions");

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _socket.SOCKET_DISCONNECTED:
    case _socket.DISCONNECT_SOCKET:
      return {
        connected: false,
        state: action.state
      };

    case _socket.CONNECT_SOCKET:
      return {
        connected: state.connected,
        state: action.state
      };

    case _socket.SOCKET_CONNECTED:
      return {
        connected: true,
        state: action.state
      };

    default:
      return state;
  }
};

exports["default"] = _default;