"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _assets = require("./assets.actions");

/**
 * Created by EHelbing on 22.12.2016.
 */
var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _assets.CREATE_ASSET_ON_VEHICLE_LEVEL_SUCCESS:
      {
        var newState = state.slice(); // check if the asset exists in the action

        if (action.body.asset) {
          // if the state has no assets yet, we can push the asset without checking for its existence
          // else we check if the asset already exists in the state
          if (newState.length === 0 || newState.find(function (asset) {
            return asset.id === action.body.asset.id;
          }) === undefined) {
            newState.push(action.body.asset);
          }
        }

        return newState;
      }
    // ALSO USED FOR REPLACE ASSET
    // Reply to Link HwAsset is UPDATE_ASSET_SUCCESS
    // Reply to UnLink HwAsset is UPDATE_ASSET_SUCCESS

    case _assets.UPDATE_ASSET_SUCCESS:
      {
        var _newState = state.slice(); // check if the asset exists in the action


        if (action.body.asset) {
          // check if the asset already exists in the state
          var oldAsset = _newState.find(function (asset) {
            return asset.id === action.body.asset.id;
          });

          if (oldAsset !== undefined) {
            var indexOfAsset = _newState.indexOf(oldAsset);

            _newState[indexOfAsset] = action.body.asset;
          }
        }

        return _newState;
      }

    case _assets.REPLACE_ASSETS_SUCCESS:
    case _assets.FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_SUCCESS:
      {
        return action.body.assets;
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
    case _assets.REPLACE_ASSETS_ERROR:
    case _assets.FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_ERROR:
      {
        return [];
      }

    case _assets.DELETE_ASSETS_REQUEST:
    case _assets.DELETE_ASSETS_SUCCESS:
      {
        var _newState2 = state.slice(); // get the asset id


        var assetId = action.body.id || action.body.asset.id; // remove the deleted asset

        return _newState2.filter(function (asset) {
          return asset.id !== assetId;
        });
      }

    default:
      {
        return state;
      }
  }
};

exports["default"] = _default;