"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _workspaces = require("./workspaces.actions");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function workspacesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _workspaces.REPLACE_STATE:
      return action.body.newState ? action.body.newState : state;

    case _workspaces.ADD_WORKSPACE_SUCCESS:
      return Object.assign({}, state, _defineProperty({}, action.body.workspace.id, Object.assign({}, action.body.workspace, {
        name: action.body.workspace.name === '' ? 'unnamed' : action.body.workspace.name
      })));

    case _workspaces.RENAME_WORKSPACE_SUCCESS:
      return Object.assign({}, state, _defineProperty({}, action.body.workspace.id, Object.assign({}, state[action.body.workspace.id], {
        name: action.body.workspace.name
      })));

    case _workspaces.REMOVE_WORKSPACE_SUCCESS:
      return Object.assign({}, Object.keys(state).filter(function (id) {
        return id !== String(action.body.workspace.id);
      }).reduce(function (prev, key) {
        var result = prev;
        result[key] = state[key];
        return result;
      }, {}));

    case _workspaces.FIND_WORKSPACES_SUCCESS:
      {
        var _action$body$workspac = action.body.workspaces,
            workspaces = _action$body$workspac === void 0 ? [] : _action$body$workspac;
        return Object.assign({}, state, workspaces.reduce(function (result, next) {
          result[next.id] = next;
          return result;
        }, {}));
      }

    case _workspaces.CHANGE_WORKSPACE_POSITION_SUCCESS:
      return Object.assign({}, state, Object.assign({}, // replace positions in existing workspaces
      action.body.workspaces.reduce(function (prev, next) {
        var result = prev; // keep the previous state for each workspace,
        // but replace the positions with the new positions

        result[next.id] = Object.assign({}, state[next.id], {
          position: next.position
        });
        return result;
      }, {})));

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return {};

    default:
      return state;
  }
}

var _default = workspacesReducer;
exports["default"] = _default;