"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _redux = require("redux");

var _fleetCheck = require("./fleet-check.actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var overviewReducer = function overviewReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _fleetCheck.FIND_FLEET_CHECK_OVERVIEW_SUCCESS:
      {
        var widgetId = action.headers.widgetId;
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, widgetId, action.body));
      }

    default:
      return state;
  }
};

var detailReducer = function detailReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _fleetCheck.FIND_FLEET_CHECK_DETAIL_SUCCESS:
      {
        var widgetId = action.headers.widgetId;
        var _action$body = action.body,
            body = _action$body === void 0 ? {} : _action$body;
        var category = body.category;

        if (!category) {
          return state;
        }

        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, widgetId, _objectSpread(_objectSpread({}, state[widgetId]), {}, _defineProperty({}, category, body))));
      }

    default:
      {
        return state;
      }
  }
};

var _default = (0, _redux.combineReducers)({
  overview: overviewReducer,
  details: detailReducer
});

exports["default"] = _default;