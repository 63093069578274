"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findCapacities = exports.FIND_ALL_CAPACITIES_SUCCESS = exports.FIND_ALL_CAPACITIES_REQUEST = exports.FIND_ALL_CAPACITIES_ERROR = void 0;
var FIND_ALL_CAPACITIES_REQUEST = 'FIND_ALL_CAPACITIES_REQUEST';
exports.FIND_ALL_CAPACITIES_REQUEST = FIND_ALL_CAPACITIES_REQUEST;
var FIND_ALL_CAPACITIES_SUCCESS = 'FIND_ALL_CAPACITIES_SUCCESS';
exports.FIND_ALL_CAPACITIES_SUCCESS = FIND_ALL_CAPACITIES_SUCCESS;
var FIND_ALL_CAPACITIES_ERROR = 'FIND_ALL_CAPACITIES_ERROR';
exports.FIND_ALL_CAPACITIES_ERROR = FIND_ALL_CAPACITIES_ERROR;

var findCapacities = function findCapacities() {
  return {
    type: FIND_ALL_CAPACITIES_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {}
  };
};

exports.findCapacities = findCapacities;