"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BoxField = void 0;

/* eslint-disable import/prefer-default-export */
// see: https://kasasi.atlassian.net/wiki/spaces/DEV/pages/476381945/boxdata+Field-Enumeration
var BoxField;
exports.BoxField = BoxField;

(function (BoxField) {
  BoxField[BoxField["BoxCoupledCalculated"] = 101] = "BoxCoupledCalculated";
  BoxField[BoxField["BoxMaxVoltage"] = 102] = "BoxMaxVoltage";
  BoxField[BoxField["BoxBatteryVoltage"] = 103] = "BoxBatteryVoltage";
  BoxField[BoxField["BoxExternalVoltage"] = 104] = "BoxExternalVoltage";
  BoxField[BoxField["BoxSpeedCalculated"] = 121] = "BoxSpeedCalculated";
  BoxField[BoxField["PanicButton"] = 124] = "PanicButton";
  BoxField[BoxField["TempBodyTemperatures"] = 304] = "TempBodyTemperatures";
  BoxField[BoxField["TempRise"] = 305] = "TempRise";
  BoxField[BoxField["SecurityDeviceArmed"] = 450] = "SecurityDeviceArmed";
  BoxField[BoxField["SecurityInfraredCalculated"] = 452] = "SecurityInfraredCalculated";
  BoxField[BoxField["SecurityInductionLoopCalculated"] = 454] = "SecurityInductionLoopCalculated";
  BoxField[BoxField["ReeferStatus"] = 501] = "ReeferStatus";
  BoxField[BoxField["FuelLevelLiter"] = 506] = "FuelLevelLiter";
  BoxField[BoxField["FuelLevelPercent"] = 507] = "FuelLevelPercent";
  BoxField[BoxField["ReeferFuelLiterDifference"] = 518] = "ReeferFuelLiterDifference";
  BoxField[BoxField["ReeferFuelPercentageDifference"] = 519] = "ReeferFuelPercentageDifference";
  BoxField[BoxField["RailShockAmplitudes"] = 1004] = "RailShockAmplitudes";
  BoxField[BoxField["RailLoadDescription"] = 1034] = "RailLoadDescription";
  BoxField[BoxField["DistanceFromAsset"] = 9009] = "DistanceFromAsset";
  BoxField[BoxField["RelativeSpeedToAsset"] = 9010] = "RelativeSpeedToAsset";
})(BoxField || (exports.BoxField = BoxField = {}));