"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _latestDataNeo = require("./latest-data-neo.actions");

Object.keys(_latestDataNeo).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _latestDataNeo[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _latestDataNeo[key];
    }
  });
});