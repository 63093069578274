"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.searchAddressForMaps = exports.SEARCH_ADDRESS_SUCCESS = exports.SEARCH_ADDRESS_REQUEST = exports.SEARCH_ADDRESS_ERROR = void 0;
var SEARCH_ADDRESS_SUCCESS = 'SEARCH_ADDRESS_SUCCESS';
exports.SEARCH_ADDRESS_SUCCESS = SEARCH_ADDRESS_SUCCESS;
var SEARCH_ADDRESS_ERROR = 'SEARCH_ADDRESS_ERROR';
exports.SEARCH_ADDRESS_ERROR = SEARCH_ADDRESS_ERROR;
var SEARCH_ADDRESS_REQUEST = 'SEARCH_ADDRESS_REQUEST';
exports.SEARCH_ADDRESS_REQUEST = SEARCH_ADDRESS_REQUEST;

var searchAddressForMaps = function searchAddressForMaps(searchTerm, widgetId, portalId) {
  return {
    type: SEARCH_ADDRESS_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      searchName: searchTerm
    }
  };
};

exports.searchAddressForMaps = searchAddressForMaps;