import React from 'react';
import PropTypes from 'prop-types';

import style from './navigation-list.module.scss';

export const NavigationList = ({ children }) => <div className={style.navigationList}>{children}</div>;

NavigationList.propTypes = {
  children: PropTypes.node,
};
NavigationList.defaultProps = {
  children: null,
};
export default NavigationList;
