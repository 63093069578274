"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FIND_ALL_USER_SETTINGS_SUCCESS = exports.FIND_ALL_USER_SETTINGS_REQUEST = void 0;
exports.findAllUserSettings = findAllUserSettings;

/**
 * Created by srabmund on 02.02.2017.
 */
var FIND_ALL_USER_SETTINGS_REQUEST = 'GET_USER_SETTINGS_REQUEST';
exports.FIND_ALL_USER_SETTINGS_REQUEST = FIND_ALL_USER_SETTINGS_REQUEST;
var FIND_ALL_USER_SETTINGS_SUCCESS = 'GET_USER_SETTINGS_SUCCESS';
exports.FIND_ALL_USER_SETTINGS_SUCCESS = FIND_ALL_USER_SETTINGS_SUCCESS;

function findAllUserSettings() {
  return {
    type: FIND_ALL_USER_SETTINGS_REQUEST,
    headers: {
      version: 'v1',
      portalId: 1
    },
    body: {
      portalId: 1
    }
  };
}