"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS = exports.UPDATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST = exports.UPDATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_ERROR = exports.FIND_USERGROUPS_SUCCESS = exports.FIND_USERGROUPS_REQUEST = exports.FIND_USERGROUPS_ERROR = exports.DELETE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS = exports.DELETE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST = exports.DELETE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_ERROR = exports.CREATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS = exports.CREATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST = exports.CREATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_ERROR = void 0;
exports.createUsergroupForUsergroupAdministration = createUsergroupForUsergroupAdministration;
exports.deleteUsergroupForUsergroupAdministration = deleteUsergroupForUsergroupAdministration;
exports.getUsergroups = getUsergroups;
exports.updateUsergroupForUsergroupAdministration = updateUsergroupForUsergroupAdministration;

/**
 * Created by Florian Fendt on 27.04.2017.
 */
var FIND_USERGROUPS_REQUEST = 'FIND_USERGROUPS_INSIDE_PORTAL_REQUEST';
exports.FIND_USERGROUPS_REQUEST = FIND_USERGROUPS_REQUEST;
var FIND_USERGROUPS_SUCCESS = 'FIND_USERGROUPS_INSIDE_PORTAL_SUCCESS';
exports.FIND_USERGROUPS_SUCCESS = FIND_USERGROUPS_SUCCESS;
var FIND_USERGROUPS_ERROR = 'FIND_USERGROUPS_INSIDE_PORTAL_ERROR';
exports.FIND_USERGROUPS_ERROR = FIND_USERGROUPS_ERROR;

function getUsergroups(portalId) {
  return {
    type: FIND_USERGROUPS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {}
  };
}

var CREATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS = 'CREATE_USERGROUP_FOR_ADMINISTRATION_SUCCESS';
exports.CREATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS = CREATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS;
var CREATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_ERROR = 'CREATE_USERGROUP_FOR_ADMINISTRATION_ERROR';
exports.CREATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_ERROR = CREATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_ERROR;
var CREATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST = 'CREATE_USERGROUP_FOR_ADMINISTRATION_REQUEST';
exports.CREATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST = CREATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST;

function createUsergroupForUsergroupAdministration() {
  var userGroup = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: CREATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      userGroup: userGroup
    }
  };
}

var UPDATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS = 'UPDATE_USERGROUP_FOR_ADMINISTRATION_SUCCESS';
exports.UPDATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS = UPDATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS;
var UPDATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_ERROR = 'UPDATE_USERGROUP_FOR_ADMINISTRATION_ERROR';
exports.UPDATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_ERROR = UPDATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_ERROR;
var UPDATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST = 'UPDATE_USERGROUP_FOR_ADMINISTRATION_REQUEST';
exports.UPDATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST = UPDATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST;

function updateUsergroupForUsergroupAdministration() {
  var userGroup = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: UPDATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      userGroup: userGroup
    }
  };
}

var DELETE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS = 'DELETE_USERGROUP_FOR_ADMINISTRATION_SUCCESS';
exports.DELETE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS = DELETE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS;
var DELETE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_ERROR = 'DELETE_USERGROUP_FOR_ADMINISTRATION_ERROR';
exports.DELETE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_ERROR = DELETE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_ERROR;
var DELETE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST = 'DELETE_USERGROUP_FOR_ADMINISTRATION_REQUEST';
exports.DELETE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST = DELETE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST;

function deleteUsergroupForUsergroupAdministration() {
  var userGroupId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: DELETE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      userGroup: {
        id: userGroupId
      }
    }
  };
}