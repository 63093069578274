"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GPS_HISTORY_DATA_SUCCESS = exports.GPS_HISTORY_DATA_REQUEST = exports.GPS_HISTORY_DATA_ERROR = void 0;
exports.loadRoutedirectionData = loadRoutedirectionData;
var GPS_HISTORY_DATA_REQUEST = 'GPS_HISTORY_DATA_REQUEST';
exports.GPS_HISTORY_DATA_REQUEST = GPS_HISTORY_DATA_REQUEST;
var GPS_HISTORY_DATA_SUCCESS = 'GPS_HISTORY_DATA_SUCCESS';
exports.GPS_HISTORY_DATA_SUCCESS = GPS_HISTORY_DATA_SUCCESS;
var GPS_HISTORY_DATA_ERROR = 'GPS_HISTORY_DATA_ERROR';
exports.GPS_HISTORY_DATA_ERROR = GPS_HISTORY_DATA_ERROR;

function loadRoutedirectionData(widgetId) {
  var filterJson = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var portalId = arguments.length > 2 ? arguments[2] : undefined;
  return {
    type: GPS_HISTORY_DATA_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      widgetId: widgetId,
      dynamicFilters: filterJson,
      dynamicSorters: [{
        attributeName: 'eventTime',
        sortOrder: 'asc'
      }],
      dynamicPaging: null
    }
  };
}