"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findUnassignedBoxes = exports.assignBoxToPortal = exports.FIND_UNASSIGNED_BOXES_BY_FILTER_SUCCESS = exports.FIND_UNASSIGNED_BOXES_BY_FILTER_REQUEST = exports.FIND_UNASSIGNED_BOXES_BY_FILTER_ERROR = exports.ASSIGN_BOX_TO_PORTAL_SUCCESS = exports.ASSIGN_BOX_TO_PORTAL_REQUEST = exports.ASSIGN_BOX_TO_PORTAL_ERROR = exports.ASSIGN_BOXES_TO_PORTAL_SUCCESS = exports.ASSIGN_BOXES_TO_PORTAL_ERROR = void 0;
var FIND_UNASSIGNED_BOXES_BY_FILTER_REQUEST = 'FIND_UNASSIGNED_BOXES_BY_FILTER_REQUEST';
exports.FIND_UNASSIGNED_BOXES_BY_FILTER_REQUEST = FIND_UNASSIGNED_BOXES_BY_FILTER_REQUEST;
var FIND_UNASSIGNED_BOXES_BY_FILTER_SUCCESS = 'FIND_UNASSIGNED_BOXES_BY_FILTER_SUCCESS';
exports.FIND_UNASSIGNED_BOXES_BY_FILTER_SUCCESS = FIND_UNASSIGNED_BOXES_BY_FILTER_SUCCESS;
var FIND_UNASSIGNED_BOXES_BY_FILTER_ERROR = 'FIND_UNASSIGNED_BOXES_BY_FILTER_ERROR';
exports.FIND_UNASSIGNED_BOXES_BY_FILTER_ERROR = FIND_UNASSIGNED_BOXES_BY_FILTER_ERROR;

var findUnassignedBoxes = function findUnassignedBoxes(portalId) {
  var accountIds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var dynamicFilters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var dynamicSorters = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var dynamicPaging = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  return {
    type: FIND_UNASSIGNED_BOXES_BY_FILTER_REQUEST,
    headers: {
      portalId: portalId,
      version: 'v1'
    },
    body: {
      accountIds: accountIds,
      dynamicFilters: dynamicFilters,
      dynamicSorters: dynamicSorters,
      dynamicPaging: dynamicPaging
    }
  };
};

exports.findUnassignedBoxes = findUnassignedBoxes;
var ASSIGN_BOX_TO_PORTAL_REQUEST = 'ASSIGN_BOX_TO_PORTAL_REQUEST';
exports.ASSIGN_BOX_TO_PORTAL_REQUEST = ASSIGN_BOX_TO_PORTAL_REQUEST;
var ASSIGN_BOX_TO_PORTAL_SUCCESS = 'ASSIGN_BOX_TO_PORTAL_SUCCESS';
exports.ASSIGN_BOX_TO_PORTAL_SUCCESS = ASSIGN_BOX_TO_PORTAL_SUCCESS;
var ASSIGN_BOX_TO_PORTAL_ERROR = 'ASSIGN_BOX_TO_PORTAL_ERROR';
exports.ASSIGN_BOX_TO_PORTAL_ERROR = ASSIGN_BOX_TO_PORTAL_ERROR;

var assignBoxToPortal = function assignBoxToPortal(_ref) {
  var portalId = _ref.portalId,
      boxId = _ref.boxId,
      vehicleName = _ref.vehicleName,
      vehicleType = _ref.vehicleType,
      wagonNumber = _ref.wagonNumber,
      accountIds = _ref.accountIds;
  return {
    type: ASSIGN_BOX_TO_PORTAL_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      boxId: boxId,
      vehicleName: vehicleName,
      vehicleType: vehicleType,
      wagonNumber: wagonNumber,
      accountIds: accountIds
    }
  };
};

exports.assignBoxToPortal = assignBoxToPortal;
var ASSIGN_BOXES_TO_PORTAL_SUCCESS = 'ASSIGN_BOXES_TO_PORTAL_SUCCESS';
exports.ASSIGN_BOXES_TO_PORTAL_SUCCESS = ASSIGN_BOXES_TO_PORTAL_SUCCESS;
var ASSIGN_BOXES_TO_PORTAL_ERROR = 'ASSIGN_BOXES_TO_PORTAL_ERROR';
exports.ASSIGN_BOXES_TO_PORTAL_ERROR = ASSIGN_BOXES_TO_PORTAL_ERROR;