"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _details = require("./details.actions");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function detailData() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _details.LOAD_DETAIL_DATA_SUCCESS:
      {
        return Object.assign({}, state, _defineProperty({}, action.headers.widgetId, action.body.detailData));
      }

    case _details.LOAD_DETAIL_DATA_ERROR:
      {
        var widgetId = action.headers.widgetId;
        return Object.assign({}, state, _defineProperty({}, widgetId, {}));
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return {};

    default:
      return state;
  }
}

var _default = detailData;
exports["default"] = _default;