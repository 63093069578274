"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _layoutAdministration = require("./layoutAdministration.actions");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _layoutAdministration.FIND_ALL_LAYOUTS_SUCCESS:
      {
        var _action$body$layouts = action.body.layouts,
            layouts = _action$body$layouts === void 0 ? [] : _action$body$layouts;
        var newState = layouts.reduce(function (result, layout) {
          result[layout.id] = layout;
          return result;
        }, {});
        return Object.assign({}, state, newState);
      }

    case _layoutAdministration.CREATE_LAYOUT_SUCCESS:
    case _layoutAdministration.UPDATE_LAYOUT_SUCCESS:
      {
        var layout = action.body.layout;
        return Object.assign({}, state, _defineProperty({}, layout.id, layout));
      }

    case _layoutAdministration.DELETE_LAYOUT_SUCCESS:
      {
        var _layout = action.body.layout;
        return Object.keys(state).filter(function (id) {
          return id !== String(_layout.id);
        }).reduce(function (result, layoutId) {
          result[layoutId] = state[layoutId];
          return result;
        }, {});
      }

    default:
      return state;
  }
};

exports["default"] = _default;