"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _assetGroups = require("./assetGroups.actions");

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _assetGroups.FIND_ASSETGROUPS_SUCCESS:
      return action.body.assetGroups;

    case _activePortal.CHANGE_ACTIVE_PORTAL:
    case _assetGroups.FIND_ASSETGROUPS_ERROR:
      {
        return [];
      }

    case _assetGroups.CREATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS:
      {
        var newState = state.slice(); // TODO: Delete unnecessary checks when new asset version is available

        var actionAssetGroup = action.body.assetGroup ? action.body.assetGroup : action.body.assetgroup;

        if (newState.length === 0 || newState.find(function (assetgroup) {
          return assetgroup.id === actionAssetGroup.id;
        }) === undefined) {
          newState.push(actionAssetGroup);
        }

        return newState;
      }

    case _assetGroups.UPDATE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS:
      {
        var _newState = state.slice(); // TODO: Delete unnecessary checks when new asset version is available


        var _actionAssetGroup = action.body.assetGroup ? action.body.assetGroup : action.body.assetgroup; // check if the asset group already exists in the state


        var oldAssetgroup = _newState.find(function (assetgroup) {
          return assetgroup.id === _actionAssetGroup.id;
        });

        if (oldAssetgroup !== undefined) {
          var indexOfAsset = _newState.indexOf(oldAssetgroup);

          _newState[indexOfAsset] = _actionAssetGroup;
        }

        return _newState;
      }

    case _assetGroups.DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_REQUEST:
    case _assetGroups.DELETE_ASSETGROUP_FOR_ASSETGROUP_ADMINISTRATION_SUCCESS:
      {
        var _newState2 = state.slice(); // get the asset group id
        // TODO: Delete unnecessary checks when new asset version is available


        var assetGroupId = action.body.id || (action.body.assetGroup ? action.body.assetGroup.id : action.body.assetgroup.id); // remove the deleted asset group

        return _newState2.filter(function (assetGroup) {
          return assetGroup.id !== assetGroupId;
        });
      }

    default:
      return state;
  }
};

exports["default"] = _default;