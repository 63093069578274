"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadWidgetTypes = exports.LOAD_WIDGET_TYPES_SUCCESS = exports.LOAD_WIDGET_TYPES_REQUEST = exports.LOAD_WIDGET_TYPES_ERROR = void 0;
var LOAD_WIDGET_TYPES_REQUEST = 'LOAD_WIDGET_TYPES_REQUEST';
exports.LOAD_WIDGET_TYPES_REQUEST = LOAD_WIDGET_TYPES_REQUEST;
var LOAD_WIDGET_TYPES_SUCCESS = 'LOAD_WIDGET_TYPES_SUCCESS';
exports.LOAD_WIDGET_TYPES_SUCCESS = LOAD_WIDGET_TYPES_SUCCESS;
var LOAD_WIDGET_TYPES_ERROR = 'LOAD_WIDGET_TYPES_ERROR';
exports.LOAD_WIDGET_TYPES_ERROR = LOAD_WIDGET_TYPES_ERROR;

var loadWidgetTypes = function loadWidgetTypes(portalId) {
  return {
    type: LOAD_WIDGET_TYPES_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {}
  };
};

exports.loadWidgetTypes = loadWidgetTypes;