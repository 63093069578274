"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _app = require("./app.actions");

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    mode: 'desktop',
    detectedMode: 'desktop',
    width: 0,
    height: 0,
    forceDesktopMode: false
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _app.SET_RESPONSIVE_MODE:
      {
        var detectedMode = action.body.detectedMode ? action.body.detectedMode : state.detectedMode;
        return Object.assign({}, state, {
          detectedMode: detectedMode,
          mode: state.forceDesktopMode ? 'desktop' : detectedMode,
          width: action.body.width,
          height: action.body.height
        });
      }

    case _app.TOGGLE_FORCE_DESKTOP_MODE:
      {
        var forceDesktopMode = !state.forceDesktopMode;
        return Object.assign({}, state, {
          forceDesktopMode: forceDesktopMode,
          mode: forceDesktopMode ? 'desktop' : state.detectedMode
        });
      }

    default:
      return state;
  }
};

exports["default"] = _default;