"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useClustering = void 0;

var _react = require("react");

var _hooks = require("@nicbase/hooks");

var _supercluster = _interopRequireDefault(require("supercluster"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var defaultOptions = {
  /** Debounce time for changed zoom and bounds after which clusters are generated. Affects performance. */
  debounceTime: 100,

  /** Minimum zoom level at which clusters are generated. */
  minZoom: 0,

  /** Maximum zoom level at which clusters are generated. */
  maxZoom: 16,

  /** Minimum number of points to form a cluster. */
  minPoints: 2,

  /** Cluster radius, in pixels. */
  radius: 60,

  /** (Tiles) Tile extent. Radius is calculated relative to this value. */
  extent: 512,

  /** Size of the KD-tree leaf node. Affects performance. */
  nodeSize: 64,

  /** Whether timing info should be logged. */
  log: false,

  /** Whether to generate ids for input features in vector tiles. */
  generateId: false
};
/** This hook takes geojson features (geometry must be type:"Point") and returns clusters based on bounds and zoom. */

var useClustering = function useClustering(_ref) {
  var features = _ref.features,
      bounds = _ref.bounds,
      zoom = _ref.zoom,
      _ref$options = _ref.options,
      options = _ref$options === void 0 ? {} : _ref$options;

  // clusters of geojson features that are calculated for bounds + zoom
  var _useState = (0, _react.useState)([]),
      _useState2 = _slicedToArray(_useState, 2),
      clusters = _useState2[0],
      setClusters = _useState2[1];

  var mergedOptions = (0, _react.useMemo)(function () {
    return _objectSpread(_objectSpread({}, defaultOptions), options);
  }, [options]);
  var prevFeatures = (0, _hooks.usePrevious)(features);
  var prevOptions = (0, _hooks.usePrevious)(mergedOptions); // instance of supercluster

  var indexRef = (0, _react.useRef)(new _supercluster["default"](mergedOptions));
  (0, _react.useEffect)(function () {
    if (!indexRef.current || prevOptions !== mergedOptions) {
      indexRef.current = new _supercluster["default"](mergedOptions);
      indexRef.current.load(features);
    }
  }, [features, mergedOptions, prevOptions]); // bounds + zoom need to be debounced together, otherwise clusters would get calculated 2 times (for each change separately)

  var viewport = (0, _react.useMemo)(function () {
    return {
      zoom: zoom,
      bounds: bounds
    };
  }, [bounds, zoom]);
  var debouncedViewport = (0, _hooks.useDebounce)(viewport, mergedOptions.debounceTime); // zoom must be rounded to integer, because supercluster only works with int.

  var debouncedZoom = Math.round(debouncedViewport.zoom);
  var debouncedBounds = debouncedViewport.bounds;
  (0, _react.useEffect)(function () {
    // load new features if changed
    if (features !== prevFeatures) {
      indexRef.current.load(features);
    }

    if (indexRef.current && debouncedBounds && debouncedZoom) {
      setClusters(indexRef.current.getClusters(debouncedBounds, debouncedZoom));
    }
  }, [debouncedBounds, debouncedZoom, features, prevFeatures]);
  /**
   * Returns all the points of a cluster (with pagination support).
   *
   * @param clusterId — Cluster ID (cluster_id value from feature properties).
   * @param limit — The number of points to return (set to Infinity for all points).
   * @param offset — The amount of points to skip (for pagination).
   */

  var getClusterFeatures = (0, _react.useCallback)(function (clusterId, limit, offset) {
    return indexRef.current.getLeaves(clusterId, limit, offset);
  }, []);
  /** Returns the zoom level on which the cluster expands into several children (useful for "click to zoom" feature).
   *
   * @param clusterId — Cluster ID (cluster_id value from feature properties).
   */

  var getClusterExpansionZoom = (0, _react.useCallback)(function (clusterId) {
    return indexRef.current.getClusterExpansionZoom(clusterId);
  }, []);
  return {
    clusters: clusters,
    getClusterFeatures: getClusterFeatures,
    getClusterExpansionZoom: getClusterExpansionZoom
  };
};

exports.useClustering = useClustering;