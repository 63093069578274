import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useRequest } from '@nicbase/request';
import { NicbaseSpinnerComponent } from 'nicbase-icons';
import { findWorkspaces, usePortal } from 'nicbase-reducers';

import { NavigationItem } from './navigation-item';
import { NavigationList } from './navigation-list';

const WorkspaceNav = () => {
  const { request } = useRequest();
  const dispatch = useDispatch();
  const { id: portalId } = usePortal();

  // request to load portal workspaces
  const { data: workspaceData, isLoading: isLoadingWorkspaces } = useQuery(['portal.workspaces', { portalId }], () => request(findWorkspaces(portalId)), {
    onSuccess: response => dispatch(response),
  });
  const { workspaces = [] } = workspaceData?.body || {};

  if (isLoadingWorkspaces) {
    return (
      <div className="h-full w-full flex">
        <NicbaseSpinnerComponent />
      </div>
    );
  }

  return (
    <NavigationList>
      {[...workspaces]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(workspace => (
          <NavigationItem key={workspace.id} to={{ pathname: `/dashboard/${portalId}/workspace/${workspace.id}`, state: { title: workspace.name } }}>
            {workspace.name}
          </NavigationItem>
        ))}
    </NavigationList>
  );
};

export default WorkspaceNav;
