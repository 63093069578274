"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _workspaces = require("../workspaces/workspaces.actions");

function appVariables() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    editModeActive: false,
    manageModeActive: false,
    lastActiveWorkspace: 0
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _workspaces.TOGGLE_EDIT_MODE:
      return Object.assign({}, state, {
        editModeActive: !state.editModeActive
      });

    case _workspaces.TOGGLE_MANAGE_MODE:
      return Object.assign({}, state, {
        manageModeActive: !state.manageModeActive,
        lastActiveWorkspace: action.workspaceActiveBefore
      });

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      {
        return Object.assign({}, state, {
          editModeActive: false
        });
      }

    default:
      return state;
  }
}

var _default = appVariables;
exports["default"] = _default;