"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTemperatureHistory = exports.createTempChartExport = exports.createTempChartDataExport = exports.TEMPERATURE_HISTORY_DATA_SUCCESS = exports.TEMPERATURE_HISTORY_DATA_REQUEST = exports.TEMPERATURE_HISTORY_DATA_ERROR = exports.CREATE_TEMP_CHART_EXPORT_SUCCESS = exports.CREATE_TEMP_CHART_EXPORT_REQUEST = exports.CREATE_TEMP_CHART_EXPORT_ERROR = exports.CREATE_TEMP_CHART_DATA_EXPORT_SUCCESS = exports.CREATE_TEMP_CHART_DATA_EXPORT_REQUEST = exports.CREATE_TEMP_CHART_DATA_EXPORT_ERROR = void 0;

var _uuid = require("uuid");

var TEMPERATURE_HISTORY_DATA_REQUEST = 'TEMPERATURE_HISTORY_DATA_REQUEST';
exports.TEMPERATURE_HISTORY_DATA_REQUEST = TEMPERATURE_HISTORY_DATA_REQUEST;
var TEMPERATURE_HISTORY_DATA_SUCCESS = 'TEMPERATURE_HISTORY_DATA_SUCCESS';
exports.TEMPERATURE_HISTORY_DATA_SUCCESS = TEMPERATURE_HISTORY_DATA_SUCCESS;
var TEMPERATURE_HISTORY_DATA_ERROR = 'TEMPERATURE_HISTORY_DATA_ERROR';
exports.TEMPERATURE_HISTORY_DATA_ERROR = TEMPERATURE_HISTORY_DATA_ERROR;

var getTemperatureHistory = function getTemperatureHistory(widgetId) {
  var filterJson = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var portalId = arguments.length > 2 ? arguments[2] : undefined;
  return {
    type: TEMPERATURE_HISTORY_DATA_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId,
      requestId: (0, _uuid.v4)()
    },
    body: {
      widgetId: widgetId,
      dynamicFilters: filterJson,
      dynamicSorters: [{
        attributeName: 'eventTime',
        sortOrder: 'ASC'
      }],
      dynamicPaging: null
    }
  };
};

exports.getTemperatureHistory = getTemperatureHistory;
var CREATE_TEMP_CHART_EXPORT_REQUEST = 'CREATE_TEMP_CHART_EXPORT_REQUEST';
exports.CREATE_TEMP_CHART_EXPORT_REQUEST = CREATE_TEMP_CHART_EXPORT_REQUEST;
var CREATE_TEMP_CHART_EXPORT_SUCCESS = 'CREATE_TEMP_CHART_EXPORT_SUCCESS';
exports.CREATE_TEMP_CHART_EXPORT_SUCCESS = CREATE_TEMP_CHART_EXPORT_SUCCESS;
var CREATE_TEMP_CHART_EXPORT_ERROR = 'CREATE_TEMP_CHART_EXPORT_ERROR';
exports.CREATE_TEMP_CHART_EXPORT_ERROR = CREATE_TEMP_CHART_EXPORT_ERROR;

var createTempChartExport = function createTempChartExport(widgetId, filterJson, portalId) {
  return {
    type: CREATE_TEMP_CHART_EXPORT_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      widgetId: widgetId,
      dynamicFilters: filterJson,
      dynamicSorters: [{
        attributeName: 'eventTime',
        sortOrder: 'ASC'
      }]
    }
  };
};

exports.createTempChartExport = createTempChartExport;
var CREATE_TEMP_CHART_DATA_EXPORT_REQUEST = 'CREATE_TEMP_CHART_DATA_EXPORT_REQUEST';
exports.CREATE_TEMP_CHART_DATA_EXPORT_REQUEST = CREATE_TEMP_CHART_DATA_EXPORT_REQUEST;
var CREATE_TEMP_CHART_DATA_EXPORT_SUCCESS = 'CREATE_TEMP_CHART_DATA_EXPORT_SUCCESS';
exports.CREATE_TEMP_CHART_DATA_EXPORT_SUCCESS = CREATE_TEMP_CHART_DATA_EXPORT_SUCCESS;
var CREATE_TEMP_CHART_DATA_EXPORT_ERROR = 'CREATE_TEMP_CHART_DATA_EXPORT_ERROR';
exports.CREATE_TEMP_CHART_DATA_EXPORT_ERROR = CREATE_TEMP_CHART_DATA_EXPORT_ERROR;

var createTempChartDataExport = function createTempChartDataExport(widgetId, withPosition, filterJson, portalId) {
  return {
    type: CREATE_TEMP_CHART_DATA_EXPORT_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      widgetId: widgetId,
      withPosition: withPosition,
      dynamicFilters: filterJson,
      dynamicSorters: [{
        attributeName: 'eventTime',
        sortOrder: 'ASC'
      }]
    }
  };
};

exports.createTempChartDataExport = createTempChartDataExport;