"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getHistoricalAssetInformationList = exports.getCoupledHistoricalAssetInformationList = exports.getCoupledAssetInformationList = exports.getAssetInformationList = exports.getAssetGroupInformationList = exports.getAllVehicleAssetTypesForAdminTool = exports.getAllVehicleAssetTypes = exports.LOAD_COUPLED_ASSETS_SUCCESS = exports.LOAD_COUPLED_ASSETS_REQUEST = exports.LOAD_COUPLED_ASSETS_HISTORICAL_SUCCESS = exports.LOAD_COUPLED_ASSETS_HISTORICAL_REQUEST = exports.LOAD_COUPLED_ASSETS_HISTORICAL_ERROR = exports.LOAD_COUPLED_ASSETS_ERROR = exports.LOAD_ASSET_GROUPS_SUCCESS = exports.LOAD_ASSET_GROUPS_REQUEST = exports.LOAD_ASSET_GROUPS_ERROR = exports.LOAD_ASSETS_SUCCESS = exports.LOAD_ASSETS_REQUEST = exports.LOAD_ASSETS_HISTORICAL_SUCCESS = exports.LOAD_ASSETS_HISTORICAL_REQUEST = exports.LOAD_ASSETS_HISTORICAL_ERROR = exports.LOAD_ASSETS_ERROR = exports.FIND_ALL_VEHICLE_ASSET_TYPES_SUCCESS = exports.FIND_ALL_VEHICLE_ASSET_TYPES_REQUEST = exports.FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_SUCCESS = exports.FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_REQUEST = exports.FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_ERROR = exports.FIND_ALL_VEHICLE_ASSET_TYPES_ERROR = exports.DELETE_COUPLED_ASSET_SUCCESS = exports.DELETE_ASSET_SUCCESS = exports.DELETE_ASSET_GROUP_SUCCESS = exports.ADD_COUPLED_ASSET_SUCCESS = exports.ADD_COUPLED_ASSETS_SUCCESS = exports.ADD_COUPLED_ASSETS_HISTORICAL_SUCCESS = exports.ADD_ASSET_SUCCESS = exports.ADD_ASSET_GROUP_SUCCESS = exports.ADD_ASSET_GROUPS_SUCCESS = exports.ADD_ASSETS_SUCCESS = exports.ADD_ASSETS_HISTORICAL_SUCCESS = void 0;

/**
 * Created by EHelbing on 12.06.2017.
 */
var ADD_ASSETS_SUCCESS = 'ADD_ASSETS_SUCCESS';
exports.ADD_ASSETS_SUCCESS = ADD_ASSETS_SUCCESS;
var ADD_ASSET_SUCCESS = 'ADD_ASSET_SUCCESS';
exports.ADD_ASSET_SUCCESS = ADD_ASSET_SUCCESS;
var DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
exports.DELETE_ASSET_SUCCESS = DELETE_ASSET_SUCCESS;
var LOAD_ASSETS_REQUEST = 'LOAD_ASSETS_REQUEST';
exports.LOAD_ASSETS_REQUEST = LOAD_ASSETS_REQUEST;
var LOAD_ASSETS_SUCCESS = 'LOAD_ASSETS_SUCCESS';
exports.LOAD_ASSETS_SUCCESS = LOAD_ASSETS_SUCCESS;
var LOAD_ASSETS_ERROR = 'LOAD_ASSETS_ERROR';
exports.LOAD_ASSETS_ERROR = LOAD_ASSETS_ERROR;

var getAssetInformationList = function getAssetInformationList(portalId) {
  return {
    type: LOAD_ASSETS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    }
  };
};

exports.getAssetInformationList = getAssetInformationList;
var LOAD_ASSETS_HISTORICAL_REQUEST = 'LOAD_ASSETS_HISTORICAL_REQUEST';
exports.LOAD_ASSETS_HISTORICAL_REQUEST = LOAD_ASSETS_HISTORICAL_REQUEST;
var LOAD_ASSETS_HISTORICAL_SUCCESS = 'LOAD_ASSETS_HISTORICAL_SUCCESS';
exports.LOAD_ASSETS_HISTORICAL_SUCCESS = LOAD_ASSETS_HISTORICAL_SUCCESS;
var LOAD_ASSETS_HISTORICAL_ERROR = 'LOAD_ASSETS_HISTORICAL_ERROR';
exports.LOAD_ASSETS_HISTORICAL_ERROR = LOAD_ASSETS_HISTORICAL_ERROR;

var getHistoricalAssetInformationList = function getHistoricalAssetInformationList(portalId) {
  return {
    type: LOAD_ASSETS_HISTORICAL_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    }
  };
};

exports.getHistoricalAssetInformationList = getHistoricalAssetInformationList;
var LOAD_COUPLED_ASSETS_REQUEST = 'LOAD_COUPLED_ASSETS_REQUEST';
exports.LOAD_COUPLED_ASSETS_REQUEST = LOAD_COUPLED_ASSETS_REQUEST;
var LOAD_COUPLED_ASSETS_SUCCESS = 'LOAD_COUPLED_ASSETS_SUCCESS';
exports.LOAD_COUPLED_ASSETS_SUCCESS = LOAD_COUPLED_ASSETS_SUCCESS;
var LOAD_COUPLED_ASSETS_ERROR = 'LOAD_COUPLED_ASSETS_ERROR';
exports.LOAD_COUPLED_ASSETS_ERROR = LOAD_COUPLED_ASSETS_ERROR;

var getCoupledAssetInformationList = function getCoupledAssetInformationList(portalId) {
  return {
    type: LOAD_COUPLED_ASSETS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    }
  };
};

exports.getCoupledAssetInformationList = getCoupledAssetInformationList;
var LOAD_COUPLED_ASSETS_HISTORICAL_REQUEST = 'LOAD_COUPLED_ASSETS_HISTORICAL_REQUEST';
exports.LOAD_COUPLED_ASSETS_HISTORICAL_REQUEST = LOAD_COUPLED_ASSETS_HISTORICAL_REQUEST;
var LOAD_COUPLED_ASSETS_HISTORICAL_SUCCESS = 'LOAD_COUPLED_ASSETS_HISTORICAL_SUCCESS';
exports.LOAD_COUPLED_ASSETS_HISTORICAL_SUCCESS = LOAD_COUPLED_ASSETS_HISTORICAL_SUCCESS;
var LOAD_COUPLED_ASSETS_HISTORICAL_ERROR = 'LOAD_COUPLED_ASSETS_HISTORICAL_ERROR';
exports.LOAD_COUPLED_ASSETS_HISTORICAL_ERROR = LOAD_COUPLED_ASSETS_HISTORICAL_ERROR;

var getCoupledHistoricalAssetInformationList = function getCoupledHistoricalAssetInformationList(portalId) {
  return {
    type: LOAD_COUPLED_ASSETS_HISTORICAL_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    }
  };
};

exports.getCoupledHistoricalAssetInformationList = getCoupledHistoricalAssetInformationList;
var LOAD_ASSET_GROUPS_REQUEST = 'LOAD_ASSET_GROUPS_REQUEST';
exports.LOAD_ASSET_GROUPS_REQUEST = LOAD_ASSET_GROUPS_REQUEST;
var LOAD_ASSET_GROUPS_SUCCESS = 'LOAD_ASSET_GROUPS_SUCCESS';
exports.LOAD_ASSET_GROUPS_SUCCESS = LOAD_ASSET_GROUPS_SUCCESS;
var LOAD_ASSET_GROUPS_ERROR = 'LOAD_ASSET_GROUPS_ERROR';
exports.LOAD_ASSET_GROUPS_ERROR = LOAD_ASSET_GROUPS_ERROR;

var getAssetGroupInformationList = function getAssetGroupInformationList(portalId) {
  return {
    type: LOAD_ASSET_GROUPS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    }
  };
};

exports.getAssetGroupInformationList = getAssetGroupInformationList;
var ADD_COUPLED_ASSETS_SUCCESS = 'ADD_COUPLED_ASSETS_SUCCESS';
exports.ADD_COUPLED_ASSETS_SUCCESS = ADD_COUPLED_ASSETS_SUCCESS;
var ADD_COUPLED_ASSET_SUCCESS = 'ADD_COUPLED_ASSET_SUCCESS';
exports.ADD_COUPLED_ASSET_SUCCESS = ADD_COUPLED_ASSET_SUCCESS;
var DELETE_COUPLED_ASSET_SUCCESS = 'DELETE_COUPLED_ASSET_SUCCESS';
exports.DELETE_COUPLED_ASSET_SUCCESS = DELETE_COUPLED_ASSET_SUCCESS;
var ADD_ASSETS_HISTORICAL_SUCCESS = 'ADD_ASSETS_HISTORICAL_SUCCESS';
exports.ADD_ASSETS_HISTORICAL_SUCCESS = ADD_ASSETS_HISTORICAL_SUCCESS;
var ADD_COUPLED_ASSETS_HISTORICAL_SUCCESS = 'ADD_COUPLED_ASSETS_HISTORICAL_SUCCESS';
exports.ADD_COUPLED_ASSETS_HISTORICAL_SUCCESS = ADD_COUPLED_ASSETS_HISTORICAL_SUCCESS;
var ADD_ASSET_GROUPS_SUCCESS = 'ADD_ASSET_GROUPS_SUCCESS';
exports.ADD_ASSET_GROUPS_SUCCESS = ADD_ASSET_GROUPS_SUCCESS;
var ADD_ASSET_GROUP_SUCCESS = 'ADD_ASSET_GROUP_SUCCESS';
exports.ADD_ASSET_GROUP_SUCCESS = ADD_ASSET_GROUP_SUCCESS;
var DELETE_ASSET_GROUP_SUCCESS = 'DELETE_ASSET_GROUP_SUCCESS';
exports.DELETE_ASSET_GROUP_SUCCESS = DELETE_ASSET_GROUP_SUCCESS;
var FIND_ALL_VEHICLE_ASSET_TYPES_REQUEST = 'FIND_ALL_VEHICLE_ASSET_TYPES_REQUEST';
exports.FIND_ALL_VEHICLE_ASSET_TYPES_REQUEST = FIND_ALL_VEHICLE_ASSET_TYPES_REQUEST;
var FIND_ALL_VEHICLE_ASSET_TYPES_SUCCESS = 'FIND_ALL_VEHICLE_ASSET_TYPES_SUCCESS';
exports.FIND_ALL_VEHICLE_ASSET_TYPES_SUCCESS = FIND_ALL_VEHICLE_ASSET_TYPES_SUCCESS;
var FIND_ALL_VEHICLE_ASSET_TYPES_ERROR = 'FIND_ALL_VEHICLE_ASSET_TYPES_ERROR';
exports.FIND_ALL_VEHICLE_ASSET_TYPES_ERROR = FIND_ALL_VEHICLE_ASSET_TYPES_ERROR;

var getAllVehicleAssetTypes = function getAllVehicleAssetTypes(portalId) {
  return {
    type: FIND_ALL_VEHICLE_ASSET_TYPES_REQUEST,
    headers: {
      version: 'v2',
      portalId: portalId
    }
  };
};

exports.getAllVehicleAssetTypes = getAllVehicleAssetTypes;
var FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_REQUEST = 'FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_REQUEST';
exports.FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_REQUEST = FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_REQUEST;
var FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_SUCCESS = 'FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_SUCCESS';
exports.FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_SUCCESS = FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_SUCCESS;
var FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_ERROR = 'FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_ERROR';
exports.FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_ERROR = FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_ERROR;

var getAllVehicleAssetTypesForAdminTool = function getAllVehicleAssetTypesForAdminTool(portalId) {
  return {
    type: FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_REQUEST,
    headers: {
      version: 'v2',
      portalId: portalId
    }
  };
};

exports.getAllVehicleAssetTypesForAdminTool = getAllVehicleAssetTypesForAdminTool;