"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.or = exports.notEqual = exports.like = exports.lessThanEqual = exports.lessThan = exports.isNull = exports.isNotNull = exports.isNotIn = exports.isIn = exports.greaterThanEqual = exports.greaterThan = exports.equal = exports.and = void 0;

var comparators = _interopRequireWildcard(require("./comparators"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// conditions
var and = function and() {
  for (var _len = arguments.length, filterFunctions = new Array(_len), _key = 0; _key < _len; _key++) {
    filterFunctions[_key] = arguments[_key];
  }

  return function (value) {
    return filterFunctions.every(function (filterFunction) {
      return filterFunction(value);
    });
  };
};

exports.and = and;

var or = function or() {
  for (var _len2 = arguments.length, filterFunctions = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    filterFunctions[_key2] = arguments[_key2];
  }

  return function (value) {
    return filterFunctions.length === 0 || filterFunctions.some(function (filterFunction) {
      return filterFunction(value);
    });
  };
}; // filters


exports.or = or;

var equal = function equal(presetValue) {
  return function (value) {
    if (presetValue === null || presetValue === undefined) {
      return true;
    }

    return comparators.equal(value, presetValue);
  };
};

exports.equal = equal;

var notEqual = function notEqual(presetValue) {
  return function (value) {
    if (presetValue === null || presetValue === undefined) {
      return true;
    }

    return comparators.notEqual(value, presetValue);
  };
};

exports.notEqual = notEqual;

var lessThan = function lessThan(presetValue) {
  return function (value) {
    if (presetValue === null || presetValue === undefined) {
      return true;
    }

    return comparators.lessThan(value, presetValue);
  };
};

exports.lessThan = lessThan;

var lessThanEqual = function lessThanEqual(presetValue) {
  return function (value) {
    if (presetValue === null || presetValue === undefined) {
      return true;
    }

    return comparators.lessThanEqual(value, presetValue);
  };
};

exports.lessThanEqual = lessThanEqual;

var greaterThan = function greaterThan(presetValue) {
  return function (value) {
    if (presetValue === null || presetValue === undefined) {
      return true;
    }

    return comparators.greaterThan(value, presetValue);
  };
};

exports.greaterThan = greaterThan;

var greaterThanEqual = function greaterThanEqual(presetValue) {
  return function (value) {
    if (presetValue === null || presetValue === undefined) {
      return true;
    }

    return comparators.greaterThanEqual(value, presetValue);
  };
};

exports.greaterThanEqual = greaterThanEqual;

var like = function like(presetValue) {
  return function (value) {
    if (presetValue === null || presetValue === undefined) {
      return true;
    }

    return comparators.like(value, presetValue);
  };
};

exports.like = like;

var isIn = function isIn(presetValue) {
  return function (value) {
    if (presetValue === null || presetValue === undefined) {
      return true;
    }

    return comparators.isIn(value, presetValue);
  };
};

exports.isIn = isIn;

var isNotIn = function isNotIn(presetValue) {
  return function (value) {
    if (presetValue === null || presetValue === undefined) {
      return true;
    }

    return comparators.isNotIn(value, presetValue);
  };
};

exports.isNotIn = isNotIn;

var isNull = function isNull(value) {
  return value === null;
};

exports.isNull = isNull;

var isNotNull = function isNotNull(value) {
  return value !== null;
};

exports.isNotNull = isNotNull;