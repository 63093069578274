"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _accounting = require("../accounting/accounting.actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var accountingExportReducer = function accountingExportReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    // Tour
    case _accounting.CREATE_TOUR_EXPORT_FOR_BILLING_REQUEST:
      {
        // After button press
        return _objectSpread(_objectSpread({}, state), {}, {
          exportTourList: false
        });
      }

    case _accounting.CREATE_TOUR_EXPORT_FOR_BILLING_ERROR:
    case _accounting.CREATE_TOUR_EXPORT_FOR_BILLING_SUCCESS:
      {
        // After report from backend arrives
        return _objectSpread(_objectSpread({}, state), {}, {
          exportTourList: true
        });
      }
    // Asset

    case _accounting.CREATE_ASSET_EXPORT_FOR_BILLING_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          exportAssetList: false
        });
      }

    case _accounting.CREATE_ASSET_EXPORT_FOR_BILLING_ERROR:
    case _accounting.CREATE_ASSET_EXPORT_FOR_BILLING_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          exportAssetList: true
        });
      }
    // SMS

    case _accounting.CREATE_SMS_EXPORT_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          exportSMSList: false
        });
      }

    case _accounting.CREATE_SMS_EXPORT_ERROR:
    case _accounting.CREATE_SMS_EXPORT_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          exportSMSList: true
        });
      }

    case _accounting.TRIGGER_BILLING_EXPORT_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          accountingBillingList: false
        });
      }

    case _accounting.TRIGGER_BILLING_EXPORT_ERROR:
    case _accounting.TRIGGER_BILLING_EXPORT_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          accountingBillingList: true
        });
      }

    default:
      {
        return state;
      }
  }
};

var _default = accountingExportReducer;
exports["default"] = _default;