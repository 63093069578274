"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findGatewayProvidersByFilter = exports.FIND_PROVIDERS_BY_FILTER_SUCCESS = exports.FIND_PROVIDERS_BY_FILTER_REQUEST = exports.FIND_PROVIDERS_BY_FILTER_ERROR = void 0;
var FIND_PROVIDERS_BY_FILTER_REQUEST = 'FIND_PROVIDERS_BY_FILTER_REQUEST';
exports.FIND_PROVIDERS_BY_FILTER_REQUEST = FIND_PROVIDERS_BY_FILTER_REQUEST;
var FIND_PROVIDERS_BY_FILTER_SUCCESS = 'FIND_PROVIDERS_BY_FILTER_SUCCESS';
exports.FIND_PROVIDERS_BY_FILTER_SUCCESS = FIND_PROVIDERS_BY_FILTER_SUCCESS;
var FIND_PROVIDERS_BY_FILTER_ERROR = 'FIND_PROVIDERS_BY_FILTER_ERROR';
exports.FIND_PROVIDERS_BY_FILTER_ERROR = FIND_PROVIDERS_BY_FILTER_ERROR;

var findGatewayProvidersByFilter = function findGatewayProvidersByFilter(portalId) {
  var dynamicFilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var dynamicSorters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  return {
    type: FIND_PROVIDERS_BY_FILTER_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      dynamicFilters: dynamicFilters,
      dynamicSorters: dynamicSorters
    }
  };
};

exports.findGatewayProvidersByFilter = findGatewayProvidersByFilter;