"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _actions = _interopRequireDefault(require("./reducers/actions"));

var _state = require("./state");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = function _default() {
  var _useApplicationState = (0, _state.useApplicationState)(),
      dispatch = _useApplicationState.dispatch;

  return (0, _react.useMemo)(function () {
    return (0, _actions["default"])({
      dispatch: dispatch
    });
  }, [dispatch]);
};

exports["default"] = _default;