"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findGatewayAccountsByPortal = exports.createUnassignedBoxesExport = exports.FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_SUCCESS = exports.FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_REQUEST = exports.FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_ERROR = exports.CREATE_UNASSIGNED_BOXES_EXPORT_SUCCESS = exports.CREATE_UNASSIGNED_BOXES_EXPORT_REQUEST = exports.CREATE_UNASSIGNED_BOXES_EXPORT_ERROR = void 0;
var FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_REQUEST = 'FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_REQUEST';
exports.FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_REQUEST = FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_REQUEST;
var FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_SUCCESS = 'FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_SUCCESS';
exports.FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_SUCCESS = FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_SUCCESS;
var FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_ERROR = 'FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_ERROR';
exports.FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_ERROR = FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_ERROR;

var findGatewayAccountsByPortal = function findGatewayAccountsByPortal(portalId) {
  return {
    type: FIND_ALL_GATEWAY_ACCOUNTS_BY_PORTALID_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {}
  };
};

exports.findGatewayAccountsByPortal = findGatewayAccountsByPortal;
var CREATE_UNASSIGNED_BOXES_EXPORT_REQUEST = 'CREATE_UNASSIGNED_BOXES_EXPORT_REQUEST';
exports.CREATE_UNASSIGNED_BOXES_EXPORT_REQUEST = CREATE_UNASSIGNED_BOXES_EXPORT_REQUEST;
var CREATE_UNASSIGNED_BOXES_EXPORT_SUCCESS = 'CREATE_UNASSIGNED_BOXES_EXPORT_SUCCESS';
exports.CREATE_UNASSIGNED_BOXES_EXPORT_SUCCESS = CREATE_UNASSIGNED_BOXES_EXPORT_SUCCESS;
var CREATE_UNASSIGNED_BOXES_EXPORT_ERROR = 'CREATE_UNASSIGNED_BOXES_EXPORT_ERROR';
exports.CREATE_UNASSIGNED_BOXES_EXPORT_ERROR = CREATE_UNASSIGNED_BOXES_EXPORT_ERROR;

var createUnassignedBoxesExport = function createUnassignedBoxesExport(portalId) {
  var accountIds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var dynamicFilters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return {
    type: CREATE_UNASSIGNED_BOXES_EXPORT_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      accountIds: accountIds,
      dynamicFilters: dynamicFilters
    }
  };
};

exports.createUnassignedBoxesExport = createUnassignedBoxesExport;