"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findSharedVehiclesExport = exports.FIND_SHARED_VEHICLES_EXPORT_SUCCESS = exports.FIND_SHARED_VEHICLES_EXPORT_REQUEST = exports.FIND_SHARED_VEHICLES_EXPORT_ERROR = void 0;
var FIND_SHARED_VEHICLES_EXPORT_REQUEST = 'FIND_SHARED_VEHICLES_EXPORT_REQUEST';
exports.FIND_SHARED_VEHICLES_EXPORT_REQUEST = FIND_SHARED_VEHICLES_EXPORT_REQUEST;
var FIND_SHARED_VEHICLES_EXPORT_SUCCESS = 'FIND_SHARED_VEHICLES_EXPORT_SUCCESS';
exports.FIND_SHARED_VEHICLES_EXPORT_SUCCESS = FIND_SHARED_VEHICLES_EXPORT_SUCCESS;
var FIND_SHARED_VEHICLES_EXPORT_ERROR = 'FIND_SHARED_VEHICLES_EXPORT_ERROR';
exports.FIND_SHARED_VEHICLES_EXPORT_ERROR = FIND_SHARED_VEHICLES_EXPORT_ERROR;

var findSharedVehiclesExport = function findSharedVehiclesExport(portalId) {
  var dynamicFilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    type: FIND_SHARED_VEHICLES_EXPORT_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      filters: {
        dynamicFilters: dynamicFilters
      }
    }
  };
};

exports.findSharedVehiclesExport = findSharedVehiclesExport;