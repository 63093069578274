"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _capacity = require("./capacity.actions");

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _capacity.FIND_ALL_CAPACITIES_SUCCESS:
      {
        return action.body.capacities;
      }

    default:
      return state;
  }
};

exports["default"] = _default;