"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_LINKED_PORTALS_SUCCESS = exports.GET_LINKED_PORTALS_REQUEST = exports.GET_LINKED_PORTALS_ERROR = void 0;
exports.getLinkedPortalsInformationListFromBackend = getLinkedPortalsInformationListFromBackend;
var GET_LINKED_PORTALS_REQUEST = 'GET_LINKED_PORTALS_REQUEST';
exports.GET_LINKED_PORTALS_REQUEST = GET_LINKED_PORTALS_REQUEST;
var GET_LINKED_PORTALS_ERROR = 'GET_LINKED_PORTALS_ERROR';
exports.GET_LINKED_PORTALS_ERROR = GET_LINKED_PORTALS_ERROR;
var GET_LINKED_PORTALS_SUCCESS = 'GET_LINKED_PORTALS_SUCCESS';
exports.GET_LINKED_PORTALS_SUCCESS = GET_LINKED_PORTALS_SUCCESS;

function getLinkedPortalsInformationListFromBackend(portalId) {
  return {
    type: GET_LINKED_PORTALS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    }
  };
}