"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.or = exports.notEqual = exports.like = exports.lessThanEqual = exports.lessThan = exports.isNull = exports.isNotNull = exports.isNotIn = exports.isIn = exports.greaterThanEqual = exports.greaterThan = exports.equal = exports.and = void 0;

var _property = _interopRequireDefault(require("./filterFunctions/property"));

var _typeMapper = _interopRequireDefault(require("./filterFunctions/typeMapper"));

var filterFunctions = _interopRequireWildcard(require("./filterFunctions"));

var filterNodes = _interopRequireWildcard(require("./filterNodes"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

// conditions
var and = function and() {
  for (var _len = arguments.length, inputs = new Array(_len), _key = 0; _key < _len; _key++) {
    inputs[_key] = arguments[_key];
  }

  return {
    filterTree: filterNodes.andCondition.apply(filterNodes, _toConsumableArray(inputs.filter(function (input) {
      return Boolean(input);
    }).map(function (_ref) {
      var filterTree = _ref.filterTree;
      return filterTree;
    }))),
    filterFunction: filterFunctions.and.apply(filterFunctions, _toConsumableArray(inputs.filter(function (input) {
      return Boolean(input);
    }).map(function (_ref2) {
      var filterFunction = _ref2.filterFunction;
      return filterFunction;
    })))
  };
};

exports.and = and;

var or = function or() {
  for (var _len2 = arguments.length, inputs = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    inputs[_key2] = arguments[_key2];
  }

  return {
    filterTree: filterNodes.orCondition.apply(filterNodes, _toConsumableArray(inputs.filter(function (input) {
      return Boolean(input);
    }).map(function (_ref3) {
      var filterTree = _ref3.filterTree;
      return filterTree;
    }))),
    filterFunction: filterFunctions.or.apply(filterFunctions, _toConsumableArray(inputs.filter(function (input) {
      return Boolean(input);
    }).map(function (_ref4) {
      var filterFunction = _ref4.filterFunction;
      return filterFunction;
    })))
  };
}; // filters


exports.or = or;

var equal = function equal(name, value, type) {
  return {
    filterFunction: (0, _property["default"])(name, filterFunctions.equal((0, _typeMapper["default"])(type)(value)), type),
    filterTree: filterNodes.equalNode(name, value, type)
  };
};

exports.equal = equal;

var notEqual = function notEqual(name, value, type) {
  return {
    filterFunction: (0, _property["default"])(name, filterFunctions.notEqual((0, _typeMapper["default"])(type)(value)), type),
    filterTree: filterNodes.notEqualNode(name, value, type)
  };
};

exports.notEqual = notEqual;

var like = function like(name, value, type) {
  return {
    filterFunction: (0, _property["default"])(name, filterFunctions.like((0, _typeMapper["default"])(type)(value)), type),
    filterTree: filterNodes.likeNode(name, value, type)
  };
};

exports.like = like;

var lessThan = function lessThan(name, value, type) {
  return {
    filterFunction: (0, _property["default"])(name, filterFunctions.lessThan((0, _typeMapper["default"])(type)(value)), type),
    filterTree: filterNodes.lessThanNode(name, value, type)
  };
};

exports.lessThan = lessThan;

var lessThanEqual = function lessThanEqual(name, value, type) {
  return {
    filterFunction: (0, _property["default"])(name, filterFunctions.lessThanEqual((0, _typeMapper["default"])(type)(value)), type),
    filterTree: filterNodes.lessThanEqualNode(name, value, type)
  };
};

exports.lessThanEqual = lessThanEqual;

var greaterThan = function greaterThan(name, value, type) {
  return {
    filterFunction: (0, _property["default"])(name, filterFunctions.greaterThan((0, _typeMapper["default"])(type)(value)), type),
    filterTree: filterNodes.greaterThanNode(name, value, type)
  };
};

exports.greaterThan = greaterThan;

var greaterThanEqual = function greaterThanEqual(name, value, type) {
  return {
    filterFunction: (0, _property["default"])(name, filterFunctions.greaterThanEqual((0, _typeMapper["default"])(type)(value)), type),
    filterTree: filterNodes.greaterThanEqualNode(name, value, type)
  };
};

exports.greaterThanEqual = greaterThanEqual;

var isNull = function isNull(name) {
  return {
    filterFunction: (0, _property["default"])(name, filterFunctions.isNull),
    filterTree: filterNodes.isNullNode(name)
  };
};

exports.isNull = isNull;

var isNotNull = function isNotNull(name) {
  return {
    filterFunction: (0, _property["default"])(name, filterFunctions.isNotNull),
    filterTree: filterNodes.isNotNullNode(name)
  };
};

exports.isNotNull = isNotNull;

var isIn = function isIn(name, values, type) {
  return {
    filterFunction: (0, _property["default"])(name, filterFunctions.isIn(Array.isArray(values) ? values.map(function (value) {
      return (0, _typeMapper["default"])(type)(value);
    }) : null), type),
    filterTree: filterNodes.isInNode(name, values, type)
  };
};

exports.isIn = isIn;

var isNotIn = function isNotIn(name, values, type) {
  return {
    filterFunction: (0, _property["default"])(name, filterFunctions.isNotIn(Array.isArray(values) ? values.map(function (value) {
      return (0, _typeMapper["default"])(type)(value);
    }) : null), type),
    filterTree: filterNodes.isNotInNode(name, values, type)
  };
};

exports.isNotIn = isNotIn;