"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _alarms = require("./alarms.actions");

/**
 * Created by PKing on 28.09.2017
 */
var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _alarms.CREATE_ALARM_SUCCESS:
      {
        // return a cloned copy of state with the new alarmData concatenated into it
        return state.concat(action.body.alarms);
      }

    case _alarms.UPDATE_ALARM_SUCCESS:
      {
        return state.map(function (obj) {
          return obj.id === action.body.alarms.id ? Object.assign({}, obj, action.body.alarms) : obj;
        });
      }

    case _alarms.DELETE_ALARMS_REQUEST:
    case _alarms.DELETE_ALARMS_SUCCESS:
      {
        return state.filter(function (obj) {
          return !action.body.alarmIds.includes(obj.id);
        });
      }

    case _alarms.SET_ALARM_ACTIVE_REQUEST:
      {
        return state.map(function (obj) {
          return obj.id === action.body.alarmId ? Object.assign({}, obj, {
            active: action.body.activationStatus
          }) : obj;
        });
      }

    case _alarms.UPDATE_ASSETS_TO_ALARM_SUCCESS:
      {
        var _action$body$alarm = action.body.alarm,
            assets = _action$body$alarm.assets,
            assetGroups = _action$body$alarm.assetGroups;
        return state.map(function (obj) {
          return obj.id === action.body.alarm.id ? Object.assign({}, obj, {
            assets: assets,
            assetGroups: assetGroups
          }) : obj;
        });
      }

    case _alarms.FIND_ALARMS_SUCCESS:
      {
        return action.body.alarms;
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
    case _alarms.FIND_ALARMS_ERROR:
      {
        return [];
      }

    default:
      {
        return state;
      }
  }
};

exports["default"] = _default;