"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_VEHICLE_ATTRIBUTES_FOR_ADMIN_TOOL_ERROR = exports.REVOKE_BOX_FROM_PORTAL_FOR_ADMIN_TOOL_ERROR = exports.CHANGE_BOXES_FOR_ADMIN_TOOL_ERROR = exports.ASSIGN_BOX_TO_PORTAL_FOR_ADMIN_TOOL_ERROR = void 0;
var ASSIGN_BOX_TO_PORTAL_FOR_ADMIN_TOOL_ERROR = 'ASSIGN_BOX_TO_PORTAL_FOR_ADMIN_TOOL_ERROR';
exports.ASSIGN_BOX_TO_PORTAL_FOR_ADMIN_TOOL_ERROR = ASSIGN_BOX_TO_PORTAL_FOR_ADMIN_TOOL_ERROR;
var REVOKE_BOX_FROM_PORTAL_FOR_ADMIN_TOOL_ERROR = 'REVOKE_BOX_FROM_PORTAL_FOR_ADMIN_TOOL_ERROR';
exports.REVOKE_BOX_FROM_PORTAL_FOR_ADMIN_TOOL_ERROR = REVOKE_BOX_FROM_PORTAL_FOR_ADMIN_TOOL_ERROR;
var CHANGE_BOXES_FOR_ADMIN_TOOL_ERROR = 'CHANGE_BOXES_FOR_ADMIN_TOOL_ERROR';
exports.CHANGE_BOXES_FOR_ADMIN_TOOL_ERROR = CHANGE_BOXES_FOR_ADMIN_TOOL_ERROR;
var UPDATE_VEHICLE_ATTRIBUTES_FOR_ADMIN_TOOL_ERROR = 'UPDATE_VEHICLE_ATTRIBUTES_FOR_ADMIN_TOOL_ERROR';
exports.UPDATE_VEHICLE_ATTRIBUTES_FOR_ADMIN_TOOL_ERROR = UPDATE_VEHICLE_ATTRIBUTES_FOR_ADMIN_TOOL_ERROR;