"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOAD_DETAIL_DATA_SUCCESS = exports.LOAD_DETAIL_DATA_REQUEST = exports.LOAD_DETAIL_DATA_ERROR = void 0;
exports.loadDetailData = loadDetailData;
var LOAD_DETAIL_DATA_REQUEST = 'LOAD_DETAIL_DATA_REQUEST';
exports.LOAD_DETAIL_DATA_REQUEST = LOAD_DETAIL_DATA_REQUEST;
var LOAD_DETAIL_DATA_SUCCESS = 'LOAD_DETAIL_DATA_SUCCESS';
exports.LOAD_DETAIL_DATA_SUCCESS = LOAD_DETAIL_DATA_SUCCESS;
var LOAD_DETAIL_DATA_ERROR = 'LOAD_DETAIL_DATA_ERROR';
exports.LOAD_DETAIL_DATA_ERROR = LOAD_DETAIL_DATA_ERROR;

function loadDetailData(boxDataIds, widgetId, locale, portalId) {
  return {
    type: LOAD_DETAIL_DATA_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      boxDataIds: boxDataIds,
      locale: locale
    }
  };
}