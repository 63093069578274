"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  TK: {
    0: 'errorcode.reefer.TK.0',
    1: 'errorcode.reefer.TK.1',
    2: 'errorcode.reefer.TK.2',
    3: 'errorcode.reefer.TK.3',
    4: 'errorcode.reefer.TK.4',
    5: 'errorcode.reefer.TK.5',
    6: 'errorcode.reefer.TK.6',
    7: 'errorcode.reefer.TK.7',
    8: 'errorcode.reefer.TK.8',
    9: 'errorcode.reefer.TK.9',
    10: 'errorcode.reefer.TK.10',
    11: 'errorcode.reefer.TK.11',
    12: 'errorcode.reefer.TK.12',
    13: 'errorcode.reefer.TK.13',
    14: 'errorcode.reefer.TK.14',
    15: 'errorcode.reefer.TK.15',
    16: 'errorcode.reefer.TK.16',
    17: 'errorcode.reefer.TK.17',
    18: 'errorcode.reefer.TK.18',
    19: 'errorcode.reefer.TK.19',
    20: 'errorcode.reefer.TK.20',
    21: 'errorcode.reefer.TK.21',
    22: 'errorcode.reefer.TK.22',
    23: 'errorcode.reefer.TK.23',
    24: 'errorcode.reefer.TK.24',
    25: 'errorcode.reefer.TK.25',
    26: 'errorcode.reefer.TK.26',
    27: 'errorcode.reefer.TK.27',
    28: 'errorcode.reefer.TK.28',
    29: 'errorcode.reefer.TK.29',
    30: 'errorcode.reefer.TK.30',
    31: 'errorcode.reefer.TK.31',
    32: 'errorcode.reefer.TK.32',
    33: 'errorcode.reefer.TK.33',
    34: 'errorcode.reefer.TK.34',
    35: 'errorcode.reefer.TK.35',
    36: 'errorcode.reefer.TK.36',
    37: 'errorcode.reefer.TK.37',
    38: 'errorcode.reefer.TK.38',
    39: 'errorcode.reefer.TK.39',
    40: 'errorcode.reefer.TK.40',
    41: 'errorcode.reefer.TK.41',
    42: 'errorcode.reefer.TK.42',
    43: 'errorcode.reefer.TK.43',
    44: 'errorcode.reefer.TK.44',
    45: 'errorcode.reefer.TK.45',
    46: 'errorcode.reefer.TK.46',
    47: 'errorcode.reefer.TK.47',
    48: 'errorcode.reefer.TK.48',
    49: 'errorcode.reefer.TK.49',
    50: 'errorcode.reefer.TK.50',
    51: 'errorcode.reefer.TK.51',
    52: 'errorcode.reefer.TK.52',
    53: 'errorcode.reefer.TK.53',
    54: 'errorcode.reefer.TK.54',
    55: 'errorcode.reefer.TK.55',
    56: 'errorcode.reefer.TK.56',
    57: 'errorcode.reefer.TK.57',
    58: 'errorcode.reefer.TK.58',
    59: 'errorcode.reefer.TK.59',
    60: 'errorcode.reefer.TK.60',
    61: 'errorcode.reefer.TK.61',
    62: 'errorcode.reefer.TK.62',
    63: 'errorcode.reefer.TK.63',
    64: 'errorcode.reefer.TK.64',
    65: 'errorcode.reefer.TK.65',
    66: 'errorcode.reefer.TK.66',
    67: 'errorcode.reefer.TK.67',
    68: 'errorcode.reefer.TK.68',
    69: 'errorcode.reefer.TK.69',
    71: 'errorcode.reefer.TK.71',
    72: 'errorcode.reefer.TK.72',
    73: 'errorcode.reefer.TK.73',
    74: 'errorcode.reefer.TK.74',
    75: 'errorcode.reefer.TK.75',
    76: 'errorcode.reefer.TK.76',
    77: 'errorcode.reefer.TK.77',
    78: 'errorcode.reefer.TK.78',
    79: 'errorcode.reefer.TK.79',
    80: 'errorcode.reefer.TK.80',
    81: 'errorcode.reefer.TK.81',
    82: 'errorcode.reefer.TK.82',
    83: 'errorcode.reefer.TK.83',
    84: 'errorcode.reefer.TK.84',
    85: 'errorcode.reefer.TK.85',
    86: 'errorcode.reefer.TK.86',
    87: 'errorcode.reefer.TK.87',
    88: 'errorcode.reefer.TK.88',
    89: 'errorcode.reefer.TK.89',
    90: 'errorcode.reefer.TK.90',
    91: 'errorcode.reefer.TK.91',
    92: 'errorcode.reefer.TK.92',
    93: 'errorcode.reefer.TK.93',
    94: 'errorcode.reefer.TK.94',
    95: 'errorcode.reefer.TK.95',
    96: 'errorcode.reefer.TK.96',
    97: 'errorcode.reefer.TK.97',
    98: 'errorcode.reefer.TK.98',
    99: 'errorcode.reefer.TK.99',
    100: 'errorcode.reefer.TK.100',
    101: 'errorcode.reefer.TK.101',
    102: 'errorcode.reefer.TK.102',
    103: 'errorcode.reefer.TK.103',
    104: 'errorcode.reefer.TK.104',
    105: 'errorcode.reefer.TK.105',
    106: 'errorcode.reefer.TK.106',
    107: 'errorcode.reefer.TK.107',
    108: 'errorcode.reefer.TK.108',
    109: 'errorcode.reefer.TK.109',
    110: 'errorcode.reefer.TK.110',
    111: 'errorcode.reefer.TK.111',
    112: 'errorcode.reefer.TK.112',
    113: 'errorcode.reefer.TK.113',
    114: 'errorcode.reefer.TK.114',
    115: 'errorcode.reefer.TK.115',
    116: 'errorcode.reefer.TK.116',
    117: 'errorcode.reefer.TK.117',
    118: 'errorcode.reefer.TK.118',
    119: 'errorcode.reefer.TK.119',
    120: 'errorcode.reefer.TK.120',
    121: 'errorcode.reefer.TK.121',
    122: 'errorcode.reefer.TK.122',
    123: 'errorcode.reefer.TK.123',
    124: 'errorcode.reefer.TK.124',
    125: 'errorcode.reefer.TK.125',
    126: 'errorcode.reefer.TK.126',
    127: 'errorcode.reefer.TK.127',
    128: 'errorcode.reefer.TK.128',
    129: 'errorcode.reefer.TK.129',
    130: 'errorcode.reefer.TK.130',
    131: 'errorcode.reefer.TK.131',
    132: 'errorcode.reefer.TK.132',
    133: 'errorcode.reefer.TK.133',
    134: 'errorcode.reefer.TK.134',
    135: 'errorcode.reefer.TK.135',
    136: 'errorcode.reefer.TK.136',
    137: 'errorcode.reefer.TK.137',
    138: 'errorcode.reefer.TK.138',
    139: 'errorcode.reefer.TK.139',
    140: 'errorcode.reefer.TK.140',
    141: 'errorcode.reefer.TK.141',
    142: 'errorcode.reefer.TK.142',
    143: 'errorcode.reefer.TK.143',
    144: 'errorcode.reefer.TK.144',
    145: 'errorcode.reefer.TK.145',
    146: 'errorcode.reefer.TK.146',
    147: 'errorcode.reefer.TK.147',
    148: 'errorcode.reefer.TK.148',
    149: 'errorcode.reefer.TK.149',
    150: 'errorcode.reefer.TK.150',
    151: 'errorcode.reefer.TK.151',
    152: 'errorcode.reefer.TK.152',
    153: 'errorcode.reefer.TK.153',
    154: 'errorcode.reefer.TK.154',
    155: 'errorcode.reefer.TK.155',
    156: 'errorcode.reefer.TK.156',
    157: 'errorcode.reefer.TK.157',
    158: 'errorcode.reefer.TK.158',
    159: 'errorcode.reefer.TK.159',
    160: 'errorcode.reefer.TK.160',
    161: 'errorcode.reefer.TK.161',
    163: 'errorcode.reefer.TK.163',
    175: 'errorcode.reefer.TK.175',
    176: 'errorcode.reefer.TK.176',
    177: 'errorcode.reefer.TK.177',
    178: 'errorcode.reefer.TK.178',
    180: 'errorcode.reefer.TK.180',
    188: 'errorcode.reefer.TK.188',
    203: 'errorcode.reefer.TK.203',
    204: 'errorcode.reefer.TK.204',
    216: 'errorcode.reefer.TK.216',
    234: 'errorcode.reefer.TK.234',
    235: 'errorcode.reefer.TK.235',
    250: 'errorcode.reefer.TK.250',
    251: 'errorcode.reefer.TK.251',
    252: 'errorcode.reefer.TK.252',
    264: 'errorcode.reefer.TK.264',
    265: 'errorcode.reefer.TK.265',
    266: 'errorcode.reefer.TK.266',
    267: 'errorcode.reefer.TK.267',
    268: 'errorcode.reefer.TK.268',
    269: 'errorcode.reefer.TK.269',
    270: 'errorcode.reefer.TK.270',
    271: 'errorcode.reefer.TK.271',
    272: 'errorcode.reefer.TK.272',
    273: 'errorcode.reefer.TK.273',
    274: 'errorcode.reefer.TK.274',
    275: 'errorcode.reefer.TK.275',
    276: 'errorcode.reefer.TK.276',
    277: 'errorcode.reefer.TK.277',
    278: 'errorcode.reefer.TK.278',
    279: 'errorcode.reefer.TK.279',
    280: 'errorcode.reefer.TK.280',
    281: 'errorcode.reefer.TK.281',
    282: 'errorcode.reefer.TK.282',
    526: 'errorcode.reefer.TK.526',
    555: 'errorcode.reefer.TK.555'
  },
  CARRIER: {
    1: 'errorcode.reefer.CARRIER.1',
    2: 'errorcode.reefer.CARRIER.2',
    3: 'errorcode.reefer.CARRIER.3',
    11: 'errorcode.reefer.CARRIER.11',
    12: 'errorcode.reefer.CARRIER.12',
    13: 'errorcode.reefer.CARRIER.13',
    14: 'errorcode.reefer.CARRIER.14',
    15: 'errorcode.reefer.CARRIER.15',
    16: 'errorcode.reefer.CARRIER.16',
    17: 'errorcode.reefer.CARRIER.17',
    18: 'errorcode.reefer.CARRIER.18',
    19: 'errorcode.reefer.CARRIER.19',
    20: 'errorcode.reefer.CARRIER.20',
    22: 'errorcode.reefer.CARRIER.22',
    23: 'errorcode.reefer.CARRIER.23',
    27: 'errorcode.reefer.CARRIER.27',
    28: 'errorcode.reefer.CARRIER.28',
    30: 'errorcode.reefer.CARRIER.30',
    31: 'errorcode.reefer.CARRIER.31',
    32: 'errorcode.reefer.CARRIER.32',
    34: 'errorcode.reefer.CARRIER.34',
    35: 'errorcode.reefer.CARRIER.35',
    36: 'errorcode.reefer.CARRIER.36',
    37: 'errorcode.reefer.CARRIER.37',
    38: 'errorcode.reefer.CARRIER.38',
    39: 'errorcode.reefer.CARRIER.39',
    40: 'errorcode.reefer.CARRIER.40',
    41: 'errorcode.reefer.CARRIER.41',
    51: 'errorcode.reefer.CARRIER.51',
    53: 'errorcode.reefer.CARRIER.53',
    54: 'errorcode.reefer.CARRIER.54',
    55: 'errorcode.reefer.CARRIER.55',
    56: 'errorcode.reefer.CARRIER.56',
    57: 'errorcode.reefer.CARRIER.57',
    58: 'errorcode.reefer.CARRIER.58',
    59: 'errorcode.reefer.CARRIER.59',
    60: 'errorcode.reefer.CARRIER.60',
    61: 'errorcode.reefer.CARRIER.61',
    62: 'errorcode.reefer.CARRIER.62',
    63: 'errorcode.reefer.CARRIER.63',
    71: 'errorcode.reefer.CARRIER.71',
    72: 'errorcode.reefer.CARRIER.72',
    73: 'errorcode.reefer.CARRIER.73',
    75: 'errorcode.reefer.CARRIER.75',
    76: 'errorcode.reefer.CARRIER.76',
    77: 'errorcode.reefer.CARRIER.77',
    78: 'errorcode.reefer.CARRIER.78',
    79: 'errorcode.reefer.CARRIER.79',
    81: 'errorcode.reefer.CARRIER.81',
    82: 'errorcode.reefer.CARRIER.82',
    83: 'errorcode.reefer.CARRIER.83',
    84: 'errorcode.reefer.CARRIER.84',
    85: 'errorcode.reefer.CARRIER.85',
    86: 'errorcode.reefer.CARRIER.86',
    87: 'errorcode.reefer.CARRIER.87',
    88: 'errorcode.reefer.CARRIER.88',
    89: 'errorcode.reefer.CARRIER.89',
    90: 'errorcode.reefer.CARRIER.90',
    91: 'errorcode.reefer.CARRIER.91',
    92: 'errorcode.reefer.CARRIER.92',
    93: 'errorcode.reefer.CARRIER.93',
    94: 'errorcode.reefer.CARRIER.94',
    95: 'errorcode.reefer.CARRIER.95',
    96: 'errorcode.reefer.CARRIER.96',
    97: 'errorcode.reefer.CARRIER.97',
    98: 'errorcode.reefer.CARRIER.98',
    99: 'errorcode.reefer.CARRIER.99',
    100: 'errorcode.reefer.CARRIER.100',
    101: 'errorcode.reefer.CARRIER.101',
    102: 'errorcode.reefer.CARRIER.102',
    103: 'errorcode.reefer.CARRIER.103',
    104: 'errorcode.reefer.CARRIER.104',
    105: 'errorcode.reefer.CARRIER.105',
    106: 'errorcode.reefer.CARRIER.106',
    107: 'errorcode.reefer.CARRIER.107',
    108: 'errorcode.reefer.CARRIER.108',
    109: 'errorcode.reefer.CARRIER.109',
    110: 'errorcode.reefer.CARRIER.110',
    111: 'errorcode.reefer.CARRIER.111',
    121: 'errorcode.reefer.CARRIER.121',
    122: 'errorcode.reefer.CARRIER.122',
    123: 'errorcode.reefer.CARRIER.123',
    124: 'errorcode.reefer.CARRIER.124',
    125: 'errorcode.reefer.CARRIER.125',
    126: 'errorcode.reefer.CARRIER.126',
    127: 'errorcode.reefer.CARRIER.127',
    128: 'errorcode.reefer.CARRIER.128',
    129: 'errorcode.reefer.CARRIER.129',
    130: 'errorcode.reefer.CARRIER.130',
    131: 'errorcode.reefer.CARRIER.131',
    132: 'errorcode.reefer.CARRIER.132',
    133: 'errorcode.reefer.CARRIER.133',
    134: 'errorcode.reefer.CARRIER.134',
    135: 'errorcode.reefer.CARRIER.135',
    136: 'errorcode.reefer.CARRIER.136',
    137: 'errorcode.reefer.CARRIER.137',
    138: 'errorcode.reefer.CARRIER.138',
    139: 'errorcode.reefer.CARRIER.139',
    140: 'errorcode.reefer.CARRIER.140',
    141: 'errorcode.reefer.CARRIER.141',
    142: 'errorcode.reefer.CARRIER.142',
    143: 'errorcode.reefer.CARRIER.143',
    144: 'errorcode.reefer.CARRIER.144',
    145: 'errorcode.reefer.CARRIER.145',
    146: 'errorcode.reefer.CARRIER.146',
    147: 'errorcode.reefer.CARRIER.147',
    148: 'errorcode.reefer.CARRIER.148',
    149: 'errorcode.reefer.CARRIER.149',
    150: 'errorcode.reefer.CARRIER.150',
    151: 'errorcode.reefer.CARRIER.151',
    152: 'errorcode.reefer.CARRIER.152',
    153: 'errorcode.reefer.CARRIER.153',
    154: 'errorcode.reefer.CARRIER.154',
    155: 'errorcode.reefer.CARRIER.155',
    156: 'errorcode.reefer.CARRIER.156',
    157: 'errorcode.reefer.CARRIER.157',
    158: 'errorcode.reefer.CARRIER.158',
    159: 'errorcode.reefer.CARRIER.159',
    160: 'errorcode.reefer.CARRIER.160',
    161: 'errorcode.reefer.CARRIER.161',
    162: 'errorcode.reefer.CARRIER.162',
    163: 'errorcode.reefer.CARRIER.163',
    164: 'errorcode.reefer.CARRIER.164',
    165: 'errorcode.reefer.CARRIER.165',
    166: 'errorcode.reefer.CARRIER.166',
    167: 'errorcode.reefer.CARRIER.167',
    168: 'errorcode.reefer.CARRIER.168',
    169: 'errorcode.reefer.CARRIER.169',
    170: 'errorcode.reefer.CARRIER.170',
    171: 'errorcode.reefer.CARRIER.171',
    172: 'errorcode.reefer.CARRIER.172',
    173: 'errorcode.reefer.CARRIER.173',
    174: 'errorcode.reefer.CARRIER.174',
    175: 'errorcode.reefer.CARRIER.175',
    176: 'errorcode.reefer.CARRIER.176',
    177: 'errorcode.reefer.CARRIER.177',
    178: 'errorcode.reefer.CARRIER.178',
    179: 'errorcode.reefer.CARRIER.179',
    180: 'errorcode.reefer.CARRIER.180',
    181: 'errorcode.reefer.CARRIER.181',
    182: 'errorcode.reefer.CARRIER.182',
    183: 'errorcode.reefer.CARRIER.183',
    184: 'errorcode.reefer.CARRIER.184',
    185: 'errorcode.reefer.CARRIER.185',
    186: 'errorcode.reefer.CARRIER.186',
    187: 'errorcode.reefer.CARRIER.187',
    188: 'errorcode.reefer.CARRIER.188',
    189: 'errorcode.reefer.CARRIER.189',
    190: 'errorcode.reefer.CARRIER.190',
    191: 'errorcode.reefer.CARRIER.191',
    192: 'errorcode.reefer.CARRIER.192',
    193: 'errorcode.reefer.CARRIER.193',
    194: 'errorcode.reefer.CARRIER.194',
    195: 'errorcode.reefer.CARRIER.195',
    196: 'errorcode.reefer.CARRIER.196',
    197: 'errorcode.reefer.CARRIER.197',
    198: 'errorcode.reefer.CARRIER.198',
    199: 'errorcode.reefer.CARRIER.199',
    200: 'errorcode.reefer.CARRIER.200',
    201: 'errorcode.reefer.CARRIER.201',
    202: 'errorcode.reefer.CARRIER.202',
    203: 'errorcode.reefer.CARRIER.203',
    204: 'errorcode.reefer.CARRIER.204',
    205: 'errorcode.reefer.CARRIER.205',
    206: 'errorcode.reefer.CARRIER.206',
    207: 'errorcode.reefer.CARRIER.207',
    208: 'errorcode.reefer.CARRIER.208',
    209: 'errorcode.reefer.CARRIER.209',
    210: 'errorcode.reefer.CARRIER.210',
    211: 'errorcode.reefer.CARRIER.211',
    212: 'errorcode.reefer.CARRIER.212',
    213: 'errorcode.reefer.CARRIER.213',
    214: 'errorcode.reefer.CARRIER.214',
    215: 'errorcode.reefer.CARRIER.215',
    216: 'errorcode.reefer.CARRIER.216',
    217: 'errorcode.reefer.CARRIER.217',
    218: 'errorcode.reefer.CARRIER.218',
    219: 'errorcode.reefer.CARRIER.219',
    220: 'errorcode.reefer.CARRIER.220',
    221: 'errorcode.reefer.CARRIER.221',
    222: 'errorcode.reefer.CARRIER.222',
    223: 'errorcode.reefer.CARRIER.223',
    224: 'errorcode.reefer.CARRIER.224',
    225: 'errorcode.reefer.CARRIER.225',
    226: 'errorcode.reefer.CARRIER.226',
    227: 'errorcode.reefer.CARRIER.227',
    228: 'errorcode.reefer.CARRIER.228',
    229: 'errorcode.reefer.CARRIER.229',
    230: 'errorcode.reefer.CARRIER.230',
    232: 'errorcode.reefer.CARRIER.232',
    233: 'errorcode.reefer.CARRIER.233',
    234: 'errorcode.reefer.CARRIER.234',
    235: 'errorcode.reefer.CARRIER.235',
    236: 'errorcode.reefer.CARRIER.236',
    237: 'errorcode.reefer.CARRIER.237',
    238: 'errorcode.reefer.CARRIER.238',
    239: 'errorcode.reefer.CARRIER.239',
    240: 'errorcode.reefer.CARRIER.240',
    241: 'errorcode.reefer.CARRIER.241',
    242: 'errorcode.reefer.CARRIER.242',
    243: 'errorcode.reefer.CARRIER.243',
    244: 'errorcode.reefer.CARRIER.244',
    245: 'errorcode.reefer.CARRIER.245',
    246: 'errorcode.reefer.CARRIER.246',
    247: 'errorcode.reefer.CARRIER.247',
    248: 'errorcode.reefer.CARRIER.248',
    249: 'errorcode.reefer.CARRIER.249'
  },
  CBT: {
    0: 'errorcode.reefer.CBT.0',
    1: 'errorcode.reefer.CBT.1',
    2: 'errorcode.reefer.CBT.2',
    3: 'errorcode.reefer.CBT.3',
    4: 'errorcode.reefer.CBT.4',
    5: 'errorcode.reefer.CBT.5',
    6: 'errorcode.reefer.CBT.6',
    7: 'errorcode.reefer.CBT.7',
    8: 'errorcode.reefer.CBT.8',
    9: 'errorcode.reefer.CBT.9',
    10: 'errorcode.reefer.CBT.10',
    11: 'errorcode.reefer.CBT.11',
    12: 'errorcode.reefer.CBT.12',
    13: 'errorcode.reefer.CBT.13',
    14: 'errorcode.reefer.CBT.14',
    15: 'errorcode.reefer.CBT.15',
    16: 'errorcode.reefer.CBT.16',
    17: 'errorcode.reefer.CBT.17',
    18: 'errorcode.reefer.CBT.18',
    19: 'errorcode.reefer.CBT.19',
    20: 'errorcode.reefer.CBT.20',
    21: 'errorcode.reefer.CBT.21',
    22: 'errorcode.reefer.CBT.22',
    23: 'errorcode.reefer.CBT.23',
    24: 'errorcode.reefer.CBT.24',
    25: 'errorcode.reefer.CBT.25',
    26: 'errorcode.reefer.CBT.26',
    27: 'errorcode.reefer.CBT.27',
    28: 'errorcode.reefer.CBT.28',
    29: 'errorcode.reefer.CBT.29',
    30: 'errorcode.reefer.CBT.30',
    31: 'errorcode.reefer.CBT.31',
    32: 'errorcode.reefer.CBT.32',
    33: 'errorcode.reefer.CBT.33',
    34: 'errorcode.reefer.CBT.34',
    35: 'errorcode.reefer.CBT.35',
    36: 'errorcode.reefer.CBT.36',
    37: 'errorcode.reefer.CBT.37',
    38: 'errorcode.reefer.CBT.38',
    39: 'errorcode.reefer.CBT.39',
    40: 'errorcode.reefer.CBT.40',
    41: 'errorcode.reefer.CBT.41',
    42: 'errorcode.reefer.CBT.42',
    43: 'errorcode.reefer.CBT.43',
    44: 'errorcode.reefer.CBT.44',
    45: 'errorcode.reefer.CBT.45',
    46: 'errorcode.reefer.CBT.46',
    47: 'errorcode.reefer.CBT.47',
    48: 'errorcode.reefer.CBT.48',
    49: 'errorcode.reefer.CBT.49',
    50: 'errorcode.reefer.CBT.50',
    51: 'errorcode.reefer.CBT.51',
    52: 'errorcode.reefer.CBT.52',
    53: 'errorcode.reefer.CBT.53',
    54: 'errorcode.reefer.CBT.54',
    55: 'errorcode.reefer.CBT.55',
    56: 'errorcode.reefer.CBT.56',
    57: 'errorcode.reefer.CBT.57',
    58: 'errorcode.reefer.CBT.58',
    59: 'errorcode.reefer.CBT.59',
    60: 'errorcode.reefer.CBT.60',
    61: 'errorcode.reefer.CBT.61',
    62: 'errorcode.reefer.CBT.62',
    63: 'errorcode.reefer.CBT.63',
    64: 'errorcode.reefer.CBT.64',
    65: 'errorcode.reefer.CBT.65',
    66: 'errorcode.reefer.CBT.66',
    67: 'errorcode.reefer.CBT.67',
    68: 'errorcode.reefer.CBT.68',
    69: 'errorcode.reefer.CBT.69',
    71: 'errorcode.reefer.CBT.71',
    72: 'errorcode.reefer.CBT.72',
    73: 'errorcode.reefer.CBT.73',
    74: 'errorcode.reefer.CBT.74',
    75: 'errorcode.reefer.CBT.75',
    76: 'errorcode.reefer.CBT.76',
    77: 'errorcode.reefer.CBT.77',
    78: 'errorcode.reefer.CBT.78',
    79: 'errorcode.reefer.CBT.79',
    80: 'errorcode.reefer.CBT.80',
    81: 'errorcode.reefer.CBT.81',
    82: 'errorcode.reefer.CBT.82',
    83: 'errorcode.reefer.CBT.83',
    84: 'errorcode.reefer.CBT.84',
    85: 'errorcode.reefer.CBT.85',
    86: 'errorcode.reefer.CBT.86',
    87: 'errorcode.reefer.CBT.87',
    88: 'errorcode.reefer.CBT.88',
    89: 'errorcode.reefer.CBT.89',
    90: 'errorcode.reefer.CBT.90',
    91: 'errorcode.reefer.CBT.91',
    92: 'errorcode.reefer.CBT.92',
    93: 'errorcode.reefer.CBT.93',
    94: 'errorcode.reefer.CBT.94',
    95: 'errorcode.reefer.CBT.95',
    96: 'errorcode.reefer.CBT.96',
    97: 'errorcode.reefer.CBT.97',
    98: 'errorcode.reefer.CBT.98',
    99: 'errorcode.reefer.CBT.99',
    100: 'errorcode.reefer.CBT.100',
    101: 'errorcode.reefer.CBT.101',
    102: 'errorcode.reefer.CBT.102',
    103: 'errorcode.reefer.CBT.103',
    104: 'errorcode.reefer.CBT.104',
    105: 'errorcode.reefer.CBT.105',
    106: 'errorcode.reefer.CBT.106',
    107: 'errorcode.reefer.CBT.107',
    108: 'errorcode.reefer.CBT.108',
    109: 'errorcode.reefer.CBT.109',
    110: 'errorcode.reefer.CBT.110',
    111: 'errorcode.reefer.CBT.111',
    112: 'errorcode.reefer.CBT.112',
    113: 'errorcode.reefer.CBT.113',
    114: 'errorcode.reefer.CBT.114',
    115: 'errorcode.reefer.CBT.115',
    116: 'errorcode.reefer.CBT.116'
  }
};
exports["default"] = _default;