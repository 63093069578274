"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _types = require("@nicbase/types");

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getAttributeValue = function getAttributeValue(type, value) {
  if (value === null || value === undefined) {
    return {
      type: type,
      value: null
    };
  }

  switch (type) {
    case _types.FilterAttributeType.String:
    case _types.FilterAttributeType.ObjectId:
      return {
        type: type,
        value: String(value)
      };

    case _types.FilterAttributeType.Integer:
      if (typeof value === 'string') {
        return {
          type: type,
          value: parseInt(value, 10)
        };
      }

      if (typeof value === 'number') {
        return {
          type: type,
          value: value
        };
      }

      return {
        type: type,
        value: null
      };

    case _types.FilterAttributeType.Double:
      if (typeof value === 'string') {
        return {
          type: type,
          value: parseFloat(value)
        };
      }

      if (typeof value === 'number') {
        return {
          type: type,
          value: value
        };
      }

      return {
        type: type,
        value: null
      };

    case _types.FilterAttributeType.Boolean:
      return {
        type: type,
        value: Boolean(value)
      };

    case _types.FilterAttributeType.DateTime:
      {
        if (typeof value === 'string' || typeof value === 'number' || value instanceof Date) {
          return {
            type: type,
            value: (0, _moment["default"])(value).utc().format('YYYY-MM-DD HH:mm:ss')
          };
        }

        return {
          type: type,
          value: null
        };
      }

    default:
      return null;
  }
};

var _default = getAttributeValue;
exports["default"] = _default;