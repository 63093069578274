"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateFilters = exports.UPDATE_FILTERS = void 0;
var UPDATE_FILTERS = 'UPDATE_FILTERS';
exports.UPDATE_FILTERS = UPDATE_FILTERS;

var updateFilters = function updateFilters(id, values) {
  return {
    type: UPDATE_FILTERS,
    body: {
      id: id,
      values: values
    }
  };
};

exports.updateFilters = updateFilters;