"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_POI_CATEGORIES_SUCCESS = exports.GET_POI_CATEGORIES_REQUEST = exports.GET_POI_CATEGORIES_ERROR = void 0;
exports.getPoiCategoryInformationListFromBackend = getPoiCategoryInformationListFromBackend;

/*
 * Use for a reduced list of info for pois. Mostly just the id and name.
 * Any updates will be saved from the poi-administration actions, so we only need the Get action in this file.
 */
var GET_POI_CATEGORIES_REQUEST = 'GET_POI_CATEGORIES_REQUEST';
exports.GET_POI_CATEGORIES_REQUEST = GET_POI_CATEGORIES_REQUEST;
var GET_POI_CATEGORIES_ERROR = 'GET_POI_CATEGORIES_ERROR';
exports.GET_POI_CATEGORIES_ERROR = GET_POI_CATEGORIES_ERROR;
var GET_POI_CATEGORIES_SUCCESS = 'GET_POI_CATEGORIES_SUCCESS';
exports.GET_POI_CATEGORIES_SUCCESS = GET_POI_CATEGORIES_SUCCESS;

function getPoiCategoryInformationListFromBackend(portalId) {
  var dynamicFilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var action = {
    type: GET_POI_CATEGORIES_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    }
  };

  if (dynamicFilters !== null) {
    action.body = {
      dynamicFilters: dynamicFilters,
      dynamicPaging: null,
      dynamicSorters: null
    };
  }

  return action;
}