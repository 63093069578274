"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _dialogs = require("./dialogs.actions");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _dialogs.OPEN_DIALOG:
      return Object.assign({}, state, _defineProperty({}, action.body.widgetId, Object.assign({}, state[action.body.widgetId], _defineProperty({}, action.body.dialogName, true))));

    case _dialogs.CLOSE_DIALOG:
      return Object.assign({}, state, _defineProperty({}, action.body.widgetId, Object.assign({}, state[action.body.widgetId], _defineProperty({}, action.body.dialogName, false))));

    default:
      return state;
  }
};

exports["default"] = _default;