import React, { useEffect } from 'react';
import loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Home, HomeBGL, HomeLight } from 'nicbase-home';

import AppBar from './app-bar';
import MainNavigation from './main-nav';
import PortalNavigation from './portal-nav';
import WorkspaceNavigation from './workspace-nav';

import style from './mobile-app.module.scss';

const Dashboard = loadable({
  loader: () => import('nicbase-dashboard'),
  loading: () => null,
  render(loaded, props) {
    const { Dashboard: DashboardFrontend } = loaded;
    return <DashboardFrontend {...props} />;
  },
});

export const MobileApp = ({ theme }) => {
  const portals = useSelector(state => Object.values(state.portals));

  useEffect(() => {
    Dashboard.preload();
  }, []);

  return (
    <div className={style.mobile} style={theme}>
      <Route path="/(.+)" render={props => <AppBar {...props} />} />
      <Switch>
        <Route exact path="/" render={() => <MainNavigation />} />
        <Route exact path="/dashboard" render={() => <PortalNavigation />} />
        <Route exact path={'/dashboard/:portalId(\\d+)/workspace'} render={() => <WorkspaceNavigation />} />
        <Route path="/dashboard/:portalId" render={props => <Dashboard {...props} responsiveMode={{ mode: 'mobile' }} />} />
        <Route
          path="/"
          render={() => {
            if (portals.length === 1 && portals[0].type === 'NIC_PLACE_LIGHT') {
              return <HomeLight />;
            }
            if (portals.length === 1 && portals[0].type === 'BGL') {
              return <HomeBGL />;
            }

            return <Home responsiveMode={{ mode: 'mobile' }} type={portals[0].type} />;
          }}
        />
      </Switch>
    </div>
  );
};

MobileApp.propTypes = {
  theme: PropTypes.shape({}),
  match: PropTypes.shape({ params: PropTypes.shape({ portalId: PropTypes.string }) }).isRequired,
};
MobileApp.defaultProps = {
  theme: {},
};

export default MobileApp;
