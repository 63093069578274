"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FIND_TRIGGERED_ALARM_DETAILS_SUCCESS = exports.FIND_TRIGGERED_ALARM_DETAILS_REQUEST = exports.FIND_TRIGGERED_ALARM_DETAILS_ERROR = exports.FIND_TRIGGERED_ALARMS_SUCCESS = exports.FIND_TRIGGERED_ALARMS_REQUEST = exports.FIND_TRIGGERED_ALARMS_ERROR = void 0;
exports.getTriggeredAlarmDetails = getTriggeredAlarmDetails;
exports.getTriggeredAlarms = getTriggeredAlarms;

var _uuid = require("uuid");

var FIND_TRIGGERED_ALARMS_SUCCESS = 'FIND_TRIGGERED_ALARMS_SUCCESS';
exports.FIND_TRIGGERED_ALARMS_SUCCESS = FIND_TRIGGERED_ALARMS_SUCCESS;
var FIND_TRIGGERED_ALARMS_REQUEST = 'FIND_TRIGGERED_ALARMS_REQUEST';
exports.FIND_TRIGGERED_ALARMS_REQUEST = FIND_TRIGGERED_ALARMS_REQUEST;
var FIND_TRIGGERED_ALARMS_ERROR = 'FIND_TRIGGERED_ALARMS_ERROR';
exports.FIND_TRIGGERED_ALARMS_ERROR = FIND_TRIGGERED_ALARMS_ERROR;

function getTriggeredAlarms(widgetId, portalId) {
  var dynamicFilters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var action = {
    type: FIND_TRIGGERED_ALARMS_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId,
      requestId: (0, _uuid.v4)()
    }
  };

  if (dynamicFilters !== null) {
    action.body = {
      dynamicFilters: dynamicFilters,
      dynamicPaging: null,
      dynamicSorters: null
    };
  }

  return action;
}

var FIND_TRIGGERED_ALARM_DETAILS_SUCCESS = 'FIND_TRIGGERED_ALARM_DETAILS_SUCCESS';
exports.FIND_TRIGGERED_ALARM_DETAILS_SUCCESS = FIND_TRIGGERED_ALARM_DETAILS_SUCCESS;
var FIND_TRIGGERED_ALARM_DETAILS_REQUEST = 'FIND_TRIGGERED_ALARM_DETAILS_REQUEST';
exports.FIND_TRIGGERED_ALARM_DETAILS_REQUEST = FIND_TRIGGERED_ALARM_DETAILS_REQUEST;
var FIND_TRIGGERED_ALARM_DETAILS_ERROR = 'FIND_TRIGGERED_ALARM_DETAILS_ERROR';
exports.FIND_TRIGGERED_ALARM_DETAILS_ERROR = FIND_TRIGGERED_ALARM_DETAILS_ERROR;

function getTriggeredAlarmDetails(widgetId, portalId) {
  var dynamicFilters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var dynamicPaging = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var dynamicSorters = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  return {
    type: FIND_TRIGGERED_ALARM_DETAILS_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      dynamicFilters: dynamicFilters,
      dynamicPaging: dynamicPaging,
      dynamicSorters: dynamicSorters
    }
  };
}