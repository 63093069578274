"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createTourExportForBilling = exports.createSMSExport = exports.createAssetExportForBilling = exports.createAccountingExportForBilling = exports.TRIGGER_BILLING_EXPORT_SUCCESS = exports.TRIGGER_BILLING_EXPORT_REQUEST = exports.TRIGGER_BILLING_EXPORT_ERROR = exports.CREATE_TOUR_EXPORT_FOR_BILLING_SUCCESS = exports.CREATE_TOUR_EXPORT_FOR_BILLING_REQUEST = exports.CREATE_TOUR_EXPORT_FOR_BILLING_ERROR = exports.CREATE_SMS_EXPORT_SUCCESS = exports.CREATE_SMS_EXPORT_REQUEST = exports.CREATE_SMS_EXPORT_ERROR = exports.CREATE_ASSET_EXPORT_FOR_BILLING_SUCCESS = exports.CREATE_ASSET_EXPORT_FOR_BILLING_REQUEST = exports.CREATE_ASSET_EXPORT_FOR_BILLING_ERROR = void 0;
var CREATE_TOUR_EXPORT_FOR_BILLING_REQUEST = 'CREATE_TOUR_EXPORT_FOR_BILLING_REQUEST';
exports.CREATE_TOUR_EXPORT_FOR_BILLING_REQUEST = CREATE_TOUR_EXPORT_FOR_BILLING_REQUEST;
var CREATE_TOUR_EXPORT_FOR_BILLING_SUCCESS = 'CREATE_TOUR_EXPORT_FOR_BILLING_SUCCESS';
exports.CREATE_TOUR_EXPORT_FOR_BILLING_SUCCESS = CREATE_TOUR_EXPORT_FOR_BILLING_SUCCESS;
var CREATE_TOUR_EXPORT_FOR_BILLING_ERROR = 'CREATE_TOUR_EXPORT_FOR_BILLING_ERROR';
exports.CREATE_TOUR_EXPORT_FOR_BILLING_ERROR = CREATE_TOUR_EXPORT_FOR_BILLING_ERROR;

var createTourExportForBilling = function createTourExportForBilling(dynamicFilters) {
  return {
    type: CREATE_TOUR_EXPORT_FOR_BILLING_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      dynamicFilters: dynamicFilters
    }
  };
};

exports.createTourExportForBilling = createTourExportForBilling;
var CREATE_ASSET_EXPORT_FOR_BILLING_REQUEST = 'CREATE_ASSET_EXPORT_FOR_BILLING_REQUEST';
exports.CREATE_ASSET_EXPORT_FOR_BILLING_REQUEST = CREATE_ASSET_EXPORT_FOR_BILLING_REQUEST;
var CREATE_ASSET_EXPORT_FOR_BILLING_SUCCESS = 'CREATE_ASSET_EXPORT_FOR_BILLING_SUCCESS';
exports.CREATE_ASSET_EXPORT_FOR_BILLING_SUCCESS = CREATE_ASSET_EXPORT_FOR_BILLING_SUCCESS;
var CREATE_ASSET_EXPORT_FOR_BILLING_ERROR = 'CREATE_ASSET_EXPORT_FOR_BILLING_ERROR';
exports.CREATE_ASSET_EXPORT_FOR_BILLING_ERROR = CREATE_ASSET_EXPORT_FOR_BILLING_ERROR;

var createAssetExportForBilling = function createAssetExportForBilling(dynamicFilters, sharingTypes) {
  return {
    type: CREATE_ASSET_EXPORT_FOR_BILLING_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      dynamicFilters: dynamicFilters,
      sharingTypes: sharingTypes
    }
  };
};

exports.createAssetExportForBilling = createAssetExportForBilling;
var CREATE_SMS_EXPORT_REQUEST = 'CREATE_SMS_EXPORT_REQUEST';
exports.CREATE_SMS_EXPORT_REQUEST = CREATE_SMS_EXPORT_REQUEST;
var CREATE_SMS_EXPORT_SUCCESS = 'CREATE_SMS_EXPORT_SUCCESS';
exports.CREATE_SMS_EXPORT_SUCCESS = CREATE_SMS_EXPORT_SUCCESS;
var CREATE_SMS_EXPORT_ERROR = 'CREATE_SMS_EXPORT_ERROR';
exports.CREATE_SMS_EXPORT_ERROR = CREATE_SMS_EXPORT_ERROR;

var createSMSExport = function createSMSExport(dynamicFilters) {
  return {
    type: CREATE_SMS_EXPORT_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      dynamicFilters: dynamicFilters
    }
  };
};

exports.createSMSExport = createSMSExport;
var TRIGGER_BILLING_EXPORT_REQUEST = 'TRIGGER_BILLING_EXPORT_REQUEST';
exports.TRIGGER_BILLING_EXPORT_REQUEST = TRIGGER_BILLING_EXPORT_REQUEST;
var TRIGGER_BILLING_EXPORT_SUCCESS = 'TRIGGER_BILLING_EXPORT_SUCCESS';
exports.TRIGGER_BILLING_EXPORT_SUCCESS = TRIGGER_BILLING_EXPORT_SUCCESS;
var TRIGGER_BILLING_EXPORT_ERROR = 'TRIGGER_BILLING_EXPORT_ERROR';
exports.TRIGGER_BILLING_EXPORT_ERROR = TRIGGER_BILLING_EXPORT_ERROR;

var createAccountingExportForBilling = function createAccountingExportForBilling() {
  return {
    type: 'TRIGGER_BILLING_EXPORT_REQUEST',
    headers: {
      version: 'v1'
    },
    body: {}
  };
};

exports.createAccountingExportForBilling = createAccountingExportForBilling;