"use strict";

require("@formatjs/intl-getcanonicallocales/polyfill");

require("@formatjs/intl-locale/polyfill");

require("@formatjs/intl-pluralrules/polyfill");

require("@formatjs/intl-pluralrules/locale-data/de");

require("@formatjs/intl-pluralrules/locale-data/cs");

require("@formatjs/intl-pluralrules/locale-data/da");

require("@formatjs/intl-pluralrules/locale-data/nl");

require("@formatjs/intl-pluralrules/locale-data/es");

require("@formatjs/intl-pluralrules/locale-data/it");

require("@formatjs/intl-pluralrules/locale-data/fr");

require("@formatjs/intl-pluralrules/locale-data/ro");

require("@formatjs/intl-pluralrules/locale-data/hu");

require("@formatjs/intl-pluralrules/locale-data/lt");

require("@formatjs/intl-pluralrules/locale-data/el");

require("@formatjs/intl-pluralrules/locale-data/sl");

require("@formatjs/intl-pluralrules/locale-data/sk");

require("@formatjs/intl-pluralrules/locale-data/fi");

require("@formatjs/intl-pluralrules/locale-data/ru");

require("@formatjs/intl-pluralrules/locale-data/en");

require("@formatjs/intl-relativetimeformat/polyfill");

require("@formatjs/intl-relativetimeformat/locale-data/de");

require("@formatjs/intl-relativetimeformat/locale-data/cs");

require("@formatjs/intl-relativetimeformat/locale-data/da");

require("@formatjs/intl-relativetimeformat/locale-data/nl");

require("@formatjs/intl-relativetimeformat/locale-data/pl");

require("@formatjs/intl-relativetimeformat/locale-data/es");

require("@formatjs/intl-relativetimeformat/locale-data/it");

require("@formatjs/intl-relativetimeformat/locale-data/fr");

require("@formatjs/intl-relativetimeformat/locale-data/ro");

require("@formatjs/intl-relativetimeformat/locale-data/hu");

require("@formatjs/intl-relativetimeformat/locale-data/lt");

require("@formatjs/intl-relativetimeformat/locale-data/el");

require("@formatjs/intl-relativetimeformat/locale-data/sl");

require("@formatjs/intl-relativetimeformat/locale-data/sk");

require("@formatjs/intl-relativetimeformat/locale-data/fi");

require("@formatjs/intl-relativetimeformat/locale-data/ru");

require("@formatjs/intl-relativetimeformat/locale-data/en");

require("@formatjs/intl-relativetimeformat/locale-data/en-GB");

require("@formatjs/intl-relativetimeformat/locale-data/en-US");