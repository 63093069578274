"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var useDidMount = function useDidMount(callback) {
  var isFirstUpdate = (0, _react.useRef)(true);
  (0, _react.useEffect)(function () {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;

      if (typeof callback === 'function') {
        callback();
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);
};

var _default = useDidMount;
exports["default"] = _default;