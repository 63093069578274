"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setpointMonitorReducer = exports.makeGetSetpointIntervalDetails = exports.makeGetSetpointIntervalAssets = exports.getSetpointIntervals = void 0;

var _reselect = require("reselect");

var _setpointMonitor = require("./setpoint-monitor.actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialIntervals = {
  green1: 0,
  green2: 0,
  yellow1: 0,
  yellow2: 0,
  red1: 0,
  red2: 0
};

var intervalsReducer = function intervalsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialIntervals;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _setpointMonitor.SETPOINT_MONITOR_SUCCESS:
      {
        var intervals = action.body.intervals;
        return _objectSpread(_objectSpread({}, state), intervals);
      }

    default:
      {
        return state;
      }
  }
};

var initialDetails = {
  green1: [],
  green2: [],
  yellow1: [],
  yellow2: [],
  red1: [],
  red2: []
};

var detailsReducer = function detailsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialDetails;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _setpointMonitor.SETPOINT_MONITOR_DETAILS_SUCCESS:
      {
        var _action$body = action.body,
            interval = _action$body.interval,
            details = _action$body.details;
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, interval, details));
      }

    default:
      {
        return state;
      }
  }
};

var setpointMonitorReducer = function setpointMonitorReducer() {
  var _action$headers, _state$widgetId, _state$widgetId2;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var widgetId = (_action$headers = action.headers) === null || _action$headers === void 0 ? void 0 : _action$headers.widgetId;

  if (!widgetId) {
    return state;
  }

  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, widgetId, {
    assetCount: intervalsReducer((_state$widgetId = state[widgetId]) === null || _state$widgetId === void 0 ? void 0 : _state$widgetId.assetCount, action),
    details: detailsReducer((_state$widgetId2 = state[widgetId]) === null || _state$widgetId2 === void 0 ? void 0 : _state$widgetId2.details, action)
  }));
};

exports.setpointMonitorReducer = setpointMonitorReducer;
var getSetpointIntervals = (0, _reselect.createSelector)([function (state, props) {
  return state.setpointMonitor[props.id];
}], function (intervals) {
  return intervals !== null && intervals !== void 0 ? intervals : {};
});
exports.getSetpointIntervals = getSetpointIntervals;

var makeGetSetpointIntervalAssets = function makeGetSetpointIntervalAssets() {
  return (0, _reselect.createSelector)([getSetpointIntervals], function (intervals) {
    var _intervals$assetCount;

    return (_intervals$assetCount = intervals.assetCount) !== null && _intervals$assetCount !== void 0 ? _intervals$assetCount : {};
  });
};

exports.makeGetSetpointIntervalAssets = makeGetSetpointIntervalAssets;

var makeGetSetpointIntervalDetails = function makeGetSetpointIntervalDetails() {
  return (0, _reselect.createSelector)([getSetpointIntervals], function (intervals) {
    var _intervals$details;

    return (_intervals$details = intervals.details) !== null && _intervals$details !== void 0 ? _intervals$details : {};
  });
};

exports.makeGetSetpointIntervalDetails = makeGetSetpointIntervalDetails;