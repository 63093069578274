"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateContentBoxOrder = exports.updateContentBox = exports.findAllContentBoxes = exports.deleteContentBox = exports.createContentBox = exports.UPDATE_CONTENT_BOX_SUCCESS = exports.UPDATE_CONTENT_BOX_REQUEST = exports.UPDATE_CONTENT_BOX_ERROR = exports.UPDATE_CONTENT_BOXES_ORDER_SUCCESS = exports.UPDATE_CONTENT_BOXES_ORDER_REQUEST = exports.UPDATE_CONTENT_BOXES_ORDER_ERROR = exports.FIND_CONTENT_BOXES_SUCCESS = exports.FIND_CONTENT_BOXES_REQUEST = exports.FIND_CONTENT_BOXES_ERROR = exports.DELETE_CONTENT_BOX_SUCCESS = exports.DELETE_CONTENT_BOX_REQUEST = exports.DELETE_CONTENT_BOX_ERROR = exports.CREATE_CONTENT_BOX_SUCCESS = exports.CREATE_CONTENT_BOX_REQUEST = exports.CREATE_CONTENT_BOX_ERROR = void 0;
var FIND_CONTENT_BOXES_REQUEST = 'FIND_CONTENT_BOXES_REQUEST';
exports.FIND_CONTENT_BOXES_REQUEST = FIND_CONTENT_BOXES_REQUEST;
var FIND_CONTENT_BOXES_SUCCESS = 'FIND_CONTENT_BOXES_SUCCESS';
exports.FIND_CONTENT_BOXES_SUCCESS = FIND_CONTENT_BOXES_SUCCESS;
var FIND_CONTENT_BOXES_ERROR = 'FIND_CONTENT_BOXES_ERROR';
exports.FIND_CONTENT_BOXES_ERROR = FIND_CONTENT_BOXES_ERROR;

var findAllContentBoxes = function findAllContentBoxes() {
  return {
    type: FIND_CONTENT_BOXES_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {}
  };
};

exports.findAllContentBoxes = findAllContentBoxes;
var CREATE_CONTENT_BOX_REQUEST = 'CREATE_CONTENT_BOX_REQUEST';
exports.CREATE_CONTENT_BOX_REQUEST = CREATE_CONTENT_BOX_REQUEST;
var CREATE_CONTENT_BOX_SUCCESS = 'CREATE_CONTENT_BOX_SUCCESS';
exports.CREATE_CONTENT_BOX_SUCCESS = CREATE_CONTENT_BOX_SUCCESS;
var CREATE_CONTENT_BOX_ERROR = 'CREATE_CONTENT_BOX_ERROR';
exports.CREATE_CONTENT_BOX_ERROR = CREATE_CONTENT_BOX_ERROR;

var createContentBox = function createContentBox(contentBox) {
  return {
    type: CREATE_CONTENT_BOX_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      contentBox: contentBox
    }
  };
};

exports.createContentBox = createContentBox;
var UPDATE_CONTENT_BOX_REQUEST = 'UPDATE_CONTENT_BOX_REQUEST';
exports.UPDATE_CONTENT_BOX_REQUEST = UPDATE_CONTENT_BOX_REQUEST;
var UPDATE_CONTENT_BOX_SUCCESS = 'UPDATE_CONTENT_BOX_SUCCESS';
exports.UPDATE_CONTENT_BOX_SUCCESS = UPDATE_CONTENT_BOX_SUCCESS;
var UPDATE_CONTENT_BOX_ERROR = 'UPDATE_CONTENT_BOX_ERROR';
exports.UPDATE_CONTENT_BOX_ERROR = UPDATE_CONTENT_BOX_ERROR;

var updateContentBox = function updateContentBox(contentBox) {
  return {
    type: UPDATE_CONTENT_BOX_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      contentBox: contentBox
    }
  };
};

exports.updateContentBox = updateContentBox;
var UPDATE_CONTENT_BOXES_ORDER_REQUEST = 'UPDATE_CONTENT_BOXES_ORDER_REQUEST';
exports.UPDATE_CONTENT_BOXES_ORDER_REQUEST = UPDATE_CONTENT_BOXES_ORDER_REQUEST;
var UPDATE_CONTENT_BOXES_ORDER_SUCCESS = 'UPDATE_CONTENT_BOXES_ORDER_SUCCESS';
exports.UPDATE_CONTENT_BOXES_ORDER_SUCCESS = UPDATE_CONTENT_BOXES_ORDER_SUCCESS;
var UPDATE_CONTENT_BOXES_ORDER_ERROR = 'UPDATE_CONTENT_BOXES_ORDER_ERROR';
exports.UPDATE_CONTENT_BOXES_ORDER_ERROR = UPDATE_CONTENT_BOXES_ORDER_ERROR;

var updateContentBoxOrder = function updateContentBoxOrder(contentBoxes) {
  return {
    type: UPDATE_CONTENT_BOXES_ORDER_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      contentBoxes: contentBoxes
    }
  };
};

exports.updateContentBoxOrder = updateContentBoxOrder;
var DELETE_CONTENT_BOX_REQUEST = 'DELETE_CONTENT_BOX_REQUEST';
exports.DELETE_CONTENT_BOX_REQUEST = DELETE_CONTENT_BOX_REQUEST;
var DELETE_CONTENT_BOX_SUCCESS = 'DELETE_CONTENT_BOX_SUCCESS';
exports.DELETE_CONTENT_BOX_SUCCESS = DELETE_CONTENT_BOX_SUCCESS;
var DELETE_CONTENT_BOX_ERROR = 'DELETE_CONTENT_BOX_ERROR';
exports.DELETE_CONTENT_BOX_ERROR = DELETE_CONTENT_BOX_ERROR;

var deleteContentBox = function deleteContentBox(id) {
  return {
    type: DELETE_CONTENT_BOX_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      id: id
    }
  };
};

exports.deleteContentBox = deleteContentBox;