"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _geofencesMileage = require("./geofences-mileage.actions");

/**
 * Created by SHartmann on 02.03.2017.
 */
var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _geofencesMileage.LOAD_GEOFENCES_MILEAGE_SUCCESS:
      return action.body.geofencesMileage;

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return [];

    default:
      {
        return state;
      }
  }
};

exports["default"] = _default;