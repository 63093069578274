"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findRouteAnalysisData = exports.ROUTE_ANALYSIS_DATA_SUCCESS = exports.ROUTE_ANALYSIS_DATA_REQUEST = exports.ROUTE_ANALYSIS_DATA_ERROR = void 0;
var ROUTE_ANALYSIS_DATA_REQUEST = 'ROUTE_ANALYSIS_DATA_REQUEST';
exports.ROUTE_ANALYSIS_DATA_REQUEST = ROUTE_ANALYSIS_DATA_REQUEST;
var ROUTE_ANALYSIS_DATA_SUCCESS = 'ROUTE_ANALYSIS_DATA_SUCCESS';
exports.ROUTE_ANALYSIS_DATA_SUCCESS = ROUTE_ANALYSIS_DATA_SUCCESS;
var ROUTE_ANALYSIS_DATA_ERROR = 'ROUTE_ANALYSIS_DATA_ERROR';
exports.ROUTE_ANALYSIS_DATA_ERROR = ROUTE_ANALYSIS_DATA_ERROR;

var findRouteAnalysisData = function findRouteAnalysisData(widgetId, dynamicFilters, portalId) {
  return {
    type: ROUTE_ANALYSIS_DATA_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      dynamicFilters: dynamicFilters,
      dynamicSorters: null,
      dynamicPaging: null
    }
  };
};

exports.findRouteAnalysisData = findRouteAnalysisData;