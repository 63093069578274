"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _bg_BG = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/bg_BG"));

var _cs_CZ = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/cs_CZ"));

var _de_DE = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/de_DE"));

var _el_GR = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/el_GR"));

var _en_GB = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/en_GB"));

var _en_US = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/en_US"));

var _es_ES = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/es_ES"));

var _et_EE = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/et_EE"));

var _fi_FI = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/fi_FI"));

var _fr_BE = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/fr_BE"));

var _fr_FR = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/fr_FR"));

var _it_IT = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/it_IT"));

var _nl_BE = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/nl_BE"));

var _nl_NL = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/nl_NL"));

var _pl_PL = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/pl_PL"));

var _pt_PT = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/pt_PT"));

var _ru_RU = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/ru_RU"));

var _sk_SK = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/sk_SK"));

var _sl_SI = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/sl_SI"));

var _sr_RS = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/sr_RS"));

var _sv_SE = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/sv_SE"));

var _tr_TR = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/tr_TR"));

var _uk_UA = _interopRequireDefault(require("nicbase-rc-calendar/lib/locale/uk_UA"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = {
  'sq-AL': _en_GB["default"],
  'be-BY': _en_GB["default"],
  'bg-BG': _bg_BG["default"],
  'hr-HR': _en_GB["default"],
  'cs-CZ': _cs_CZ["default"],
  'da-DK': _en_GB["default"],
  'nl-BE': _nl_BE["default"],
  'nl-NL': _nl_NL["default"],
  'en-CA': _en_GB["default"],
  'en-GB': _en_GB["default"],
  'en-US': _en_US["default"],
  'et-EE': _et_EE["default"],
  'fi-FI': _fi_FI["default"],
  'fr-BE': _fr_BE["default"],
  'fr-CA': _fr_FR["default"],
  'fr-FR': _fr_FR["default"],
  'fr-LU': _fr_FR["default"],
  'fr-CH': _fr_FR["default"],
  'de-AT': _de_DE["default"],
  'de-DE': _de_DE["default"],
  'de-LU': _de_DE["default"],
  'de-CH': _de_DE["default"],
  'el-GR': _el_GR["default"],
  'hu-HU': _en_GB["default"],
  'it-IT': _it_IT["default"],
  'it-CH': _en_GB["default"],
  'lv-LV': _en_GB["default"],
  'lt-LT': _en_GB["default"],
  'mk-MK': _en_GB["default"],
  'no-NO': _en_GB["default"],
  'pl-PL': _pl_PL["default"],
  'pt-PT': _pt_PT["default"],
  'ro-RO': _en_GB["default"],
  'ru-RU': _ru_RU["default"],
  'sr-RS': _sr_RS["default"],
  'sk-SK': _sk_SK["default"],
  'sl-SI': _sl_SI["default"],
  'es-ES': _es_ES["default"],
  'sv-SE': _sv_SE["default"],
  'tr-TR': _tr_TR["default"],
  'uk-UA': _uk_UA["default"]
};
exports["default"] = _default;