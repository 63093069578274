"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AssetIcon", {
  enumerable: true,
  get: function get() {
    return _nicAssetMapping.AssetIcon;
  }
});
Object.defineProperty(exports, "assetToIcon", {
  enumerable: true,
  get: function get() {
    return _nicAssetMapping.assetToIcon;
  }
});
Object.defineProperty(exports, "assetToTranslation", {
  enumerable: true,
  get: function get() {
    return _nicAssetMapping.assetToTranslation;
  }
});
Object.defineProperty(exports, "findAssetKind", {
  enumerable: true,
  get: function get() {
    return _assetKind.findAssetKind;
  }
});
Object.defineProperty(exports, "isCoupledAsset", {
  enumerable: true,
  get: function get() {
    return _assetKind.isCoupledAsset;
  }
});
Object.defineProperty(exports, "isRailAsset", {
  enumerable: true,
  get: function get() {
    return _assetKind.isRailAsset;
  }
});

var _assetKind = require("./asset-kind");

var _nicAssetMapping = require("./nic-asset-mapping");