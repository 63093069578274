import { auth } from 'nicbase-security';

const addAccessToken = () => next => action => {
  if (action.type.endsWith('_REQUEST')) {
    const { headers } = action;
    const oauthToken = auth.getAccessToken();
    const mergedHeaders = Object.assign({}, { oauthToken }, headers);
    return next(Object.assign({}, action, { headers: mergedHeaders }));
  }
  return next(action);
};

export default addAccessToken;
