"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCoupledAsset = exports.findCoupledAssets = exports.dissolveCoupledAsset = exports.createCoupledAsset = exports.UPDATE_COUPLED_ASSET_SUCCESS = exports.UPDATE_COUPLED_ASSET_REQUEST = exports.UPDATE_COUPLED_ASSET_ERROR = exports.FIND_COUPLED_ASSETS_SUCCESS = exports.FIND_COUPLED_ASSETS_REQUEST = exports.FIND_COUPLED_ASSETS_ERROR = exports.DISSOLVE_COUPLED_ASSET_SUCCESS = exports.DISSOLVE_COUPLED_ASSET_REQUEST = exports.DISSOLVE_COUPLED_ASSET_ERROR = exports.CREATE_COUPLED_ASSET_SUCCESS = exports.CREATE_COUPLED_ASSET_REQUEST = exports.CREATE_COUPLED_ASSET_ERROR = void 0;
var FIND_COUPLED_ASSETS_REQUEST = 'FIND_COUPLED_ASSETS_REQUEST';
exports.FIND_COUPLED_ASSETS_REQUEST = FIND_COUPLED_ASSETS_REQUEST;
var FIND_COUPLED_ASSETS_SUCCESS = 'FIND_COUPLED_ASSETS_SUCCESS';
exports.FIND_COUPLED_ASSETS_SUCCESS = FIND_COUPLED_ASSETS_SUCCESS;
var FIND_COUPLED_ASSETS_ERROR = 'FIND_COUPLED_ASSETS_ERROR';
exports.FIND_COUPLED_ASSETS_ERROR = FIND_COUPLED_ASSETS_ERROR;

var findCoupledAssets = function findCoupledAssets(portalId) {
  return {
    type: FIND_COUPLED_ASSETS_REQUEST,
    headers: {
      version: 'v3',
      portalId: portalId
    },
    body: {}
  };
};

exports.findCoupledAssets = findCoupledAssets;
var CREATE_COUPLED_ASSET_REQUEST = 'CREATE_COUPLED_ASSET_REQUEST';
exports.CREATE_COUPLED_ASSET_REQUEST = CREATE_COUPLED_ASSET_REQUEST;
var CREATE_COUPLED_ASSET_SUCCESS = 'CREATE_COUPLED_ASSET_SUCCESS';
exports.CREATE_COUPLED_ASSET_SUCCESS = CREATE_COUPLED_ASSET_SUCCESS;
var CREATE_COUPLED_ASSET_ERROR = 'CREATE_COUPLED_ASSET_ERROR';
exports.CREATE_COUPLED_ASSET_ERROR = CREATE_COUPLED_ASSET_ERROR;

var createCoupledAsset = function createCoupledAsset(asset, portalId) {
  return {
    type: CREATE_COUPLED_ASSET_REQUEST,
    headers: {
      version: 'v3',
      portalId: portalId
    },
    body: {
      asset: asset
    }
  };
};

exports.createCoupledAsset = createCoupledAsset;
var UPDATE_COUPLED_ASSET_REQUEST = 'UPDATE_COUPLED_ASSET_REQUEST';
exports.UPDATE_COUPLED_ASSET_REQUEST = UPDATE_COUPLED_ASSET_REQUEST;
var UPDATE_COUPLED_ASSET_SUCCESS = 'UPDATE_COUPLED_ASSET_SUCCESS';
exports.UPDATE_COUPLED_ASSET_SUCCESS = UPDATE_COUPLED_ASSET_SUCCESS;
var UPDATE_COUPLED_ASSET_ERROR = 'UPDATE_COUPLED_ASSET_ERROR';
exports.UPDATE_COUPLED_ASSET_ERROR = UPDATE_COUPLED_ASSET_ERROR;

var updateCoupledAsset = function updateCoupledAsset(asset, portalId) {
  return {
    type: UPDATE_COUPLED_ASSET_REQUEST,
    headers: {
      version: 'v3',
      portalId: portalId
    },
    body: {
      asset: asset
    }
  };
};

exports.updateCoupledAsset = updateCoupledAsset;
var DISSOLVE_COUPLED_ASSET_REQUEST = 'DISSOLVE_COUPLED_ASSET_REQUEST';
exports.DISSOLVE_COUPLED_ASSET_REQUEST = DISSOLVE_COUPLED_ASSET_REQUEST;
var DISSOLVE_COUPLED_ASSET_SUCCESS = 'DISSOLVE_COUPLED_ASSET_SUCCESS';
exports.DISSOLVE_COUPLED_ASSET_SUCCESS = DISSOLVE_COUPLED_ASSET_SUCCESS;
var DISSOLVE_COUPLED_ASSET_ERROR = 'DISSOLVE_COUPLED_ASSET_ERROR';
exports.DISSOLVE_COUPLED_ASSET_ERROR = DISSOLVE_COUPLED_ASSET_ERROR;

var dissolveCoupledAsset = function dissolveCoupledAsset(asset, portalId) {
  return {
    type: DISSOLVE_COUPLED_ASSET_REQUEST,
    headers: {
      version: 'v3',
      portalId: portalId
    },
    body: {
      asset: asset
    }
  };
};

exports.dissolveCoupledAsset = dissolveCoupledAsset;