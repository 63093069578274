"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
// coordinates are in the form of [longitude, latitude]
var CountryCoords = {
  US: [[71.0926, -144.7149], [70.9245, -50.6142], [6.8894, -49.4494], [7.5846, -146.0953], [71.0926, -144.7149]],
  EU: [[63.1907, -15.0020], [63.1622, 50.9175], [27.4329, 56.8788], [26.4993, -18.0136], [63.1907, -15.0020]]
};
var _default = CountryCoords;
exports["default"] = _default;