"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _assetids = require("./assetids.actions");

var INITIAL_STATE = [];

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _assetids.FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_SUCCESS:
      {
        var _action$body$assetIds = action.body.assetIds,
            assetIds = _action$body$assetIds === void 0 ? [] : _action$body$assetIds;
        return assetIds;
      }

    default:
      return state;
  }
};

exports["default"] = _default;