"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var Languages = {
  'de-DE': 'userProfile.personal-data.language.german-de',
  'en-GB': 'userProfile.personal-data.language.english-gb',
  'en-US': 'userProfile.personal-data.language.english-us',
  'cs-CZ': 'userProfile.personal-data.language.czech',
  'da-DK': 'userProfile.personal-data.language.denmark',
  'nl-NL': 'userProfile.personal-data.language.netherlands',
  'pl-PL': 'userProfile.personal-data.language.poland',
  'es-ES': 'userProfile.personal-data.language.spain',
  'it-IT': 'userProfile.personal-data.language.italy',
  'fr-FR': 'userProfile.personal-data.language.french',
  'ro-RO': 'userProfile.personal-data.language.romanian',
  'hu-HU': 'userProfile.personal-data.language.hungary',
  'lt-LT': 'userProfile.personal-data.language.lithuanian',
  'el-GR': 'userProfile.personal-data.language.greece',
  'sl-SL': 'userProfile.personal-data.language.slovenia',
  'sk-SK': 'userProfile.personal-data.language.slovakia',
  'fi-FI': 'userProfile.personal-data.language.finland',
  'ru-RU': 'userProfile.personal-data.language.russia',
  'hr-HR': 'userProfile.personal-data.language.croatian',
  'mk-MK': 'userProfile.personal-data.language.macedonian',
  'bs-BA': 'userProfile.personal-data.language.bosnian',
  'sr-Latn-RS': 'userProfile.personal-data.language.serbian-latin',
  'sr-Cyrl-RS': 'userProfile.personal-data.language.serbian-cyrillic'
};
var _default = Languages;
exports["default"] = _default;