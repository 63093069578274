"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findStandingTimes = exports.findStandingTimeDetails = exports.STANDING_TIME_DETAILS_SUCCESS = exports.STANDING_TIME_DETAILS_REQUEST = exports.STANDING_TIME_DETAILS_ERROR = exports.STANDING_TIMES_SUCCESS = exports.STANDING_TIMES_REQUEST = exports.STANDING_TIMES_ERROR = void 0;
var STANDING_TIMES_REQUEST = 'STANDING_TIMES_REQUEST';
exports.STANDING_TIMES_REQUEST = STANDING_TIMES_REQUEST;
var STANDING_TIMES_SUCCESS = 'STANDING_TIMES_SUCCESS';
exports.STANDING_TIMES_SUCCESS = STANDING_TIMES_SUCCESS;
var STANDING_TIMES_ERROR = 'STANDING_TIMES_ERROR';
exports.STANDING_TIMES_ERROR = STANDING_TIMES_ERROR;

var findStandingTimes = function findStandingTimes(portalId, widgetId, _ref) {
  var intervalEnds = _ref.intervalEnds,
      assetIds = _ref.assetIds,
      assetGroupIds = _ref.assetGroupIds;
  return {
    type: STANDING_TIMES_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      intervalEnds: intervalEnds,
      assetIds: assetIds,
      assetGroupIds: assetGroupIds
    }
  };
};

exports.findStandingTimes = findStandingTimes;
var STANDING_TIME_DETAILS_REQUEST = 'STANDING_TIME_DETAILS_REQUEST';
exports.STANDING_TIME_DETAILS_REQUEST = STANDING_TIME_DETAILS_REQUEST;
var STANDING_TIME_DETAILS_SUCCESS = 'STANDING_TIME_DETAILS_SUCCESS';
exports.STANDING_TIME_DETAILS_SUCCESS = STANDING_TIME_DETAILS_SUCCESS;
var STANDING_TIME_DETAILS_ERROR = 'STANDING_TIME_DETAILS_ERROR';
exports.STANDING_TIME_DETAILS_ERROR = STANDING_TIME_DETAILS_ERROR;

var findStandingTimeDetails = function findStandingTimeDetails(portalId, widgetId, _ref2) {
  var interval = _ref2.interval,
      intervalStart = _ref2.intervalStart,
      intervalEnd = _ref2.intervalEnd,
      assetIds = _ref2.assetIds,
      assetGroupIds = _ref2.assetGroupIds,
      dynamicPaging = _ref2.dynamicPaging;
  return {
    type: STANDING_TIME_DETAILS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      interval: interval,
      intervalStart: intervalStart,
      intervalEnd: intervalEnd,
      assetIds: assetIds,
      assetGroupIds: assetGroupIds,
      dynamicPaging: dynamicPaging
    }
  };
};

exports.findStandingTimeDetails = findStandingTimeDetails;