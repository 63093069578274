"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _historyData = require("./history-data.actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _historyData.HISTORY_DATA_REQUEST:
      {
        var widgetId = action.headers.widgetId;
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, widgetId, []));
      }

    case _historyData.HISTORY_DATA_SUCCESS:
      {
        var data = action.body.data;
        var _widgetId = action.headers.widgetId;
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, _widgetId, data));
      }

    case _historyData.HISTORY_DATA_ERROR:
      {
        var _widgetId2 = action.headers.widgetId;
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, _widgetId2, []));
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return {};

    default:
      return state;
  }
};

exports["default"] = _default;