"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Scope = void 0;
var Scope; // string templates as type requires typescript version >= 4.1
// lint fails here in pipeline, probably requires update of eslint typescript plugin / parser?
// export type RequestType = `${string}_REQUEST`;
// export type SuccessType = `${string}_SUCCESS`;
// export type ErrorType = `${string}_ERROR`;

exports.Scope = Scope;

(function (Scope) {
  Scope["Base"] = "BASE";
  Scope["Portal"] = "PORTAL";
  Scope["Admin"] = "ADMIN";
})(Scope || (exports.Scope = Scope = {}));