"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PositionShape = exports.PolygonShape = exports.PointShape = exports.MultiPolygonShape = exports.MultiPointShape = exports.MultiLineStringShape = exports.LineStringShape = exports.GeometryCollectionShape = exports.FeatureShape = exports.FeatureCollectionShape = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// Geometries
var PositionShape = _propTypes["default"].arrayOf(_propTypes["default"].number);

exports.PositionShape = PositionShape;

var PointShape = _propTypes["default"].shape({
  type: _propTypes["default"].oneOf(['Point']).isRequired,
  coordinates: PositionShape.isRequired
});

exports.PointShape = PointShape;

var LineStringShape = _propTypes["default"].shape({
  type: _propTypes["default"].oneOf(['LineString']).isRequired,
  coordinates: _propTypes["default"].arrayOf(PositionShape).isRequired
});

exports.LineStringShape = LineStringShape;

var PolygonShape = _propTypes["default"].shape({
  type: _propTypes["default"].oneOf(['Polygon']).isRequired,
  coordinates: _propTypes["default"].arrayOf(_propTypes["default"].arrayOf(PositionShape)).isRequired
});

exports.PolygonShape = PolygonShape;

var MultiPointShape = _propTypes["default"].shape({
  type: _propTypes["default"].oneOf(['MultiPoint']).isRequired,
  coordinates: _propTypes["default"].arrayOf(PositionShape).isRequired
});

exports.MultiPointShape = MultiPointShape;

var MultiLineStringShape = _propTypes["default"].shape({
  type: _propTypes["default"].oneOf(['MultiLineString']).isRequired,
  coordinates: _propTypes["default"].arrayOf(_propTypes["default"].arrayOf(PositionShape)).isRequired
});

exports.MultiLineStringShape = MultiLineStringShape;

var MultiPolygonShape = _propTypes["default"].shape({
  type: _propTypes["default"].oneOf(['MultiPolygon']).isRequired,
  coordinates: _propTypes["default"].arrayOf(_propTypes["default"].arrayOf(_propTypes["default"].arrayOf(PositionShape))).isRequired
});

exports.MultiPolygonShape = MultiPolygonShape;

var GeometryCollectionShape = _propTypes["default"].shape({
  type: _propTypes["default"].oneOf(['GeometryCollection']).isRequired,
  geometries: _propTypes["default"].arrayOf(_propTypes["default"].oneOfType([PointShape, LineStringShape, PolygonShape, MultiPointShape, MultiLineStringShape, MultiPolygonShape]))
}); // Features


exports.GeometryCollectionShape = GeometryCollectionShape;

var FeatureShape = function FeatureShape() {
  var properties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _propTypes["default"].shape({
    type: _propTypes["default"].oneOf(['Feature']).isRequired,
    geometry: _propTypes["default"].oneOfType([PointShape, LineStringShape, PolygonShape, MultiPointShape, MultiLineStringShape, MultiPolygonShape]),
    properties: _propTypes["default"].shape(properties).isRequired
  });
};

exports.FeatureShape = FeatureShape;

var FeatureCollectionShape = function FeatureCollectionShape() {
  var featureShapes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return _propTypes["default"].shape({
    type: _propTypes["default"].oneOf(['FeatureCollection']).isRequired,
    features: _propTypes["default"].arrayOf(_propTypes["default"].oneOfType(featureShapes)).isRequired
  });
};

exports.FeatureCollectionShape = FeatureCollectionShape;