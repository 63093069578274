"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_ALARMS_SUCCESS = exports.GET_ALARMS_REQUEST = exports.GET_ALARMS_ERROR = void 0;
exports.getAlarmInformationListFromBackend = getAlarmInformationListFromBackend;

/*
 * Use for a reduced list of info for alarms. Mostly just the id and name.
 * Any updates will be saved from the alarm-administration actions, so we only need the Get action in this file.
 */
var GET_ALARMS_REQUEST = 'GET_ALARMS_REQUEST';
exports.GET_ALARMS_REQUEST = GET_ALARMS_REQUEST;
var GET_ALARMS_ERROR = 'GET_ALARMS_ERROR';
exports.GET_ALARMS_ERROR = GET_ALARMS_ERROR;
var GET_ALARMS_SUCCESS = 'GET_ALARMS_SUCCESS';
exports.GET_ALARMS_SUCCESS = GET_ALARMS_SUCCESS;

function getAlarmInformationListFromBackend(portalId) {
  var dynamicFilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var action = {
    type: GET_ALARMS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    }
  };

  if (dynamicFilters !== null) {
    action.body = {
      dynamicFilters: dynamicFilters,
      dynamicPaging: null,
      dynamicSorters: null
    };
  }

  return action;
}