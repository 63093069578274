"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = notificationsReducer;

var _admin = require("../admin");

var _accounting = require("../admin/accounting/accounting.actions");

var _boxes2 = require("../admin/boxes/boxes.actions");

var _account = require("../admin/users/push/account.actions");

var _endpoint = require("../admin/users/push/endpoint.actions");

var _users = require("../admin/users/users.actions");

var _incidents = require("../alarm-management/incidents.actions");

var _assets = require("../asset-administration/assets.actions");

var _exchange = require("../b2b-exchange/exchange.actions");

var _assets2 = require("../fleet-administration/assets.actions");

var _accounts = require("../gatewayAccounts/accounts.actions");

var _transportExport = require("../greenyard-export/transportExport.actions");

var _historyData = require("../history-data/history-data.actions");

var _latestData = require("../latest-data/latest-data.actions");

var _pois = require("../pois/pois.actions");

var _boxes3 = require("../portal/boxes/boxes.actions");

var _portals = require("../portal/portals.actions");

var _portalAdministration = require("../portal-administration");

var _rentalTool = require("../rentalTool/rental-tool.actions");

var _reports = require("../reports/reports.actions");

var _tempChart = require("../tempChart/temp-chart.actions");

var _test = require("../testData/test.actions");

var _tours = require("../tours/tours.actions");

var _commands = require("../twoway-communication/commands/commands.actions");

var _users2 = require("../user-administration/users.actions");

var WORKSPACE_ACTIONS = _interopRequireWildcard(require("../workspaces/workspaces.actions"));

var _notifications = require("./notifications.actions");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function notificationsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case WORKSPACE_ACTIONS.RENAME_WORKSPACE_ERROR:
    case WORKSPACE_ACTIONS.ADD_WORKSPACE_ERROR:
      return state.concat(action.body.translationKeys);

    case _notifications.DELETE_NOTIFICATION:
      return state.filter(function (index) {
        return index !== action.body.index;
      });

    case 'CREATE_MILEAGE_EXPORT_SUCCESS':
      {
        var link = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.mileageCountry.success',
          text: 'notification.export.mileageCountry.download',
          link: link,
          autoClose: false
        };
      }

    case 'CREATE_MILEAGE_EXPORT_ERROR':
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.mileageCountry.error',
          autoClose: true
        };
      }

    case _exchange.FIND_SHARED_VEHICLES_EXPORT_SUCCESS:
      {
        var _link = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.b2bExchange.success',
          text: 'notification.export.b2bExchange.download',
          link: _link,
          autoClose: false
        };
      }

    case _exchange.FIND_SHARED_VEHICLES_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.b2bExchange.error',
          autoClose: true
        };
      }

    case _pois.CREATE_POI_MONITOR_EXPORT_SUCCESS:
      {
        var _link2 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.poiMonitor.success',
          text: 'notification.export.poiMonitor.download',
          link: _link2,
          autoClose: false
        };
      }

    case _pois.CREATE_POI_MONITOR_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.poiMonitor.error',
          autoClose: true
        };
      }

    case _pois.GENERATE_POI_EXPORT_SUCCESS:
      {
        var _link3 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.poiAdministration.success',
          text: 'notification.export.poiAdministration.download',
          link: _link3,
          autoClose: false
        };
      }

    case _pois.GENERATE_POI_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.poiAdministration.error',
          autoClose: true
        };
      }

    case _historyData.CREATE_HISTORY_DATA_EXPORT_SUCCESS:
      {
        var _link4 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.historyData.success',
          text: 'notification.export.historyData.download',
          link: _link4,
          autoClose: false
        };
      }

    case _historyData.CREATE_HISTORY_DATA_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.historyData.error',
          autoClose: true
        };
      }

    case _latestData.CREATE_LATEST_DATA_EXPORT_SUCCESS:
      {
        var _link5 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.latestData.success',
          text: 'notification.export.latestData.download',
          link: _link5,
          autoClose: false
        };
      }

    case _latestData.CREATE_LATEST_DATA_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.latestData.error',
          autoClose: true
        };
      }

    case _assets.CREATE_ASSET_EXPORT_SUCCESS:
      {
        var _link6 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.asset.success',
          text: 'notification.export.asset.download',
          link: _link6,
          autoClose: false
        };
      }

    case _assets.CREATE_ASSET_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.asset.error',
          autoClose: true
        };
      }

    case _portals.SEND_PORTAL_CONNECT_SUCCESS:
      {
        var widgetId = action.headers.widgetId;
        var _action$body = action.body,
            email = _action$body.email,
            _action$body$contact = _action$body.contact,
            contact = _action$body$contact === void 0 ? {} : _action$body$contact;
        var _contact$status = contact.status,
            status = _contact$status === void 0 ? '' : _contact$status; // mass assigned requests use 0 for widgetId

        if (status === 'REQUESTED' && widgetId !== 0) {
          return {
            severity: 'green',
            icon: 'Message',
            title: 'notification.sendPortalConnect.success',
            text: 'notification.sendPortalConnect.success.text',
            titleAddition: {
              email: email
            },
            autoClose: true
          };
        }

        if (status === 'RECEIVED') {
          return {
            severity: 'green',
            icon: 'Message',
            title: 'notification.getPortalConnect.success',
            text: 'notification.getPortalConnect.success.text',
            titleAddition: {
              email: email
            },
            autoClose: true
          };
        }

        return state;
      }

    case _portals.SEND_ALL_PORTAL_CONNECT_SUCCESS:
      {
        var _action$body$contact2 = action.body.contact,
            _contact = _action$body$contact2 === void 0 ? [] : _action$body$contact2;

        var requestedContacts = _contact.filter(function (item) {
          return item.status === 'REQUESTED';
        });

        if (requestedContacts.length > 0) {
          var notification = {
            severity: 'green',
            icon: 'Message',
            title: 'notification.sendAllPortalConnect.success',
            text: 'notification.sendAllPortalConnect.success.text',
            titleAddition: {
              email: requestedContacts[0].name
            },
            autoClose: true
          };
          return notification;
        }

        return state;
      }

    case _portals.SEND_PORTAL_CONNECT_ERROR:
      {
        var headers = action.headers,
            _action$body2 = action.body,
            body = _action$body2 === void 0 ? {} : _action$body2;
        var _widgetId = headers.widgetId;

        var _body$email = body.email,
            _email = _body$email === void 0 ? '' : _body$email,
            _body$exceptionName = body.exceptionName,
            exceptionName = _body$exceptionName === void 0 ? '' : _body$exceptionName;

        var text = 'notification.sendPortalConnect.error.text'; // mass assigned requests use 0 for widgetId

        if (_widgetId === 0) {
          return state;
        }

        if (exceptionName === 'PortalAlreadyConnectedException') {
          text = 'notification.sendPortalConnect.error.portalAlreadyConnectedText';
        }

        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.sendPortalConnect.error',
          text: text,
          titleAddition: {
            email: _email
          },
          autoClose: true
        };
      }

    case _portals.SEND_ALL_PORTAL_CONNECT_ERROR:
      {
        var _action$body$contact3 = action.body.contact,
            _contact2 = _action$body$contact3 === void 0 ? [] : _action$body$contact3;

        var errorContacts = _contact2.filter(function (item) {
          return item.status === 'ERROR';
        });

        if (errorContacts.length > 0) {
          var errorNotifications = errorContacts.map(function (contactItem) {
            var _contactItem$email = contactItem.email,
                email = _contactItem$email === void 0 ? '' : _contactItem$email,
                _contactItem$exceptio = contactItem.exceptionName,
                exceptionName = _contactItem$exceptio === void 0 ? '' : _contactItem$exceptio;
            var text = 'notification.sendPortalConnect.error.text';

            if (exceptionName === 'PortalAlreadyConnectedException') {
              text = 'notification.sendPortalConnect.error.portalAlreadyConnectedText';
            }

            return {
              severity: 'red',
              icon: 'Message',
              title: 'notification.sendPortalConnect.error',
              text: text,
              titleAddition: {
                email: email
              },
              autoClose: true
            };
          });
          return errorNotifications;
        }

        return state;
      }

    case _portals.CHANGE_PORTAL_CONTACT_STATUS_SUCCESS:
      {
        var _action$body$contact4 = action.body.contact,
            _contact3 = _action$body$contact4 === void 0 ? {} : _action$body$contact4;

        var portalId = action.headers.portalId;

        var _contact3$status = _contact3.status,
            _status = _contact3$status === void 0 ? '' : _contact3$status,
            toPortalId = _contact3.portalId;

        var isOwnPortal = portalId === toPortalId;

        if (_status === 'ACCEPTED') {
          return {
            severity: 'green',
            icon: 'Message',
            title: 'notification.acceptPortalContact.success',
            text: 'notification.acceptPortalContact.success.text',
            autoClose: true
          };
        }

        if (_status === 'CANCELED') {
          return {
            severity: 'green',
            icon: 'Message',
            title: isOwnPortal ? 'notification.withdrawPortalContact.success' : 'notification.declinePortalContact.success',
            autoClose: true
          };
        }

        return state;
      }

    case _portals.CHANGE_PORTAL_CONTACT_STATUS_ERROR:
      {
        var _action$body$contact5 = action.body.contact,
            _contact4 = _action$body$contact5 === void 0 ? {} : _action$body$contact5;

        var _portalId = action.headers.portalId;

        var _contact4$status = _contact4.status,
            _status2 = _contact4$status === void 0 ? '' : _contact4$status,
            _toPortalId = _contact4.portalId;

        var _isOwnPortal = _portalId === _toPortalId;

        if (_status2 === 'ACCEPTED') {
          return {
            severity: 'red',
            icon: 'Message',
            title: 'notification.acceptPortalContact.error',
            autoClose: true
          };
        }

        if (_status2 === 'CANCELED') {
          return {
            severity: 'red',
            icon: 'Message',
            title: _isOwnPortal ? 'notification.withdrawPortalContact.error' : 'notification.declinePortalContact.error',
            autoClose: true
          };
        }

        return state;
      }

    case _transportExport.CREATE_GREENYARD_EXPORT_SUCCESS:
      {
        var _action$body3 = action.body,
            _body = _action$body3 === void 0 ? {} : _action$body3;

        var _body$link = _body.link,
            _link7 = _body$link === void 0 ? '' : _body$link;

        return {
          severity: 'green',
          icon: 'Message',
          title: 'notification.exportGreenyardTour.success',
          text: 'notification.export.tempChart.download',
          link: _link7,
          autoClose: true
        };
      }

    case _transportExport.CREATE_GREENYARD_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.exportGreenyardTour.error',
          autoClose: true
        };
      }

    case _tempChart.CREATE_TEMP_CHART_DATA_EXPORT_SUCCESS:
    case _tempChart.CREATE_TEMP_CHART_EXPORT_SUCCESS:
      {
        var _link8 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.tempChart.success',
          text: 'notification.export.tempChart.download',
          link: _link8,
          autoClose: false
        };
      }

    case _reports.CREATE_REPORT_SERIES_SUCCESS:
      {
        var interval = action.body.reportSeries.interval;

        if (interval !== 'immediate') {
          return {
            severity: 'green',
            icon: 'Message',
            title: 'notification.report.series.success',
            autoClose: true
          };
        }

        return {
          severity: 'green',
          icon: 'Message',
          title: 'notification.report.immediate.success',
          autoClose: true
        };
      }

    case _assets2.CREATE_ASSET_MASTERDATA_EXPORT_SUCCESS:
      {
        var _link9 = action.body.link;

        if (_link9 === undefined) {
          return {
            severity: 'green',
            icon: 'Message',
            title: 'notification.export.fleetAdministration.email.success',
            autoClose: true
          };
        }

        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.fleetAdministration.download.success',
          text: 'notification.export.fleetAdministration.download.here',
          link: _link9,
          autoClose: false
        };
      }

    case _assets2.CREATE_ASSET_MASTERDATA_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.fleetAdministration.error',
          autoClose: true
        };
      }

    case _commands.SEND_TWOWAY_COMMAND_SUCCESS:
      {
        var command = action.body.command;

        if ((command === null || command === void 0 ? void 0 : command.status) === 'ERROR') {
          return {
            severity: 'red',
            icon: 'CloseButton',
            title: 'notification.2wayCommunication.command.error',
            autoClose: true
          };
        }

        return {
          severity: 'green',
          icon: 'GreenCheck',
          title: 'notification.2wayCommunication.command.success',
          autoClose: true
        };
      }

    case _commands.SEND_TWOWAY_COMMAND_ERROR:
      {
        return {
          severity: 'red',
          icon: 'CloseButton',
          title: 'notification.2wayCommunication.command.errorNotSuccessful',
          autoClose: true
        };
      }

    case _tempChart.CREATE_TEMP_CHART_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.tempChart.error',
          autoClose: true
        };
      }

    case _users2.REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_SUCCESS:
      {
        var _email2 = action.body.email;
        return {
          severity: 'green',
          icon: 'Message',
          title: 'notification.userAdministration.refreshUserRegistration.success',
          titleAddition: {
            email: _email2
          },
          autoClose: true
        };
      }

    case _users2.REFRESH_USER_REGISTRATION_FOR_USER_ADMINISTRATION_ERROR:
      {
        var _email3 = action.body.email;
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.userAdministration.refreshUserRegistration.error',
          titleAddition: {
            email: _email3
          },
          autoClose: true
        };
      }

    case _assets2.UPLOAD_MASTERDATA_SUCCESS:
      {
        return {
          severity: 'green',
          icon: 'GreenCheck',
          title: 'notification.fleetAdministration.masterdataUpload.success',
          autoClose: true
        };
      }

    case _assets2.UPLOAD_MASTERDATA_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.fleetAdministration.masterdataUpload.error',
          autoClose: true
        };
      }

    case _tours.TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_SUCCESS:
      {
        var _link10 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.tourBoard.success',
          text: 'notification.export.tourBoard.download',
          link: _link10,
          autoClose: false
        };
      }

    case _tours.TRIGGER_TOUR_REPORT_FOR_TOUR_BOARD_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.tourBoard.error',
          autoClose: true
        };
      }

    case _assets.UPDATE_ASSET_ERROR:
      {
        return {
          severity: 'red',
          icon: 'CloseButton',
          title: 'notification.assetAdministration.asset.error',
          autoClose: true
        };
      }

    case _users.CREATE_USER_EXPORT_SUCCESS:
      {
        var _link11 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.admintoolUserAdministration.success',
          text: 'notification.export.admintoolUserAdministration.download',
          link: _link11,
          autoClose: false
        };
      }

    case _users.CREATE_USER_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.admintoolUserAdministration.error',
          autoClose: true
        };
      }

    case _portalAdministration.CREATE_PORTAL_EXPORT_SUCCESS:
      {
        var _link12 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.portalAdministration.success',
          text: 'notification.export.portalAdministration.download',
          link: _link12,
          autoClose: false
        };
      }

    case _portalAdministration.CREATE_PORTAL_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.portalAdministration.error',
          autoClose: true
        };
      }

    case _users.REFRESH_USER_REGISTRATION_ADMINTOOL_SUCCESS:
      {
        var _email4 = action.body.user.email;
        return {
          severity: 'green',
          icon: 'Message',
          title: 'notification.admintoolUserAdministration.refreshRegistration.success',
          titleAddition: {
            email: _email4
          },
          autoClose: true
        };
      }

    case _users.REFRESH_USER_REGISTRATION_ADMINTOOL_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.admintoolUserAdministration.refreshRegistration.error',
          autoClose: true
        };
      }

    case _incidents.GENERATE_INCIDENTS_EXPORT_BY_FILTER_SUCCESS:
      {
        var _link13 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.alarmManagement.overall.success',
          text: 'notification.export.alarmManagement.overall.download',
          link: _link13,
          autoClose: false
        };
      }

    case _incidents.GENERATE_INCIDENTS_EXPORT_BY_FILTER_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.alarmManagement.overall.error',
          autoClose: false
        };
      }

    case _incidents.GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_SUCCESS:
      {
        var _link14 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.alarmManagement.detail.success',
          text: 'notification.export.alarmManagement.detail.download',
          link: _link14,
          autoClose: false
        };
      }

    case _incidents.GENERATE_INCIDENT_DETAIL_EXPORT_BY_FILTER_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.alarmManagement.detail.error',
          autoClose: false
        };
      }

    case _accounting.CREATE_TOUR_EXPORT_FOR_BILLING_SUCCESS:
      {
        var _link15 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.accounting.tour.success',
          text: 'notification.export.accounting.tour.download',
          link: _link15,
          autoClose: false
        };
      }

    case _accounting.CREATE_TOUR_EXPORT_FOR_BILLING_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.accounting.tour.error',
          autoClose: true
        };
      }

    case _accounting.CREATE_ASSET_EXPORT_FOR_BILLING_SUCCESS:
      {
        var _link16 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.accounting.asset.success',
          text: 'notification.export.accounting.asset.download',
          link: _link16,
          autoClose: false
        };
      }

    case _accounting.CREATE_ASSET_EXPORT_FOR_BILLING_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.accounting.asset.error',
          autoClose: true
        };
      }

    case _accounting.CREATE_SMS_EXPORT_SUCCESS:
      {
        var _link17 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.accounting.sms.success',
          text: 'notification.export.accounting.sms.download',
          link: _link17,
          autoClose: false
        };
      }

    case _accounting.CREATE_SMS_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.accounting.sms.error',
          autoClose: true
        };
      }

    case _accounting.TRIGGER_BILLING_EXPORT_SUCCESS:
      {
        var _link18 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.accounting.billing.success',
          text: 'notification.export.accounting.billing.download',
          link: _link18,
          autoClose: false
        };
      }

    case _accounting.TRIGGER_BILLING_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.accounting.billing.error',
          autoClose: true
        };
      }

    case _rentalTool.RENT_VEHICLES_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.rentalTool.rentVehicles.error',
          autoClose: true
        };
      }

    case _rentalTool.CANCEL_VEHICLE_RENTALS_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.rentalTool.cancelVehicleRentals.error',
          autoClose: true
        };
      }

    case _users.CREATE_USER_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.admintoolUserAdministration.createUser.error',
          autoClose: true
        };
      }

    case _users.UPDATE_USER_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.admintoolUserAdministration.updateUser.error',
          autoClose: true
        };
      }

    case _users.DELETE_USER_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.admintoolUserAdministration.deleteUser.error',
          autoClose: true
        };
      }

    case _admin.AUTHORIZE_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.admintoolUserAdministration.authorize.error',
          autoClose: true
        };
      }

    case _admin.DELETE_AUTHORIZATION_CODE_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.admintoolUserAdministration.deleteAuthCode.error',
          autoClose: true
        };
      }

    case _account.CREATE_PUSH_ACCOUNT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.admintoolUserAdministration.createPushAccount.error',
          autoClose: true
        };
      }

    case _account.UPDATE_PUSH_ACCOUNT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.admintoolUserAdministration.updatePushAccount.error',
          autoClose: true
        };
      }

    case _account.DELETE_PUSH_ACCOUNT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.admintoolUserAdministration.deletePushAccount.error',
          autoClose: true
        };
      }

    case _endpoint.CREATE_ENDPOINT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.admintoolUserAdministration.createPushEndpoint.error',
          autoClose: true
        };
      }

    case _endpoint.UPDATE_ENDPOINT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.admintoolUserAdministration.updatePushEndpoint.error',
          autoClose: true
        };
      }

    case _boxes3.ASSIGN_BOXES_TO_PORTAL_SUCCESS:
      {
        var _action$body$boxes = action.body.boxes,
            boxes = _action$body$boxes === void 0 ? [] : _action$body$boxes;
        return {
          severity: 'green',
          icon: 'GreenCheck',
          title: 'notification.data-control-center.assign-asset.success.title',
          text: 'notification.data-control-center.assign-asset.success.text',
          textAddition: {
            boxCount: boxes.length
          },
          autoClose: true
        };
      }

    case _boxes3.ASSIGN_BOXES_TO_PORTAL_ERROR:
      {
        var _action$body$boxes2 = action.body.boxes,
            _boxes = _action$body$boxes2 === void 0 ? [] : _action$body$boxes2;

        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.data-control-center.assign-asset.error.title',
          text: 'notification.data-control-center.assign-asset.error.text',
          textAddition: {
            boxCount: _boxes.length
          },
          autoClose: true
        };
      }

    case _tours.CREATE_TOUR_BOARD_EXPORT_SUCCESS:
      {
        var _link19 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.tourBoard.excel.success',
          text: 'notification.export.tourBoard.download',
          link: _link19,
          autoClose: false
        };
      }

    case _tours.CREATE_TOUR_BOARD_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.tourBoard.excel.error',
          autoClose: true
        };
      }
    // trigger intern with react query

    case 'CREATE_TOUR_BOARD_EXPORT_LIMIT_ERROR':
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.tourBoard.excel.limit.error',
          autoClose: true
        };
      }

    case _test.TRIGGER_TEST_REPORT_SUCCESS:
      {
        var _link20 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'Test-Report Export fertig',
          text: 'Download',
          link: _link20,
          autoClose: false
        };
      }

    case _portalAdministration.CONNECT_PORTALS_FROM_ADMIN_TOOL_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.portalAdministration.connectPortals.error.title',
          autoClose: true
        };
      }

    case _portalAdministration.DISCONNECT_PORTALS_FROM_ADMIN_TOOL_ERROR:
      {
        var _action$body4 = action.body,
            _body2 = _action$body4 === void 0 ? {} : _action$body4;

        var _body2$exceptionName = _body2.exceptionName,
            _exceptionName = _body2$exceptionName === void 0 ? '' : _body2$exceptionName,
            _body2$translationObj = _body2.translationObjects,
            translationObjects = _body2$translationObj === void 0 ? [] : _body2$translationObj;

        if (_exceptionName === 'PortalSharesStillActiveException') {
          return {
            severity: 'red',
            icon: 'Message',
            title: 'notification.portalAdministration.disconnectPortals.error.title',
            text: 'notification.portalAdministration.disconnectPortals.error.text',
            textAddition: {
              sharedFromPortalCount: translationObjects[0] || 0,
              sharedToPortalCount: translationObjects[1] || 0
            },
            autoClose: true
          };
        }

        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.portalAdministration.disconnectPortals.error.title',
          autoClose: true
        };
      }

    case _portalAdministration.CREATE_PORTAL_ERROR:
      {
        var _action$body5;

        if ((action === null || action === void 0 ? void 0 : (_action$body5 = action.body) === null || _action$body5 === void 0 ? void 0 : _action$body5.exceptionName) === 'DuplicateVatException') {
          return {
            severity: 'red',
            icon: 'Message',
            title: 'notification.portalAdministration.createPortal.error.title',
            text: 'notification.portalAdministration.createPortal.error.duplicateVat',
            autoClose: true
          };
        }

        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.portalAdministration.createPortal.error.title',
          autoClose: true
        };
      }

    case _portalAdministration.UPDATE_PORTAL_ERROR:
      {
        var _action$body6;

        if ((action === null || action === void 0 ? void 0 : (_action$body6 = action.body) === null || _action$body6 === void 0 ? void 0 : _action$body6.exceptionName) === 'DuplicateVatException') {
          return {
            severity: 'red',
            icon: 'Message',
            title: 'notification.portalAdministration.updatePortal.error.title',
            text: 'notification.portalAdministration.updatePortal.error.duplicateVat',
            autoClose: true
          };
        }

        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.portalAdministration.updatePortal.error.title',
          autoClose: true
        };
      }

    case _portalAdministration.DELETE_PORTAL_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.portalAdministration.deletePortal.error.title',
          autoClose: true
        };
      }

    case _accounts.CREATE_UNASSIGNED_BOXES_EXPORT_SUCCESS:
      {
        var _link21 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.accountsAndBoxes.success',
          text: 'notification.export.accountsAndBoxes.download',
          link: _link21,
          autoClose: false
        };
      }

    case _accounts.CREATE_UNASSIGNED_BOXES_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.accountsAndBoxes.error',
          autoClose: true
        };
      }

    case _boxes2.ASSIGN_BOX_TO_PORTAL_FOR_ADMIN_TOOL_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.boxAdministration.assignBox.error.title',
          autoClose: true
        };
      }

    case _boxes2.REVOKE_BOX_FROM_PORTAL_FOR_ADMIN_TOOL_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.boxAdministration.revokeBox.error.title',
          autoClose: true
        };
      }

    case _boxes2.CHANGE_BOXES_FOR_ADMIN_TOOL_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.boxAdministration.changeBox.error.title',
          autoClose: true
        };
      }

    case _boxes2.UPDATE_VEHICLE_ATTRIBUTES_FOR_ADMIN_TOOL_ERROR:
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.boxAdministration.updateVehicle.error.title',
          autoClose: true
        };
      }

    case _rentalTool.CREATE_RENTAL_EXPORT_SUCCESS:
      {
        var _link22 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.rentalTool.success',
          text: 'notification.export.rentalTool.download',
          link: _link22,
          autoClose: false
        };
      }

    case _rentalTool.CREATE_RENTAL_EXPORT_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.rentalTool.error',
          autoClose: true
        };
      }

    case _tours.GET_TOUR_IMPORT_PATTERN_SUCCESS:
      {
        var _link23 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.tourBoard.excelImport.template.success',
          text: 'notification.tourBoard.excelImport.template.download',
          link: _link23,
          autoClose: false
        };
      }

    case _tours.GET_TOUR_IMPORT_PATTERN_ERROR:
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.tourBoard.excelImport.template.error',
          autoClose: true
        };
      }

    case 'CREATE_TRANSLATION_DEFINITION_ERROR':
      {
        return {
          severity: 'red',
          icon: 'ErrorSolid',
          title: 'notification.translationAdministration.createDefinition.error',
          text: 'notification.translationAdministration.definition.checkDuplicates',
          autoClose: true
        };
      }

    case 'UPDATE_TRANSLATION_DEFINITION_ERROR':
      {
        return {
          severity: 'red',
          icon: 'ErrorSolid',
          title: 'notification.translationAdministration.updateDefinition.error',
          text: 'notification.translationAdministration.definition.checkDuplicates',
          autoClose: true
        };
      }

    case 'DELETE_TRANSLATION_DEFINITION_ERROR':
      {
        return {
          severity: 'red',
          icon: 'ErrorSolid',
          title: 'notification.translationAdministration.deleteDefinition.error',
          autoClose: true
        };
      }

    case 'CREATE_TRANSLATION_ERROR':
      {
        return {
          severity: 'red',
          icon: 'ErrorSolid',
          title: 'notification.translationAdministration.createTranslation.error',
          autoClose: true
        };
      }

    case 'UPDATE_TRANSLATION_ERROR':
      {
        return {
          severity: 'red',
          icon: 'ErrorSolid',
          title: 'notification.translationAdministration.updateTranslation.error',
          autoClose: true
        };
      }

    case 'DELETE_TRANSLATION_ERROR':
      {
        return {
          severity: 'red',
          icon: 'ErrorSolid',
          title: 'notification.translationAdministration.deleteTranslation.error',
          autoClose: true
        };
      }

    case 'CREATE_TRANSLATION_KEY_ERROR':
      {
        return {
          severity: 'red',
          icon: 'ErrorSolid',
          title: 'notification.translationAdministration.createKey.error',
          autoClose: true
        };
      }

    case 'DELETE_TRANSLATION_KEY_ERROR':
      {
        return {
          severity: 'red',
          icon: 'ErrorSolid',
          title: 'notification.translationAdministration.deleteKey.error',
          autoClose: true
        };
      }

    case 'CREATE_B2B_PORTAL_ASSET_SHARINGS_EXPORT_SUCCESS':
      {
        var _link24 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.b2b.portal.asset.sharing.success',
          text: 'notification.export.b2b.portal.asset.sharing.download',
          link: _link24,
          autoClose: false
        };
      }

    case 'CREATE_B2B_PORTAL_ASSET_SHARINGS_EXPORT_ERROR':
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.b2b.portal.asset.sharing.error',
          autoClose: true
        };
      }

    case 'CREATE_PUBLISH_ENDPOINT_ADMIN_EXPORT_SUCCESS':
      {
        var _link25 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.endpointAdministration.success',
          text: 'notification.export.endpointAdministration.download',
          link: _link25,
          autoClose: false
        };
      }

    case 'CREATE_PUBLISH_ENDPOINT_ADMIN_EXPORT_ERROR':
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.endpointAdministration.error',
          autoClose: true
        };
      }

    case 'CREATE_PUBLISH_ACCOUNT_ADMIN_EXPORT_SUCCESS':
      {
        var _link26 = action.body.link;
        return {
          severity: 'green',
          icon: 'ExportDownload',
          title: 'notification.export.publishAccountAdministration.success',
          text: 'notification.export.publishAccountAdministration.download',
          link: _link26,
          autoClose: false
        };
      }

    case 'CREATE_PUBLISH_ACCOUNT_ADMIN_EXPORT_ERROR':
      {
        return {
          severity: 'red',
          icon: 'ExportFailed',
          title: 'notification.export.publishAccountAdministration.error',
          autoClose: true
        };
      }

    case 'B2B_CONNECT_EXTERNAL_ID_INFO':
      {
        return {
          severity: 'white',
          icon: 'Info',
          title: 'notification.b2bConnect.externalIdInfo.title',
          text: 'notification.b2bConnect.externalIdInfo.text',
          autoClose: true
        };
      }

    case 'RESET_PASSWORD_SUCCESS':
      {
        return {
          severity: 'green',
          icon: 'Message',
          title: 'userProfile.resetpassword.success.message',
          autoClose: true
        };
      }

    case 'RESET_PASSWORD_ERROR':
      {
        return {
          severity: 'red',
          icon: 'Message',
          title: 'notification.userAdministration.error',
          autoClose: true
        };
      }

    default:
      if (global.godmode) {
        if (/^.*_ERROR$/.test(action.type)) {
          // TODO adapt to new toaster interface
          return Object.assign({}, state, {
            error: {
              name: action.type,
              severity: 'red'
            }
          });
        }
      }

      return state;
  }
}