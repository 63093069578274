"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _queryKeys = _interopRequireDefault(require("./assets/queryKeys"));

var _portalAdminQueryKey = _interopRequireDefault(require("./portalAdminQueryKey"));

var _portalDccQueryKey = _interopRequireDefault(require("./portalDccQueryKey"));

var _portalQueryKey = _interopRequireDefault(require("./portalQueryKey"));

var _usePortalAdminPoiCategories = require("./usePortalAdminPoiCategories");

var _usePortalAdminPois = require("./usePortalAdminPois");

var _usePortalAlarms = require("./usePortalAlarms");

var _usePortalAssetGroups = require("./usePortalAssetGroups");

var _usePortalWidgets = require("./usePortalWidgets");

var _usePortalWidgetTypes = require("./usePortalWidgetTypes");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = {
  all: _portalQueryKey["default"],
  admin: {
    all: _portalAdminQueryKey["default"],
    pois: _usePortalAdminPois.queryKeys,
    poiCategories: _usePortalAdminPoiCategories.queryKeys
  },
  dcc: _portalDccQueryKey["default"],
  assets: _queryKeys["default"],
  assetGroups: _usePortalAssetGroups.queryKeys,
  alarms: _usePortalAlarms.queryKeys,
  widgets: _usePortalWidgets.queryKeys,
  widgetTypes: _usePortalWidgetTypes.queryKeys
};
exports["default"] = _default;