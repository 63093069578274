"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.projectsReducer = exports["default"] = exports.accountsReducer = void 0;

var _activePortal = require("../activePortal");

var _systemExchange = require("./system-exchange.actions");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var projectsReducer = function projectsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _activePortal.CHANGE_ACTIVE_PORTAL:
      {
        return [];
      }

    case _systemExchange.CREATE_SYSTEM_EXCHANGE_PROJECT_SUCCESS:
      {
        var _action$body$project = action.body.project,
            project = _action$body$project === void 0 ? {} : _action$body$project;
        return [].concat(_toConsumableArray(state), [project]);
      }

    case _systemExchange.FIND_SYSTEM_EXCHANGE_PROJECTS_SUCCESS:
      {
        var _action$body$projects = action.body.projects,
            projects = _action$body$projects === void 0 ? [] : _action$body$projects;
        return _toConsumableArray(projects);
      }

    default:
      {
        return state;
      }
  }
};

exports.projectsReducer = projectsReducer;

var accountsReducer = function accountsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _activePortal.CHANGE_ACTIVE_PORTAL:
      {
        return [];
      }

    case _systemExchange.FIND_PUSH_ACCOUNTS_SUCCESS:
      {
        var _action$body$accounts = action.body.accounts,
            accounts = _action$body$accounts === void 0 ? [] : _action$body$accounts;
        return _toConsumableArray(accounts);
      }

    default:
      {
        return state;
      }
  }
};

exports.accountsReducer = accountsReducer;
var _default = {
  projects: projectsReducer,
  accounts: accountsReducer
};
exports["default"] = _default;