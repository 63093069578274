"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_ASSET_SUCCESS = exports.UPDATE_ASSET_REQUEST = exports.UPDATE_ASSET_ERROR = exports.REPLACE_UNASSIGNED_HW_ASSETS_SUCCESS = exports.REPLACE_UNASSIGNED_HW_ASSETS_REQUEST = exports.REPLACE_UNASSIGNED_HW_ASSETS_ERROR = exports.REPLACE_ASSETS_SUCCESS = exports.REPLACE_ASSETS_ERROR = exports.FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_SUCCESS = exports.FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_REQUEST = exports.FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_ERROR = exports.DELETE_ASSETS_SUCCESS = exports.DELETE_ASSETS_REQUEST = exports.DELETE_ASSETS_ERROR = exports.CREATE_ASSET_ON_VEHICLE_LEVEL_SUCCESS = exports.CREATE_ASSET_ON_VEHICLE_LEVEL_REQUEST = exports.CREATE_ASSET_ON_VEHICLE_LEVEL_ERROR = exports.CREATE_ASSET_EXPORT_SUCCESS = exports.CREATE_ASSET_EXPORT_REQUEST = exports.CREATE_ASSET_EXPORT_ERROR = void 0;
exports.createAssetExport = createAssetExport;
exports.createNewAsset = createNewAsset;
exports.deleteAssets = deleteAssets;
exports.getAssetsFromBackend = getAssetsFromBackend;
exports.getUnassignedHwAssets = getUnassignedHwAssets;
exports.updateAsset = updateAsset;

/**
 * Created by EHelbing on 22.12.2016.
 */
var CREATE_ASSET_EXPORT_REQUEST = 'CREATE_ASSET_EXPORT_REQUEST';
exports.CREATE_ASSET_EXPORT_REQUEST = CREATE_ASSET_EXPORT_REQUEST;
var CREATE_ASSET_EXPORT_ERROR = 'CREATE_ASSET_EXPORT_ERROR';
exports.CREATE_ASSET_EXPORT_ERROR = CREATE_ASSET_EXPORT_ERROR;
var CREATE_ASSET_EXPORT_SUCCESS = 'CREATE_ASSET_EXPORT_SUCCESS';
exports.CREATE_ASSET_EXPORT_SUCCESS = CREATE_ASSET_EXPORT_SUCCESS;

function createAssetExport(portalId) {
  var dynamicFilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return {
    type: CREATE_ASSET_EXPORT_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      filters: {
        dynamicFilters: dynamicFilters
      }
    }
  };
}

var CREATE_ASSET_ON_VEHICLE_LEVEL_SUCCESS = 'CREATE_ASSET_ON_VEHICLE_LEVEL_FOR_ADMINISTRATION_SUCCESS';
exports.CREATE_ASSET_ON_VEHICLE_LEVEL_SUCCESS = CREATE_ASSET_ON_VEHICLE_LEVEL_SUCCESS;
var CREATE_ASSET_ON_VEHICLE_LEVEL_REQUEST = 'CREATE_ASSET_ON_VEHICLE_LEVEL_FOR_ADMINISTRATION_REQUEST';
exports.CREATE_ASSET_ON_VEHICLE_LEVEL_REQUEST = CREATE_ASSET_ON_VEHICLE_LEVEL_REQUEST;
var CREATE_ASSET_ON_VEHICLE_LEVEL_ERROR = 'CREATE_ASSET_ON_VEHICLE_LEVEL_FOR_ADMINISTRATION_ERROR';
exports.CREATE_ASSET_ON_VEHICLE_LEVEL_ERROR = CREATE_ASSET_ON_VEHICLE_LEVEL_ERROR;

function createNewAsset(asset, portalId) {
  return {
    type: CREATE_ASSET_ON_VEHICLE_LEVEL_REQUEST,
    headers: {
      version: 'v2',
      portalId: portalId
    },
    body: {
      asset: asset
    }
  };
}

var UPDATE_ASSET_SUCCESS = 'UPDATE_ASSET_ON_VEHICLE_LEVEL_FOR_ADMINISTRATION_SUCCESS';
exports.UPDATE_ASSET_SUCCESS = UPDATE_ASSET_SUCCESS;
var UPDATE_ASSET_REQUEST = 'UPDATE_ASSET_ON_VEHICLE_LEVEL_FOR_ADMINISTRATION_REQUEST';
exports.UPDATE_ASSET_REQUEST = UPDATE_ASSET_REQUEST;
var UPDATE_ASSET_ERROR = 'UPDATE_ASSET_ON_VEHICLE_LEVEL_FOR_ADMINISTRATION_ERROR';
exports.UPDATE_ASSET_ERROR = UPDATE_ASSET_ERROR;

function updateAsset(asset, portalId) {
  return {
    type: UPDATE_ASSET_REQUEST,
    headers: {
      version: 'v2',
      portalId: portalId
    },
    body: {
      asset: asset
    }
  };
}

var DELETE_ASSETS_SUCCESS = 'DELETE_ASSET_ON_VEHICLE_LEVEL_FOR_ADMINISTRATION_SUCCESS';
exports.DELETE_ASSETS_SUCCESS = DELETE_ASSETS_SUCCESS;
var DELETE_ASSETS_REQUEST = 'DELETE_ASSET_ON_VEHICLE_LEVEL_FOR_ADMINISTRATION_REQUEST';
exports.DELETE_ASSETS_REQUEST = DELETE_ASSETS_REQUEST;
var DELETE_ASSETS_ERROR = 'DELETE_ASSET_ON_VEHICLE_LEVEL_FOR_ADMINISTRATION_ERROR';
exports.DELETE_ASSETS_ERROR = DELETE_ASSETS_ERROR;

function deleteAssets(assedId, portalId) {
  return {
    type: DELETE_ASSETS_REQUEST,
    headers: {
      version: 'v2',
      portalId: portalId
    },
    body: {
      id: assedId
    }
  };
}

var REPLACE_UNASSIGNED_HW_ASSETS_SUCCESS = 'FIND_UNASSIGNED_HARDWARE_ASSETS_SUCCESS';
exports.REPLACE_UNASSIGNED_HW_ASSETS_SUCCESS = REPLACE_UNASSIGNED_HW_ASSETS_SUCCESS;
var REPLACE_UNASSIGNED_HW_ASSETS_REQUEST = 'FIND_UNASSIGNED_HARDWARE_ASSETS_REQUEST';
exports.REPLACE_UNASSIGNED_HW_ASSETS_REQUEST = REPLACE_UNASSIGNED_HW_ASSETS_REQUEST;
var REPLACE_UNASSIGNED_HW_ASSETS_ERROR = 'FIND_UNASSIGNED_HARDWARE_ASSETS_ERROR';
exports.REPLACE_UNASSIGNED_HW_ASSETS_ERROR = REPLACE_UNASSIGNED_HW_ASSETS_ERROR;

function getUnassignedHwAssets(portalId) {
  return {
    type: REPLACE_UNASSIGNED_HW_ASSETS_REQUEST,
    headers: {
      version: 'v2',
      portalId: portalId
    }
  };
}

var REPLACE_ASSETS_SUCCESS = 'REPLACE_ASSETSnpm _SUCCESS';
exports.REPLACE_ASSETS_SUCCESS = REPLACE_ASSETS_SUCCESS;
var REPLACE_ASSETS_ERROR = 'REPLACE_ASSETS_ERROR';
exports.REPLACE_ASSETS_ERROR = REPLACE_ASSETS_ERROR;
var FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_REQUEST = 'FIND_ASSETS_FOR_ADMINISTRATION_REQUEST';
exports.FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_REQUEST = FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_REQUEST;
var FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_SUCCESS = 'FIND_ASSETS_FOR_ADMINISTRATION_SUCCESS';
exports.FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_SUCCESS = FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_SUCCESS;
var FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_ERROR = 'FIND_ASSETS_FOR_ADMINISTRATION_ERROR';
exports.FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_ERROR = FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_ERROR;

function getAssetsFromBackend() {
  var dynamicFilters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  var action = {
    type: FIND_ASSETS_FOR_USER_VEHICLE_ADMINISTRATION_REQUEST,
    headers: {
      version: 'v2',
      portalId: portalId
    }
  };

  if (dynamicFilters !== null) {
    action.body = {
      dynamicFilters: dynamicFilters,
      dynamicPaging: null,
      dynamicSorters: null
    };
  }

  return action;
}