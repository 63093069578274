"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.notEqual = exports.like = exports.lessThanEqual = exports.lessThan = exports.isNotIn = exports.isIn = exports.greaterThanEqual = exports.greaterThan = exports.equal = void 0;

var equal = function equal(value, presetValue) {
  if (presetValue instanceof Date && value instanceof Date) {
    return value.getTime() === presetValue.getTime();
  }

  return value === presetValue;
};

exports.equal = equal;

var notEqual = function notEqual(value, presetValue) {
  if (presetValue instanceof Date && value instanceof Date) {
    return value.getTime() !== presetValue.getTime();
  }

  return value !== presetValue;
};

exports.notEqual = notEqual;

var greaterThan = function greaterThan(value, presetValue) {
  return value > presetValue;
};

exports.greaterThan = greaterThan;

var greaterThanEqual = function greaterThanEqual(value, presetValue) {
  return value >= presetValue;
};

exports.greaterThanEqual = greaterThanEqual;

var lessThan = function lessThan(value, presetValue) {
  return value < presetValue;
};

exports.lessThan = lessThan;

var lessThanEqual = function lessThanEqual(value, presetValue) {
  return value <= presetValue;
}; // string only


exports.lessThanEqual = lessThanEqual;

var like = function like(value, presetValue) {
  return String(value).toLowerCase().includes(String(presetValue).toLowerCase());
}; // array only


exports.like = like;

var isIn = function isIn(value, presetValue) {
  if (!Array.isArray(presetValue)) {
    return false;
  }

  return presetValue.includes(value);
};

exports.isIn = isIn;

var isNotIn = function isNotIn(value, presetValue) {
  if (!Array.isArray(presetValue)) {
    return true;
  }

  return !presetValue.includes(value);
};

exports.isNotIn = isNotIn;