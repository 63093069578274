"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _commands = require("./commands.actions");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var INITIAL_STATE = {};

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _commands.FIND_TWOWAY_SYSTEMS_BY_ASSET_SUCCESS:
      {
        var headers = action.headers,
            body = action.body;
        var widget = state[headers.widgetId];
        return Object.assign({}, state, _defineProperty({}, headers.widgetId, Object.assign({}, widget, body)));
      }

    case _commands.FIND_TWOWAY_COMMANDS_BY_ASSET_SUCCESS:
      {
        var _headers = action.headers,
            _body = action.body;
        var _widget = state[_headers.widgetId];
        return Object.assign({}, state, _defineProperty({}, _headers.widgetId, Object.assign({}, _widget, {
          history: _body
        })));
      }

    default:
      return state;
  }
};

exports["default"] = _default;