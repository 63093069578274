"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PoiType = void 0;
var PoiType;
exports.PoiType = PoiType;

(function (PoiType) {
  PoiType["Circle"] = "CIRCLE";
  PoiType["Polygon"] = "POLYGON";
})(PoiType || (exports.PoiType = PoiType = {}));