"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FIND_ASSIGNABLE_USERGROUPS_SUCCESS = exports.FIND_ASSIGNABLE_USERGROUPS_REQUEST = exports.FIND_ASSIGNABLE_USERGROUPS_ERROR = void 0;
exports.getAssignableUsergroups = getAssignableUsergroups;

/**
 * Created by EHelbing on 06.11.2017.
 */
var FIND_ASSIGNABLE_USERGROUPS_REQUEST = 'FIND_ASSIGNABLE_USERGROUPS_FOR_ADMINISTRATION_REQUEST';
exports.FIND_ASSIGNABLE_USERGROUPS_REQUEST = FIND_ASSIGNABLE_USERGROUPS_REQUEST;
var FIND_ASSIGNABLE_USERGROUPS_SUCCESS = 'FIND_ASSIGNABLE_USERGROUPS_FOR_ADMINISTRATION_SUCCESS';
exports.FIND_ASSIGNABLE_USERGROUPS_SUCCESS = FIND_ASSIGNABLE_USERGROUPS_SUCCESS;
var FIND_ASSIGNABLE_USERGROUPS_ERROR = 'FIND_ASSIGNABLE_USERGROUPS_FOR_ADMINISTRATION_ERROR';
exports.FIND_ASSIGNABLE_USERGROUPS_ERROR = FIND_ASSIGNABLE_USERGROUPS_ERROR;

function getAssignableUsergroups(portalId) {
  return {
    type: FIND_ASSIGNABLE_USERGROUPS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {}
  };
}