"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findFleetCheckOverview = exports.findFleetCheckDetails = exports.FIND_FLEET_CHECK_OVERVIEW_SUCCESS = exports.FIND_FLEET_CHECK_OVERVIEW_REQUEST = exports.FIND_FLEET_CHECK_OVERVIEW_ERROR = exports.FIND_FLEET_CHECK_DETAIL_SUCCESS = exports.FIND_FLEET_CHECK_DETAIL_REQUEST = exports.FIND_FLEET_CHECK_DETAIL_ERROR = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var FIND_FLEET_CHECK_OVERVIEW_REQUEST = 'FIND_FLEET_CHECK_OVERVIEW_REQUEST';
exports.FIND_FLEET_CHECK_OVERVIEW_REQUEST = FIND_FLEET_CHECK_OVERVIEW_REQUEST;
var FIND_FLEET_CHECK_OVERVIEW_SUCCESS = 'FIND_FLEET_CHECK_OVERVIEW_SUCCESS';
exports.FIND_FLEET_CHECK_OVERVIEW_SUCCESS = FIND_FLEET_CHECK_OVERVIEW_SUCCESS;
var FIND_FLEET_CHECK_OVERVIEW_ERROR = 'FIND_FLEET_CHECK_OVERVIEW_ERROR';
exports.FIND_FLEET_CHECK_OVERVIEW_ERROR = FIND_FLEET_CHECK_OVERVIEW_ERROR;

var findFleetCheckOverview = function findFleetCheckOverview(widgetId, portalId) {
  var filters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return {
    type: FIND_FLEET_CHECK_OVERVIEW_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: filters
  };
};

exports.findFleetCheckOverview = findFleetCheckOverview;
var FIND_FLEET_CHECK_DETAIL_REQUEST = 'FIND_FLEET_CHECK_DETAIL_REQUEST';
exports.FIND_FLEET_CHECK_DETAIL_REQUEST = FIND_FLEET_CHECK_DETAIL_REQUEST;
var FIND_FLEET_CHECK_DETAIL_SUCCESS = 'FIND_FLEET_CHECK_DETAIL_SUCCESS';
exports.FIND_FLEET_CHECK_DETAIL_SUCCESS = FIND_FLEET_CHECK_DETAIL_SUCCESS;
var FIND_FLEET_CHECK_DETAIL_ERROR = 'FIND_FLEET_CHECK_DETAIL_ERROR';
exports.FIND_FLEET_CHECK_DETAIL_ERROR = FIND_FLEET_CHECK_DETAIL_ERROR;

var findFleetCheckDetails = function findFleetCheckDetails(widgetId, portalId, category) {
  var filters = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  return {
    type: FIND_FLEET_CHECK_DETAIL_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: _objectSpread({
      category: category
    }, filters)
  };
};

exports.findFleetCheckDetails = findFleetCheckDetails;