"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createLatestDataExport = exports.CREATE_LATEST_DATA_EXPORT_SUCCESS = exports.CREATE_LATEST_DATA_EXPORT_REQUEST = exports.CREATE_LATEST_DATA_EXPORT_ERROR = void 0;
var CREATE_LATEST_DATA_EXPORT_REQUEST = 'CREATE_LATEST_DATA_EXPORT_REQUEST';
exports.CREATE_LATEST_DATA_EXPORT_REQUEST = CREATE_LATEST_DATA_EXPORT_REQUEST;
var CREATE_LATEST_DATA_EXPORT_SUCCESS = 'CREATE_LATEST_DATA_EXPORT_SUCCESS';
exports.CREATE_LATEST_DATA_EXPORT_SUCCESS = CREATE_LATEST_DATA_EXPORT_SUCCESS;
var CREATE_LATEST_DATA_EXPORT_ERROR = 'CREATE_LATEST_DATA_EXPORT_ERROR';
exports.CREATE_LATEST_DATA_EXPORT_ERROR = CREATE_LATEST_DATA_EXPORT_ERROR;

var createLatestDataExport = function createLatestDataExport(widgetId, filterNodes, visibleColumns, portalId) {
  return {
    type: CREATE_LATEST_DATA_EXPORT_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      filters: {
        dynamicFilters: filterNodes,
        dynamicSorters: null,
        dynamicPaging: null
      },
      visibleColumns: visibleColumns
    }
  };
};

exports.createLatestDataExport = createLatestDataExport;