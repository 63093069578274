"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _department = require("./department.actions");

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _department.FIND_ALL_DEPARTMENTS_SUCCESS:
      {
        return action.body.departments;
      }

    default:
      return state;
  }
};

exports["default"] = _default;