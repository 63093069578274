import React from 'react';
import { Help } from 'nicbase-icons';
import { useUser } from 'nicbase-reducers';
import { FormattedMessage } from 'nicbase-translation';

import './help-button.scss';

export default () => {
  const { locale } = useUser();

  const languageCode = locale?.substring(0, Math.max(2, locale.lastIndexOf('-')));
  const helpUrl = languageCode === 'de' ? 'https://help.nic-place.com' : 'https://help.nic-place.com/en';
  const termsAndConditionUrl = 'https://www.fourkites.com/legal/general-terms-and-conditions-for-data-providers/';
  const disclosureUrl = 'https://nic-place.com/en-US/imprint.html';
  const dataProtectionUrl = languageCode === 'de' ? 'https://nic-place.com/de/legal/privacy-policy/' : 'https://nic-place.com/legal/privacy-policy/';

  return (
    <div className="help-button">
      <Help className="icon" />
      <ul className="help-menu">
        <div className="arrow" />
        <li>
          <a className="p-3 block text-primary" href={helpUrl} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="navigation.help.helpCenter" />
          </a>
        </li>
        <li>
          <a className="p-3 block text-primary" href={dataProtectionUrl} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="navigation.help.dataProtection" />
          </a>
        </li>
        <li>
          <a className="p-3 block text-primary" href={termsAndConditionUrl} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="navigation.help.gtc" />
          </a>
        </li>
        <li>
          <a className="p-3 block text-primary" href={disclosureUrl} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="navigation.help.legalDisclosure" />
          </a>
        </li>
      </ul>
    </div>
  );
};
