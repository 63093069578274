"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _alarmDetails = require("./alarmDetails.actions");

/**
 * Created by PKing on 06.10.2017
 */
var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _alarmDetails.FIND_ALARM_BY_ID_SUCCESS:
      {
        return action.body.alarms;
      }

    case _alarmDetails.FIND_ALARM_BY_ID_ERROR:
      {
        return {};
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return {};

    default:
      {
        return state;
      }
  }
};

exports["default"] = _default;