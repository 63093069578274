import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as Icons from 'nicbase-icons';
import { FormattedMessage } from 'nicbase-translation';

import './notifications.scss';

const Notification = ({ severity, icon, title, titleAddition, text, textAddition, link }) => {
  const NotificationIcon = Icons[icon];
  return (
    <a href={link} rel="noopener noreferrer" target="_blank" className="block pr-2 font-sans">
      <div className="flex items-center">
        {icon ? (
          <NotificationIcon
            className={cx('pr-3 w-12 h-12 flex-shrink-0', {
              'fill-green-500': severity === 'green',
              'fill-red-500': severity === 'yellow',
              'fill-white': severity === 'red',
              'fill-primary': severity === 'default' || severity === 'white',
            })}
          />
        ) : null}
        <div className="flex flex-col">
          {title ? (
            <span
              className={cx('font-bold text-base', {
                'text-red-500': severity === 'yellow',
                'text-white': severity === 'red',
                'text-primary': severity === 'default' || severity === 'green' || severity === 'white',
              })}
            >
              <FormattedMessage id={title} values={titleAddition} />
            </span>
          ) : null}
          {text ? (
            <span
              className={cx('pt-1 text-sm', {
                'text-red-500': severity === 'yellow',
                'text-white': severity === 'red',
                'text-primary': severity === 'default' || severity === 'green' || severity === 'white',
              })}
            >
              <FormattedMessage id={text} values={textAddition} />
            </span>
          ) : null}
        </div>
      </div>
    </a>
  );
};

Notification.propTypes = {
  severity: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  text: PropTypes.string,
  textAddition: PropTypes.shape(),
  titleAddition: PropTypes.shape(),
  link: PropTypes.string,
};

Notification.defaultProps = {
  severity: 'white',
  icon: null,
  title: null,
  text: null,
  textAddition: {},
  titleAddition: {},
  link: null,
};

export default Notification;
