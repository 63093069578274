"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _alarm = require("./alarm");

Object.keys(_alarm).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _alarm[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _alarm[key];
    }
  });
});