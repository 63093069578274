"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isRailAsset = exports.isCoupledAsset = exports.findAssetKind = void 0;
var AssetKind;

(function (AssetKind) {
  AssetKind["RoadVehicle"] = "ROAD_VEHICLE";
  AssetKind["RoadVehicleCoupled"] = "ROAD_VEHICLE_COUPLED";
  AssetKind["RailVehicle"] = "RAIL_VEHICLE";
  AssetKind["RailVehicleCoupled"] = "RAIL_VEHICLE_COUPLED";
  AssetKind["Aircraft"] = "AIRCRAFT";
  AssetKind["Ship"] = "SHIP";
  AssetKind["Building"] = "BUILDING";
})(AssetKind || (AssetKind = {}));

var assetKinds = [{
  name: AssetKind.RoadVehicle,
  values: [100, 101, 103, 104, 105, 111, 120, 121, 122, 126, 127, 131, 150],
  range: [1000, 1500]
}, {
  name: AssetKind.RoadVehicleCoupled,
  values: [200],
  range: [1500, 2000]
}, {
  name: AssetKind.RailVehicle,
  values: [140, 141, 142, 143, 144],
  range: [2000, 2500]
}, {
  name: AssetKind.RailVehicleCoupled,
  values: [240, 241, 242, 244, 245],
  range: [2500, 3000]
}, {
  name: AssetKind.Aircraft,
  values: [],
  range: [3000, 4000]
}, {
  name: AssetKind.Ship,
  values: [],
  range: [4000, 5000]
}, {
  name: AssetKind.Ship,
  values: [102],
  range: [5000, 6000]
}];

var findAssetKind = function findAssetKind(assetType) {
  var _assetKinds$find;

  return (_assetKinds$find = assetKinds.find(function (kind) {
    return kind.values.some(function (index) {
      return index === assetType;
    }) || assetType >= kind.range[0] && assetType < kind.range[1];
  })) === null || _assetKinds$find === void 0 ? void 0 : _assetKinds$find.name;
};

exports.findAssetKind = findAssetKind;

var isCoupledAsset = function isCoupledAsset(assetType) {
  var _findAssetKind;

  return (_findAssetKind = findAssetKind(assetType)) === null || _findAssetKind === void 0 ? void 0 : _findAssetKind.endsWith('COUPLED');
};

exports.isCoupledAsset = isCoupledAsset;

var isRailAsset = function isRailAsset(assetType) {
  var _findAssetKind2;

  return (_findAssetKind2 = findAssetKind(assetType)) === null || _findAssetKind2 === void 0 ? void 0 : _findAssetKind2.startsWith('RAIL');
};

exports.isRailAsset = isRailAsset;