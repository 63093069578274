"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Mode = void 0;
var Mode;
exports.Mode = Mode;

(function (Mode) {
  Mode["Mobile"] = "mobile";
  Mode["Tablet"] = "tablet";
  Mode["Desktop"] = "desktop";
  Mode["DesktopLarge"] = "desktop-large";
})(Mode || (exports.Mode = Mode = {}));