"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _usergroups = require("./usergroups.actions");

/**
 * Created by FFendt on 27.04.2017.
 */
var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _usergroups.FIND_USERGROUPS_SUCCESS:
      return action.body.userGroups;

    case _usergroups.CREATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS:
      {
        // return a cloned copy of state with the new user concatenated into it
        return state.concat(action.body.userGroup);
      }

    case _usergroups.UPDATE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS:
      {
        // replace the user group
        return state.map(function (obj) {
          return obj.id === action.body.userGroup.id ? action.body.userGroup : obj;
        });
      }

    case _usergroups.DELETE_USERGROUP_FOR_USERGROUP_ADMINISTRATION_SUCCESS:
      {
        // remove the user group from the users list
        return state.filter(function (obj) {
          return obj.id !== action.body.userGroup.id;
        });
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return [];

    default:
      return state;
  }
};

exports["default"] = _default;