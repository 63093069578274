"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _impersonation = require("../impersonation/impersonation.actions");

var _portalAdministration = require("../portal-administration/portalAdministration.actions");

var _portals = require("./portals.actions");

/* eslint-disable no-param-reassign */
var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _portals.FIND_ALL_PORTALS_FOR_USER_SUCCESS:
      {
        var _action$body$portals = action.body.portals,
            portals = _action$body$portals === void 0 ? [] : _action$body$portals;
        var newState = portals.reduce(function (prev, portal) {
          var result = prev;
          result[portal.id] = portal;
          return result;
        }, {});
        return Object.assign({}, state, newState);
      }

    case _impersonation.IMPERSONATE_USER:
    case _impersonation.CANCEL_IMPERSONATION:
      return {};

    case _portalAdministration.DELETE_PORTAL_SUCCESS:
      {
        var portalId = action.body.portalId;
        return Object.keys(state).reduce(function (result, id) {
          if (id === String(portalId)) {
            return result;
          }

          result[id] = state[id];
          return result;
        }, {});
      }

    default:
      return state;
  }
};

exports["default"] = _default;