"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getColumnDefinitionsWithDefaultWidth = exports.defaultRailColumns = exports.defaultColumnsTouboardLite = exports.defaultColumns = exports["default"] = exports.columnWidths = exports.columnDefinition = void 0;

var _ActionsCell = _interopRequireDefault(require("./cells/ActionsCell"));

var _AssetNameCell = _interopRequireDefault(require("./cells/AssetNameCell"));

var _AssignedAlarmsCell = _interopRequireDefault(require("./cells/AssignedAlarmsCell"));

var _CellDataGetters = require("./cells/CellDataGetters");

var _CurrentAlarmCell = _interopRequireWildcard(require("./cells/CurrentAlarmCell"));

var _DateCell = _interopRequireDefault(require("./cells/DateCell"));

var _DeviationCellRenderer = _interopRequireDefault(require("./cells/DeviationCellRenderer"));

var _NameCell = _interopRequireDefault(require("./cells/NameCell"));

var _NameSegmentsCell = _interopRequireDefault(require("./cells/NameSegmentsCell"));

var _PoiNameCell = _interopRequireDefault(require("./cells/PoiNameCell"));

var _PortalNameCell = _interopRequireDefault(require("./cells/PortalNameCell"));

var _PortalNamesCell = _interopRequireDefault(require("./cells/PortalNamesCell"));

var _RealStartEndTimeCell = _interopRequireDefault(require("./cells/RealStartEndTimeCell"));

var _rtvProviderCell = _interopRequireDefault(require("./cells/rtv-provider-cell"));

var _StatusCell = _interopRequireDefault(require("./cells/StatusCell"));

var _StoringPlacesCell = _interopRequireDefault(require("./cells/StoringPlacesCell"));

var _TriggeredAlarmsCell = _interopRequireWildcard(require("./cells/TriggeredAlarmsCell"));

var _sorters = require("./sorters");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var columnDefinition = {
  assetId: {
    label: 'widgets.tourBoard.headers.assetName',
    sortable: true,
    cellDataGetter: _CellDataGetters.assetIdCellDataGetter,
    cellRenderer: _AssetNameCell["default"],
    sorterFunction: _sorters.sortString
  },
  train: {
    label: 'widgets.tourBoard.headers.train',
    sortable: true,
    cellDataGetter: _CellDataGetters.assetIdCellDataGetter,
    cellRenderer: _AssetNameCell["default"],
    sorterFunction: _sorters.sortString
  },
  name: {
    label: 'widgets.tourBoard.headers.name',
    sortable: true,
    cellDataGetter: _CellDataGetters.nameCellDataGetter,
    cellRenderer: _NameSegmentsCell["default"],
    sorterFunction: _sorters.sortString
  },
  externalId: {
    label: 'widgets.tourBoard.headers.externalId',
    sortable: true,
    cellDataGetter: _CellDataGetters.externalIdCellDataGetter,
    cellRenderer: _NameCell["default"],
    sorterFunction: _sorters.sortString
  },
  earliestStartTime: {
    label: 'widgets.tourBoard.headers.tourDate',
    cellDataGetter: _CellDataGetters.earliestStartTimeCellDataGetter,
    cellRenderer: _DateCell["default"],
    sortable: true,
    sorterFunction: _sorters.sortDate
  },
  plannedStartTime: {
    label: 'widgets.tourBoard.headers.startPoiTime',
    cellDataGetter: _CellDataGetters.startPoiTimeCellDataGetter,
    cellRenderer: _DateCell["default"],
    sortable: true,
    sorterFunction: _sorters.sortDate
  },
  plannedEndTime: {
    label: 'widgets.tourBoard.headers.endPoiTime',
    cellDataGetter: _CellDataGetters.endPoiTimeCellDataGetter,
    cellRenderer: _DateCell["default"],
    sortable: true,
    sorterFunction: _sorters.sortDate
  },
  startPoi: {
    label: 'widgets.tourBoard.headers.startPoi',
    cellDataGetter: _CellDataGetters.startPoiCellDataGetter,
    cellRenderer: _PoiNameCell["default"]
  },
  endPoi: {
    label: 'widgets.tourBoard.headers.endPoi',
    cellDataGetter: _CellDataGetters.endPoiCellDataGetter,
    cellRenderer: _PoiNameCell["default"]
  },
  scheduledStartTime: {
    label: 'widgets.tourBoard.headers.scheduledStartTime',
    sortable: true,
    cellDataGetter: _CellDataGetters.scheduledStartTimeCellDataGetter,
    cellRenderer: _DateCell["default"],
    sorterFunction: _sorters.sortDate
  },
  scheduledEndTime: {
    label: 'widgets.tourBoard.headers.scheduledEndTime',
    sortable: true,
    cellDataGetter: _CellDataGetters.scheduledEndTimeCellDataGetter,
    cellRenderer: _DateCell["default"],
    sorterFunction: _sorters.sortDate
  },
  realStartTime: {
    label: 'widgets.tourBoard.headers.realStartTime',
    sortable: true,
    cellDataGetter: _CellDataGetters.realStartTimeCellDataGetter,
    cellRenderer: _RealStartEndTimeCell["default"],
    sorterFunction: _sorters.sortRealStartEndDate
  },
  realEndTime: {
    label: 'widgets.tourBoard.headers.realEndTime',
    sortable: true,
    cellDataGetter: _CellDataGetters.realEndTimeCellDataGetter,
    cellRenderer: _RealStartEndTimeCell["default"],
    sorterFunction: _sorters.sortRealStartEndDate
  },
  arrivalDeviation: {
    label: 'widgets.tourBoard.headers.arrivalDeviation',
    cellDataGetter: _CellDataGetters.waypointsDataGetter,
    cellRenderer: _DeviationCellRenderer["default"]
  },
  eta: {
    label: 'widgets.tourBoard.headers.tourEta',
    cellDataGetter: _CellDataGetters.etaCellDataGetter,
    cellRenderer: _DateCell["default"],
    sortable: true,
    sorterFunction: _sorters.sortDate
  },
  status: {
    label: 'widgets.tourBoard.headers.status',
    sortable: true,
    cellDataGetter: _CellDataGetters.statusCellDataGetter,
    cellRenderer: _StatusCell["default"]
  },
  storingPlaces: {
    label: 'widgets.tourBoard.headers.storingPlaces',
    cellDataGetter: _CellDataGetters.storingPlacesCellDataGetter,
    cellRenderer: _StoringPlacesCell["default"]
  },
  remainingDrivingTime: {
    label: 'widgets.tourBoard.headers.remainingDrivingTime',
    cellDataGetter: function cellDataGetter() {},
    // for sale
    cellRenderer: _NameCell["default"]
  },
  assignedAlarms: {
    label: 'widgets.tourBoard.headers.assignedAlarms',
    cellDataGetter: _CellDataGetters.assignedAlarmsCellDataGetter,
    cellRenderer: _AssignedAlarmsCell["default"]
  },
  customerPortalIds: {
    label: 'widgets.tourBoard.headers.customerPortalIds',
    sortable: false,
    cellDataGetter: _CellDataGetters.customerPortalIdsCellDataGetter,
    cellRenderer: _PortalNamesCell["default"]
  },
  serviceProviderPortalId: {
    label: 'widgets.tourBoard.headers.serviceProviderPortalId',
    sortable: false,
    cellDataGetter: _CellDataGetters.serviceProviderPortalIdCellDataGetter,
    cellRenderer: _PortalNameCell["default"]
  },
  rtvProvider: {
    label: 'widgets.tourBoard.headers.rtvProvider',
    sortable: false,
    cellDataGetter: function cellDataGetter(_ref) {
      var _rowData$projects, _rowData$segments$map, _rowData$segments;

      var rowData = _ref.rowData;
      return {
        tourProjects: (_rowData$projects = rowData.projects) !== null && _rowData$projects !== void 0 ? _rowData$projects : [],
        segmentProjects: (_rowData$segments$map = (_rowData$segments = rowData.segments) === null || _rowData$segments === void 0 ? void 0 : _rowData$segments.map(function (segment) {
          var _segment$projects;

          return (_segment$projects = segment.projects) !== null && _segment$projects !== void 0 ? _segment$projects : [];
        }).flat()) !== null && _rowData$segments$map !== void 0 ? _rowData$segments$map : []
      };
    },
    cellRenderer: _rtvProviderCell["default"]
  },
  currentAlarm: {
    label: 'widgets.tourBoard.headers.currentAlarm',
    cellDataGetter: _CurrentAlarmCell.cellDataGetter,
    cellRenderer: _CurrentAlarmCell["default"]
  },
  triggeredAlarms: {
    label: 'widgets.tourBoard.headers.triggeredAlarms',
    cellDataGetter: _TriggeredAlarmsCell.cellDataGetter,
    cellRenderer: _TriggeredAlarmsCell["default"]
  },
  customerName: {
    label: 'widgets.tourBoard.headers.customerName',
    cellDataGetter: _CellDataGetters.customerNameCellDataGetter,
    cellRenderer: _NameCell["default"]
  },
  railwayUndertaking: {
    label: 'widgets.tourBoard.headers.railwayUndertaking',
    cellDataGetter: _CellDataGetters.railwayUndertakingCellDataGetter,
    cellRenderer: _NameCell["default"]
  },
  loaded: {
    label: 'widgets.tourBoard.headers.loaded',
    cellDataGetter: _CellDataGetters.loadedCellDataGetter,
    cellRenderer: _NameCell["default"]
  },
  actions: {
    label: 'widgets.tourBoard.headers.actions',
    sortable: false,
    cellDataGetter: _CellDataGetters.actionsCellDataGetter,
    cellRenderer: _ActionsCell["default"],
    style: {
      overflow: 'visible'
    },
    columnData: {
      onReportExportClicked: 'a function, which is set in the presentation constructor',
      useAsTemplate: 'a function, which is set in the presentation constructor',
      onDetailsClick: 'a function, which is set in the presentation constructor',
      onEditClick: 'a function, which is set in the presentation constructor',
      onTerminateClick: 'a function, which is set in the presentation constructor',
      onStartClick: 'a function, which is set in the presentation constructor',
      onDeleteClick: 'a function, which is set in the presentation constructor',
      onCreateLinkClick: 'a function, which is set in the presentation constructor',
      onExportTransportOrderClick: 'a function, which is set in the presentation constructor',
      onAssetRequestClick: 'a function, which is set in the presentation constructor',
      tourStatus: 'tour status string'
    }
  }
};
exports.columnDefinition = columnDefinition;
var defaultColumns = [{
  dataKey: 'assetId'
}, {
  dataKey: 'name'
}, {
  dataKey: 'externalId'
}, {
  dataKey: 'earliestStartTime'
}, {
  dataKey: 'plannedStartTime'
}, {
  dataKey: 'startPoi'
}, {
  dataKey: 'endPoi'
}, {
  dataKey: 'realStartTime'
}, {
  dataKey: 'realEndTime'
}, {
  dataKey: 'arrivalDeviation'
}, {
  dataKey: 'status'
}, {
  dataKey: 'assignedAlarms'
}, {
  dataKey: 'customerPortalIds'
}, {
  dataKey: 'serviceProviderPortalId'
}, {
  dataKey: 'currentAlarm'
}, {
  dataKey: 'triggeredAlarms'
}, {
  dataKey: 'actions'
}];
exports.defaultColumns = defaultColumns;
var defaultRailColumns = [{
  dataKey: 'assetId'
}, {
  dataKey: 'train'
}, {
  dataKey: 'name'
}, {
  dataKey: 'externalId'
}, {
  dataKey: 'earliestStartTime'
}, {
  dataKey: 'plannedStartTime'
}, {
  dataKey: 'startPoi'
}, {
  dataKey: 'endPoi'
}, {
  dataKey: 'realStartTime'
}, {
  dataKey: 'realEndTime'
}, {
  dataKey: 'arrivalDeviation'
}, {
  dataKey: 'status'
}, {
  dataKey: 'assignedAlarms'
}, {
  dataKey: 'currentAlarm'
}, {
  dataKey: 'triggeredAlarms'
}, {
  dataKey: 'actions'
}, {
  dataKey: 'customerName'
}, {
  dataKey: 'railwayUndertaking'
}, {
  dataKey: 'loaded'
}];
exports.defaultRailColumns = defaultRailColumns;
var defaultColumnsTouboardLite = [{
  dataKey: 'assetId'
}, {
  dataKey: 'name'
}, {
  dataKey: 'externalId'
}, {
  dataKey: 'customerPortalIds'
}, {
  dataKey: 'plannedStartTime'
}, {
  dataKey: 'startPoi'
}, {
  dataKey: 'endPoi'
}, {
  dataKey: 'realStartTime'
}, {
  dataKey: 'realEndTime'
}, {
  dataKey: 'arrivalDeviation'
}, {
  dataKey: 'status'
}, {
  dataKey: 'actions'
}];
exports.defaultColumnsTouboardLite = defaultColumnsTouboardLite;
var columnWidths = {
  tourBoardLite: {
    assetId: 180,
    name: 180,
    externalId: 180,
    plannedStartTime: 180,
    plannedEndTime: 180,
    startPoi: 260,
    endPoi: 260,
    realStartTime: 180,
    realEndTime: 180,
    arrivalDeviation: 180,
    status: 120,
    customerPortalIds: 200,
    actions: 120
  },
  tourBoardExtended: {
    assetId: 100,
    name: 100,
    externalId: 180,
    earliestStartTime: 140,
    plannedStartTime: 140,
    plannedEndTime: 140,
    startPoi: 180,
    endPoi: 180,
    scheduledStartTime: 140,
    scheduledEndTime: 140,
    realStartTime: 160,
    realEndTime: 160,
    arrivalDeviation: 100,
    eta: 140,
    status: 140,
    assignedAlarms: 100,
    customerPortalIds: 140,
    serviceProviderPortalId: 140,
    rtvProvider: 140,
    currentAlarm: 100,
    triggeredAlarms: 100,
    storingPlaces: 100,
    remainingDrivingTime: 100,
    actions: 100
  },
  rail: {
    assetId: 100,
    train: 100,
    name: 100,
    externalId: 180,
    earliestStartTime: 140,
    plannedStartTime: 140,
    plannedEndTime: 140,
    startPoi: 180,
    endPoi: 180,
    scheduledStartTime: 140,
    scheduledEndTime: 140,
    realStartTime: 160,
    realEndTime: 160,
    arrivalDeviation: 100,
    eta: 140,
    status: 140,
    assignedAlarms: 100,
    currentAlarm: 100,
    triggeredAlarms: 100,
    storingPlaces: 100,
    remainingDrivingTime: 100,
    actions: 100,
    customerName: 100,
    railwayUndertaking: 100,
    loaded: 100,
    rtvProvider: 140
  }
};
exports.columnWidths = columnWidths;

var getColumnDefinitionsWithDefaultWidth = function getColumnDefinitionsWithDefaultWidth(version) {
  var colDefs = _objectSpread({}, columnDefinition);

  Object.keys(columnWidths[version]).forEach(function (key) {
    colDefs[key].width = columnWidths[version][key];
  });
  return colDefs;
};

exports.getColumnDefinitionsWithDefaultWidth = getColumnDefinitionsWithDefaultWidth;
var _default = columnDefinition;
exports["default"] = _default;