"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _deepmerge = _interopRequireDefault(require("deepmerge"));

var _activePortal = require("../activePortal");

var widgetTypes = _interopRequireWildcard(require("../widgetTypes/types"));

var _widgets = require("./widgets.actions");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var overwriteMerge = function overwriteMerge(destinationArray, sourceArray) {
  return sourceArray;
};

var defaultWidgetReducer = function defaultWidgetReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _widgets.ADD_WIDGET_SUCCESS:
      {
        var _widgetType$settings;

        var widget = action.body.widget;
        var id = widget.id,
            layout = widget.layout,
            _widget$settings = widget.settings,
            settings = _widget$settings === void 0 ? {} : _widget$settings,
            connectProperties = widget.connectProperties;
        var widgetType = Object.values(widgetTypes).find(function (_ref) {
          var type = _ref.type;
          return type === widget.type;
        });
        var defaultSettings = (_widgetType$settings = widgetType === null || widgetType === void 0 ? void 0 : widgetType.settings) !== null && _widgetType$settings !== void 0 ? _widgetType$settings : {};
        return Object.assign({}, state, _defineProperty({}, widget.id, _objectSpread(_objectSpread({}, widget), {}, {
          layout: _objectSpread(_objectSpread({}, layout), {}, {
            i: String(id)
          }),
          connectProperties: _objectSpread(_objectSpread({}, connectProperties), {}, {
            incoming: {},
            outgoing: {}
          }),
          settings: (0, _deepmerge["default"])(defaultSettings, settings, {
            arrayMerge: overwriteMerge
          })
        })));
      }

    case _widgets.FIND_WIDGETS_BY_WORKSPACE_ID_SUCCESS:
      {
        var widgets = action.body.widgets;
        var addedWidgets = widgets.reduce(function (result, widget) {
          var _widgetType$settings2;

          var id = widget.id,
              layout = widget.layout,
              _widget$settings2 = widget.settings,
              settings = _widget$settings2 === void 0 ? {} : _widget$settings2,
              connectProperties = widget.connectProperties;
          var widgetType = Object.values(widgetTypes).find(function (_ref2) {
            var type = _ref2.type;
            return type === widget.type;
          });
          var defaultSettings = (_widgetType$settings2 = widgetType === null || widgetType === void 0 ? void 0 : widgetType.settings) !== null && _widgetType$settings2 !== void 0 ? _widgetType$settings2 : {}; // eslint-disable-next-line no-param-reassign

          result[widget.id] = _objectSpread(_objectSpread({}, widget), {}, {
            layout: _objectSpread(_objectSpread({}, layout), {}, {
              i: String(id)
            }),
            connectProperties: _objectSpread(_objectSpread({}, connectProperties), {}, {
              incoming: {},
              outgoing: {}
            }),
            settings: (0, _deepmerge["default"])(defaultSettings, settings, {
              arrayMerge: overwriteMerge
            })
          });
          return result;
        }, {});
        return _objectSpread(_objectSpread({}, state), addedWidgets);
      }

    case _widgets.REMOVE_WIDGET_SUCCESS:
      return Object.assign({}, Object.keys(state).filter(function (id) {
        return id !== String(action.body.widget.id);
      }).reduce(function (prev, key) {
        var result = prev;
        result[key] = state[key];
        return result;
      }, {}));

    case _widgets.TOGGLE_WIDGET_SETTINGS:
      return Object.assign({}, state, _defineProperty({}, action.body.widget.id, Object.assign({}, state[action.body.widget.id], {
        showSettings: state[action.body.widget.id].showSettings !== true
      })));

    case _widgets.MOVE_WIDGET_REQUEST:
    case _widgets.MOVE_WIDGET_SUCCESS:
    case _widgets.RESIZE_WIDGET_REQUEST:
    case _widgets.RESIZE_WIDGET_SUCCESS:
      return Object.assign({}, state, Object.assign({}, // replace all layouts in existing widgets
      action.body.widgets.reduce(function (prev, next) {
        var result = prev; // keep the previous state for each widget, but replace the layouts with the new layouts

        result[next.id] = Object.assign({}, state[next.id], {
          layout: next.layout
        });
        return result;
      }, {})));

    case _widgets.CONNECT_WIDGET_SUCCESS:
      {
        var _widget = state[action.body.widget.id];
        return Object.assign({}, state, _defineProperty({}, _widget.id, Object.assign({}, _widget, {
          connected: true
        })));
      }

    case _widgets.DISCONNECT_WIDGET_SUCCESS:
      {
        var _widget2 = state[action.body.widget.id];
        return Object.assign({}, state, _defineProperty({}, _widget2.id, Object.assign({}, _widget2, {
          connected: false
        })));
      }

    case _widgets.UPDATE_WIDGET_SETTINGS_SUCCESS:
      {
        var _widget3 = state[action.body.widget.id];
        return Object.assign({}, state, _defineProperty({}, _widget3.id, Object.assign({}, _widget3, {
          settings: action.body.widget.settings
        })));
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return {};

    default:
      return state;
  }
};

var _default = defaultWidgetReducer;
exports["default"] = _default;