"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAllContentBoxCategories = exports.FIND_CONTENT_BOX_CATEGORIES_SUCCESS = exports.FIND_CONTENT_BOX_CATEGORIES_REQUEST = exports.FIND_CONTENT_BOX_CATEGORIES_ERROR = void 0;
var FIND_CONTENT_BOX_CATEGORIES_REQUEST = 'FIND_CONTENT_BOX_CATEGORIES_REQUEST';
exports.FIND_CONTENT_BOX_CATEGORIES_REQUEST = FIND_CONTENT_BOX_CATEGORIES_REQUEST;
var FIND_CONTENT_BOX_CATEGORIES_SUCCESS = 'FIND_CONTENT_BOX_CATEGORIES_SUCCESS';
exports.FIND_CONTENT_BOX_CATEGORIES_SUCCESS = FIND_CONTENT_BOX_CATEGORIES_SUCCESS;
var FIND_CONTENT_BOX_CATEGORIES_ERROR = 'FIND_CONTENT_BOX_CATEGORIES_ERROR';
exports.FIND_CONTENT_BOX_CATEGORIES_ERROR = FIND_CONTENT_BOX_CATEGORIES_ERROR;

var findAllContentBoxCategories = function findAllContentBoxCategories() {
  return {
    type: FIND_CONTENT_BOX_CATEGORIES_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {}
  };
};

exports.findAllContentBoxCategories = findAllContentBoxCategories;