"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _poiCategories = require("./poi-categories.actions");

/**
 * Created by EHelbing on 16.11.2017.
 */
var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _poiCategories.FIND_POI_CATEGORIES_SUCCESS:
      {
        return action.body.poiCategories;
      }

    case _poiCategories.DELETE_POI_CATEGORY_ERROR:
    case _poiCategories.CREATE_POI_CATEGORY_SUCCESS:
      {
        var newState = state.slice(); // check if the poi category exists in the action

        if (action.body.poiCategory) {
          // if the state has no poi categories yet, we can push the poi without checking for its existence
          // else we check if the poi category already exists in the state
          if (newState.length === 0 || newState.find(function (poiCategory) {
            return poiCategory.id === action.body.poiCategory.id;
          }) === undefined) {
            newState.push(action.body.poiCategory);
          }
        }

        return newState;
      }

    case _poiCategories.UPDATE_POI_CATEGORY_SUCCESS:
      {
        var _newState = state.slice(); // check if the poi category exists in the action


        if (action.body.poiCategory) {
          // check if the poi category already exists in the state
          var oldPOICategory = _newState.find(function (poiCategory) {
            return poiCategory.id === action.body.poiCategory.id;
          });

          if (oldPOICategory !== undefined) {
            var indexOfPOICategory = _newState.indexOf(oldPOICategory);

            _newState[indexOfPOICategory] = action.body.poiCategory;
          }
        }

        return _newState;
      }

    case _poiCategories.DELETE_POI_CATEGORY_SUCCESS:
    case _poiCategories.DELETE_POI_CATEGORY_REQUEST:
      {
        var _newState2 = state.slice(); // get the poi category id


        var poiCategoryId = action.body.id || action.body.poiCategory.id; // remove the deleted poi category

        return _newState2.filter(function (poiCategory) {
          return poiCategory.id !== poiCategoryId;
        });
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return [];

    default:
      return state;
  }
};

exports["default"] = _default;