"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ACTIVATE_USER_SUCCESS = exports.ACTIVATE_USER_REQUEST = exports.ACTIVATE_USER_ERROR = void 0;
exports.activateUser = activateUser;

/**
 * The string identifier for the request to activate a user.
 * @type {string}
 */
var ACTIVATE_USER_REQUEST = 'ACTIVATE_USER_REQUEST';
/**
 * The string identifier for the successful response type.
 * @type {string}
 */

exports.ACTIVATE_USER_REQUEST = ACTIVATE_USER_REQUEST;
var ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
/**
 * The string identifier for the error response type.
 * @type {string}
 */

exports.ACTIVATE_USER_SUCCESS = ACTIVATE_USER_SUCCESS;
var ACTIVATE_USER_ERROR = 'ACTIVATE_USER_ERROR';
/**
 * Defines and returns the action that can be dispathed to activate a user
 * @param {string} email the email of the user to activate
 * @param {string} userToken the confirmation token of the user
 * @param {string} newPassword the password to set choosen by the user
 */

exports.ACTIVATE_USER_ERROR = ACTIVATE_USER_ERROR;

function activateUser(email, userToken, newPassword) {
  return {
    type: ACTIVATE_USER_REQUEST,
    headers: {
      version: 'v1',
      scope: 'BASE'
    },
    body: {
      user: {
        email: email,
        userToken: userToken,
        newPassword: newPassword
      }
    }
  };
}