"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Autocomplete", {
  enumerable: true,
  get: function get() {
    return _autocomplete["default"];
  }
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _typedAutocomplete["default"];
  }
});

var _autocomplete = _interopRequireDefault(require("./autocomplete"));

var _typedAutocomplete = _interopRequireDefault(require("./typed-autocomplete"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }