"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.disconnectPortalsFromAdminTool = exports.deletePortal = exports.createPortalExport = exports.createPortal = exports.connectPortalsFromAdminTool = exports.UPDATE_PORTAL_SUCCESS = exports.UPDATE_PORTAL_REQUEST = exports.UPDATE_PORTAL_ERROR = exports.UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_SUCCESS = exports.UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_REQUEST = exports.UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_ERROR = exports.LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_SUCCESS = exports.LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_REQUEST = exports.LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_ERROR = exports.GET_LINKED_PORTALS_FOR_PORTAL_SUCCESS = exports.GET_LINKED_PORTALS_FOR_PORTAL_REQUEST = exports.GET_LINKED_PORTALS_FOR_PORTAL_ERROR = exports.FIND_PORTALS_BY_FILTER_SUCCESS = exports.FIND_PORTALS_BY_FILTER_REQUEST = exports.FIND_PORTALS_BY_FILTER_ERROR = exports.FIND_ASSIGNABLE_PERMISSION_PACKAGES_SUCCESS = exports.FIND_ASSIGNABLE_PERMISSION_PACKAGES_REQUEST = exports.FIND_ASSIGNABLE_PERMISSION_PACKAGES_ERROR = exports.FIND_ALL_PORTAL_USERS_SUCCSS = exports.FIND_ALL_PORTAL_USERS_REQUEST = exports.FIND_ALL_PORTAL_USERS_ERROR = exports.DISCONNECT_PORTALS_FROM_ADMIN_TOOL_SUCCESS = exports.DISCONNECT_PORTALS_FROM_ADMIN_TOOL_REQUEST = exports.DISCONNECT_PORTALS_FROM_ADMIN_TOOL_ERROR = exports.DELETE_PORTAL_SUCCESS = exports.DELETE_PORTAL_REQUEST = exports.DELETE_PORTAL_ERROR = exports.CREATE_PORTAL_SUCCESS = exports.CREATE_PORTAL_REQUEST = exports.CREATE_PORTAL_EXPORT_SUCCESS = exports.CREATE_PORTAL_EXPORT_REQUEST = exports.CREATE_PORTAL_EXPORT_ERROR = exports.CREATE_PORTAL_ERROR = exports.CONNECT_PORTALS_FROM_ADMIN_TOOL_SUCCESS = exports.CONNECT_PORTALS_FROM_ADMIN_TOOL_REQUEST = exports.CONNECT_PORTALS_FROM_ADMIN_TOOL_ERROR = void 0;
exports.findAllPortalUsers = findAllPortalUsers;
exports.updatePortal = exports.updateCustomTourPropertiesForPortal = exports.loadCustomTourPropertiesForPortal = exports.getLinkedPortalsForPortal = exports.findPortalsByFilter = exports.findAssignablePermissionPackages = void 0;
var FIND_ASSIGNABLE_PERMISSION_PACKAGES_REQUEST = 'FIND_ASSIGNABLE_PERMISSION_PACKAGES_REQUEST';
exports.FIND_ASSIGNABLE_PERMISSION_PACKAGES_REQUEST = FIND_ASSIGNABLE_PERMISSION_PACKAGES_REQUEST;
var FIND_ASSIGNABLE_PERMISSION_PACKAGES_SUCCESS = 'FIND_ASSIGNABLE_PERMISSION_PACKAGES_SUCCESS';
exports.FIND_ASSIGNABLE_PERMISSION_PACKAGES_SUCCESS = FIND_ASSIGNABLE_PERMISSION_PACKAGES_SUCCESS;
var FIND_ASSIGNABLE_PERMISSION_PACKAGES_ERROR = 'FIND_ASSIGNABLE_PERMISSION_PACKAGES_ERROR';
exports.FIND_ASSIGNABLE_PERMISSION_PACKAGES_ERROR = FIND_ASSIGNABLE_PERMISSION_PACKAGES_ERROR;

var findAssignablePermissionPackages = function findAssignablePermissionPackages() {
  return {
    type: FIND_ASSIGNABLE_PERMISSION_PACKAGES_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {}
  };
};

exports.findAssignablePermissionPackages = findAssignablePermissionPackages;
var FIND_PORTALS_BY_FILTER_REQUEST = 'FIND_PORTALS_BY_FILTER_REQUEST';
exports.FIND_PORTALS_BY_FILTER_REQUEST = FIND_PORTALS_BY_FILTER_REQUEST;
var FIND_PORTALS_BY_FILTER_SUCCESS = 'FIND_PORTALS_BY_FILTER_SUCCESS';
exports.FIND_PORTALS_BY_FILTER_SUCCESS = FIND_PORTALS_BY_FILTER_SUCCESS;
var FIND_PORTALS_BY_FILTER_ERROR = 'FIND_PORTALS_BY_FILTER_ERROR';
exports.FIND_PORTALS_BY_FILTER_ERROR = FIND_PORTALS_BY_FILTER_ERROR;

var findPortalsByFilter = function findPortalsByFilter(dynamicFilters) {
  return {
    type: FIND_PORTALS_BY_FILTER_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      dynamicPaging: null,
      dynamicSorters: null,
      dynamicFilters: dynamicFilters
    }
  };
};

exports.findPortalsByFilter = findPortalsByFilter;
var CREATE_PORTAL_REQUEST = 'CREATE_PORTAL_REQUEST';
exports.CREATE_PORTAL_REQUEST = CREATE_PORTAL_REQUEST;
var CREATE_PORTAL_SUCCESS = 'CREATE_PORTAL_SUCCESS';
exports.CREATE_PORTAL_SUCCESS = CREATE_PORTAL_SUCCESS;
var CREATE_PORTAL_ERROR = 'CREATE_PORTAL_ERROR';
exports.CREATE_PORTAL_ERROR = CREATE_PORTAL_ERROR;

var createPortal = function createPortal(portal) {
  return {
    type: CREATE_PORTAL_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      portal: portal
    }
  };
};

exports.createPortal = createPortal;
var UPDATE_PORTAL_REQUEST = 'UPDATE_PORTAL_REQUEST';
exports.UPDATE_PORTAL_REQUEST = UPDATE_PORTAL_REQUEST;
var UPDATE_PORTAL_SUCCESS = 'UPDATE_PORTAL_SUCCESS';
exports.UPDATE_PORTAL_SUCCESS = UPDATE_PORTAL_SUCCESS;
var UPDATE_PORTAL_ERROR = 'UPDATE_PORTAL_ERROR';
exports.UPDATE_PORTAL_ERROR = UPDATE_PORTAL_ERROR;

var updatePortal = function updatePortal(portal) {
  return {
    type: UPDATE_PORTAL_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      portal: portal
    }
  };
};

exports.updatePortal = updatePortal;
var DELETE_PORTAL_REQUEST = 'DELETE_PORTAL_REQUEST';
exports.DELETE_PORTAL_REQUEST = DELETE_PORTAL_REQUEST;
var DELETE_PORTAL_SUCCESS = 'DELETE_PORTAL_SUCCESS';
exports.DELETE_PORTAL_SUCCESS = DELETE_PORTAL_SUCCESS;
var DELETE_PORTAL_ERROR = 'DELETE_PORTAL_ERROR';
exports.DELETE_PORTAL_ERROR = DELETE_PORTAL_ERROR;

var deletePortal = function deletePortal(portalId) {
  return {
    type: DELETE_PORTAL_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      portalId: portalId
    }
  };
};

exports.deletePortal = deletePortal;
var CREATE_PORTAL_EXPORT_REQUEST = 'CREATE_PORTAL_EXPORT_REQUEST';
exports.CREATE_PORTAL_EXPORT_REQUEST = CREATE_PORTAL_EXPORT_REQUEST;
var CREATE_PORTAL_EXPORT_SUCCESS = 'CREATE_PORTAL_EXPORT_SUCCESS';
exports.CREATE_PORTAL_EXPORT_SUCCESS = CREATE_PORTAL_EXPORT_SUCCESS;
var CREATE_PORTAL_EXPORT_ERROR = 'CREATE_PORTAL_EXPORT_ERROR';
exports.CREATE_PORTAL_EXPORT_ERROR = CREATE_PORTAL_EXPORT_ERROR;

var createPortalExport = function createPortalExport() {
  var dynamicFilters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var dynamicSorters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return {
    type: CREATE_PORTAL_EXPORT_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      filters: {
        dynamicFilters: dynamicFilters,
        dynamicSorters: dynamicSorters,
        dynamicPaging: null
      }
    }
  };
};

exports.createPortalExport = createPortalExport;
var FIND_ALL_PORTAL_USERS_REQUEST = 'FIND_ALL_PORTAL_USERS_REQUEST';
exports.FIND_ALL_PORTAL_USERS_REQUEST = FIND_ALL_PORTAL_USERS_REQUEST;
var FIND_ALL_PORTAL_USERS_SUCCSS = 'FIND_ALL_PORTAL_USERS_SUCCESS';
exports.FIND_ALL_PORTAL_USERS_SUCCSS = FIND_ALL_PORTAL_USERS_SUCCSS;
var FIND_ALL_PORTAL_USERS_ERROR = 'FIND_ALL_PORTAL_USERS_ERROR';
exports.FIND_ALL_PORTAL_USERS_ERROR = FIND_ALL_PORTAL_USERS_ERROR;

function findAllPortalUsers(portalId) {
  return {
    type: FIND_ALL_PORTAL_USERS_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      portalId: portalId
    }
  };
}

var GET_LINKED_PORTALS_FOR_PORTAL_REQUEST = 'GET_LINKED_PORTALS_FOR_PORTAL_REQUEST';
exports.GET_LINKED_PORTALS_FOR_PORTAL_REQUEST = GET_LINKED_PORTALS_FOR_PORTAL_REQUEST;
var GET_LINKED_PORTALS_FOR_PORTAL_SUCCESS = 'GET_LINKED_PORTALS_FOR_PORTAL_SUCCESS';
exports.GET_LINKED_PORTALS_FOR_PORTAL_SUCCESS = GET_LINKED_PORTALS_FOR_PORTAL_SUCCESS;
var GET_LINKED_PORTALS_FOR_PORTAL_ERROR = 'GET_LINKED_PORTALS_FOR_PORTAL_ERROR';
exports.GET_LINKED_PORTALS_FOR_PORTAL_ERROR = GET_LINKED_PORTALS_FOR_PORTAL_ERROR;

var getLinkedPortalsForPortal = function getLinkedPortalsForPortal(portalId) {
  return {
    type: GET_LINKED_PORTALS_FOR_PORTAL_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      portalId: portalId
    }
  };
};

exports.getLinkedPortalsForPortal = getLinkedPortalsForPortal;
var CONNECT_PORTALS_FROM_ADMIN_TOOL_REQUEST = 'CONNECT_PORTALS_FROM_ADMIN_TOOL_REQUEST';
exports.CONNECT_PORTALS_FROM_ADMIN_TOOL_REQUEST = CONNECT_PORTALS_FROM_ADMIN_TOOL_REQUEST;
var CONNECT_PORTALS_FROM_ADMIN_TOOL_SUCCESS = 'CONNECT_PORTALS_FROM_ADMIN_TOOL_SUCCESS';
exports.CONNECT_PORTALS_FROM_ADMIN_TOOL_SUCCESS = CONNECT_PORTALS_FROM_ADMIN_TOOL_SUCCESS;
var CONNECT_PORTALS_FROM_ADMIN_TOOL_ERROR = 'CONNECT_PORTALS_FROM_ADMIN_TOOL_ERROR';
exports.CONNECT_PORTALS_FROM_ADMIN_TOOL_ERROR = CONNECT_PORTALS_FROM_ADMIN_TOOL_ERROR;

var connectPortalsFromAdminTool = function connectPortalsFromAdminTool(fromPortalId, toPortalId) {
  return {
    type: CONNECT_PORTALS_FROM_ADMIN_TOOL_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      connection: {
        fromPortalId: fromPortalId,
        toPortalId: toPortalId
      }
    }
  };
};

exports.connectPortalsFromAdminTool = connectPortalsFromAdminTool;
var DISCONNECT_PORTALS_FROM_ADMIN_TOOL_REQUEST = 'DISCONNECT_PORTALS_FROM_ADMIN_TOOL_REQUEST';
exports.DISCONNECT_PORTALS_FROM_ADMIN_TOOL_REQUEST = DISCONNECT_PORTALS_FROM_ADMIN_TOOL_REQUEST;
var DISCONNECT_PORTALS_FROM_ADMIN_TOOL_SUCCESS = 'DISCONNECT_PORTALS_FROM_ADMIN_TOOL_SUCCESS';
exports.DISCONNECT_PORTALS_FROM_ADMIN_TOOL_SUCCESS = DISCONNECT_PORTALS_FROM_ADMIN_TOOL_SUCCESS;
var DISCONNECT_PORTALS_FROM_ADMIN_TOOL_ERROR = 'DISCONNECT_PORTALS_FROM_ADMIN_TOOL_ERROR';
exports.DISCONNECT_PORTALS_FROM_ADMIN_TOOL_ERROR = DISCONNECT_PORTALS_FROM_ADMIN_TOOL_ERROR;

var disconnectPortalsFromAdminTool = function disconnectPortalsFromAdminTool(fromPortalId, toPortalId) {
  return {
    type: DISCONNECT_PORTALS_FROM_ADMIN_TOOL_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      connection: {
        fromPortalId: fromPortalId,
        toPortalId: toPortalId
      }
    }
  };
};

exports.disconnectPortalsFromAdminTool = disconnectPortalsFromAdminTool;
var LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_REQUEST = 'LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_REQUEST';
exports.LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_REQUEST = LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_REQUEST;
var LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_SUCCESS = 'LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_SUCCESS';
exports.LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_SUCCESS = LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_SUCCESS;
var LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_ERROR = 'LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_ERROR';
exports.LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_ERROR = LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_ERROR;

var loadCustomTourPropertiesForPortal = function loadCustomTourPropertiesForPortal(portalId) {
  return {
    type: LOAD_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      portalId: portalId
    }
  };
};

exports.loadCustomTourPropertiesForPortal = loadCustomTourPropertiesForPortal;
var UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_REQUEST = 'UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_REQUEST';
exports.UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_REQUEST = UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_REQUEST;
var UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_SUCCESS = 'UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_SUCCESS';
exports.UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_SUCCESS = UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_SUCCESS;
var UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_ERROR = 'UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_ERROR';
exports.UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_ERROR = UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_ERROR;

var updateCustomTourPropertiesForPortal = function updateCustomTourPropertiesForPortal(portalId, properties) {
  return {
    type: UPDATE_CUSTOM_TOUR_PROPERTIES_FOR_PORTAL_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      portalId: portalId,
      properties: properties
    }
  };
};

exports.updateCustomTourPropertiesForPortal = updateCustomTourPropertiesForPortal;