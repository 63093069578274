"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _types = require("@nicbase/types");

var _nicbaseIcons = require("nicbase-icons");

var _ebsErrorCodeIcon;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ebsErrorCodeIcon = (_ebsErrorCodeIcon = {}, _defineProperty(_ebsErrorCodeIcon, _types.LatestDataErrorCodeSeverity.Alert, _nicbaseIcons.DtcAlarm), _defineProperty(_ebsErrorCodeIcon, _types.LatestDataErrorCodeSeverity.Caution, _nicbaseIcons.DtcCaution), _defineProperty(_ebsErrorCodeIcon, _types.LatestDataErrorCodeSeverity.Notification, _nicbaseIcons.DtcNotification), _defineProperty(_ebsErrorCodeIcon, _types.LatestDataErrorCodeSeverity.Information, _nicbaseIcons.DtcNotification), _defineProperty(_ebsErrorCodeIcon, _types.LatestDataErrorCodeSeverity.Unknown, null), _ebsErrorCodeIcon);

var ebsErrorCodeToIcon = function ebsErrorCodeToIcon(severity) {
  if (!severity || typeof severity !== 'string') return null;
  var icon = ebsErrorCodeIcon[severity.toUpperCase()];

  if (icon === undefined) {
    console.error("Ebs error code mapping - Missing icon for ".concat(severity));
    icon = null;
  }

  return icon;
};

var _default = ebsErrorCodeToIcon;
exports["default"] = _default;