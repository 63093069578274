"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = settingsReducer;

var _activePortal = require("../activePortal");

var _tempChart = require("./temp-chart.actions");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function settingsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _tempChart.TEMPERATURE_HISTORY_DATA_SUCCESS:
      {
        var data = action.body.data;
        var widgetId = action.headers.widgetId;
        return Object.assign({}, state, _defineProperty({}, widgetId, data));
      }

    case _tempChart.TEMPERATURE_HISTORY_DATA_ERROR:
      {
        var _widgetId = action.headers.widgetId;
        return Object.assign({}, state, _defineProperty({}, _widgetId, []));
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return {};

    default:
      return state;
  }
}