"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _workspaces = require("../workspaces/workspaces.actions");

/**
 * Created by PKing on 16.08.2016.
 */
function activeWorkspaceId() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _workspaces.ADD_WORKSPACE_SUCCESS:
      return action.body.workspace.id;

    case _activePortal.CHANGE_ACTIVE_PORTAL:
    case _workspaces.REMOVE_WORKSPACE_SUCCESS:
      return 0;

    default:
      return state;
  }
}

var _default = activeWorkspaceId;
exports["default"] = _default;