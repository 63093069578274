"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _portalAdministration = require("./portalAdministration.actions");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _portalAdministration.FIND_ALL_PORTAL_USERS_SUCCSS:
      {
        var _action$body = action.body,
            portalId = _action$body.portalId,
            users = _action$body.users;
        return Object.assign({}, state, _defineProperty({}, portalId, users));
      }

    default:
      return state;
  }
};

exports["default"] = _default;