"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllDamageReporters = exports.FIND_DAMAGE_REPORTER_CATEGORIES_SUCCESS = exports.FIND_DAMAGE_REPORTER_CATEGORIES_REQUEST = void 0;
var FIND_DAMAGE_REPORTER_CATEGORIES_REQUEST = 'FIND_DAMAGE_REPORTER_CATEGORIES_REQUEST';
exports.FIND_DAMAGE_REPORTER_CATEGORIES_REQUEST = FIND_DAMAGE_REPORTER_CATEGORIES_REQUEST;
var FIND_DAMAGE_REPORTER_CATEGORIES_SUCCESS = 'FIND_DAMAGE_REPORTER_CATEGORIES_SUCCESS';
exports.FIND_DAMAGE_REPORTER_CATEGORIES_SUCCESS = FIND_DAMAGE_REPORTER_CATEGORIES_SUCCESS;

var getAllDamageReporters = function getAllDamageReporters(portalId) {
  return {
    type: FIND_DAMAGE_REPORTER_CATEGORIES_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    }
  };
};

exports.getAllDamageReporters = getAllDamageReporters;