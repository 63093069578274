"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVehiclesForRentalTool = exports["default"] = void 0;

var _reselect = require("reselect");

var _rentalTool = require("./rental-tool.actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var allVehicles = function allVehicles() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _rentalTool.FIND_VEHICLES_FOR_RENTAL_TOOL_REQUEST:
      {
        return [];
      }

    case _rentalTool.FIND_VEHICLES_FOR_RENTAL_TOOL_SUCCESS:
    case _rentalTool.RENT_VEHICLES_SUCCESS:
    case _rentalTool.CANCEL_VEHICLE_RENTALS_SUCCESS:
      {
        var _action$body$vehicles = action.body.vehicles,
            vehicles = _action$body$vehicles === void 0 ? [] : _action$body$vehicles;
        var newState = vehicles.reduce(function (result, vehicle) {
          if (!state.some(function (obj) {
            return obj === vehicle.id;
          })) {
            result.push(vehicle.id);
          }

          return result;
        }, []);
        return [].concat(_toConsumableArray(state), _toConsumableArray(newState));
      }

    case _rentalTool.UPDATE_RENTAL_DETAILS_SUCCESS:
      {
        var vehicle = action.body.vehicle;

        if (!state.some(function (obj) {
          return obj === vehicle.id;
        })) {
          return [].concat(_toConsumableArray(state), [vehicle.id]);
        }

        return state;
      }

    default:
      {
        return state;
      }
  }
};

var vehiclesById = function vehiclesById() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _rentalTool.FIND_VEHICLES_FOR_RENTAL_TOOL_REQUEST:
      {
        return {};
      }

    case _rentalTool.FIND_VEHICLES_FOR_RENTAL_TOOL_SUCCESS:
    case _rentalTool.RENT_VEHICLES_SUCCESS:
    case _rentalTool.CANCEL_VEHICLE_RENTALS_SUCCESS:
      {
        var _action$body$vehicles2 = action.body.vehicles,
            vehicles = _action$body$vehicles2 === void 0 ? [] : _action$body$vehicles2;
        var newState = vehicles.reduce(function (result, vehicle) {
          var existingVehicle = state[vehicle.id];
          result[vehicle.id] = _objectSpread(_objectSpread({}, existingVehicle), vehicle);
          return result;
        }, {});
        return _objectSpread(_objectSpread({}, state), newState);
      }

    case _rentalTool.UPDATE_RENTAL_DETAILS_SUCCESS:
      {
        var vehicle = action.body.vehicle;
        var existingVehicle = state[vehicle.id];
        var _newState = {};
        _newState[vehicle.id] = _objectSpread(_objectSpread(_objectSpread({}, existingVehicle), vehicle), {}, {
          rental: _objectSpread(_objectSpread({}, existingVehicle.rental), vehicle.rental)
        });
        return _objectSpread(_objectSpread({}, state), _newState);
      }

    default:
      {
        return state;
      }
  }
};

var getVehiclesForRentalTool = (0, _reselect.createSelector)([function (state) {
  return state.rentalTool.allIds;
}, function (state) {
  return state.rentalTool.byId;
}], function (allIds, vehicles) {
  return allIds.map(function (id) {
    return vehicles[id];
  });
});
exports.getVehiclesForRentalTool = getVehiclesForRentalTool;
var _default = {
  allIds: allVehicles,
  byId: vehiclesById
};
exports["default"] = _default;