"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PostSetupStrategyType = exports.BoxChangeStrategy = void 0;
var PostSetupStrategyType;
exports.PostSetupStrategyType = PostSetupStrategyType;

(function (PostSetupStrategyType) {
  PostSetupStrategyType["Permanent"] = "PERMANENT";
  PostSetupStrategyType["Tours"] = "TOURS";
  PostSetupStrategyType["Rental"] = "RENTAL";
  PostSetupStrategyType["AddToAssetGroup"] = "ADD_TO_ASSET_GROUP";
})(PostSetupStrategyType || (exports.PostSetupStrategyType = PostSetupStrategyType = {}));

var BoxChangeStrategy;
exports.BoxChangeStrategy = BoxChangeStrategy;

(function (BoxChangeStrategy) {
  BoxChangeStrategy["WagonNumber"] = "WagonNumber";
  BoxChangeStrategy["AssetName"] = "AssetName";
})(BoxChangeStrategy || (exports.BoxChangeStrategy = BoxChangeStrategy = {}));