"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findPermissionPackagesByFilter = exports.FIND_PERMISSION_PACKAGES_SUCCESS = exports.FIND_PERMISSION_PACKAGES_REQUEST = exports.FIND_PERMISSION_PACKAGES_ERROR = void 0;
var FIND_PERMISSION_PACKAGES_REQUEST = 'FIND_PERMISSION_PACKAGES_REQUEST';
exports.FIND_PERMISSION_PACKAGES_REQUEST = FIND_PERMISSION_PACKAGES_REQUEST;
var FIND_PERMISSION_PACKAGES_SUCCESS = 'FIND_PERMISSION_PACKAGES_SUCCESS';
exports.FIND_PERMISSION_PACKAGES_SUCCESS = FIND_PERMISSION_PACKAGES_SUCCESS;
var FIND_PERMISSION_PACKAGES_ERROR = 'FIND_PERMISSION_PACKAGES_ERROR';
exports.FIND_PERMISSION_PACKAGES_ERROR = FIND_PERMISSION_PACKAGES_ERROR;

var findPermissionPackagesByFilter = function findPermissionPackagesByFilter(dynamicFilters) {
  return {
    type: FIND_PERMISSION_PACKAGES_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      dynamicPaging: null,
      dynamicSorters: null,
      dynamicFilters: dynamicFilters
    }
  };
};

exports.findPermissionPackagesByFilter = findPermissionPackagesByFilter;