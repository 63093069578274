"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _providers = require("./providers.actions");

var providersReducer = function providersReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _providers.FIND_PROVIDERS_SUCCESS:
      {
        var providers = action.body.providers || [];
        var newState = {};
        providers.forEach(function (provider) {
          newState[provider.id] = {
            id: provider.id,
            name: provider.name
          };
        });
        return newState;
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return {};

    default:
      return state;
  }
};

var _default = providersReducer;
exports["default"] = _default;