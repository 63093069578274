"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllPoiInfos = exports["default"] = void 0;

var _reselect = require("reselect");

var _activePortal = require("../activePortal");

var _pois = require("../pois/pois.actions");

var _poiInformationList = require("./poi-information-list.actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _poiInformationList.ADD_POI_SUCCESS:
      {
        var _action$body = action.body,
            body = _action$body === void 0 ? {} : _action$body;
        var _body$poi = body.poi,
            poi = _body$poi === void 0 ? {} : _body$poi;

        if (!state.some(function (oldPoi) {
          return oldPoi.id === poi.id;
        })) {
          return [].concat(_toConsumableArray(state), [poi]);
        }

        return state.map(function (oldPoi) {
          if (oldPoi.id === poi.id) {
            return _objectSpread(_objectSpread({}, oldPoi), poi);
          }

          return oldPoi;
        });
      }

    case _poiInformationList.REVOKE_POI_SUCCESS:
    case _pois.DELETE_POI_SUCCESS:
      {
        var _action$body2 = action.body,
            _body = _action$body2 === void 0 ? {} : _action$body2;

        var _body$poi2 = _body.poi,
            _poi = _body$poi2 === void 0 ? {} : _body$poi2;

        return state.filter(function (oldPoi) {
          return _poi.id !== oldPoi.id;
        });
      }

    case _poiInformationList.GET_POIS_SUCCESS:
      {
        return action.body.pois;
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
    case _poiInformationList.GET_POIS_ERROR:
      {
        return [];
      }

    default:
      {
        return state;
      }
  }
};

exports["default"] = _default;
var getAllPoiInfos = (0, _reselect.createSelector)([function (state) {
  return state.informationLists.pois;
}], function (pois) {
  return pois;
});
exports.getAllPoiInfos = getAllPoiInfos;