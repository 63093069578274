"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REVOKE_POI_SUCCESS = exports.REVOKE_POI_REQUEST = exports.REVOKE_POI_ERROR = exports.GET_POIS_SUCCESS = exports.GET_POIS_REQUEST = exports.GET_POIS_ERROR = exports.ADD_POI_SUCCESS = exports.ADD_POI_REQUEST = exports.ADD_POI_ERROR = void 0;
exports.getPoiInformationListFromBackend = getPoiInformationListFromBackend;
// POI List for Portal
var GET_POIS_REQUEST = 'GET_POIS_REQUEST';
exports.GET_POIS_REQUEST = GET_POIS_REQUEST;
var GET_POIS_ERROR = 'GET_POIS_ERROR';
exports.GET_POIS_ERROR = GET_POIS_ERROR;
var GET_POIS_SUCCESS = 'GET_POIS_SUCCESS';
exports.GET_POIS_SUCCESS = GET_POIS_SUCCESS;

function getPoiInformationListFromBackend(portalId) {
  var dynamicFilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var action = {
    type: GET_POIS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    }
  };

  if (dynamicFilters !== null) {
    action.body = {
      dynamicFilters: dynamicFilters,
      dynamicPaging: null,
      dynamicSorters: null
    };
  }

  return action;
}

var ADD_POI_REQUEST = 'ADD_POI_REQUEST';
exports.ADD_POI_REQUEST = ADD_POI_REQUEST;
var ADD_POI_ERROR = 'ADD_POI_ERROR';
exports.ADD_POI_ERROR = ADD_POI_ERROR;
var ADD_POI_SUCCESS = 'ADD_POI_SUCCESS';
exports.ADD_POI_SUCCESS = ADD_POI_SUCCESS;
var REVOKE_POI_REQUEST = 'REVOKE_POI_REQUEST';
exports.REVOKE_POI_REQUEST = REVOKE_POI_REQUEST;
var REVOKE_POI_ERROR = 'REVOKE_POI_ERROR';
exports.REVOKE_POI_ERROR = REVOKE_POI_ERROR;
var REVOKE_POI_SUCCESS = 'REVOKE_POI_SUCCESS';
exports.REVOKE_POI_SUCCESS = REVOKE_POI_SUCCESS;