"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendTwowayAccount = exports.findTwowayHistoryCommands = exports.findTwowayCommand = exports.SEND_TWOWAY_COMMAND_SUCCESS = exports.SEND_TWOWAY_COMMAND_REQUEST = exports.SEND_TWOWAY_COMMAND_ERROR = exports.FIND_TWOWAY_SYSTEMS_BY_ASSET_SUCCESS = exports.FIND_TWOWAY_SYSTEMS_BY_ASSET_REQUEST = exports.FIND_TWOWAY_SYSTEMS_BY_ASSET_ERROR = exports.FIND_TWOWAY_COMMANDS_BY_ASSET_SUCCESS = exports.FIND_TWOWAY_COMMANDS_BY_ASSET_REQUEST = exports.FIND_TWOWAY_COMMANDS_BY_ASSET_ERROR = void 0;
var FIND_TWOWAY_SYSTEMS_BY_ASSET_REQUEST = 'FIND_TWOWAY_SYSTEMS_BY_ASSET_REQUEST';
exports.FIND_TWOWAY_SYSTEMS_BY_ASSET_REQUEST = FIND_TWOWAY_SYSTEMS_BY_ASSET_REQUEST;
var FIND_TWOWAY_SYSTEMS_BY_ASSET_SUCCESS = 'FIND_TWOWAY_SYSTEMS_BY_ASSET_SUCCESS';
exports.FIND_TWOWAY_SYSTEMS_BY_ASSET_SUCCESS = FIND_TWOWAY_SYSTEMS_BY_ASSET_SUCCESS;
var FIND_TWOWAY_SYSTEMS_BY_ASSET_ERROR = 'FIND_TWOWAY_SYSTEMS_BY_ASSET_ERROR';
exports.FIND_TWOWAY_SYSTEMS_BY_ASSET_ERROR = FIND_TWOWAY_SYSTEMS_BY_ASSET_ERROR;

var findTwowayCommand = function findTwowayCommand(widgetId) {
  var dynamicFilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var portalId = arguments.length > 2 ? arguments[2] : undefined;
  return {
    type: FIND_TWOWAY_SYSTEMS_BY_ASSET_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      dynamicFilters: dynamicFilters,
      dynamicPaging: null,
      dynamicSorters: null
    }
  };
};

exports.findTwowayCommand = findTwowayCommand;
var SEND_TWOWAY_COMMAND_REQUEST = 'SEND_TWOWAY_COMMAND_REQUEST';
exports.SEND_TWOWAY_COMMAND_REQUEST = SEND_TWOWAY_COMMAND_REQUEST;
var SEND_TWOWAY_COMMAND_SUCCESS = 'SEND_TWOWAY_COMMAND_SUCCESS';
exports.SEND_TWOWAY_COMMAND_SUCCESS = SEND_TWOWAY_COMMAND_SUCCESS;
var SEND_TWOWAY_COMMAND_ERROR = 'SEND_TWOWAY_COMMAND_ERROR';
exports.SEND_TWOWAY_COMMAND_ERROR = SEND_TWOWAY_COMMAND_ERROR;

var sendTwowayAccount = function sendTwowayAccount(widgetId, commands, portalId) {
  return {
    type: SEND_TWOWAY_COMMAND_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: commands
  };
};

exports.sendTwowayAccount = sendTwowayAccount;
var FIND_TWOWAY_COMMANDS_BY_ASSET_REQUEST = 'FIND_TWOWAY_COMMANDS_BY_ASSET_REQUEST';
exports.FIND_TWOWAY_COMMANDS_BY_ASSET_REQUEST = FIND_TWOWAY_COMMANDS_BY_ASSET_REQUEST;
var FIND_TWOWAY_COMMANDS_BY_ASSET_SUCCESS = 'FIND_TWOWAY_COMMANDS_BY_ASSET_SUCCESS';
exports.FIND_TWOWAY_COMMANDS_BY_ASSET_SUCCESS = FIND_TWOWAY_COMMANDS_BY_ASSET_SUCCESS;
var FIND_TWOWAY_COMMANDS_BY_ASSET_ERROR = 'FIND_TWOWAY_COMMANDS_BY_ASSET_ERROR';
exports.FIND_TWOWAY_COMMANDS_BY_ASSET_ERROR = FIND_TWOWAY_COMMANDS_BY_ASSET_ERROR;

var findTwowayHistoryCommands = function findTwowayHistoryCommands(widgetId) {
  var dynamicFilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var portalId = arguments.length > 2 ? arguments[2] : undefined;
  return {
    type: FIND_TWOWAY_COMMANDS_BY_ASSET_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId,
      widgetId: widgetId
    },
    body: {
      dynamicFilters: dynamicFilters,
      dynamicPaging: null,
      dynamicSorters: null
    }
  };
};

exports.findTwowayHistoryCommands = findTwowayHistoryCommands;