"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _assets = require("./assets.actions");

var INITIAL_STATE = [];
/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _assets.FIND_ASSETS_FOR_USER_SUCCESS:
      {
        var assets = action.body.assets;
        return assets;
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return INITIAL_STATE;

    default:
      return state;
  }
};

exports["default"] = _default;