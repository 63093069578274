"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FETCH_TOUR_DATA_SUCCESS = exports.FETCH_TOUR_DATA_STARTED = exports.FETCH_TOUR_DATA_ERROR = void 0;
exports.fetchTourViewData = fetchTourViewData;
var FETCH_TOUR_DATA_STARTED = 'FETCH_TOUR_DATA_STARTED';
exports.FETCH_TOUR_DATA_STARTED = FETCH_TOUR_DATA_STARTED;
var FETCH_TOUR_DATA_SUCCESS = 'FETCH_TOUR_DATA_SUCCESS';
exports.FETCH_TOUR_DATA_SUCCESS = FETCH_TOUR_DATA_SUCCESS;
var FETCH_TOUR_DATA_ERROR = 'FETCH_TOUR_DATA_ERROR';
exports.FETCH_TOUR_DATA_ERROR = FETCH_TOUR_DATA_ERROR;

function fetchTourViewData(tourToken) {
  return function (dispatch) {
    var url = new URL(ENV.tourDataViewRestAPI);
    var params = {
      tourToken: tourToken
    };
    Object.keys(params).forEach(function (key) {
      return url.searchParams.append(key, params[key]);
    });
    return fetch(url, {
      cache: 'no-cache'
    }).then(function (response) {
      if (response.ok) {
        return response.json();
      }

      throw new Error(response.statusText);
    }).then(function (data) {
      var tour = data.tour,
          latestData = data.latestData;
      return dispatch({
        type: FETCH_TOUR_DATA_SUCCESS,
        body: {
          tour: tour,
          latestData: latestData
        }
      });
    })["catch"](function () {
      return dispatch({
        type: FETCH_TOUR_DATA_ERROR
      });
    });
  };
}