"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = numberValidation;

/**
 * Created by SWensauer on 09.10.2017.
 */
function numberValidation(value) {
  // eslint-disable-next-line
  var number = new RegExp(/^\d+([\,]\d+)*([\.]\d+)?$/);
  return number.test(value);
}