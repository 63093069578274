import React from 'react';
import loadable from 'react-loadable';
import { connect } from 'react-redux';
import { NavLink, Route, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { useRequest } from '@nicbase/request';
import { Dashboard, Home } from 'nicbase-icons';
import { injectIntl, intlShape } from 'nicbase-translation';

import activePortalSelector from './activePortalSelector';
import AdminToolLink from './admin-tool-button';

import './nav-bar.scss';

export const mapStateToProps = state => ({
  activePortal: activePortalSelector(state),
});

export const PortalSelection = loadable({
  loader: () => import('nicbase-dashboard'),
  loading: () => null,
  render(loaded, props) {
    const { PortalSelectionLink } = loaded;
    return <PortalSelectionLink {...props} />;
  },
});

const NavBarLeftDesktop = ({ intl, responsiveMode, activePortal }) => {
  const { isImpersonated } = useRequest();

  return (
    <div key="navBarLeft" className="nav-bar-left nav-bar desktop">
      <NavLink to="/" className="icon home-icon" data-for="tool-tip-right" data-tip={intl.formatMessage({ id: 'navigation.home' })}>
        <span className="pointer-events-none" title={intl.formatMessage({ id: 'navigation.home' })}>
          <Home className="mx-auto" />
        </span>
        <Route exact path="/" render={() => <div className="arrow" />} />
      </NavLink>
      <PortalSelection className="icon" />
      <NavLink
        to={`/dashboard/${activePortal.id}/workspace`}
        className={`icon dashboard-icon ${!activePortal.id ? 'hidden' : ''}`}
        data-for="tool-tip-right"
        data-tip={`${activePortal.name || intl.formatMessage({ id: 'navigation.workspace' })}`}
      >
        <span className="pointer-events-none" title={activePortal.name || intl.formatMessage({ id: 'navigation.workspace' })}>
          <Dashboard className="mx-auto" />
        </span>
        <Route path="/dashboard" render={() => <div className="arrow" />} />
      </NavLink>
      {!isImpersonated && <AdminToolLink key={Date.now()} responsiveMode={responsiveMode} />}
      <ReactTooltip id="tool-tip-right" place="right" type="dark" effect="solid" />
      <ReactTooltip id="tool-tip-bottom" place="bottom" type="dark" effect="solid" />
    </div>
  );
};

NavBarLeftDesktop.propTypes = {
  activePortal: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  intl: intlShape.isRequired,
  responsiveMode: PropTypes.shape({
    detectedMode: PropTypes.string,
  }),
};

NavBarLeftDesktop.defaultProps = {
  activePortal: {},
  responsiveMode: {},
};

export default withRouter(connect(mapStateToProps)(injectIntl(NavBarLeftDesktop)));
