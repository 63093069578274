"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDamageReporters = exports.getDamageReporterCategories = exports["default"] = void 0;

var _reselect = require("reselect");

var _reporters = require("./reporters.actions");

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    categories: [],
    reporters: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _reporters.FIND_DAMAGE_REPORTER_CATEGORIES_REQUEST:
      return {
        categories: [],
        reporters: {}
      };

    case _reporters.FIND_DAMAGE_REPORTER_CATEGORIES_SUCCESS:
      {
        var categories = action.body.categories;
        return {
          categories: Object.keys(categories),
          reporters: categories
        };
      }

    default:
      return state;
  }
};

exports["default"] = _default;
var getDamageReporters = (0, _reselect.createSelector)([function (state) {
  return state.damageReporters.reporters;
}], function (reporters) {
  return reporters;
});
exports.getDamageReporters = getDamageReporters;
var getDamageReporterCategories = (0, _reselect.createSelector)([function (state) {
  return state.damageReporters.categories;
}], function (categories) {
  return categories;
});
exports.getDamageReporterCategories = getDamageReporterCategories;