"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _portalAdministration = require("./portalAdministration.actions");

/* eslint-disable no-param-reassign */
var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _portalAdministration.FIND_ASSIGNABLE_PERMISSION_PACKAGES_SUCCESS:
      {
        var _action$body$permissi = action.body.permissionPackages,
            permissionPackages = _action$body$permissi === void 0 ? [] : _action$body$permissi;
        return permissionPackages;
      }

    default:
      return state;
  }
};

exports["default"] = _default;