"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FIND_ALL_TEMPLATES_SUCCESS = exports.FIND_ALL_TEMPLATES_REQUEST = exports.FIND_ALL_TEMPLATES_ERROR = exports.DELETE_TEMPLATE_SUCCESS = exports.DELETE_TEMPLATE_REQUEST = exports.DELETE_TEMPLATE_ERROR = void 0;
exports.deleteWorkspaceTemplate = deleteWorkspaceTemplate;
exports.findAllTemplates = findAllTemplates;
var FIND_ALL_TEMPLATES_REQUEST = 'FIND_ALL_TEMPLATES_REQUEST';
exports.FIND_ALL_TEMPLATES_REQUEST = FIND_ALL_TEMPLATES_REQUEST;
var FIND_ALL_TEMPLATES_SUCCESS = 'FIND_ALL_TEMPLATES_SUCCESS';
exports.FIND_ALL_TEMPLATES_SUCCESS = FIND_ALL_TEMPLATES_SUCCESS;
var FIND_ALL_TEMPLATES_ERROR = 'FIND_ALL_TEMPLATES_ERROR';
exports.FIND_ALL_TEMPLATES_ERROR = FIND_ALL_TEMPLATES_ERROR;

function findAllTemplates(portalId) {
  return {
    type: FIND_ALL_TEMPLATES_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {}
  };
}

var DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
exports.DELETE_TEMPLATE_REQUEST = DELETE_TEMPLATE_REQUEST;
var DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
exports.DELETE_TEMPLATE_SUCCESS = DELETE_TEMPLATE_SUCCESS;
var DELETE_TEMPLATE_ERROR = 'DELETE_TEMPLATE_ERROR';
exports.DELETE_TEMPLATE_ERROR = DELETE_TEMPLATE_ERROR;

function deleteWorkspaceTemplate(_ref) {
  var portalId = _ref.portalId,
      id = _ref.id;
  return {
    type: DELETE_TEMPLATE_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      id: id
    }
  };
}