"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _userSettings = require("./user-settings.actions");

/**
 * Created by srabmund on 02.02.2017.
 */
function userSettings() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _userSettings.FIND_ALL_USER_SETTINGS_SUCCESS:
      return Object.assign({}, state, action.body.settings);

    default:
      return state;
  }
}

var _default = userSettings;
exports["default"] = _default;