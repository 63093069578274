"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = routeData;

var _activePortal = require("../activePortal");

var _routedirection = require("./routedirection.actions");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Reducer for handling loaded GPS history data.
 * Listening to 'GPS_HISTORY_DATA_SUCCESS'
 *
 * @param {object} state - the application state
 * @param {object} action - the action to process
 */
function routeData() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _routedirection.GPS_HISTORY_DATA_SUCCESS:
      {
        var headers = action.headers,
            body = action.body;
        return Object.assign({}, state, _defineProperty({}, headers.widgetId, body));
      }

    case _routedirection.GPS_HISTORY_DATA_ERROR:
      {
        var emptyState = {
          boxData: [],
          distance: 0,
          durations: {
            driving: null,
            overall: 0,
            standing: null
          }
        };
        return Object.assign({}, state, _defineProperty({}, action.headers.widgetId, emptyState));
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return {};

    default:
      return state;
  }
}