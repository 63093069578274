"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _gateways = require("./gateways.actions");

var gatewaysReducer = function gatewaysReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _gateways.FIND_GATEWAYS_SUCCESS:
      {
        var gateways = action.body.gateways || [];
        var newState = {};
        gateways.forEach(function (gateway) {
          newState[gateway.id] = gateway;
        });
        return newState;
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return {};

    default:
      return state;
  }
};

var _default = gatewaysReducer;
exports["default"] = _default;