"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _queryKeys = _interopRequireDefault(require("./admin/queryKeys"));

var _queryKeys2 = _interopRequireDefault(require("./portal/queryKeys"));

var _useScopePermissions = require("./useScopePermissions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = {
  portal: _queryKeys2["default"],
  admin: _queryKeys["default"],
  scopePermissions: _useScopePermissions.queryKeys
};
exports["default"] = _default;