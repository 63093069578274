"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOGGLE_MANAGE_MODE = exports.TOGGLE_EDIT_MODE = exports.REPLACE_STATE = exports.RENAME_WORKSPACE_SUCCESS = exports.RENAME_WORKSPACE_REQUEST = exports.RENAME_WORKSPACE_ERROR = exports.REMOVE_WORKSPACE_SUCCESS = exports.REMOVE_WORKSPACE_REQUEST = exports.REMOVE_WORKSPACE_ERROR = exports.FIND_WORKSPACES_SUCCESS = exports.FIND_WORKSPACES_REQUEST = exports.FIND_WORKSPACES_ERROR = exports.CREATE_TEMPLATE_SUCCESS = exports.CREATE_TEMPLATE_REQUEST = exports.CREATE_TEMPLATE_ERROR = exports.CHANGE_WORKSPACE_POSITION_SUCCESS = exports.CHANGE_WORKSPACE_POSITION_REQUEST = exports.CHANGE_WORKSPACE_POSITION_ERROR = exports.ADD_WORKSPACE_SUCCESS = exports.ADD_WORKSPACE_REQUEST = exports.ADD_WORKSPACE_ERROR = void 0;
exports.addWorkspace = addWorkspace;
exports.changeWorkspacePosition = changeWorkspacePosition;
exports.findWorkspaces = exports.createWorkspaceTemplate = void 0;
exports.removeWorkspace = removeWorkspace;
exports.renameWorkspace = renameWorkspace;
exports.toggleEditMode = toggleEditMode;
exports.toggleManageMode = toggleManageMode;
var FIND_WORKSPACES_REQUEST = 'FIND_WORKSPACES_REQUEST';
exports.FIND_WORKSPACES_REQUEST = FIND_WORKSPACES_REQUEST;
var FIND_WORKSPACES_SUCCESS = 'FIND_WORKSPACES_SUCCESS';
exports.FIND_WORKSPACES_SUCCESS = FIND_WORKSPACES_SUCCESS;
var FIND_WORKSPACES_ERROR = 'FIND_WORKSPACES_ERROR';
exports.FIND_WORKSPACES_ERROR = FIND_WORKSPACES_ERROR;

var findWorkspaces = function findWorkspaces(portalId) {
  return {
    type: FIND_WORKSPACES_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {}
  };
};

exports.findWorkspaces = findWorkspaces;
var TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE';
exports.TOGGLE_EDIT_MODE = TOGGLE_EDIT_MODE;

function toggleEditMode() {
  return {
    type: TOGGLE_EDIT_MODE,
    headers: {
      version: 'v1'
    },
    body: {}
  };
}

var TOGGLE_MANAGE_MODE = 'TOGGLE_MANAGE_MODE';
exports.TOGGLE_MANAGE_MODE = TOGGLE_MANAGE_MODE;

function toggleManageMode(workspaceActiveBefore) {
  return {
    type: TOGGLE_MANAGE_MODE,
    headers: {
      version: 'v1'
    },
    body: {
      workspaceActiveBefore: workspaceActiveBefore
    }
  };
}

var ADD_WORKSPACE_REQUEST = 'ADD_WORKSPACE_REQUEST';
exports.ADD_WORKSPACE_REQUEST = ADD_WORKSPACE_REQUEST;
var ADD_WORKSPACE_SUCCESS = 'ADD_WORKSPACE_SUCCESS';
exports.ADD_WORKSPACE_SUCCESS = ADD_WORKSPACE_SUCCESS;
var ADD_WORKSPACE_ERROR = 'ADD_WORKSPACE_ERROR';
exports.ADD_WORKSPACE_ERROR = ADD_WORKSPACE_ERROR;

function addWorkspace(portalId, name, position, template) {
  return {
    type: ADD_WORKSPACE_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      workspace: {
        name: name,
        position: position
      },
      templateId: template.id
    }
  };
}

var REMOVE_WORKSPACE_REQUEST = 'REMOVE_WORKSPACE_REQUEST';
exports.REMOVE_WORKSPACE_REQUEST = REMOVE_WORKSPACE_REQUEST;
var REMOVE_WORKSPACE_SUCCESS = 'REMOVE_WORKSPACE_SUCCESS';
exports.REMOVE_WORKSPACE_SUCCESS = REMOVE_WORKSPACE_SUCCESS;
var REMOVE_WORKSPACE_ERROR = 'REMOVE_WORKSPACE_ERROR';
exports.REMOVE_WORKSPACE_ERROR = REMOVE_WORKSPACE_ERROR;

function removeWorkspace(id, portalId) {
  return {
    type: REMOVE_WORKSPACE_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      workspace: {
        id: id
      }
    }
  };
}

var RENAME_WORKSPACE_REQUEST = 'RENAME_WORKSPACE_REQUEST';
exports.RENAME_WORKSPACE_REQUEST = RENAME_WORKSPACE_REQUEST;
var RENAME_WORKSPACE_SUCCESS = 'RENAME_WORKSPACE_SUCCESS';
exports.RENAME_WORKSPACE_SUCCESS = RENAME_WORKSPACE_SUCCESS;
var RENAME_WORKSPACE_ERROR = 'RENAME_WORKSPACE_ERROR';
exports.RENAME_WORKSPACE_ERROR = RENAME_WORKSPACE_ERROR;

function renameWorkspace(id, name, portalId) {
  return {
    type: RENAME_WORKSPACE_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      workspace: {
        id: id,
        name: name
      }
    }
  };
}

var CHANGE_WORKSPACE_POSITION_REQUEST = 'CHANGE_WORKSPACE_POSITION_REQUEST';
exports.CHANGE_WORKSPACE_POSITION_REQUEST = CHANGE_WORKSPACE_POSITION_REQUEST;
var CHANGE_WORKSPACE_POSITION_SUCCESS = 'CHANGE_WORKSPACE_POSITION_SUCCESS';
exports.CHANGE_WORKSPACE_POSITION_SUCCESS = CHANGE_WORKSPACE_POSITION_SUCCESS;
var CHANGE_WORKSPACE_POSITION_ERROR = 'CHANGE_WORKSPACE_POSITION_ERROR';
exports.CHANGE_WORKSPACE_POSITION_ERROR = CHANGE_WORKSPACE_POSITION_ERROR;

function changeWorkspacePosition(newWorkspaceOrder, portalId) {
  var workspaces = newWorkspaceOrder.map(function (workspace) {
    return {
      id: workspace.id,
      position: workspace.position
    };
  });
  return {
    type: CHANGE_WORKSPACE_POSITION_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      workspaces: workspaces
    }
  };
}

var REPLACE_STATE = 'REPLACE_STATE';
exports.REPLACE_STATE = REPLACE_STATE;
var CREATE_TEMPLATE_REQUEST = 'CREATE_TEMPLATE_REQUEST';
exports.CREATE_TEMPLATE_REQUEST = CREATE_TEMPLATE_REQUEST;
var CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
exports.CREATE_TEMPLATE_SUCCESS = CREATE_TEMPLATE_SUCCESS;
var CREATE_TEMPLATE_ERROR = 'CREATE_TEMPLATE_ERROR';
exports.CREATE_TEMPLATE_ERROR = CREATE_TEMPLATE_ERROR;

var createWorkspaceTemplate = function createWorkspaceTemplate(_ref) {
  var portalId = _ref.portalId,
      name = _ref.name,
      templateWidgetTypes = _ref.templateWidgetTypes;
  return {
    type: CREATE_TEMPLATE_REQUEST,
    headers: {
      portalId: portalId,
      version: 'v1'
    },
    body: {
      name: name,
      templateWidgetTypes: templateWidgetTypes
    }
  };
};

exports.createWorkspaceTemplate = createWorkspaceTemplate;