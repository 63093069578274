"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scopes = exports.getScopePermissions = exports.GET_SCOPE_PERMISSIONS_SUCCESS = exports.GET_SCOPE_PERMISSIONS_REQUEST = exports.GET_SCOPE_PERMISSIONS_ERROR = void 0;
var GET_SCOPE_PERMISSIONS_REQUEST = 'GET_SCOPE_PERMISSIONS_REQUEST';
exports.GET_SCOPE_PERMISSIONS_REQUEST = GET_SCOPE_PERMISSIONS_REQUEST;
var GET_SCOPE_PERMISSIONS_SUCCESS = 'GET_SCOPE_PERMISSIONS_SUCCESS';
exports.GET_SCOPE_PERMISSIONS_SUCCESS = GET_SCOPE_PERMISSIONS_SUCCESS;
var GET_SCOPE_PERMISSIONS_ERROR = 'GET_SCOPE_PERMISSIONS_ERROR';
exports.GET_SCOPE_PERMISSIONS_ERROR = GET_SCOPE_PERMISSIONS_ERROR;
var scopes = {
  PORTAL: 'PORTAL',
  BASE: 'BASE',
  ADMIN: 'ADMIN'
};
exports.scopes = scopes;

var getScopePermissions = function getScopePermissions(scope) {
  var portalId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return {
    type: GET_SCOPE_PERMISSIONS_REQUEST,
    headers: {
      version: 'v1',
      scope: scope,
      portalId: portalId
    },
    body: {}
  };
};

exports.getScopePermissions = getScopePermissions;