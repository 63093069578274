"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _assetMapping = require("./asset-mapping");

Object.keys(_assetMapping).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _assetMapping[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _assetMapping[key];
    }
  });
});

var _errorCodeMapping = require("./error-code-mapping");

Object.keys(_errorCodeMapping).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _errorCodeMapping[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _errorCodeMapping[key];
    }
  });
});

var _poiCategoryTypeMapping = require("./poi-category-type-mapping");

Object.keys(_poiCategoryTypeMapping).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _poiCategoryTypeMapping[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _poiCategoryTypeMapping[key];
    }
  });
});

var _statusMapping = require("./status-mapping");

Object.keys(_statusMapping).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _statusMapping[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _statusMapping[key];
    }
  });
});