import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { changeActivePortal } from 'nicbase-reducers';

import NavigationLink from './navigation-item';

export const mapStateToProps = state => ({ portals: Object.values(state.portals) });
export const mapDispatchToProps = dispatch => ({
  login: portalId => {
    dispatch(changeActivePortal(portalId));
  },
});

export const DashboardLink = ({ portals, login }) => {
  if (portals.length === 1) {
    const { id } = portals[0];

    return (
      <NavigationLink onClick={() => login(id)} to={{ pathname: `/dashboard/${id}/workspace`, state: { title: 'Dashboard' } }}>
        Dashboard
      </NavigationLink>
    );
  }
  return <NavigationLink to={{ pathname: '/dashboard', state: { title: 'Portals' } }}>Portals</NavigationLink>;
};

DashboardLink.propTypes = {
  portals: PropTypes.arrayOf(PropTypes.shape()),
  login: PropTypes.func,
};
const noop = () => {};
DashboardLink.defaultProps = {
  portals: [],
  login: noop,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLink);
