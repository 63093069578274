"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _useEventListener = _interopRequireDefault(require("./useEventListener"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var useOnClickOutside = function useOnClickOutside(ref, handler) {
  var listener = function listener(event) {
    // Do nothing if clicking ref's element or descendent elements
    if (!ref.current || event.target instanceof Element && ref.current.contains(event.target)) {
      return;
    }

    handler(event);
  };

  (0, _useEventListener["default"])('mousedown', listener);
  (0, _useEventListener["default"])('touchstart', listener);
};

var _default = useOnClickOutside;
exports["default"] = _default;