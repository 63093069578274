"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  useDebounce: true,
  useDebouncedCallback: true,
  useDidMount: true,
  useDidUpdate: true,
  useDimensions: true,
  useEventListener: true,
  useGeolocation: true,
  useKeyPress: true,
  useOnClickOutside: true,
  usePrevious: true,
  useThrottle: true,
  useVirtualWithResizeObserver: true,
  useWindowSize: true
};
Object.defineProperty(exports, "useDebounce", {
  enumerable: true,
  get: function get() {
    return _useDebounce["default"];
  }
});
Object.defineProperty(exports, "useDebouncedCallback", {
  enumerable: true,
  get: function get() {
    return _useDebouncedCallback["default"];
  }
});
Object.defineProperty(exports, "useDidMount", {
  enumerable: true,
  get: function get() {
    return _useDidMount["default"];
  }
});
Object.defineProperty(exports, "useDidUpdate", {
  enumerable: true,
  get: function get() {
    return _useDidUpdate["default"];
  }
});
Object.defineProperty(exports, "useDimensions", {
  enumerable: true,
  get: function get() {
    return _useDimensions["default"];
  }
});
Object.defineProperty(exports, "useEventListener", {
  enumerable: true,
  get: function get() {
    return _useEventListener["default"];
  }
});
Object.defineProperty(exports, "useGeolocation", {
  enumerable: true,
  get: function get() {
    return _useGeolocation["default"];
  }
});
Object.defineProperty(exports, "useKeyPress", {
  enumerable: true,
  get: function get() {
    return _useKeyPress["default"];
  }
});
Object.defineProperty(exports, "useOnClickOutside", {
  enumerable: true,
  get: function get() {
    return _useOnClickOutside["default"];
  }
});
Object.defineProperty(exports, "usePrevious", {
  enumerable: true,
  get: function get() {
    return _usePrevious["default"];
  }
});
Object.defineProperty(exports, "useThrottle", {
  enumerable: true,
  get: function get() {
    return _useThrottle["default"];
  }
});
Object.defineProperty(exports, "useVirtualWithResizeObserver", {
  enumerable: true,
  get: function get() {
    return _useVirtualWithResizeObserver["default"];
  }
});
Object.defineProperty(exports, "useWindowSize", {
  enumerable: true,
  get: function get() {
    return _useWindowSize["default"];
  }
});

var _dataHooks = require("./data-hooks");

Object.keys(_dataHooks).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _dataHooks[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _dataHooks[key];
    }
  });
});

var _useDebounce = _interopRequireDefault(require("./useDebounce"));

var _useDebouncedCallback = _interopRequireDefault(require("./useDebouncedCallback"));

var _useDidMount = _interopRequireDefault(require("./useDidMount"));

var _useDidUpdate = _interopRequireDefault(require("./useDidUpdate"));

var _useDimensions = _interopRequireDefault(require("./useDimensions"));

var _useEventListener = _interopRequireDefault(require("./useEventListener"));

var _useGeolocation = _interopRequireDefault(require("./useGeolocation"));

var _useKeyPress = _interopRequireDefault(require("./useKeyPress"));

var _useOnClickOutside = _interopRequireDefault(require("./useOnClickOutside"));

var _usePrevious = _interopRequireDefault(require("./usePrevious"));

var _useThrottle = _interopRequireDefault(require("./useThrottle"));

var _useVirtualWithResizeObserver = _interopRequireDefault(require("./useVirtualWithResizeObserver"));

var _useWindowSize = _interopRequireDefault(require("./useWindowSize"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }