"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAdminGatewayAccounts = exports.ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_SUCCESS = exports.ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_REQUEST = exports.ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_ERROR = void 0;
var ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_REQUEST = 'ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_REQUEST';
exports.ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_REQUEST = ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_REQUEST;
var ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_SUCCESS = 'ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_SUCCESS';
exports.ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_SUCCESS = ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_SUCCESS;
var ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_ERROR = 'ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_ERROR';
exports.ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_ERROR = ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_ERROR;

var findAdminGatewayAccounts = function findAdminGatewayAccounts() {
  return {
    type: ADMIN_FIND_ALL_GATEWAY_ACCOUNTS_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {}
  };
};

exports.findAdminGatewayAccounts = findAdminGatewayAccounts;