"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _permissionAdministration = require("./permission-administration.actions");

var adminToolPackagesReducer = function adminToolPackagesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _permissionAdministration.FIND_PERMISSION_PACKAGES_SUCCESS:
      {
        var permissionPackages = action.body.permissionPackages || [];
        var newState = {};
        permissionPackages.forEach(function (permissionPackage) {
          newState[permissionPackage.id] = permissionPackage;
        });
        return newState;
      }

    default:
      {
        return state;
      }
  }
};

var _default = adminToolPackagesReducer;
exports["default"] = _default;