"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _filters = require("./filters.actions");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _filters.UPDATE_FILTERS:
      {
        var _action$body = action.body,
            id = _action$body.id,
            values = _action$body.values;
        var existingFilters = state[id];
        return Object.assign({}, state, _defineProperty({}, id, Object.assign({}, existingFilters, values)));
      }

    case 'SAVE_FILTER':
      {
        var filter = action.body;
        return Object.assign({}, state, filter);
      }

    default:
      {
        return state;
      }
  }
};

exports["default"] = _default;