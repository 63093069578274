"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendTourTestResultMail = exports.getMostRecentTourTestResultsFromBackend = exports.TRIGGER_TEST_REPORT_SUCCESS = exports.TRIGGER_TEST_REPORT_REQUEST = exports.TRIGGER_TEST_REPORT_ERROR = exports.GET_MOST_RECENT_TOUR_TEST_RESULTS_SUCCESS = exports.GET_MOST_RECENT_TOUR_TEST_RESULTS_REQUEST = exports.GET_MOST_RECENT_TOUR_TEST_RESULTS_ERROR = void 0;
var GET_MOST_RECENT_TOUR_TEST_RESULTS_SUCCESS = 'GET_MOST_RECENT_TOUR_TEST_RESULTS_SUCCESS';
exports.GET_MOST_RECENT_TOUR_TEST_RESULTS_SUCCESS = GET_MOST_RECENT_TOUR_TEST_RESULTS_SUCCESS;
var GET_MOST_RECENT_TOUR_TEST_RESULTS_REQUEST = 'GET_MOST_RECENT_TOUR_TEST_RESULTS_REQUEST';
exports.GET_MOST_RECENT_TOUR_TEST_RESULTS_REQUEST = GET_MOST_RECENT_TOUR_TEST_RESULTS_REQUEST;
var GET_MOST_RECENT_TOUR_TEST_RESULTS_ERROR = 'GET_MOST_RECENT_TOUR_TEST_RESULTS_ERROR';
exports.GET_MOST_RECENT_TOUR_TEST_RESULTS_ERROR = GET_MOST_RECENT_TOUR_TEST_RESULTS_ERROR;

var getMostRecentTourTestResultsFromBackend = function getMostRecentTourTestResultsFromBackend() {
  return {
    type: GET_MOST_RECENT_TOUR_TEST_RESULTS_REQUEST,
    headers: {
      version: 'v1'
    }
  };
};

exports.getMostRecentTourTestResultsFromBackend = getMostRecentTourTestResultsFromBackend;
var TRIGGER_TEST_REPORT_SUCCESS = 'TRIGGER_TEST_REPORT_SUCCESS';
exports.TRIGGER_TEST_REPORT_SUCCESS = TRIGGER_TEST_REPORT_SUCCESS;
var TRIGGER_TEST_REPORT_REQUEST = 'TRIGGER_TEST_REPORT_REQUEST';
exports.TRIGGER_TEST_REPORT_REQUEST = TRIGGER_TEST_REPORT_REQUEST;
var TRIGGER_TEST_REPORT_ERROR = 'TRIGGER_TEST_REPORT_ERROR';
exports.TRIGGER_TEST_REPORT_ERROR = TRIGGER_TEST_REPORT_ERROR;

var sendTourTestResultMail = function sendTourTestResultMail(id) {
  return {
    type: TRIGGER_TEST_REPORT_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      resultId: id
    }
  };
};

exports.sendTourTestResultMail = sendTourTestResultMail;