"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authorizeConnection = exports.TOGGLE_FORCE_DESKTOP_MODE = exports.SET_RESPONSIVE_MODE = exports.NICPLACE_LOGIN_REQUEST = exports.NICPLACE_LOGIN_ERROR = exports.INITIAL_DATA_REQUEST = exports.AUTHORIZE_CONNECTION_REQUEST = void 0;
exports.loadInitialData = loadInitialData;
exports.nicplaceLogin = void 0;
exports.setResponsiveMode = setResponsiveMode;
exports.toggleForceDesktopMode = toggleForceDesktopMode;

/**
 * Created by andi on 09.09.16.
 */
var NICPLACE_LOGIN_REQUEST = 'NICPLACE_LOGIN_REQUEST';
exports.NICPLACE_LOGIN_REQUEST = NICPLACE_LOGIN_REQUEST;
var NICPLACE_LOGIN_ERROR = 'NICPLACE_LOGIN_ERROR';
exports.NICPLACE_LOGIN_ERROR = NICPLACE_LOGIN_ERROR;

var nicplaceLogin = function nicplaceLogin(accessToken) {
  return {
    type: NICPLACE_LOGIN_REQUEST,
    headers: {
      oauthToken: accessToken,
      version: 'v1'
    }
  };
};

exports.nicplaceLogin = nicplaceLogin;
var INITIAL_DATA_REQUEST = 'INITIAL_DATA_REQUEST';
exports.INITIAL_DATA_REQUEST = INITIAL_DATA_REQUEST;

function loadInitialData(accessToken) {
  return {
    type: INITIAL_DATA_REQUEST,
    headers: {
      oauthToken: accessToken,
      portalId: 1,
      version: 'v1'
    },
    body: {
      authToken: accessToken,
      portalId: 1
    }
  };
}

var SET_RESPONSIVE_MODE = 'SET_RESPONSIVE_MODE';
exports.SET_RESPONSIVE_MODE = SET_RESPONSIVE_MODE;

function setResponsiveMode(detectedMode, width, height) {
  var newDetectedMode = detectedMode;

  if (detectedMode !== 'mobile' && detectedMode !== 'desktop' && detectedMode !== 'tablet' && detectedMode !== 'desktop-large') {
    newDetectedMode = 'desktop';
  }

  return {
    type: SET_RESPONSIVE_MODE,
    body: {
      detectedMode: newDetectedMode,
      width: width,
      height: height
    }
  };
}

var TOGGLE_FORCE_DESKTOP_MODE = 'TOGGLE_FORCE_DESKTOP_MODE';
exports.TOGGLE_FORCE_DESKTOP_MODE = TOGGLE_FORCE_DESKTOP_MODE;

function toggleForceDesktopMode() {
  return {
    type: TOGGLE_FORCE_DESKTOP_MODE
  };
}

var AUTHORIZE_CONNECTION_REQUEST = 'AUTHORIZE_CONNECTION_REQUEST';
exports.AUTHORIZE_CONNECTION_REQUEST = AUTHORIZE_CONNECTION_REQUEST;

var authorizeConnection = function authorizeConnection(accessToken) {
  return {
    type: AUTHORIZE_CONNECTION_REQUEST,
    headers: {
      version: 'v1',
      oauthToken: accessToken
    }
  };
};

exports.authorizeConnection = authorizeConnection;