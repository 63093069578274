"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_USER_ERROR = exports.REFRESH_USER_REGISTRATION_ADMINTOOL_SUCCESS = exports.REFRESH_USER_REGISTRATION_ADMINTOOL_ERROR = exports.DELETE_USER_ERROR = exports.CREATE_USER_EXPORT_SUCCESS = exports.CREATE_USER_EXPORT_ERROR = exports.CREATE_USER_ERROR = void 0;
var CREATE_USER_EXPORT_SUCCESS = 'CREATE_USER_EXPORT_SUCCESS';
exports.CREATE_USER_EXPORT_SUCCESS = CREATE_USER_EXPORT_SUCCESS;
var CREATE_USER_EXPORT_ERROR = 'CREATE_USER_EXPORT_ERROR';
exports.CREATE_USER_EXPORT_ERROR = CREATE_USER_EXPORT_ERROR;
var REFRESH_USER_REGISTRATION_ADMINTOOL_SUCCESS = 'REFRESH_USER_REGISTRATION_ADMINTOOL_SUCCESS';
exports.REFRESH_USER_REGISTRATION_ADMINTOOL_SUCCESS = REFRESH_USER_REGISTRATION_ADMINTOOL_SUCCESS;
var REFRESH_USER_REGISTRATION_ADMINTOOL_ERROR = 'REFRESH_USER_REGISTRATION_ADMINTOOL_ERROR';
exports.REFRESH_USER_REGISTRATION_ADMINTOOL_ERROR = REFRESH_USER_REGISTRATION_ADMINTOOL_ERROR;
var CREATE_USER_ERROR = 'CREATE_USER_ERROR';
exports.CREATE_USER_ERROR = CREATE_USER_ERROR;
var UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
exports.UPDATE_USER_ERROR = UPDATE_USER_ERROR;
var DELETE_USER_ERROR = 'DELETE_USER_ERROR';
exports.DELETE_USER_ERROR = DELETE_USER_ERROR;