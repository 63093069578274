"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateLayout = exports.findAllLayouts = exports.deleteLayout = exports.createLayout = exports.UPDATE_LAYOUT_SUCCESS = exports.UPDATE_LAYOUT_REQUEST = exports.UPDATE_LAYOUT_ERROR = exports.FIND_ALL_LAYOUTS_SUCCESS = exports.FIND_ALL_LAYOUTS_REQUEST = exports.FIND_ALL_LAYOUTS_ERROR = exports.DELETE_LAYOUT_SUCCESS = exports.DELETE_LAYOUT_REQUEST = exports.DELETE_LAYOUT_ERROR = exports.CREATE_LAYOUT_SUCCESS = exports.CREATE_LAYOUT_REQUEST = exports.CREATE_LAYOUT_ERROR = void 0;
var FIND_ALL_LAYOUTS_REQUEST = 'FIND_ALL_LAYOUTS_REQUEST';
exports.FIND_ALL_LAYOUTS_REQUEST = FIND_ALL_LAYOUTS_REQUEST;
var FIND_ALL_LAYOUTS_SUCCESS = 'FIND_ALL_LAYOUTS_SUCCESS';
exports.FIND_ALL_LAYOUTS_SUCCESS = FIND_ALL_LAYOUTS_SUCCESS;
var FIND_ALL_LAYOUTS_ERROR = 'FIND_ALL_LAYOUTS_ERROR';
exports.FIND_ALL_LAYOUTS_ERROR = FIND_ALL_LAYOUTS_ERROR;

var findAllLayouts = function findAllLayouts() {
  return {
    type: FIND_ALL_LAYOUTS_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {}
  };
};

exports.findAllLayouts = findAllLayouts;
var CREATE_LAYOUT_REQUEST = 'CREATE_LAYOUT_REQUEST';
exports.CREATE_LAYOUT_REQUEST = CREATE_LAYOUT_REQUEST;
var CREATE_LAYOUT_SUCCESS = 'CREATE_LAYOUT_SUCCESS';
exports.CREATE_LAYOUT_SUCCESS = CREATE_LAYOUT_SUCCESS;
var CREATE_LAYOUT_ERROR = 'CREATE_LAYOUT_ERROR';
exports.CREATE_LAYOUT_ERROR = CREATE_LAYOUT_ERROR;

var createLayout = function createLayout(layout) {
  return {
    type: CREATE_LAYOUT_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      layout: layout
    }
  };
};

exports.createLayout = createLayout;
var UPDATE_LAYOUT_REQUEST = 'UPDATE_LAYOUT_REQUEST';
exports.UPDATE_LAYOUT_REQUEST = UPDATE_LAYOUT_REQUEST;
var UPDATE_LAYOUT_SUCCESS = 'UPDATE_LAYOUT_SUCCESS';
exports.UPDATE_LAYOUT_SUCCESS = UPDATE_LAYOUT_SUCCESS;
var UPDATE_LAYOUT_ERROR = 'UPDATE_LAYOUT_ERROR';
exports.UPDATE_LAYOUT_ERROR = UPDATE_LAYOUT_ERROR;

var updateLayout = function updateLayout(layout) {
  return {
    type: UPDATE_LAYOUT_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      layout: layout
    }
  };
};

exports.updateLayout = updateLayout;
var DELETE_LAYOUT_REQUEST = 'DELETE_LAYOUT_REQUEST';
exports.DELETE_LAYOUT_REQUEST = DELETE_LAYOUT_REQUEST;
var DELETE_LAYOUT_SUCCESS = 'DELETE_LAYOUT_SUCCESS';
exports.DELETE_LAYOUT_SUCCESS = DELETE_LAYOUT_SUCCESS;
var DELETE_LAYOUT_ERROR = 'DELETE_LAYOUT_ERROR';
exports.DELETE_LAYOUT_ERROR = DELETE_LAYOUT_ERROR;

var deleteLayout = function deleteLayout(id) {
  return {
    type: DELETE_LAYOUT_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      layout: {
        id: id
      }
    }
  };
};

exports.deleteLayout = deleteLayout;