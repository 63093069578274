"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_PUSH_ACCOUNT_ERROR = exports.DELETE_PUSH_ACCOUNT_ERROR = exports.CREATE_PUSH_ACCOUNT_ERROR = void 0;
var CREATE_PUSH_ACCOUNT_ERROR = 'CREATE_PUSH_ACCOUNT_ERROR';
exports.CREATE_PUSH_ACCOUNT_ERROR = CREATE_PUSH_ACCOUNT_ERROR;
var UPDATE_PUSH_ACCOUNT_ERROR = 'UPDATE_PUSH_ACCOUNT_ERROR';
exports.UPDATE_PUSH_ACCOUNT_ERROR = UPDATE_PUSH_ACCOUNT_ERROR;
var DELETE_PUSH_ACCOUNT_ERROR = 'DELETE_PUSH_ACCOUNT_ERROR';
exports.DELETE_PUSH_ACCOUNT_ERROR = DELETE_PUSH_ACCOUNT_ERROR;