"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_ERROR", {
  enumerable: true,
  get: function get() {
    return _assetids2.FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_ERROR;
  }
});
Object.defineProperty(exports, "FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_REQUEST", {
  enumerable: true,
  get: function get() {
    return _assetids2.FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_REQUEST;
  }
});
Object.defineProperty(exports, "FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_SUCCESS", {
  enumerable: true,
  get: function get() {
    return _assetids2.FIND_TWOWAY_ALL_ASSETIDS_FOR_PORTAL_SUCCESS;
  }
});
Object.defineProperty(exports, "FIND_TWOWAY_COMMANDS_BY_ASSET_ERROR", {
  enumerable: true,
  get: function get() {
    return _commands2.FIND_TWOWAY_COMMANDS_BY_ASSET_ERROR;
  }
});
Object.defineProperty(exports, "FIND_TWOWAY_COMMANDS_BY_ASSET_REQUEST", {
  enumerable: true,
  get: function get() {
    return _commands2.FIND_TWOWAY_COMMANDS_BY_ASSET_REQUEST;
  }
});
Object.defineProperty(exports, "FIND_TWOWAY_COMMANDS_BY_ASSET_SUCCESS", {
  enumerable: true,
  get: function get() {
    return _commands2.FIND_TWOWAY_COMMANDS_BY_ASSET_SUCCESS;
  }
});
Object.defineProperty(exports, "FIND_TWOWAY_SYSTEMS_BY_ASSET_ERROR", {
  enumerable: true,
  get: function get() {
    return _commands2.FIND_TWOWAY_SYSTEMS_BY_ASSET_ERROR;
  }
});
Object.defineProperty(exports, "FIND_TWOWAY_SYSTEMS_BY_ASSET_REQUEST", {
  enumerable: true,
  get: function get() {
    return _commands2.FIND_TWOWAY_SYSTEMS_BY_ASSET_REQUEST;
  }
});
Object.defineProperty(exports, "FIND_TWOWAY_SYSTEMS_BY_ASSET_SUCCESS", {
  enumerable: true,
  get: function get() {
    return _commands2.FIND_TWOWAY_SYSTEMS_BY_ASSET_SUCCESS;
  }
});
Object.defineProperty(exports, "SEND_TWOWAY_COMMAND_ERROR", {
  enumerable: true,
  get: function get() {
    return _commands2.SEND_TWOWAY_COMMAND_ERROR;
  }
});
Object.defineProperty(exports, "SEND_TWOWAY_COMMAND_REQUEST", {
  enumerable: true,
  get: function get() {
    return _commands2.SEND_TWOWAY_COMMAND_REQUEST;
  }
});
Object.defineProperty(exports, "SEND_TWOWAY_COMMAND_SUCCESS", {
  enumerable: true,
  get: function get() {
    return _commands2.SEND_TWOWAY_COMMAND_SUCCESS;
  }
});
exports["default"] = void 0;
Object.defineProperty(exports, "findTwoWayAllAssetIdsForPortal", {
  enumerable: true,
  get: function get() {
    return _assetids2.findTwoWayAllAssetIdsForPortal;
  }
});
Object.defineProperty(exports, "findTwowayCommand", {
  enumerable: true,
  get: function get() {
    return _commands2.findTwowayCommand;
  }
});
Object.defineProperty(exports, "findTwowayHistoryCommands", {
  enumerable: true,
  get: function get() {
    return _commands2.findTwowayHistoryCommands;
  }
});
Object.defineProperty(exports, "sendTwowayAccount", {
  enumerable: true,
  get: function get() {
    return _commands2.sendTwowayAccount;
  }
});

var _assetids = _interopRequireDefault(require("./assetids/assetids.reducer"));

var _commands = _interopRequireDefault(require("./commands/commands.reducer"));

var _assetids2 = require("./assetids/assetids.actions");

var _commands2 = require("./commands/commands.actions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = {
  widgets: _commands["default"],
  assetIds: _assetids["default"]
};
exports["default"] = _default;