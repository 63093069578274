"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  queryKeys: true,
  useScopePermissions: true,
  useTranslations: true
};
Object.defineProperty(exports, "queryKeys", {
  enumerable: true,
  get: function get() {
    return _queryKeys["default"];
  }
});
Object.defineProperty(exports, "useScopePermissions", {
  enumerable: true,
  get: function get() {
    return _useScopePermissions.useScopePermissions;
  }
});
Object.defineProperty(exports, "useTranslations", {
  enumerable: true,
  get: function get() {
    return _useTranslations.useTranslations;
  }
});

var _admin = require("./admin");

Object.keys(_admin).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _admin[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _admin[key];
    }
  });
});

var _portal = require("./portal");

Object.keys(_portal).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _portal[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _portal[key];
    }
  });
});

var _queryKeys = _interopRequireDefault(require("./queryKeys"));

var _useScopePermissions = require("./useScopePermissions");

var _useTranslations = require("./useTranslations");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }