"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TWOWAY_COMMUNICATION_WIDGET = exports.TRAIN_COMPOSITION_WIDGET = exports.TOUR_BOARD = exports.STANDING_TIME = exports.SETPOINT_DIFFERENCE = exports.ROUTE_DIRECTION = exports.ROUTE_ANALYSIS_WIDGET = exports.REPORTING_WIDGET = exports.RENTAL_TOOL_WIDGET = exports.PRECOOL_MONITOR = exports.POI_LAYOVER_MONITORING = exports.MOVING_STATUS = exports.MILEAGE_COUNTRY = exports.LATEST_DATA_TABLE = exports.LATEST_DATA_MAP = exports.HISTORY_GRAPH = exports.HISTORY_DATA_TABLE = exports.FLEET_MILEAGE = exports.FLEET_CHECK = exports.FLEET_ADMINISTRATION_WIDGET = exports.ETA_MONITOR = exports.DETAILS = exports.CHAT = exports.ARRIVAL_BOARD = exports.ARCHIVE = exports.ALARM_MONITOR = exports.ALARM_MANAGEMENT_WIDGET = exports.ALARM_CONNECTOR_WIDGET = exports.ADD_BUTTON = exports.ACTIVATED_ALARMS = void 0;

var _nicbaseIcons = require("nicbase-icons");

var HISTORY_GRAPH = {
  title: 'widgets.tempChart.title',
  type: 1,
  icon: _nicbaseIcons.TemperatureHistory,
  settings: {}
};
exports.HISTORY_GRAPH = HISTORY_GRAPH;
var LATEST_DATA_MAP = {
  title: 'widgets.latestDataMap.title',
  type: 2,
  icon: _nicbaseIcons.Map,
  settings: {
    useGoogleMap: false,
    showRailLayer: false,
    showAssetNames: false,
    showPois: false,
    selectedPois: [],
    selectedPoiCategories: [],
    selectedAllPois: true,
    disableClustering: false,
    disablePoiClustering: false,
    clusterAssetsInPoi: false,
    showTrains: false
  }
};
exports.LATEST_DATA_MAP = LATEST_DATA_MAP;
var FLEET_MILEAGE = {
  title: 'widgets.fleetMileage.title',
  type: 3,
  icon: _nicbaseIcons.FleetMileageFleet,
  settings: {}
};
exports.FLEET_MILEAGE = FLEET_MILEAGE;
var LATEST_DATA_TABLE = {
  title: 'widgets.latestDataTable.title',
  type: 5,
  icon: _nicbaseIcons.Latestdata1,
  settings: {}
};
exports.LATEST_DATA_TABLE = LATEST_DATA_TABLE;
var DETAILS = {
  title: 'widgets.details.title',
  type: 6,
  icon: _nicbaseIcons.Details,
  settings: {}
};
exports.DETAILS = DETAILS;
var MILEAGE_COUNTRY = {
  title: 'widgets.mileageCountry.title',
  type: 7,
  icon: _nicbaseIcons.FleetMileageCountry,
  settings: {}
};
exports.MILEAGE_COUNTRY = MILEAGE_COUNTRY;
var ETA_MONITOR = {
  title: 'widgets.etaCalculator.title',
  type: 12,
  icon: _nicbaseIcons.ETAMonitor,
  settings: {}
};
exports.ETA_MONITOR = ETA_MONITOR;
var ALARM_MONITOR = {
  title: 'widgets.alarmMonitor.title',
  type: 13,
  icon: _nicbaseIcons.AlarmMonitor,
  settings: {}
};
exports.ALARM_MONITOR = ALARM_MONITOR;
var CHAT = {
  type: 14,
  icon: _nicbaseIcons.Message,
  settings: {}
};
exports.CHAT = CHAT;
var ROUTE_DIRECTION = {
  title: 'widgets.routedirection.title',
  type: 15,
  icon: _nicbaseIcons.RouteHistory,
  settings: {
    useGoogleMap: false,
    showRailLayer: false,
    showPois: false,
    selectedPois: [],
    selectedPoiCategories: [],
    selectedAllPois: true,
    disablePoiClustering: false
  }
};
exports.ROUTE_DIRECTION = ROUTE_DIRECTION;
var HISTORY_DATA_TABLE = {
  title: 'widgets.historyDataTable.title',
  type: 16,
  icon: _nicbaseIcons.HistoryData,
  settings: {
    visibleColumns: [{
      dataKey: 'date'
    }, {
      dataKey: 'vehicleType'
    }, {
      dataKey: 'states'
    }, {
      dataKey: 'position'
    }]
  }
};
exports.HISTORY_DATA_TABLE = HISTORY_DATA_TABLE;
var SETPOINT_DIFFERENCE = {
  title: 'widgets.setpoint.title',
  type: 22,
  icon: _nicbaseIcons.SetpointDifference,
  settings: {
    allAssetsIncluded: true,
    assetList: [],
    groupList: [],
    setpoint: {
      value1: 0,
      value2: 2,
      value3: 2.1,
      value4: 3,
      value5: 3.1,
      value6: 4,
      value7: 4.1,
      value8: 5,
      value9: 5.1,
      value10: 7.5
    }
  }
};
exports.SETPOINT_DIFFERENCE = SETPOINT_DIFFERENCE;
var MOVING_STATUS = {
  title: 'widgets.movingStatus.title',
  type: 23,
  icon: _nicbaseIcons.MovingStatus,
  settings: {}
};
exports.MOVING_STATUS = MOVING_STATUS;
var FLEET_CHECK = {
  title: 'widgets.fleetCheck.title',
  type: 24,
  icon: _nicbaseIcons.FleetCheck,
  settings: {
    allAssetsIncluded: true,
    assetGroups: [],
    assets: [],
    hideDoorSensorsInfo: false,
    hideReeferInfo: false,
    hideTelematicsSystemInfo: false,
    hideTemperatureSensorsInfo: false,
    noGpsTimeValue: 60,
    offlineTimeValue: 50,
    offlineTimeValueRail: 1440,
    standingTimeValue: 30,
    standingTimeValueRail: 1440,
    voltageValue: 4,
    voltageValueRail: 4.5
  }
};
exports.FLEET_CHECK = FLEET_CHECK;
var STANDING_TIME = {
  title: 'widgets.standing.time.title',
  type: 25,
  icon: _nicbaseIcons.StandingTime,
  settings: {
    intervals: [120, 1440, Infinity],
    allAssetsSelected: true,
    selectedAssets: [],
    selectedAssetGroups: []
  }
};
exports.STANDING_TIME = STANDING_TIME;
var TOUR_BOARD = {
  title: 'widgets.tourBoard.title',
  type: 26,
  icon: _nicbaseIcons.TourBoard,
  settings: {}
};
exports.TOUR_BOARD = TOUR_BOARD;
var ARRIVAL_BOARD = {
  title: 'widgets.arrivalBoard.title',
  type: 41,
  icon: _nicbaseIcons.Arrivals,
  settings: {
    selectedPoiId: null,
    arrivalHours: 12
  }
};
exports.ARRIVAL_BOARD = ARRIVAL_BOARD;
var PRECOOL_MONITOR = {
  title: 'widgets.precoolMonitor.title',
  type: 42,
  icon: _nicbaseIcons.PrecoolMonitor,
  settings: {}
};
exports.PRECOOL_MONITOR = PRECOOL_MONITOR;
var POI_LAYOVER_MONITORING = {
  title: 'widgets.poiLayoverMonitoring.title',
  type: 27,
  icon: _nicbaseIcons.POILayoverMonitoring,
  settings: {}
};
exports.POI_LAYOVER_MONITORING = POI_LAYOVER_MONITORING;
var ACTIVATED_ALARMS = {
  title: 'widgets.activatedAlarms.title',
  type: 28,
  icon: _nicbaseIcons.AlarmHistory,
  settings: {}
};
exports.ACTIVATED_ALARMS = ACTIVATED_ALARMS;
var FLEET_ADMINISTRATION_WIDGET = {
  title: 'widgets.fleetAdministration.title',
  type: 29,
  icon: _nicbaseIcons.FleetAdministration,
  settings: {
    columns: [{
      dataKey: 'name'
    }, {
      dataKey: 'type'
    }, {
      dataKey: 'wagonNumber'
    }, {
      dataKey: 'wagonNumberFreight'
    }, {
      dataKey: 'previousWagonNumberFreight'
    }, {
      dataKey: 'registrationCountry'
    }, {
      dataKey: 'datePutIntoService'
    }, {
      dataKey: 'outOfServiceFlag'
    }, {
      dataKey: 'withdrawalCode'
    }, {
      dataKey: 'withdrawalDate'
    }, {
      dataKey: 'ownerCode'
    }, {
      dataKey: 'ownerName'
    }, {
      dataKey: 'keeperCompanyCode'
    }, {
      dataKey: 'keeperName'
    }, {
      dataKey: 'keeperShortNameVKM'
    }, {
      dataKey: 'previousKeeperCompanyCode'
    }, {
      dataKey: 'letterMarking'
    }, {
      dataKey: 'couplingType'
    }, {
      dataKey: 'bufferType'
    }, {
      dataKey: 'normalLoadingGauge'
    }, {
      dataKey: 'wagonWeightEmpty'
    }, {
      dataKey: 'lengthOverBuffers'
    }, {
      dataKey: 'maxAxleWeight'
    }, {
      dataKey: 'maxGrossWeight'
    }, {
      dataKey: 'qrCodeAddress'
    }, {
      dataKey: 'isUhfRfid'
    }, {
      dataKey: 'rfidPayload'
    }, {
      dataKey: 'wagonNumberOfAxles'
    }, {
      dataKey: 'wheelsetType'
    }, {
      dataKey: 'wheelDiameter'
    }, {
      dataKey: 'wheelsetGauge'
    }, {
      dataKey: 'wheelsetTransformationMethod'
    }, {
      dataKey: 'numberOfBogies'
    }, {
      dataKey: 'bogiePitch'
    }, {
      dataKey: 'bogiePivotPitch'
    }, {
      dataKey: 'innerWheelbase'
    }, {
      dataKey: 'maxLengthOfLoad'
    }, {
      dataKey: 'loadArea'
    }, {
      dataKey: 'loadingCapacity'
    }, {
      dataKey: 'heightOfLoadingPlaneUnladen'
    }, {
      dataKey: 'tankMaterial'
    }, {
      dataKey: 'hasTankCoating'
    }, {
      dataKey: 'coatingMaterial'
    }, {
      dataKey: 'hasHeating'
    }, {
      dataKey: 'hasInsulation'
    }, {
      dataKey: 'removableAccessories'
    }, {
      dataKey: 'vapourReturnSystem'
    }, {
      dataKey: 'numberOfBrakes'
    }, {
      dataKey: 'brakeSystem'
    }, {
      dataKey: 'airbrakeType'
    }, {
      dataKey: 'brakingPowerVariationDevice'
    }, {
      dataKey: 'airbrakedMass'
    }, {
      dataKey: 'changeoverWeight'
    }, {
      dataKey: 'airbrakedMassLoadedWithChangeoverWeight'
    }, {
      dataKey: 'brakeSpecialCharacteristics'
    }, {
      dataKey: 'handbrakeType'
    }, {
      dataKey: 'handbrakedWeight'
    }, {
      dataKey: 'parkingBrakeForce'
    }, {
      dataKey: 'maxInclination'
    }, {
      dataKey: 'brakeBlock'
    }, {
      dataKey: 'loadLimits'
    }, {
      dataKey: 'combinedTransportWagonType'
    }, {
      dataKey: 'gcuWagon'
    }, {
      dataKey: 'interopCapability'
    }, {
      dataKey: 'multilateralAuthorisationCountries'
    }, {
      dataKey: 'channelTunnelPermitted'
    }, {
      dataKey: 'tankCode'
    }, {
      dataKey: 'minVerticalRadiusYardHump'
    }, {
      dataKey: 'maxDesignSpeed'
    }, {
      dataKey: 'minCurveRadius'
    }, {
      dataKey: 'ferryPermittedFlag'
    }, {
      dataKey: 'ferryRampAngle'
    }, {
      dataKey: 'minTemp'
    }, {
      dataKey: 'maxTemp'
    }, {
      dataKey: 'noise'
    }, {
      dataKey: 'noiseByPassLimit'
    }, {
      dataKey: 'technicalForwardingRestrictions'
    }, {
      dataKey: 'dateLastOverhaul'
    }, {
      dataKey: 'typeLastOverhaul'
    }, {
      dataKey: 'workshopLastOverhaul'
    }, {
      dataKey: 'dateNextOverhaul'
    }, {
      dataKey: 'permittedTolerance'
    }, {
      dataKey: 'dateNextTankInspection'
    }, {
      dataKey: 'maintenancePlanRef'
    }, {
      dataKey: 'dateSpecialExamination'
    }, {
      dataKey: 'descriptionSpecialExamination'
    }, {
      dataKey: 'ecmCompanyCode'
    }, {
      dataKey: 'ecmName'
    }, {
      dataKey: 'currentEcmAssignedUntil'
    }, {
      dataKey: 'subsequentEcmCompanyCode'
    }, {
      dataKey: 'subsequentEcm'
    }, {
      dataKey: 'ein'
    }, {
      dataKey: 'ecmCertificateValidFrom'
    }, {
      dataKey: 'ecmCertificateValidTo'
    }, {
      dataKey: 'ecmCoversTankWagonsForDangerousGoods'
    }, {
      dataKey: 'ecmCoversNonTankWagonsForDangerousGoods'
    }, {
      dataKey: 'ecmCertificateSuspended'
    }, {
      dataKey: 'ecmDateCertificateSuspended'
    }, {
      dataKey: 'nsaCompanyCode'
    }, {
      dataKey: 'nsaName'
    }, {
      dataKey: 'authorisationReference'
    }, {
      dataKey: 'authorisationDate'
    }, {
      dataKey: 'authorisationValidUntil'
    }, {
      dataKey: 'suspensionOfAuthorisation'
    }, {
      dataKey: 'dateSuspensionOfAuthorisation'
    }, {
      dataKey: 'notifiedBodyCompanyCode'
    }, {
      dataKey: 'notifiedBodyName'
    }, {
      dataKey: 'ecVerificationDate'
    }, {
      dataKey: 'ecDeclarationVerificationReference'
    }, {
      dataKey: 'eratvReference'
    }, {
      dataKey: 'additionalCertification'
    }],
    dataGroups: [{
      groupKey: 'common',
      selection: 'all'
    }, {
      groupKey: 'rsrdCommon',
      selection: 'all'
    }, {
      groupKey: 'chassis',
      selection: 'all'
    }, {
      groupKey: 'superstructure',
      selection: 'all'
    }, {
      groupKey: 'brakeSystem',
      selection: 'all'
    }, {
      groupKey: 'usage',
      selection: 'all'
    }, {
      groupKey: 'maintenance',
      selection: 'all'
    }, {
      groupKey: 'ecm',
      selection: 'all'
    }, {
      groupKey: 'authorisation',
      selection: 'all'
    }, {
      groupKey: 'ecVerification',
      selection: 'all'
    }]
  }
};
exports.FLEET_ADMINISTRATION_WIDGET = FLEET_ADMINISTRATION_WIDGET;
var TWOWAY_COMMUNICATION_WIDGET = {
  title: 'widgets.2wayCommunication.title',
  type: 30,
  icon: _nicbaseIcons.TwoWayCommunication,
  settings: {}
};
exports.TWOWAY_COMMUNICATION_WIDGET = TWOWAY_COMMUNICATION_WIDGET;
var ALARM_CONNECTOR_WIDGET = {
  title: 'widgets.alarmConnector.title',
  type: 31,
  icon: _nicbaseIcons.AlarmConnector,
  settings: {
    assetToAlarmCols: [{
      dataKey: 'name',
      width: 264
    }, {
      dataKey: 'type',
      width: 140
    }, {
      dataKey: 'assignedAlarms',
      width: 260
    }, {
      dataKey: 'actions',
      width: 100
    }],
    alarmToAssetCols: [{
      dataKey: 'flag',
      width: 64
    }, {
      dataKey: 'name',
      width: 300
    }, {
      dataKey: 'assignedAssets',
      width: 100
    }, {
      dataKey: 'notification',
      width: 100
    }, {
      dataKey: 'active',
      width: 100
    }, {
      dataKey: 'actions',
      width: 100
    }]
  }
};
exports.ALARM_CONNECTOR_WIDGET = ALARM_CONNECTOR_WIDGET;
var REPORTING_WIDGET = {
  title: 'widgets.reporting.title',
  type: 33,
  icon: _nicbaseIcons.ReportWidget,
  settings: {}
};
exports.REPORTING_WIDGET = REPORTING_WIDGET;
var TRAIN_COMPOSITION_WIDGET = {
  title: 'widgets.trainComposition.title',
  type: 35,
  icon: _nicbaseIcons.TrainComposition,
  settings: {}
};
exports.TRAIN_COMPOSITION_WIDGET = TRAIN_COMPOSITION_WIDGET;
var ALARM_MANAGEMENT_WIDGET = {
  title: 'widgets.alarmManagement.title',
  type: 40,
  icon: _nicbaseIcons.AlarmManagement,
  settings: {}
};
exports.ALARM_MANAGEMENT_WIDGET = ALARM_MANAGEMENT_WIDGET;
var RENTAL_TOOL_WIDGET = {
  title: 'widgets.rentalTool.title',
  type: 43,
  icon: _nicbaseIcons.RentalTool,
  settings: {}
};
exports.RENTAL_TOOL_WIDGET = RENTAL_TOOL_WIDGET;
var ROUTE_ANALYSIS_WIDGET = {
  title: 'widgets.routeAnalysisTool.title',
  type: 47,
  icon: _nicbaseIcons.RouteAnalysis,
  settings: {}
};
exports.ROUTE_ANALYSIS_WIDGET = ROUTE_ANALYSIS_WIDGET;
var ARCHIVE = {
  title: 'widgets.archive.title',
  type: 55,
  icon: _nicbaseIcons.HistoryData,
  settings: {
    visibleColumns: [{
      dataKey: 'date'
    }, {
      dataKey: 'name'
    }, {
      dataKey: 'vehicleType'
    }, {
      dataKey: 'position'
    }, {
      dataKey: 'coupledType'
    }, {
      dataKey: 'states'
    }, {
      dataKey: 'mileage'
    }, {
      dataKey: 'load'
    }, {
      dataKey: 'speed'
    }, {
      dataKey: 'voltage'
    }, {
      dataKey: 'temperatures'
    }, {
      dataKey: 'bodyTemperatures'
    }, {
      dataKey: 'setpoint1'
    }, {
      dataKey: 'setpoint2'
    }, {
      dataKey: 'setpoint3'
    }, {
      dataKey: 'reeferReturn1'
    }, {
      dataKey: 'reeferReturn2'
    }, {
      dataKey: 'reeferReturn3'
    }, {
      dataKey: 'supplyAir1'
    }, {
      dataKey: 'supplyAir2'
    }, {
      dataKey: 'supplyAir3'
    }, {
      dataKey: 'reeferFuelLevelInPercent'
    }, {
      dataKey: 'reeferFuelLevelInLiter'
    }, {
      dataKey: 'shocks'
    }, {
      dataKey: 'stopCount'
    }, {
      dataKey: 'startCount'
    }, {
      dataKey: 'batteryLevel'
    }, {
      dataKey: 'energyConsumption'
    }]
  }
};
exports.ARCHIVE = ARCHIVE;
var ADD_BUTTON = {
  type: -1,
  icon: null,
  settings: {}
};
exports.ADD_BUTTON = ADD_BUTTON;