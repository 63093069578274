"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _layoutInformationlist = require("./layout-informationlist.actions");

var _layoutAdministration = require("./layoutAdministration.actions");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _layoutInformationlist.FIND_ALL_LAYOUTS_SHORT_SUCCESS:
      {
        var body = action.body;
        var layouts = body.layouts;
        return layouts;
      }

    case _layoutAdministration.UPDATE_LAYOUT_SUCCESS:
      {
        var _body = action.body;
        var layout = _body.layout;
        var name = layout.name,
            id = layout.id;
        return state.map(function (l) {
          if (l.id !== id) {
            return l;
          }

          return {
            id: id,
            name: name
          };
        });
      }

    case _layoutAdministration.CREATE_LAYOUT_SUCCESS:
      {
        var _body2 = action.body;
        var _layout = _body2.layout;
        var _name = _layout.name,
            _id = _layout.id;
        return [].concat(_toConsumableArray(state), [{
          id: _id,
          name: _name
        }]);
      }

    case _layoutAdministration.DELETE_LAYOUT_SUCCESS:
      {
        var _body3 = action.body;
        var _layout2 = _body3.layout;
        return state.filter(function (l) {
          return l.id !== _layout2.id;
        });
      }

    default:
      return state;
  }
};

exports["default"] = _default;