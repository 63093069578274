"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getNonHistoricalAssetInfos = exports.getHistoricalCoupledAssetInfos = exports.getHistoricalAssetInfos = exports.getCoupledAssetInfos = exports.getAssetTypeInfos = exports.getAssetInfos = exports.getAssetGroupInfos = exports["default"] = void 0;

var _reselect = require("reselect");

var _activePortal = require("../activePortal");

var _assetInformation = require("./asset-information.actions");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var compare = function compare(a, b) {
  if (a.name === null) {
    return 1;
  }

  if (b.name === null) {
    return -1;
  }

  return a.name.localeCompare(b.name);
};

var handleAddAsset = function handleAddAsset(assets, asset) {
  var index = assets.findIndex(function (item) {
    return item.id === asset.id;
  });
  var newAssets;

  if (index > -1) {
    newAssets = [].concat(_toConsumableArray(assets.slice(0, index)), [asset], _toConsumableArray(assets.slice(index + 1)));
  } else {
    newAssets = assets.concat(asset);
  }

  newAssets.sort(compare);
  return newAssets;
};

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    coupledAssets: [],
    assets: [],
    assetGroups: [],
    vehicleAssetTypes: [],
    hardwareAssetTypes: [],
    historicalAssets: [],
    historicalCoupledAssets: []
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _activePortal.CHANGE_ACTIVE_PORTAL:
      {
        return {
          coupledAssets: [],
          assets: [],
          assetGroups: [],
          vehicleAssetTypes: [],
          hardwareAssetTypes: [],
          historicalAssets: [],
          historicalCoupledAssets: []
        };
      }

    case _assetInformation.LOAD_ASSETS_SUCCESS:
    case _assetInformation.ADD_ASSETS_SUCCESS:
      {
        var assets = action.body.assets;

        if (!Array.isArray(assets)) {
          return state;
        }

        assets.sort(compare);
        return Object.assign({}, state, {
          assets: assets
        });
      }

    case _assetInformation.ADD_ASSET_SUCCESS:
      {
        var _assets = state.assets;
        var asset = action.body.asset;
        return Object.assign({}, state, {
          assets: handleAddAsset(_assets, asset)
        });
      }

    case _assetInformation.LOAD_COUPLED_ASSETS_SUCCESS:
    case _assetInformation.ADD_COUPLED_ASSETS_SUCCESS:
      {
        var coupledAssets = action.body.coupledAssets;

        if (!Array.isArray(coupledAssets)) {
          return state;
        }

        coupledAssets.sort(compare);
        return Object.assign({}, state, {
          coupledAssets: coupledAssets
        });
      }

    case _assetInformation.ADD_COUPLED_ASSET_SUCCESS:
      {
        var _coupledAssets = state.coupledAssets;
        var coupledAsset = action.body.coupledAsset;
        return Object.assign({}, state, {
          coupledAssets: handleAddAsset(_coupledAssets, coupledAsset)
        });
      }

    case _assetInformation.DELETE_COUPLED_ASSET_SUCCESS:
      {
        var _coupledAsset = action.body.coupledAsset;
        return Object.assign({}, state, {
          coupledAssets: state.coupledAssets.filter(function (item) {
            return item.id !== _coupledAsset.id;
          })
        });
      }

    case _assetInformation.DELETE_ASSET_SUCCESS:
      {
        var _asset = action.body.asset;
        return Object.assign({}, state, {
          assets: state.assets.filter(function (item) {
            return item.id !== _asset.id;
          })
        });
      }

    case _assetInformation.LOAD_ASSETS_HISTORICAL_SUCCESS:
    case _assetInformation.ADD_ASSETS_HISTORICAL_SUCCESS:
      {
        var _assets2 = action.body.assets;

        if (!Array.isArray(_assets2)) {
          return state;
        }

        _assets2.sort(compare);

        return Object.assign({}, state, {
          historicalAssets: _assets2
        });
      }

    case _assetInformation.LOAD_COUPLED_ASSETS_HISTORICAL_SUCCESS:
    case _assetInformation.ADD_COUPLED_ASSETS_HISTORICAL_SUCCESS:
      {
        var _coupledAssets2 = action.body.coupledAssets;

        if (!Array.isArray(_coupledAssets2)) {
          return state;
        }

        _coupledAssets2.sort(compare);

        return Object.assign({}, state, {
          historicalCoupledAssets: _coupledAssets2
        });
      }

    case _assetInformation.LOAD_ASSET_GROUPS_SUCCESS:
    case _assetInformation.ADD_ASSET_GROUPS_SUCCESS:
      {
        var assetGroups = action.body.assetGroups;

        if (!Array.isArray(assetGroups)) {
          return state;
        }

        return Object.assign({}, state, {
          assetGroups: assetGroups.sort(compare)
        });
      }

    case _assetInformation.ADD_ASSET_GROUP_SUCCESS:
      {
        var _assetGroups = state.assetGroups;
        var _action$body = action.body,
            assetGroup = _action$body.assetGroup,
            assetgroup = _action$body.assetgroup;
        var newGroup = assetGroup || assetgroup;

        var index = _assetGroups.findIndex(function (group) {
          return group.id === newGroup.id;
        });

        var newAssetGroups = [];

        if (index > -1) {
          newAssetGroups = [].concat(_toConsumableArray(_assetGroups.slice(0, index)), [newGroup], _toConsumableArray(_assetGroups.slice(index + 1)));
        } else {
          newAssetGroups = _assetGroups.concat(newGroup);
        }

        newAssetGroups.sort(compare);
        return Object.assign({}, state, {
          assetGroups: newAssetGroups
        });
      }

    case _assetInformation.DELETE_ASSET_GROUP_SUCCESS:
      {
        var _assetGroup = action.body.assetGroup;
        return Object.assign({}, state, {
          assetGroups: state.assetGroups.filter(function (group) {
            return group.id !== _assetGroup.id;
          })
        });
      }

    case _assetInformation.FIND_ALL_VEHICLE_ASSET_TYPES_SUCCESS:
    case _assetInformation.FIND_ALL_VEHICLE_ASSET_TYPES_FOR_ADMIN_TOOL_SUCCESS:
      {
        var vehicleAssetTypes = action.body.vehicleAssetTypes;

        if (!Array.isArray(vehicleAssetTypes)) {
          return state;
        }

        vehicleAssetTypes.sort(compare);
        return Object.assign({}, state, {
          vehicleAssetTypes: vehicleAssetTypes
        });
      }

    default:
      return state;
  }
};

exports["default"] = _default;
var getAssetInfos = (0, _reselect.createSelector)([function (state) {
  var _state$assetInformati;

  return (_state$assetInformati = state.assetInformationLists) === null || _state$assetInformati === void 0 ? void 0 : _state$assetInformati.assets;
}], function (assets) {
  return assets;
});
exports.getAssetInfos = getAssetInfos;
var getAssetGroupInfos = (0, _reselect.createSelector)([function (state) {
  var _state$assetInformati2;

  return (_state$assetInformati2 = state.assetInformationLists) === null || _state$assetInformati2 === void 0 ? void 0 : _state$assetInformati2.assetGroups;
}], function (assetGroups) {
  return assetGroups;
});
exports.getAssetGroupInfos = getAssetGroupInfos;
var getCoupledAssetInfos = (0, _reselect.createSelector)([function (state) {
  var _state$assetInformati3;

  return (_state$assetInformati3 = state.assetInformationLists) === null || _state$assetInformati3 === void 0 ? void 0 : _state$assetInformati3.coupledAssets;
}], function (coupledAssets) {
  return coupledAssets;
});
exports.getCoupledAssetInfos = getCoupledAssetInfos;
var getAssetTypeInfos = (0, _reselect.createSelector)([function (state) {
  var _state$assetInformati4;

  return (_state$assetInformati4 = state.assetInformationLists) === null || _state$assetInformati4 === void 0 ? void 0 : _state$assetInformati4.vehicleAssetTypes;
}], function (assetTypes) {
  return assetTypes;
});
exports.getAssetTypeInfos = getAssetTypeInfos;
var getHistoricalAssetInfos = (0, _reselect.createSelector)([function (state) {
  var _state$assetInformati5;

  return (_state$assetInformati5 = state.assetInformationLists) === null || _state$assetInformati5 === void 0 ? void 0 : _state$assetInformati5.historicalAssets;
}], function (historicalAssets) {
  return historicalAssets;
});
exports.getHistoricalAssetInfos = getHistoricalAssetInfos;
var getHistoricalCoupledAssetInfos = (0, _reselect.createSelector)([function (state) {
  var _state$assetInformati6;

  return (_state$assetInformati6 = state.assetInformationLists) === null || _state$assetInformati6 === void 0 ? void 0 : _state$assetInformati6.historicalCoupledAssets;
}], function (coupledAsset) {
  return coupledAsset;
});
exports.getHistoricalCoupledAssetInfos = getHistoricalCoupledAssetInfos;
var getNonHistoricalAssetInfos = (0, _reselect.createSelector)([getAssetInfos, getCoupledAssetInfos], function (assets, coupledAssets) {
  return [].concat(_toConsumableArray(assets), _toConsumableArray(coupledAssets));
});
exports.getNonHistoricalAssetInfos = getNonHistoricalAssetInfos;