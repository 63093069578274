"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_POI_SUCCESS = exports.UPDATE_POI_REQUEST = exports.UPDATE_POI_ERROR = exports.GENERATE_POI_EXPORT_SUCCESS = exports.GENERATE_POI_EXPORT_REQUEST = exports.GENERATE_POI_EXPORT_ERROR = exports.FIND_POIS_SUCCESS = exports.FIND_POIS_REQUEST = exports.FIND_POIS_ERROR = exports.DELETE_POI_SUCCESS = exports.DELETE_POI_REQUEST = exports.DELETE_POI_ERROR = exports.CREATE_POI_SUCCESS = exports.CREATE_POI_REQUEST = exports.CREATE_POI_MONITOR_EXPORT_SUCCESS = exports.CREATE_POI_MONITOR_EXPORT_REQUEST = exports.CREATE_POI_MONITOR_EXPORT_ERROR = exports.CREATE_POI_ERROR = void 0;
exports.createPOI = createPOI;
exports.createPoiMonitorExport = exports.createPoiAdministrationExport = void 0;
exports.deletePOI = deletePOI;
exports.getPOIs = getPOIs;
exports.updatePOI = updatePOI;
// POI List for Admin
var FIND_POIS_SUCCESS = 'FIND_POIS_SUCCESS';
exports.FIND_POIS_SUCCESS = FIND_POIS_SUCCESS;
var FIND_POIS_ERROR = 'FIND_POIS_ERROR';
exports.FIND_POIS_ERROR = FIND_POIS_ERROR;
var FIND_POIS_REQUEST = 'FIND_POIS_REQUEST';
exports.FIND_POIS_REQUEST = FIND_POIS_REQUEST;

function getPOIs(portalId) {
  return {
    type: FIND_POIS_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {}
  };
}

var CREATE_POI_SUCCESS = 'CREATE_POI_SUCCESS';
exports.CREATE_POI_SUCCESS = CREATE_POI_SUCCESS;
var CREATE_POI_ERROR = 'CREATE_POI_ERROR';
exports.CREATE_POI_ERROR = CREATE_POI_ERROR;
var CREATE_POI_REQUEST = 'CREATE_POI_REQUEST';
exports.CREATE_POI_REQUEST = CREATE_POI_REQUEST;

function createPOI() {
  var poi = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: CREATE_POI_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      poi: poi
    }
  };
}

var UPDATE_POI_SUCCESS = 'UPDATE_POI_SUCCESS';
exports.UPDATE_POI_SUCCESS = UPDATE_POI_SUCCESS;
var UPDATE_POI_ERROR = 'UPDATE_POI_ERROR';
exports.UPDATE_POI_ERROR = UPDATE_POI_ERROR;
var UPDATE_POI_REQUEST = 'UPDATE_POI_REQUEST';
exports.UPDATE_POI_REQUEST = UPDATE_POI_REQUEST;

function updatePOI() {
  var poi = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var portalId = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: UPDATE_POI_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      poi: poi
    }
  };
}

var DELETE_POI_SUCCESS = 'DELETE_POI_SUCCESS';
exports.DELETE_POI_SUCCESS = DELETE_POI_SUCCESS;
var DELETE_POI_ERROR = 'DELETE_POI_ERROR';
exports.DELETE_POI_ERROR = DELETE_POI_ERROR;
var DELETE_POI_REQUEST = 'DELETE_POI_REQUEST';
exports.DELETE_POI_REQUEST = DELETE_POI_REQUEST;

function deletePOI(poi, portalId) {
  return {
    type: DELETE_POI_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {
      poi: poi
    }
  };
}

var CREATE_POI_MONITOR_EXPORT_REQUEST = 'CREATE_POI_MONITOR_EXPORT_REQUEST';
exports.CREATE_POI_MONITOR_EXPORT_REQUEST = CREATE_POI_MONITOR_EXPORT_REQUEST;
var CREATE_POI_MONITOR_EXPORT_SUCCESS = 'CREATE_POI_MONITOR_EXPORT_SUCCESS';
exports.CREATE_POI_MONITOR_EXPORT_SUCCESS = CREATE_POI_MONITOR_EXPORT_SUCCESS;
var CREATE_POI_MONITOR_EXPORT_ERROR = 'CREATE_POI_MONITOR_EXPORT_ERROR';
exports.CREATE_POI_MONITOR_EXPORT_ERROR = CREATE_POI_MONITOR_EXPORT_ERROR;

var createPoiMonitorExport = function createPoiMonitorExport(_ref) {
  var widgetId = _ref.widgetId,
      filterNodes = _ref.filterNodes,
      settings = _ref.settings,
      visibleColumns = _ref.visibleColumns,
      portalId = _ref.portalId;
  return {
    type: CREATE_POI_MONITOR_EXPORT_REQUEST,
    headers: {
      version: 'v1',
      widgetId: widgetId,
      portalId: portalId
    },
    body: {
      filters: {
        dynamicFilters: filterNodes,
        dynamicSorters: null,
        dynamicPaging: null
      },
      settings: settings,
      visibleColumns: visibleColumns
    }
  };
};

exports.createPoiMonitorExport = createPoiMonitorExport;
var GENERATE_POI_EXPORT_REQUEST = 'GENERATE_POI_EXPORT_REQUEST';
exports.GENERATE_POI_EXPORT_REQUEST = GENERATE_POI_EXPORT_REQUEST;
var GENERATE_POI_EXPORT_SUCCESS = 'GENERATE_POI_EXPORT_SUCCESS';
exports.GENERATE_POI_EXPORT_SUCCESS = GENERATE_POI_EXPORT_SUCCESS;
var GENERATE_POI_EXPORT_ERROR = 'GENERATE_POI_EXPORT_ERROR';
exports.GENERATE_POI_EXPORT_ERROR = GENERATE_POI_EXPORT_ERROR;

var createPoiAdministrationExport = function createPoiAdministrationExport(portalId) {
  return {
    type: GENERATE_POI_EXPORT_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    },
    body: {}
  };
};

exports.createPoiAdministrationExport = createPoiAdministrationExport;