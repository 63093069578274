"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllDamageCodes = exports.LIST_DAMAGE_CODES_SUCCESS = exports.LIST_DAMAGE_CODES_REQUEST = void 0;
var LIST_DAMAGE_CODES_REQUEST = 'LIST_DAMAGE_CODES_REQUEST';
exports.LIST_DAMAGE_CODES_REQUEST = LIST_DAMAGE_CODES_REQUEST;
var LIST_DAMAGE_CODES_SUCCESS = 'LIST_DAMAGE_CODES_SUCCESS';
exports.LIST_DAMAGE_CODES_SUCCESS = LIST_DAMAGE_CODES_SUCCESS;

var getAllDamageCodes = function getAllDamageCodes(portalId) {
  return {
    type: LIST_DAMAGE_CODES_REQUEST,
    headers: {
      version: 'v1',
      portalId: portalId
    }
  };
};

exports.getAllDamageCodes = getAllDamageCodes;