import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Settings } from 'nicbase-icons';
import { authorize } from 'nicbase-security';
import { injectIntl, intlShape } from 'nicbase-translation';

export const AdminToolButton = ({ responsiveMode, intl }) => {
  if (responsiveMode.mode !== 'desktop' && responsiveMode.mode !== 'desktop-large') {
    return null;
  }
  return (
    <NavLink to="/admin" className="icon settings-icon" data-for="tool-tip-right" data-tip={intl.formatMessage({ id: 'navigation.settings' })}>
      <span className="pointer-events-none" title={intl.formatMessage({ id: 'navigation.settings' })}>
        <Settings className="mx-auto" />
      </span>
      <Route path="/admin" render={() => <div className="arrow" />} />
    </NavLink>
  );
};

AdminToolButton.propTypes = {
  responsiveMode: PropTypes.shape({ mode: PropTypes.string }).isRequired,
  intl: intlShape.isRequired,
};
AdminToolButton.defaultProps = {};

export default authorize('BASE')(['admintool'])(injectIntl(AdminToolButton));
