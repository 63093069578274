"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LatestDataSimpleStatusType = exports.LatestDataPowerType = exports.LatestDataOperationType = exports.LatestDataErrorCodeStatusType = exports.LatestDataErrorCodeSeverity = exports.LatestDataErrorCodeManufacturer = exports.LatestDataAlarmStatusType = void 0;
var LatestDataSimpleStatusType;
exports.LatestDataSimpleStatusType = LatestDataSimpleStatusType;

(function (LatestDataSimpleStatusType) {
  LatestDataSimpleStatusType["Driving"] = "DRIVING";
  LatestDataSimpleStatusType["Standing"] = "STANDING";
  LatestDataSimpleStatusType["ReeferOn"] = "REEFER_ON";
  LatestDataSimpleStatusType["ReeferOff"] = "REEFER_OFF";
  LatestDataSimpleStatusType["Door1Open"] = "DOOR_1_OPEN";
  LatestDataSimpleStatusType["Door1Closed"] = "DOOR_1_CLOSED";
  LatestDataSimpleStatusType["Door2Open"] = "DOOR_2_OPEN";
  LatestDataSimpleStatusType["Door2Closed"] = "DOOR_2_CLOSED";
  LatestDataSimpleStatusType["Door3Open"] = "DOOR_3_OPEN";
  LatestDataSimpleStatusType["Door3Closed"] = "DOOR_3_CLOSED";
  LatestDataSimpleStatusType["Coupled"] = "COUPLED";
  LatestDataSimpleStatusType["NotCoupled"] = "NOT_COUPLED";
  LatestDataSimpleStatusType["Loaded"] = "LOADED";
  LatestDataSimpleStatusType["NotLoaded"] = "NOT_LOADED";
  LatestDataSimpleStatusType["FuelReserve"] = "FUEL_RESERVE";
  LatestDataSimpleStatusType["NoFuelReserve"] = "NO_FUEL_RESERVE";
  LatestDataSimpleStatusType["NoGps"] = "NO_GPS";
  LatestDataSimpleStatusType["DtcNotification"] = "DTC_NOTIFICATION";
  LatestDataSimpleStatusType["DtcCaution"] = "DTC_CAUTION";
  LatestDataSimpleStatusType["DtcAlarm"] = "DTC_ALARM";
  LatestDataSimpleStatusType["FlatSpot"] = "FLAT_SPOT";
  LatestDataSimpleStatusType["Evaporator1Defrost"] = "EVAPORATOR_1_DEFROST";
  LatestDataSimpleStatusType["PanicButtonActive"] = "PANIC_BUTTON_ACTIVE";
  LatestDataSimpleStatusType["PanicButtonInactive"] = "PANIC_BUTTON_INACTIVE";
  LatestDataSimpleStatusType["DoorLockOpen"] = "DOOR_LOCK_OPEN";
  LatestDataSimpleStatusType["DoorLockClosed"] = "DOOR_LOCK_CLOSED";
  LatestDataSimpleStatusType["DoorLockUnlocked"] = "DOOR_LOCK_UNLOCKED";
  LatestDataSimpleStatusType["DoorLockLocked"] = "DOOR_LOCK_LOCKED";
  LatestDataSimpleStatusType["DoorLockModeSecure"] = "DOOR_LOCK_MODE_SECURE";
  LatestDataSimpleStatusType["DoorLockModeNormal"] = "DOOR_LOCK_MODE_NORMAL";
})(LatestDataSimpleStatusType || (exports.LatestDataSimpleStatusType = LatestDataSimpleStatusType = {}));

var LatestDataAlarmStatusType;
exports.LatestDataAlarmStatusType = LatestDataAlarmStatusType;

(function (LatestDataAlarmStatusType) {
  LatestDataAlarmStatusType["GreenAlarm"] = "GREEN_ALARM";
  LatestDataAlarmStatusType["YellowAlarm"] = "YELLOW_ALARM";
  LatestDataAlarmStatusType["RedAlarm"] = "RED_ALARM";
})(LatestDataAlarmStatusType || (exports.LatestDataAlarmStatusType = LatestDataAlarmStatusType = {}));

var LatestDataErrorCodeStatusType;
exports.LatestDataErrorCodeStatusType = LatestDataErrorCodeStatusType;

(function (LatestDataErrorCodeStatusType) {
  LatestDataErrorCodeStatusType["TkAlert"] = "TK_ALERT";
  LatestDataErrorCodeStatusType["TkCaution"] = "TK_CAUTION";
  LatestDataErrorCodeStatusType["TkNotification"] = "TK_NOTIFICATION";
  LatestDataErrorCodeStatusType["TkInformation"] = "TK_INFORMATION";
  LatestDataErrorCodeStatusType["CbtAlert"] = "CBT_ALERT";
  LatestDataErrorCodeStatusType["CbtCaution"] = "CBT_CAUTION";
  LatestDataErrorCodeStatusType["CbtNotification"] = "CBT_NOTIFICATION";
  LatestDataErrorCodeStatusType["CbtInformation"] = "CBT_INFORMATION";
  LatestDataErrorCodeStatusType["CarrierAlert"] = "CARRIER_ALERT";
  LatestDataErrorCodeStatusType["CarrierCaution"] = "CARRIER_CAUTION";
  LatestDataErrorCodeStatusType["CarrierNotification"] = "CARRIER_NOTIFICATION";
  LatestDataErrorCodeStatusType["CarrierInformation"] = "CARRIER_INFORMATION";
})(LatestDataErrorCodeStatusType || (exports.LatestDataErrorCodeStatusType = LatestDataErrorCodeStatusType = {}));

var LatestDataErrorCodeSeverity;
exports.LatestDataErrorCodeSeverity = LatestDataErrorCodeSeverity;

(function (LatestDataErrorCodeSeverity) {
  LatestDataErrorCodeSeverity["Alert"] = "ALERT";
  LatestDataErrorCodeSeverity["Caution"] = "CAUTION";
  LatestDataErrorCodeSeverity["Notification"] = "NOTIFICATION";
  LatestDataErrorCodeSeverity["Information"] = "INFORMATION";
  LatestDataErrorCodeSeverity["Unknown"] = "UNKNOWN";
})(LatestDataErrorCodeSeverity || (exports.LatestDataErrorCodeSeverity = LatestDataErrorCodeSeverity = {}));

var LatestDataErrorCodeManufacturer;
exports.LatestDataErrorCodeManufacturer = LatestDataErrorCodeManufacturer;

(function (LatestDataErrorCodeManufacturer) {
  LatestDataErrorCodeManufacturer["Carrier"] = "CARRIER";
  LatestDataErrorCodeManufacturer["Cbt"] = "CBT";
  LatestDataErrorCodeManufacturer["Tk"] = "TK";
})(LatestDataErrorCodeManufacturer || (exports.LatestDataErrorCodeManufacturer = LatestDataErrorCodeManufacturer = {}));

var LatestDataPowerType;
exports.LatestDataPowerType = LatestDataPowerType;

(function (LatestDataPowerType) {
  LatestDataPowerType["Diesel"] = "DIESEL";
  LatestDataPowerType["Electric"] = "ELECTRIC";
  LatestDataPowerType["Undefined"] = "UNDEFINED";
})(LatestDataPowerType || (exports.LatestDataPowerType = LatestDataPowerType = {}));

var LatestDataOperationType;
exports.LatestDataOperationType = LatestDataOperationType;

(function (LatestDataOperationType) {
  LatestDataOperationType["Continuous"] = "CONTINUOUS";
  LatestDataOperationType["StartStop"] = "START_STOP";
  LatestDataOperationType["HighSpeed"] = "HIGH_SPEED";
})(LatestDataOperationType || (exports.LatestDataOperationType = LatestDataOperationType = {}));