"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducerDefinitions = exports["default"] = void 0;

var _activatedAlarms = _interopRequireDefault(require("../activated-alarms/activated-alarms.reducer"));

var _activePortal = _interopRequireDefault(require("../activePortal/activePortal.reducer"));

var _activeWorkspaceId = _interopRequireDefault(require("../activeWorkspaceId/activeWorkspaceId.reducer"));

var _addressSearchMaps = _interopRequireDefault(require("../address-search-maps/address-search-maps.reducer"));

var _admin = _interopRequireDefault(require("../admin"));

var _alarmDetails = _interopRequireDefault(require("../alarm-administration/alarmDetails.reducer"));

var _alarms = _interopRequireDefault(require("../alarm-administration/alarms.reducer"));

var _alarmInformationList = _interopRequireDefault(require("../alarm-information-list/alarm-information-list.reducer"));

var _appVariables = _interopRequireDefault(require("../appVariables/appVariables.reducer"));

var _assets = _interopRequireDefault(require("../asset-administration/assets.reducer"));

var _unassignedHwAssets = _interopRequireDefault(require("../asset-administration/unassignedHwAssets.reducer"));

var _assetInformation = _interopRequireDefault(require("../asset-information-lists/asset-information.reducer"));

var _assetGroups = _interopRequireDefault(require("../assetGroups/assetGroups.reducer"));

var _assets2 = _interopRequireDefault(require("../assets/assets.reducer"));

var _coupledAssets = _interopRequireDefault(require("../coupledAssets/coupledAssets.reducer"));

var _codes = _interopRequireDefault(require("../damage-codes/codes.reducer"));

var _reporters = _interopRequireDefault(require("../damage-reporters/reporters.reducer"));

var _dataRequest = _interopRequireDefault(require("../dataRequest/dataRequest.reducer"));

var _details = _interopRequireDefault(require("../detailData/details.reducer"));

var _dialogs = _interopRequireDefault(require("../dialogs/dialogs.reducer"));

var _filters = _interopRequireDefault(require("../filters/filters.reducer"));

var _fleetAdministration = require("../fleet-administration");

var _fleetCheck = require("../fleet-check");

var _gatewayAccounts = require("../gatewayAccounts");

var _gatewayProviders = require("../gatewayProviders");

var _index = _interopRequireDefault(require("../gateways/index"));

var _geofencesMileage = _interopRequireDefault(require("../geofences-milage/geofences-mileage.reducer"));

var _historyData = _interopRequireDefault(require("../history-data/history-data.reducer"));

var _contentBox = _interopRequireDefault(require("../home/content-box.reducer"));

var _capacity = _interopRequireDefault(require("../information-lists/capacity.reducer"));

var _department = _interopRequireDefault(require("../information-lists/department.reducer"));

var _tourboard = _interopRequireDefault(require("../latest-data-neo/tourboard.reducer"));

var _layoutInformationlistReducer = _interopRequireDefault(require("../layout-administration/layout-informationlist-reducer"));

var _linkedPortalsInformationList = _interopRequireDefault(require("../linked-portals-information-list/linked-portals-information-list.reducer"));

var _notifications = _interopRequireDefault(require("../notifications/notifications.reducers"));

var _permissions = _interopRequireDefault(require("../permissions/permissions.reducer"));

var _poiCategories = _interopRequireDefault(require("../poi-categories/poi-categories.reducer"));

var _poiCategoryInformationList = _interopRequireDefault(require("../poi-category-information-list/poi-category-information-list.reducer"));

var _poiInformationList = _interopRequireDefault(require("../poi-information-list/poi-information-list.reducer"));

var _pois = _interopRequireDefault(require("../pois/pois.reducer"));

var _portal = require("../portal");

var _portalsConnected = _interopRequireDefault(require("../portal/portals-connected.reducer"));

var _precoolMonitor = require("../precool-monitor");

var _index2 = _interopRequireDefault(require("../providers/index"));

var _rentalTool = _interopRequireDefault(require("../rentalTool/rental-tool.reducer"));

var _reports = _interopRequireDefault(require("../reports"));

var _routeAnalysis = _interopRequireDefault(require("../routeAnalysisTool/route-analysis.reducer"));

var _routeDirection = _interopRequireDefault(require("../routeData/routeDirection.reducer"));

var _setpointMonitor = require("../setpoint-monitor");

var _socketReducer = _interopRequireDefault(require("../socket/socketReducer"));

var _standingTimes = require("../standing-times");

var _systemExchange = _interopRequireDefault(require("../system-exchange/system-exchange.reducer"));

var _tempChart = _interopRequireDefault(require("../tempChart/temp-chart.reducer"));

var _test = _interopRequireDefault(require("../testData/test.reducer"));

var _tourDataView = _interopRequireDefault(require("../tourDataView/tourDataView.reducer"));

var _translationReducer = _interopRequireDefault(require("../translation/translationReducer"));

var _twowayCommunication = _interopRequireDefault(require("../twoway-communication"));

var _user = _interopRequireDefault(require("../user/user.reducer"));

var _assignableUsergroups = _interopRequireDefault(require("../user-administration/assignableUsergroups.reducer"));

var _usergroups = _interopRequireDefault(require("../user-administration/usergroups.reducer"));

var _users = _interopRequireDefault(require("../user-administration/users.reducer"));

var _index3 = _interopRequireDefault(require("../user-profile/index"));

var _userSettings = _interopRequireDefault(require("../user-settings/user-settings.reducer"));

var _widgetsReducer = _interopRequireDefault(require("../widgets/widgetsReducer"));

var _widgetTypes = _interopRequireDefault(require("../widgets/widgetTypes.reducer"));

var _workspaceTemplates = _interopRequireDefault(require("../workspace-templates/workspace-templates.reducer"));

var _workspaces = _interopRequireDefault(require("../workspaces/workspaces.reducer"));

var _injectReducer = require("./injectReducer");

var _responsive = _interopRequireDefault(require("./responsive.reducer"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var reducerDefinitions = {
  activeWorkspaceId: _activeWorkspaceId["default"],
  appVariables: _appVariables["default"],
  responsiveMode: _responsive["default"],
  workspaces: _workspaces["default"],
  filters: _filters["default"],
  reports: _reports["default"],
  widgets: _widgetsReducer["default"],
  dialogs: _dialogs["default"],
  geofencesMileage: _geofencesMileage["default"],
  // TODO ask stefan H
  tempChart: _tempChart["default"],
  routeData: _routeDirection["default"],
  dataRequest: _dataRequest["default"],
  historyData: _historyData["default"],
  home: _contentBox["default"],
  admin: _admin["default"],
  portal: {
    boxes: _portal.boxesReducer,
    assets: _assets2["default"],
    connectedPortals: _portalsConnected["default"]
  },
  portals: _portal.portalsReducer,
  activePortal: _activePortal["default"],
  user: _user["default"],
  twowayCommunication: _twowayCommunication["default"],
  gatewayProviders: _gatewayProviders.gatewayProviderReducer,
  rentalTool: _rentalTool["default"],
  gatewayAccounts: _gatewayAccounts.gatewayAccountReducer,
  precoolMonitor: _precoolMonitor.precoolReducer,
  fleetCheck: _fleetCheck.fleetCheckReducer,
  // DATA SERVICES
  alarms: _alarms["default"],
  alarmDetails: _alarmDetails["default"],
  activatedAlarms: _activatedAlarms["default"],
  assets: _assets["default"],
  coupledAssets: _coupledAssets["default"],
  fleetAdministration: _fleetAdministration.fleetAdministrationReducer,
  assetGroups: _assetGroups["default"],
  assetInformationLists: _assetInformation["default"],
  // this reducer if meant to be for all 'information list' reducers, that return a subset of the data of the main reducers
  // for example the alarm-information-list reducer that has a list of name and id only.
  informationLists: {
    alarms: _alarmInformationList["default"],
    pois: _poiInformationList["default"],
    poiCategories: _poiCategoryInformationList["default"],
    layouts: _layoutInformationlistReducer["default"],
    linkedPortals: _linkedPortalsInformationList["default"],
    capacities: _capacity["default"],
    departments: _department["default"]
  },
  users: _users["default"],
  userGroups: _usergroups["default"],
  assignableUserGroups: _assignableUsergroups["default"],
  detailData: _details["default"],
  unassignedHwAssets: _unassignedHwAssets["default"],
  tourboard: _tourboard["default"],
  userSettings: _userSettings["default"],
  translation: _translationReducer["default"],
  permissions: _permissions["default"],
  notifications: _notifications["default"],
  widgetTypes: _widgetTypes["default"],
  workspaceTemplates: _workspaceTemplates["default"],
  userProfile: _index3["default"],
  socket: _socketReducer["default"],
  gateways: _index["default"],
  providers: _index2["default"],
  pois: _pois["default"],
  poiCategories: _poiCategories["default"],
  mapsAddressSearchResult: _addressSearchMaps["default"],
  tourDataView: _tourDataView["default"],
  routeAnalysisTool: _routeAnalysis["default"],
  testData: _test["default"],
  systemExchange: _systemExchange["default"],
  damageCodes: _codes["default"],
  damageReporters: _reporters["default"],
  setpointMonitor: _setpointMonitor.setpointMonitorReducer,
  standingTimes: _standingTimes.standingTimesReducer
};
exports.reducerDefinitions = reducerDefinitions;

var _default = (0, _injectReducer.makeInitialReducer)(reducerDefinitions);

exports["default"] = _default;