"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OPEN_DIALOG = exports.CLOSE_DIALOG = void 0;
exports.closeDialog = closeDialog;
exports.openDialog = openDialog;
var OPEN_DIALOG = 'OPEN_DIALOG';
exports.OPEN_DIALOG = OPEN_DIALOG;

function openDialog(widgetId, dialogName) {
  return {
    type: OPEN_DIALOG,
    headers: {
      widgetId: widgetId
    },
    body: {
      widgetId: widgetId,
      dialogName: dialogName
    }
  };
}

var CLOSE_DIALOG = 'CLOSE_DIALOG';
exports.CLOSE_DIALOG = CLOSE_DIALOG;

function closeDialog(widgetId, dialogName) {
  return {
    type: CLOSE_DIALOG,
    headers: {
      widgetId: widgetId
    },
    body: {
      widgetId: widgetId,
      dialogName: dialogName
    }
  };
}