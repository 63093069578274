/* eslint-disable no-param-reassign */
import {
  ASSIGN_BOX_TO_PORTAL_ERROR,
  ASSIGN_BOX_TO_PORTAL_SUCCESS,
  ASSIGN_BOXES_TO_PORTAL_ERROR,
  ASSIGN_BOXES_TO_PORTAL_SUCCESS,
  AUTHENTICATE_USER_SUCCESS,
  CHANGE_ACTIVE_PORTAL,
} from 'nicbase-reducers';

const throttleAction = (actionType, handler, timeout) => {
  let collectedActions = [];
  let timeoutId;

  return store => next => action => {
    if (action.type === actionType) {
      collectedActions.push(action);

      if (!timeoutId) {
        timeoutId = setTimeout(() => {
          handler(store.dispatch, collectedActions);
          timeoutId = null;
          collectedActions = [];
        }, timeout);
      }
      return;
    }
    if (action.type === CHANGE_ACTIVE_PORTAL || action.type === AUTHENTICATE_USER_SUCCESS) {
      collectedActions = [];
    }
    next(action);
  };
};

const dispatchSuccessCollection = (dispatch, collectedActions) => {
  dispatch({ type: ASSIGN_BOXES_TO_PORTAL_SUCCESS, body: { boxes: collectedActions.map(action => action.body) } });
};

const dispatchErrorCollection = (dispatch, collectedActions) => {
  dispatch({ type: ASSIGN_BOXES_TO_PORTAL_ERROR, body: { boxes: collectedActions.map(action => action.body) } });
};

export const throttleAssetAssignSuccess = throttleAction(ASSIGN_BOX_TO_PORTAL_SUCCESS, dispatchSuccessCollection, 3000);
export const throttleAssetAssignError = throttleAction(ASSIGN_BOX_TO_PORTAL_ERROR, dispatchErrorCollection, 3000);
