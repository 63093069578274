import { AUTHENTICATE_USER_SUCCESS, CHANGE_ACTIVE_PORTAL } from 'nicbase-reducers';

// const COLLECTION_PERIOD_DEFAULT_IN_MS = 60000;

const throttleActionsMiddlewareFactory = (actionsToThrottle, dispatchHandler, COLLECTION_PERIOD_DEFAULT_IN_MS = 60000) => {
  // an array of collected data sets
  let collectedDataActions = [];
  // timestamp of the last update to the dispatcher
  let lastUpdatedTimestamp = 0;
  // the id of the timeout function currently waiting (or null)
  let timeoutFunctionId = null;

  return store => next => action => {
    if (actionsToThrottle.includes(action.type)) {
      // collect the actions
      collectedDataActions.push(action);

      // if there is already a timeout set, then let it be
      // if not, then call the timeout for COLLECTION_PERIOD_DEFAULT_IN_MS after the last calling
      if (!timeoutFunctionId) {
        const timeSinceLastUpdate = Date.now() - lastUpdatedTimestamp;
        const timeToNextUpdate = timeSinceLastUpdate > COLLECTION_PERIOD_DEFAULT_IN_MS ? 0 : COLLECTION_PERIOD_DEFAULT_IN_MS - timeSinceLastUpdate;
        timeoutFunctionId = setTimeout(() => {
          dispatchHandler(store.dispatch, collectedDataActions, next);
          // set the last updated timestamp
          lastUpdatedTimestamp = Date.now();
          // clear the timer
          timeoutFunctionId = null;
          // clear the collected actions array
          collectedDataActions = [];
        }, timeToNextUpdate);
      }

      // return the current action without allowing it to proceed to the next middleware / reducers
      return action;
    } else if (action.type === CHANGE_ACTIVE_PORTAL || action.type === AUTHENTICATE_USER_SUCCESS) {
      // we have changed the portal or changed the user, so we need to clear all the collected actions
      collectedDataActions = [];
      // also pass the action to the next middleware
    }

    // not an action we are interested in, so pass it on
    return next(action);
  };
};

export default throttleActionsMiddlewareFactory;
