"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAdminPortal = exports.findAdminPortals = exports.findAdminPortal = exports.createPortal = exports.FIND_PORTALS_SUCCESS = exports.FIND_PORTALS_REQUEST = exports.FIND_PORTALS_ERROR = exports.CREATE_PORTAL_SUCCESS = exports.CREATE_PORTAL_REQUEST = exports.CREATE_PORTAL_ERROR = exports.ADMIN_UPDATE_PORTAL_SUCCESS = exports.ADMIN_UPDATE_PORTAL_REQUEST = exports.ADMIN_UPDATE_PORTAL_ERROR = exports.ADMIN_FIND_PORTAL_SUCCESS = exports.ADMIN_FIND_PORTAL_REQUEST = exports.ADMIN_FIND_PORTAL_ERROR = void 0;
var FIND_PORTALS_REQUEST = 'FIND_PORTALS_REQUEST';
exports.FIND_PORTALS_REQUEST = FIND_PORTALS_REQUEST;
var FIND_PORTALS_SUCCESS = 'FIND_PORTALS_SUCCESS';
exports.FIND_PORTALS_SUCCESS = FIND_PORTALS_SUCCESS;
var FIND_PORTALS_ERROR = 'FIND_PORTALS_ERROR';
exports.FIND_PORTALS_ERROR = FIND_PORTALS_ERROR;

var findAdminPortals = function findAdminPortals(dynamicFilters, dynamicPaging) {
  var dynamicSorters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return {
    type: FIND_PORTALS_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      dynamicFilters: dynamicFilters,
      dynamicPaging: dynamicPaging,
      dynamicSorters: dynamicSorters
    }
  };
};

exports.findAdminPortals = findAdminPortals;
var CREATE_PORTAL_REQUEST = 'CREATE_PORTAL_REQUEST';
exports.CREATE_PORTAL_REQUEST = CREATE_PORTAL_REQUEST;
var CREATE_PORTAL_SUCCESS = 'CREATE_PORTAL_SUCCESS';
exports.CREATE_PORTAL_SUCCESS = CREATE_PORTAL_SUCCESS;
var CREATE_PORTAL_ERROR = 'CREATE_PORTAL_ERROR';
exports.CREATE_PORTAL_ERROR = CREATE_PORTAL_ERROR;

var createPortal = function createPortal(portal) {
  return {
    type: CREATE_PORTAL_REQUEST,
    headers: {
      version: 'v2'
    },
    body: {
      portal: portal
    }
  };
};

exports.createPortal = createPortal;
var ADMIN_FIND_PORTAL_REQUEST = 'ADMIN_FIND_PORTAL_REQUEST';
exports.ADMIN_FIND_PORTAL_REQUEST = ADMIN_FIND_PORTAL_REQUEST;
var ADMIN_FIND_PORTAL_SUCCESS = 'ADMIN_FIND_PORTAL_SUCCESS';
exports.ADMIN_FIND_PORTAL_SUCCESS = ADMIN_FIND_PORTAL_SUCCESS;
var ADMIN_FIND_PORTAL_ERROR = 'ADMIN_FIND_PORTAL_ERROR';
exports.ADMIN_FIND_PORTAL_ERROR = ADMIN_FIND_PORTAL_ERROR;

var findAdminPortal = function findAdminPortal(dynamicFilters) {
  return {
    type: ADMIN_FIND_PORTAL_REQUEST,
    headers: {
      version: 'v1'
    },
    body: {
      dynamicFilters: dynamicFilters
    }
  };
};

exports.findAdminPortal = findAdminPortal;
var ADMIN_UPDATE_PORTAL_REQUEST = 'ADMIN_UPDATE_PORTAL_REQUEST';
exports.ADMIN_UPDATE_PORTAL_REQUEST = ADMIN_UPDATE_PORTAL_REQUEST;
var ADMIN_UPDATE_PORTAL_SUCCESS = 'ADMIN_UPDATE_PORTAL_SUCCESS';
exports.ADMIN_UPDATE_PORTAL_SUCCESS = ADMIN_UPDATE_PORTAL_SUCCESS;
var ADMIN_UPDATE_PORTAL_ERROR = 'ADMIN_UPDATE_PORTAL_ERROR';
exports.ADMIN_UPDATE_PORTAL_ERROR = ADMIN_UPDATE_PORTAL_ERROR;

var updateAdminPortal = function updateAdminPortal(portal) {
  return {
    type: ADMIN_UPDATE_PORTAL_REQUEST,
    headers: {
      version: 'v2'
    },
    body: {
      portal: portal
    }
  };
};

exports.updateAdminPortal = updateAdminPortal;