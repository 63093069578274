"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _activePortal = require("../activePortal");

var _pois = require("./pois.actions");

/**
 * Created by EHelbing on 16.11.2017.
 */
var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _pois.FIND_POIS_SUCCESS:
      {
        var pois = action.body.pois;
        return pois;
      }

    case _pois.DELETE_POI_ERROR:
    case _pois.CREATE_POI_SUCCESS:
      {
        var poi = action.body.poi;
        var newState = state.slice(); // check if the poi exists in the action

        if (poi) {
          // if the state has no pois yet, we can push the poi without checking for its existence
          // else we check if the poi already exists in the state
          if (newState.length === 0 || !newState.some(function (existingPoi) {
            return existingPoi.properties.id === poi.properties.id;
          })) {
            newState.push(poi);
          }
        }

        return newState;
      }

    case _pois.UPDATE_POI_SUCCESS:
      {
        var _newState = state.slice(); // check if the poi exists in the action


        if (action.body.poi) {
          // check if the poi already exists in the state
          var oldPOI = _newState.find(function (poi) {
            return poi.properties.id === action.body.poi.properties.id;
          });

          if (oldPOI !== undefined) {
            var indexOfPOI = _newState.indexOf(oldPOI);

            _newState[indexOfPOI] = action.body.poi;
          }
        }

        return _newState;
      }

    case _pois.DELETE_POI_SUCCESS:
    case _pois.DELETE_POI_REQUEST:
      {
        // get the poi id
        var poiId = action.body.poi.properties.id; // remove the deleted poi

        return state.filter(function (poi) {
          return poi.properties.id !== poiId;
        });
      }

    case _activePortal.CHANGE_ACTIVE_PORTAL:
      return [];

    default:
      return state;
  }
};

exports["default"] = _default;