"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FIND_PERMISSION_PACKAGES_ERROR", {
  enumerable: true,
  get: function get() {
    return _permissionAdministration.FIND_PERMISSION_PACKAGES_ERROR;
  }
});
Object.defineProperty(exports, "FIND_PERMISSION_PACKAGES_REQUEST", {
  enumerable: true,
  get: function get() {
    return _permissionAdministration.FIND_PERMISSION_PACKAGES_REQUEST;
  }
});
Object.defineProperty(exports, "FIND_PERMISSION_PACKAGES_SUCCESS", {
  enumerable: true,
  get: function get() {
    return _permissionAdministration.FIND_PERMISSION_PACKAGES_SUCCESS;
  }
});
exports["default"] = void 0;
Object.defineProperty(exports, "findPermissionPackagesByFilter", {
  enumerable: true,
  get: function get() {
    return _permissionAdministration.findPermissionPackagesByFilter;
  }
});
exports.permissionAdministrationReducer = void 0;

var _admintoolPackages = _interopRequireDefault(require("./admintool-packages.reducer"));

var _permissionAdministration = require("./permission-administration.actions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var permissionAdministrationReducer = {
  adminTool: {
    permissionPackages: _admintoolPackages["default"]
  }
};
exports.permissionAdministrationReducer = permissionAdministrationReducer;
var _default = permissionAdministrationReducer;
exports["default"] = _default;