"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _core = _interopRequireDefault(require("mathjs/core"));

var _add = _interopRequireDefault(require("mathjs/lib/function/arithmetic/add"));

var _divide = _interopRequireDefault(require("mathjs/lib/function/arithmetic/divide"));

var _multiply = _interopRequireDefault(require("mathjs/lib/function/arithmetic/multiply"));

var _round = _interopRequireDefault(require("mathjs/lib/function/arithmetic/round"));

var _subtract = _interopRequireDefault(require("mathjs/lib/function/arithmetic/subtract"));

var _format = _interopRequireDefault(require("mathjs/lib/function/string/format"));

var _unit = _interopRequireDefault(require("mathjs/lib/type/unit"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var math = _core["default"].create();

math["import"](_unit["default"]);
math["import"](_add["default"]);
math["import"](_subtract["default"]);
math["import"](_multiply["default"]);
math["import"](_divide["default"]);
math["import"](_format["default"]);
math["import"](_round["default"]); // new units

math.createUnit('mph', '1 mile/hour');
math.createUnit('kmh', '1 km/hour');
var _default = math;
exports["default"] = _default;