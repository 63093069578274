"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = translationReducer;

var _user = require("../user/user.actions");

var _actions = require("./actions");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var initialState = {
  locale: 'en-GB',
  messages: {
    en: {}
  }
};

var addTranslationsToState = function addTranslationsToState(state) {
  var translations = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var language = arguments.length > 2 ? arguments[2] : undefined;
  return Object.assign({}, state, {
    messages: Object.assign({}, state.messages, _defineProperty({}, language, Object.assign({}, state.messages[language], translations)))
  });
};

function translationReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _user.FIND_USER_PROFILE_SUCCESS:
    case _user.UPDATE_USER_PROFILE_REQUEST:
    case _user.UPDATE_USER_PROFILE_SUCCESS:
      {
        var user = action.body.user;
        return Object.assign({}, state, {
          locale: user.locale
        });
      }

    case _actions.CHANGE_LOCALE:
      return Object.assign({}, state, {
        locale: action.body.locale
      });
    // this event is used to load translations from backend

    case _actions.FIND_ALL_TRANSLATIONS_SUCCESS:
      {
        var _action$body = action.body,
            translations = _action$body.translations,
            language = _action$body.language;
        return addTranslationsToState(state, translations, language);
      }
    // this event occurs after login (automatically by backend) TODO: replace with find_translations_by_application logic

    case _actions.ADD_TRANSLATIONS_SUCCESS:
      {
        var _action$body2 = action.body,
            _translations = _action$body2.translations,
            languageKey = _action$body2.languageKey;
        return addTranslationsToState(state, _translations, languageKey);
      }

    default:
      return state;
  }
}