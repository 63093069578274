/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import loadable from 'react-loadable';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NicplaceLogo, PowerOff, User } from 'nicbase-icons';
import { authorize } from 'nicbase-security';
import { injectIntl, intlShape } from 'nicbase-translation';

import HelpButton from '../help-button';

import CommandCenterLinkComponent from './data-control-center-link';
import wieltonLogo from './logo-wielton.svg';

import '../nav-bar.scss';

const ImpersonationInfo = authorize('ADMIN')(['module.portal.*'])(
  loadable({
    loader: () => import('nicbase-portal-administration'),
    loading: () => null,
    render(loaded, props) {
      const { ImpersonationInfo: Info } = loaded;
      return <Info {...props} />;
    },
  })
);

const CancelImpersonationButton = authorize('ADMIN')(['module.portal.*'])(
  loadable({
    loader: () => import('nicbase-portal-administration'),
    loading: () => null,
    render(loaded, props) {
      const { CancelImpersonationButton: Cancel } = loaded;
      return <Cancel {...props} />;
    },
  })
);

// TODO: remove wielton logo after IAA
export const NavBarTopDesktop = ({ intl, showWielton, logo }) => (
  <div className="nav-bar-top nav-bar nav-bar-top-desktop">
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <NicplaceLogo className="h-5 w-auto" />
    </div>
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      {showWielton ? <img src={wieltonLogo} alt="logo wielton" style={{ height: '40px' }} /> : null}
      {logo}
    </div>
    <div className="nav-right">
      <ImpersonationInfo /> <CancelImpersonationButton />
      <CommandCenterLinkComponent />
      <Link to="/profile" data-for="tool-tip-bottom" data-tip={intl.formatMessage({ id: 'navigation.profile' })}>
        <User className="icon" />
      </Link>
      <HelpButton />
      <Link to="/logout" data-for="tool-tip-bottom" data-tip={intl.formatMessage({ id: 'navigation.logout' })}>
        <PowerOff className="icon" />
      </Link>
    </div>
  </div>
);

NavBarTopDesktop.propTypes = {
  intl: intlShape.isRequired,
  showWielton: PropTypes.bool,
  logo: PropTypes.node,
};
NavBarTopDesktop.defaultProps = {
  showWielton: false,
  logo: null,
};

export default injectIntl(NavBarTopDesktop);
